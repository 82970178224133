import { useState } from 'react';
import { 
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme
} from '@mui/material';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TimelineIcon from '@mui/icons-material/Timeline';

const MOCK_ANALYSES = [
  {
    analysis: {
      sentiment: {
        title: 'Market Sentiment',
        points: [
          'Currently showing bullish momentum',
          'Social media engagement increasing',
          'Community sentiment strongly positive'
        ]
      },
      opportunities: {
        title: 'Trading Opportunities',
        points: [
          'Support level established at current price',
          'Volume trending upward',
          'Multiple buy signals detected'
        ]
      },
      risk: {
        title: 'Risk Assessment',
        points: [
          'Market volatility: Moderate',
          'Liquidity: Sufficient',
          'Technical indicators: Favorable'
        ]
      },
      actions: {
        title: 'Recommended Actions',
        points: [
          'Consider accumulating at current levels',
          'Set stop loss at -5% from entry',
          'Monitor volume for confirmation of trend'
        ]
      }
    },
    timestamp: new Date()
  },
  // ... second mock analysis with similar structure
];

const MOCK_ACTIONS = [
  {
    description: "Detected bullish pattern forming",
    timestamp: new Date(Date.now() - 1000 * 60 * 5),
    type: 'analysis'
  },
  {
    description: "Volume spike detected above 200MA",
    timestamp: new Date(Date.now() - 1000 * 60 * 15),
    type: 'alert'
  },
  {
    description: "Support level established at current price",
    timestamp: new Date(Date.now() - 1000 * 60 * 30),
    type: 'trade'
  }
];

const AgentIntelligence = ({ agent }) => {
  const theme = useTheme();
  const [analysis, setAnalysis] = useState(null);
  const [analysisInProgress, setAnalysisInProgress] = useState(false);
  const [analysisIndex, setAnalysisIndex] = useState(0);

  const generateMarketAnalysis = async () => {
    setAnalysisInProgress(true);
    await new Promise(resolve => setTimeout(resolve, 1500));
    setAnalysis(MOCK_ANALYSES[analysisIndex].analysis);
    setAnalysisIndex((analysisIndex + 1) % MOCK_ANALYSES.length);
    setAnalysisInProgress(false);
  };

  return (
    <Card
      elevation={3}
      sx={{
        background: theme.palette.mode === 'dark' 
          ? 'linear-gradient(135deg, rgba(25, 118, 210, 0.2) 0%, rgba(13, 71, 161, 0.2) 100%)'
          : 'linear-gradient(135deg, #bbdefb 0%, #90caf9 100%)',
        borderRadius: 2,
        overflow: 'hidden',
        border: `1px solid ${theme.palette.divider}`
      }}
    >
      <CardHeader
        title={
          <Box display="flex" alignItems="center" gap={1}>
            <PsychologyIcon />
            <Typography variant="h6">
              Agent Intelligence Hub
            </Typography>
          </Box>
        }
        action={
          <Button
            variant="contained"
            onClick={generateMarketAnalysis}
            disabled={analysisInProgress}
            startIcon={analysisInProgress ? <CircularProgress size={20} /> : <TimelineIcon />}
            sx={{
              background: theme.palette.success.main,
              '&:hover': {
                background: theme.palette.success.dark
              }
            }}
          >
            Generate Analysis
          </Button>
        }
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          background: theme.palette.background.paper,
          opacity: 0.9
        }}
      />

      <CardContent>
        <Alert 
          severity="info" 
          sx={{ mb: 2 }}
          variant="outlined"
        >
          AI Agent is analyzing market conditions and providing insights
        </Alert>

        {analysis && (
          <Box sx={{ mb: 3 }}>
            {Object.entries(analysis).map(([key, section]) => (
              <Paper
                key={key}
                elevation={2}
                sx={{
                  p: 2,
                  mb: 2,
                  background: theme.palette.background.paper,
                  opacity: 0.9
                }}
              >
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  {section.title}
                </Typography>
                <List dense>
                  {section.points.map((point, index) => (
                    <ListItem key={index}>
                      <ListItemText 
                        primary={point}
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: theme.palette.text.secondary
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            ))}
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" color="primary" gutterBottom>
          Recent Actions
        </Typography>
        <List>
          {MOCK_ACTIONS.map((action, index) => (
            <ListItem
              key={index}
              sx={{
                mb: 1,
                background: theme.palette.background.paper,
                borderRadius: 1,
                opacity: 0.9
              }}
            >
              <ListItemText
                primary={action.description}
                secondary={action.timestamp.toLocaleString()}
                primaryTypographyProps={{
                  variant: 'body2',
                  color: theme.palette.text.primary
                }}
                secondaryTypographyProps={{
                  variant: 'caption',
                  color: theme.palette.text.secondary
                }}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default AgentIntelligence;