import React, { useState } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  Button, 
  IconButton, 
  Typography, 
  useMediaQuery, 
  useTheme 
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ApeBuddyChat from '../ApeBuddyChat';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'linear-gradient(135deg, rgba(197, 158, 87, 0.1), rgba(50, 46, 41, 0.9))',
    backdropFilter: 'blur(20px)',
    border: '2px solid rgba(254, 255, 235, 0.2)',
    borderRadius: theme.spacing(2),
    maxWidth: '800px',
    width: '95%',
    margin: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-50%',
      left: '-50%',
      width: '200%',
      height: '200%',
      background: 'radial-gradient(circle, rgba(254, 255, 235, 0.1) 0%, transparent 70%)',
      zIndex: 0,
      pointerEvents: 'none',
    }
  }
}));

const ApeBuddyModalWrapper = ({
  tradingWallet,
  handleBuy,
  handleSell,
  connectedWalletBalance,
  bnnaBalance,
  bnnaConnectedBalance
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{
          background: 'linear-gradient(to right, #fbbf24, #f97316)',
          '&:hover': {
            background: 'linear-gradient(to right, #f97316, #ea580c)',
          },
          '&:disabled': {
            background: 'linear-gradient(to right, #9ca3af, #6b7280)',
            opacity: 0.6,
            cursor: 'not-allowed',
          },
          textTransform: 'none',
          color: 'white',
          fontWeight: 600,
          padding: '8px 16px',
          borderRadius: '8px',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
          margin: '10px 0'
        }}
      >
        Open Ape Buddy
      </Button>

      <StyledDialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
      >
        <DialogTitle 
          sx={{ 
            color: '#FEFFEB', 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            fontFamily: "'Press Start 2P', cursive",
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
            borderBottom: '1px dashed rgba(197, 158, 87, 0.3)',
            position: 'relative',
            zIndex: 1,
            backgroundColor: 'rgba(66, 61, 54, 0.3)',
            p: { xs: 2, sm: 3 }
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#C59E57',
              fontFamily: "'Press Start 2P', cursive"
            }}
          >
            Ape Buddy
          </Typography>
          <IconButton 
            onClick={handleClose} 
            sx={{ 
              color: '#C59E57',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'rotate(90deg)',
                color: '#FEFFEB'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent 
          sx={{ 
            p: { xs: 2, sm: 3 },
            position: 'relative',
            zIndex: 1,
            backgroundColor: 'rgba(66, 61, 54, 0.3)',
            minHeight: fullScreen ? 'calc(100vh - 120px)' : 'auto'
          }}
        >
          <ApeBuddyChat
            tradingWallet={tradingWallet}
            handleBuy={handleBuy}
            handleSell={handleSell}
            connectedWalletBalance={connectedWalletBalance}
            bnnaBalance={bnnaBalance}
            bnnaConnectedBalance={bnnaConnectedBalance}
          />
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default ApeBuddyModalWrapper;