import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, IconButton, Tabs, Tab, Box, Typography, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import React from 'react';

const TelegramConfigModal = ({ open, onClose, agent, onConnect }) => {
    const [botToken, setBotToken] = useState('');
    const [chatId, setChatId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
  
    const handleConnect = async () => {
      try {
        setIsSubmitting(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/ai-agents/${agent._id}/telegram/connect`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ botToken, chatId })
          }
        );
  
        const data = await response.json();
        if (data.success) {
          toast.success('Telegram bot connected successfully');
          onConnect(data.telegram);
          onClose();
        } else {
          toast.error(data.error || 'Failed to connect Telegram bot');
        }
      } catch (error) {
        console.error('Error connecting Telegram:', error);
        toast.error('Failed to connect Telegram bot');
      } finally {
        setIsSubmitting(false);
      }
    };
  
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            bgcolor: 'background.paper'
          }
        }}
      >
        <DialogTitle>
          Configure Telegram Bot
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            sx={{ mb: 3 }}
          >
            <Tab label="Manual Setup" />
            <Tab label="How to Setup" />
          </Tabs>
  
          {activeTab === 0 ? (
            <Box sx={{ pt: 2 }}>
              <TextField
                fullWidth
                label="Bot Token"
                value={botToken}
                onChange={(e) => setBotToken(e.target.value)}
                placeholder="Enter your Telegram bot token"
                margin="normal"
                helperText="Get this from @BotFather on Telegram"
              />
              <TextField
                fullWidth
                label="Chat ID"
                value={chatId}
                onChange={(e) => setChatId(e.target.value)}
                placeholder="Enter the chat ID where bot should post"
                margin="normal"
                helperText="This is where your bot will post messages"
              />
              <Button
                fullWidth
                variant="contained"
                onClick={handleConnect}
                disabled={!botToken || !chatId || isSubmitting}
                sx={{ mt: 3 }}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} sx={{ color: 'white' }} />
                ) : (
                  'Connect Telegram Bot'
                )}
              </Button>
            </Box>
          ) : (
            <Box sx={{ pt: 2 }}>
              <Typography variant="h6" gutterBottom>
                How to Set Up Your Telegram Bot
              </Typography>
              <Typography variant="body1" component="div">
                <ol style={{ paddingLeft: '20px' }}>
                  <li>Open Telegram and search for @BotFather</li>
                  <li>Start a chat and send /newbot command</li>
                  <li>Follow the instructions to create a new bot</li>
                  <li>Copy the bot token provided by BotFather</li>
                  <li>Start a chat with your bot or add it to a group</li>
                  <li>Send a message in the chat where you want the bot to post</li>
                  <li>Visit https://api.telegram.org/bot[YOUR_BOT_TOKEN]/getUpdates</li>
                  <li>Find the chat_id in the response and copy it</li>
                  <li>Use these details in the Manual Setup tab</li>
                </ol>
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default TelegramConfigModal;