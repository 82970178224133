import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { toast } from 'react-toastify';
import DipRipStrategy from './strategies/analyzeDipRip';
import { useRef } from 'react';
import TelegramConfigModal from './TelegramConfigModal';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Modal,
  Tabs,
  Tab,
  Tooltip,
  ImageList,
  ImageListItem,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
  Style as StyleIcon,

  Image as ImageIcon,
  Stars as StarsIcon,
} from '@mui/icons-material';
import WalletClusterAnalysis from './WalletClusterAnalysis';
import { BarChart as ChartIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import AgentWalletManagement from './AgentWalletManagement';
import { Alert } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import LockIcon from '@mui/icons-material/Lock'; // Add this import at the top
import StrategyDialog from './strategies/StrategyDialog';
import TrainingModal from '../../components/TrainingModal';



const CustomizeModal = ({ open, onClose, agent }) => {
  const [tabValue, setTabValue] = useState(0);
const [userItems, setUserItems] = useState({
  collectibles: [],
  badges: [],
  botFeatures: [],
  tradingTools: []
});
const [selectedBadges, setSelectedBadges] = useState([]);
const [selectedStickers, setSelectedStickers] = useState([]);
const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  const fetchUserItems = async () => {
    if (!agent?.userId) return;
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${agent.userId}/items`);
      const data = await response.json();
      
      if (data.success) {
        setUserItems(data.items);
      }
    } catch (error) {
      console.error('Error fetching user items:', error);
    } finally {
      setIsLoading(false);
    }
  };

  fetchUserItems();
}, [agent?.userId]);

  const mockBadges = [
    { id: 1, name: 'Pro Trader', icon: '🏆', color: '#FFD700' },
    { id: 2, name: 'Risk Manager', icon: '🛡️', color: '#4CAF50' },
    { id: 3, name: 'Trend Spotter', icon: '📈', color: '#2196F3' },
    { id: 4, name: 'Volume Master', icon: '📊', color: '#9C27B0' },
    { id: 5, name: 'Whale Watcher', icon: '🐋', color: '#3F51B5' },
  ];

  const mockStickers = [
    { id: 1, url: 'https://your-cdn.com/sticker1.png', name: 'Cool Ape' },
    { id: 2, url: 'https://your-cdn.com/sticker2.png', name: 'Diamond Hands' },
    // Add more stickers
  ];

  const mockThemes = [
    { id: 1, name: 'Neon', preview: '🌈', gradient: 'linear-gradient(45deg, #ff6b6b, #4ecdc4)' },
    { id: 2, name: 'Dark', preview: '🌑', gradient: 'linear-gradient(45deg, #2c3e50, #3498db)' },
    { id: 3, name: 'Light', preview: '☀️', gradient: 'linear-gradient(45deg, #f1f2f6, #dfe4ea)' },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          width: '80%',
          maxWidth: 800,
          maxHeight: '90vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5">Customize Your Bot</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Tabs
  value={tabValue}
  onChange={(e, newValue) => setTabValue(newValue)}
  sx={{ mb: 3 }}
>
  <Tab icon={<StarsIcon />} label="Badges" />
  <Tab icon={<ImageIcon />} label="Theme" />
  <Tab icon={<StyleIcon />} label="My Items" /> {/* New tab */}
</Tabs>
{tabValue === 2 && (
  <Box sx={{ mt: 2 }}>
    {isLoading ? (
      <CircularProgress />
    ) : (
      <>
        {/* Collectibles Section */}
        {userItems.collectibles.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Collectibles</Typography>
            <Grid container spacing={2}>
              {userItems.collectibles.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Card 
                    sx={{
                      cursor: 'pointer',
                      border: '2px solid transparent',
                      '&:hover': {
                        border: '2px solid #2196f3',
                        transform: 'translateY(-4px)',
                        transition: 'all 0.3s'
                      }
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={item.imageUrl}
                      alt={item.name}
                    />
                    <CardContent>
                      <Typography variant="h6">{item.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.description}
                      </Typography>
                      <Chip 
                        label={item.rarity} 
                        size="small" 
                        sx={{ mt: 1 }}
                        color={
                          item.rarity === 'LEGENDARY' ? 'error' :
                          item.rarity === 'RARE' ? 'warning' :
                          item.rarity === 'UNCOMMON' ? 'info' : 'default'
                        }
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Badges Section */}
        {userItems.badges.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Badges</Typography>
            <Grid container spacing={2}>
              {userItems.badges.map((badge) => (
                <Grid item xs={12} sm={6} md={4} key={badge.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{badge.name}</Typography>
                      <Typography variant="body2">{badge.description}</Typography>
                      {badge.attributes.features.map((feature, index) => (
                        <Chip 
                          key={index} 
                          label={feature} 
                          size="small" 
                          sx={{ m: 0.5 }}
                        />
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Trading Tools Section */}
        {userItems.tradingTools.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Trading Tools</Typography>
            <Grid container spacing={2}>
              {userItems.tradingTools.map((tool) => (
                <Grid item xs={12} sm={6} md={4} key={tool.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{tool.name}</Typography>
                      <Typography variant="body2">{tool.description}</Typography>
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="caption">
                          Boost: +{tool.attributes.boost}%
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </>
    )}
  </Box>
)}

        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          sx={{ mb: 3 }}
        >
          <Tab icon={<StarsIcon />} label="Badges" />
          {/* <Tab icon={<EmojiIcon />} label="Stickers" /> */}
          <Tab icon={<ImageIcon />} label="Theme" />
        </Tabs>

        {tabValue === 0 && (
          <Grid container spacing={2}>
            {mockBadges.map((badge) => (
              <Grid item key={badge.id}>
                <Tooltip title={badge.name}>
                  <Chip
                    icon={<span>{badge.icon}</span>}
                    label={badge.name}
                    onClick={() => {
                      const isSelected = selectedBadges.find(b => b.id === badge.id);
                      if (isSelected) {
                        setSelectedBadges(selectedBadges.filter(b => b.id !== badge.id));
                      } else {
                        setSelectedBadges([...selectedBadges, badge]);
                      }
                    }}
                    sx={{
                      bgcolor: selectedBadges.find(b => b.id === badge.id) ? badge.color : 'transparent',
                      color: selectedBadges.find(b => b.id === badge.id) ? 'white' : 'inherit',
                      border: `1px solid ${badge.color}`,
                      '&:hover': {
                        bgcolor: `${badge.color}88`
                      }
                    }}
                  />
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        )}

        {tabValue === 1 && (
          <ImageList cols={3} gap={8}>
            {mockStickers.map((sticker) => (
              <ImageListItem
                key={sticker.id}
                sx={{
                  cursor: 'pointer',
                  border: selectedStickers.find(s => s.id === sticker.id)
                    ? '2px solid #2196F3'
                    : '2px solid transparent',
                  borderRadius: 1,
                  '&:hover': {
                    transform: 'scale(1.05)',
                    transition: 'transform 0.2s'
                  }
                }}
                onClick={() => {
                  const isSelected = selectedStickers.find(s => s.id === sticker.id);
                  if (isSelected) {
                    setSelectedStickers(selectedStickers.filter(s => s.id !== sticker.id));
                  } else {
                    setSelectedStickers([...selectedStickers, sticker]);
                  }
                }}
              >
                <img
                  src={sticker.url}
                  alt={sticker.name}
                  loading="lazy"
                  style={{ borderRadius: 8 }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}

        {tabValue === 2 && (
          <Grid container spacing={2}>
            {mockThemes.map((theme) => (
              <Grid item xs={4} key={theme.id}>
                <Box
                  sx={{
                    height: 100,
                    borderRadius: 2,
                    background: theme.gradient,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      transition: 'transform 0.2s'
                    }
                  }}
                >
                  <Typography variant="h4">{theme.preview}</Typography>
                </Box>
                <Typography align="center" sx={{ mt: 1 }}>
                  {theme.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              // Save customizations
              onClose();
            }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
const AgentsManagement = ({ activeTab,
  setActiveTab,
  handleBuy,
  handleSell }) => {
  const { publicKey, signMessage, disconnect, connected } = useWallet();

  const [promoCode, setPromoCode] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [bnnaBalance, setBnnaBalance] = useState(0);
  const [stakingBalance, setStakingBalance] = useState(0);
  const [tradingPairInputs, setTradingPairInputs] = useState({});
  const [strategyEnabled, setStrategyEnabled] = useState(false);
  const [showClusterAnalysis, setShowClusterAnalysis] = useState(false);
  const [clusterData, setClusterData] = useState(null);
  const [showTokensModal, setShowTokensModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showTokenStats, setShowTokenStats] = useState(false);
  const [isConnectingTwitter, setIsConnectingTwitter] = useState(false);
  const [isConnectingTelegram, setIsConnectingTelegram] = useState(false);
  const [isSignatureVerified, setIsSignatureVerified] = useState(false);
  const [hasExistingWallet, setHasExistingWallet] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const previousPublicKey = useRef(null);
  const [hasCheckedWallet, setHasCheckedWallet] = useState(false);
  const [error, setError] = useState(null);
  const [userTier, setUserTier] = useState('basic');
  const [tierFeatures, setTierFeatures] = useState({
    canLaunchTokens: false,
    canCreateAgents: false,
    canUseAdvancedFeatures: false
  });
  const [customizeModalOpen, setCustomizeModalOpen] = useState(false);
  const STAKERS = [
    {
      Transaction: "2eVj9x4tE6aiZHZudK3TsVTLFsBmE3E4NMt8bR1Gy1hiFcMVj2ifvFAnqUMRYrQ1iMuqHbcJXJYRsfHrS22h9foX",
      Staker: "9yrBCWFrDV2NwnDJamzWmM4A1eQdwYtj1ka46x6JH1J",
      Amount: 20000040.074184
    },
    {
      Transaction: "46zqYjM3ZXEmkCydFpqny73WF5pWKRGoaPXPaDv3Htx6nyLZDc6EKaQCvMoJdN1VktiN4vuPp49HCcb2MkKZGhPT",
      Staker: "8SctyPG92GvaTpZWWoLgtNzWTWUTGTpGBgQnLfwkJ193",
  Amount: 543741.109198
    },
    {
      Transaction: "228YziKZdtrPLhxeVchSHBuQgvEAaNhCLRN7P7h9c5nRQBwVCmiva3U1ooo84kGbJm8v6vMv1zqythnvTVZmxdWG",
      Staker: "AcWtiHkwUj2eSj69VquUDwa4XkA8LuVZo4jFuxGSLuwm",
  Amount: 8140.432964 
    },
    {
      Transaction: "4A6F34ntNAF1HiN2w4fbRf5t3f63cnjb2NwGZGYLpQBYDVtKJbehcrLL6wDx3sxV2S5RFpSYKr6NNMJZVYeb71zm",
      Staker: "3VoZ5YNTvRfEA4uHSKLePBnSDjLUHzPtZ5ERcTfiCfFd",
  Amount:543741.109198 
    },
    {
      Transaction: "5i8t4HmGkUjgF1d9pBv1cx3d6fHfJWEeNn6Pg542VfTjETAoDiF7z2ytuwJdT4adN9JPwH2AXPMu3zz4HidWmKvF",
      Staker: "BSS2ntHsrbQQgaGcTx1CeuemPD8JnaKkJL8bGRmkyTR4",
  Amount:3163928.070157 
    },
    {
      Transaction: "4kN5fJh47bdJKLcEE3UQQ3sWD2CXkGjNQ3AbLFWGTNrs56U1BLM7VsWuByZ9sLh4GJezes3RKY2XgDJrJm7xtbtC",
      Staker: "6PMGQuFavXiHyui2v8FXaKCrfxPTwQDpDPcbCgPuY78R",
  Amount: 372164.867106
    },
    {
      Transaction: "3o91heVipLtbPaMArtabWJ8wbGxUd8vjNXNwqkDmxCNPMsihT9F1iSVShCNdXfaycFMJB1As9sdFmzii13gaxZzG",
      Staker: "9yrBCWFrDV2NwnDJamzWmM4A1eQdwYtj1ka46x6JH1J",
  Amount: 14236600.0
    },
    {
      Transaction: "2dXbX3SzJTE9ckfrraA7kmFMiScg7RZKeFgri8hqBXbwQM8atQrfprresxium9oeKWxBrSCNZ5n9SYgfvQk4UAn6",
      Staker: "HR6Ww6E5isrXYNqYTNh3KwsSUQaqMVHnFsSQWN9s1PJK",
  Amount: 10000000.0
    },
    {
      Transaction: "2ka7Q1W2zdexTUySo1Xw8CS5TuYPk1bqWo4TWJBgmAMkmsUhmLYXi8zWNirJfmg1sJQJUUQzCQ36jGdLuKicf4wX",
      Staker: "6PMGQuFavXiHyui2v8FXaKCrfxPTwQDpDPcbCgPuY78R",
  Amount: 4129638.0
    },
    {
      Transaction: "V3pDFDUQU68kyKEFp7ccvpoiuE4oqwPrZJt7hVwnRWbV1qq3NH5EK3URJB3oNZTbjzyfoUhUDtQoGwDNA18v74J",
      Staker: "Cfmn1fRqd87G8Fcx3GFnQLjUqAk8C4g3ddHcWdxEhjoV",
  Amount: 10000000.0
    },
    {
      Transaction: "V3pDFDUQU68kyKEFp7ccvpoiuE4oqwPrZJt7hVwnRWbV1qq3NH5EK3URJB3oNZTbjzyfoUhUDtQoGwDNA18v74J",
      Staker: "9oMoQZf7WHz7RjP9nmQ9y894ReNvSnphLMGBKLWwwZaC",
  Amount: 10000000.0
    },
  ];
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [selectedAgentForTraining, setSelectedAgentForTraining] = useState(null);
  const [showStrategyModal, setShowStrategyModal] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [telegramModalOpen, setTelegramModalOpen] = useState(false);
  const [selectedAgentForTelegram, setSelectedAgentForTelegram] = useState(null);


  // Add this method to check balance and set tier
  const checkTierEligibility = useCallback(() => {
    // Define tier thresholds
    const TIER_THRESHOLDS = {
      elite: 10000000,  // 100k BNNA
      pro: 1000000,     // 10k BNNA
      basic: 0
    };

    // Determine tier based on balance
    let newTier = 'basic';
    let features = {
      canLaunchTokens: false,
      canCreateAgents: false,
      canUseAdvancedFeatures: false
    };

    if (stakingBalance >= TIER_THRESHOLDS.elite) {
      newTier = 'elite';
      features = {
        canLaunchTokens: true,
        canCreateAgents: true,
        canUseAdvancedFeatures: true
      };
    } else if (bnnaBalance >= TIER_THRESHOLDS.pro || stakingBalance >= TIER_THRESHOLDS.pro) {
      newTier = 'pro';
      features = {
        canLaunchTokens: true,
        canCreateAgents: true,
        canUseAdvancedFeatures: false
      };
    } else if (bnnaBalance || stakingBalance > 0) {
      newTier = 'basic';
      features = {
        canLaunchTokens: false,
        canCreateAgents: true,
        canUseAdvancedFeatures: false
      };
    }

    setUserTier(newTier);
    setTierFeatures(features);

    // Log tier update for debugging
    console.log('User tier updated:', {
      balance: bnnaBalance,
      tier: newTier,
      features
    });

    return features;
  }, [bnnaBalance]);
  const handleTelegramConnect = (telegramConfig) => {
    // Update the agent's telegram configuration in the local state
    setAgents(agents.map(agent => 
      agent._id === selectedAgentForTelegram._id 
        ? { ...agent, telegram: telegramConfig }
        : agent
    ));
  };

  // Add useEffect to run the check whenever balance changes
  useEffect(() => {
    checkTierEligibility();
  }, [bnnaBalance, stakingBalance, checkTierEligibility]);



  const handleConnection = async () => {
    console.debug('Handling wallet connection:', {
      connected,
      publicKey: publicKey?.toString(),
      isSignatureVerified,
      hasExistingWallet
    });

    if (!connected || !publicKey) {
      console.debug('Wallet disconnected or no public key, clearing state');
      clearWalletState();
      return;
    }

    const wasVerified = localStorage.getItem('signatureVerified');

    if (wasVerified === 'true') {
      console.debug('Found previous signature verification');
      setIsSignatureVerified(true);
    }

    if (!isSignatureVerified && !wasVerified) {
      console.debug('Requesting signature verification');
      const { verified, userRejected } = await requestSignature();

      if (userRejected || !verified) {
        console.debug('Signature verification failed or rejected');
        clearWalletState();
        return;
      }
      localStorage.setItem('signatureVerified', 'true');
      setIsSignatureVerified(true);
    }

    console.debug('Signature verified, fetching agent data');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/users/${publicKey.toString()}`
      );

      if (!response.ok) {
        if (response.status === 404) {
          console.debug('No existing user found');
          setHasExistingWallet(false);
          setError('No existing user found. Please create an account first.');
          return;
        }
        throw new Error(`Failed to fetch user data: ${response.status}`);
      }

      const userData = await response.json();
      setUserDetails(userData);
      console.debug('Received user data:', userData);

      // Update all relevant state from user data
      setHasExistingWallet(true);
      previousPublicKey.current = publicKey.toString();
      setHasCheckedWallet(true);
      localStorage.setItem('walletConnected', 'true');

      // After verification succeeds, fetch agents
      await fetchAgents();

    } catch (error) {
      console.error('User data fetch error:', error);
      setError(error.message || 'Failed to complete wallet setup');
      clearWalletState();
      toast.error('Failed to complete wallet setup');
    }
  };
  const checkStakingBalance = useCallback(() => {
    if (!publicKey) return;
    
    const stakerInfo = STAKERS.find(
      staker => staker.Staker.toLowerCase() === publicKey.toString().toLowerCase()
    );
    
    setStakingBalance(stakerInfo ? stakerInfo.Amount : 0);
  }, [publicKey]);

  const clearWalletState = () => {
    setIsSignatureVerified(false);
    setHasExistingWallet(false);
    setUserDetails(null);
    setError(null);
    localStorage.removeItem('signatureVerified');
    localStorage.removeItem('walletConnected');
    previousPublicKey.current = null;
    setHasCheckedWallet(false);
  };

  const requestSignature = async () => {
    if (!publicKey || !signMessage) {
      console.error('Wallet not properly connected');
      throw new Error('Wallet not properly configured');
    }

    const message = new TextEncoder().encode(
      'Please sign this message to verify your wallet ownership and complete the connection process.'
    );

    try {
      const signature = await signMessage(message);
      const signatureUint8 = new Uint8Array(signature);
      const isValid = publicKey.toBytes().length === 32 && signatureUint8.length === 64;

      if (!isValid) {
        console.error('Invalid signature format');
        await disconnect();
        clearWalletState();
        throw new Error('Invalid signature format');
      }

      setIsSignatureVerified(true);
      return { signature, verified: true };
    } catch (error) {
      if (error.message?.includes('User rejected')) {
        toast.error('You must sign the message to complete the connection');
        await disconnect();
        clearWalletState();
        return { signature: null, verified: false, userRejected: true };
      }

      toast.error('Failed to verify wallet ownership');
      await disconnect();
      clearWalletState();
      return { signature: null, verified: false, error };
    }
  };

  const fetchWalletClusters = async () => {
    console.log('Fetching wallet clusters...');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/wallet-clusters/${publicKey.toString()}`
      );
      const data = await response.json();

      if (data.success) {
        console.log('Successfully fetched wallet clusters:', data);
        setClusterData(data.clusters);
      } else {
        console.error('Failed to fetch wallet clusters:', data.error);
        toast.error('Failed to fetch wallet clusters');
      }
    } catch (error) {
      console.error('Error in fetchWalletClusters:', error);
      toast.error('Error fetching wallet clusters');
    }
  };


  useEffect(() => {
    if (publicKey && isSignatureVerified) {
      fetchAgents();
      fetchBnnaBalance();
      checkStakingBalance(); // Add this line
    } else {
      setIsLoading(false);
    }
  }, [publicKey, isSignatureVerified]);

  useEffect(() => {
    const checkWalletConnection = async () => {
      if (connected && publicKey) {
        const wasVerified = localStorage.getItem('signatureVerified');
        if (!wasVerified) {
          await handleConnection();
        } else {
          setIsSignatureVerified(true);
          // Fetch data after verification
          await fetchAgents();
          await fetchBnnaBalance();
        }
      } else {
        clearWalletState();
      }
    };

    checkWalletConnection();
  }, [connected, publicKey]); // Remove isSignatureVerified from dependencies

  const fetchBnnaBalance = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/user_balances/${publicKey.toString()}`
      );
      const data = await response.json();

      if (data.success) {
        const bnnaToken = data.balances.find(
          token => token.mint === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
        );
        setBnnaBalance(bnnaToken?.balance || 0);

      }
    } catch (error) {
      console.error('Error fetching BNNA balance:', error);
    }
  };
  const handleDeleteAgent = async (agentId) => {
    if (!window.confirm('Are you sure you want to delete this agent?')) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${agentId}?userId=${publicKey.toString()}`,
        {
          method: 'DELETE'
        }
      );

      const data = await response.json();

      if (data.success) {
        setAgents(agents.filter(agent => agent._id !== agentId));
        toast.success('Agent deleted successfully');
      } else {
        toast.error('Failed to delete agent');
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
      toast.error('Failed to delete agent');
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${publicKey.toString()}`
      );
      const data = await response.json();

      if (data.success) {
        setAgents(data.agents);
      } else {
        toast.error('Failed to fetch agents');
        // Set empty array instead of mock data in production
        setAgents([]);
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
      toast.error('Failed to fetch agents');
      setAgents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePromoSubmit = async (e) => {
    e.preventDefault();
    if (!promoCode.trim()) {
      toast.error('Please enter a promo code');
      return;
    }

    toast.info("Applying promo code...");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/promo-codes/redeem`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            code: promoCode,
            walletAddress: publicKey.toString()
          })
        }
      );

      const data = await response.json();

      if (data.success) {
        toast.success('Promo code applied successfully!');
        setPromoCode('');
        fetchAgents(); // Refresh agents to show updated credits
      } else {
        toast.error(data.message || 'Failed to apply promo code');
      }
    } catch (error) {
      console.error('Error applying promo code:', error);
      toast.error('Failed to apply promo code');
    }
  };

  const toggleAgentStatus = async (agentId) => {
    const agent = agents.find(a => a.id === agentId);
    if (!agent) return;

    const newStatus = agent.status === 'active' ? 'inactive' : 'inactive';

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${agentId}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            status: newStatus,
            walletAddress: publicKey.toString()
          })
        }
      );

      const data = await response.json();

      if (data.success) {
        setAgents(agents.map(agent =>
          agent.id === agentId ? { ...agent, status: newStatus } : agent
        ));
        toast.success(`Agent ${newStatus === 'active' ? 'activated' : 'deactivated'}`);
      } else {
        toast.error('Failed to update agent status');
      }
    } catch (error) {
      console.error('Error updating agent status:', error);
      toast.error('Failed to update agent status');
    }
  };



  const toggleStrategy = async (agentId) => {
    try {
      // Find the current agent
      const currentAgent = agents.find(a => a._id === agentId);
      const newStrategyState = !currentAgent.settings?.strategyEnabled;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${agentId}/strategy`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            strategyEnabled: newStrategyState,
            walletAddress: publicKey.toString()
          })
        }
      );

      const data = await response.json();

      if (data.success) {
        setAgents(agents.map(agent =>
          agent._id === agentId
            ? {
              ...agent,
              settings: {
                ...agent.settings,
                strategyEnabled: newStrategyState
              }
            }
            : agent
        ));
        toast.success(`Trading strategy ${newStrategyState ? 'enabled' : 'disabled'}`);

        // Add this to make it clear what's happening
        if (newStrategyState) {
          toast.info('Bot will now monitor price and volume for trading opportunities');
        }
      } else {
        toast.error('Failed to update strategy settings');
      }
    } catch (error) {
      console.error('Error updating strategy:', error);
      toast.error('Failed to update strategy settings');
    }
  };

  const handleTradingPairUpdate = async (agentId, mintAddress) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/ai-agents/${agentId}/trading-pair`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            tradingPairs: [mintAddress],
            walletAddress: publicKey.toString()
          })
        }
      );

      const data = await response.json();

      if (data.success) {
        setAgents(agents.map(agent =>
          agent._id === agentId
            ? {
              ...agent,
              settings: {
                ...agent.settings,
                tradingPairs: [mintAddress]
              }
            }
            : agent
        ));
        toast.success('Trading pair updated successfully');
      } else {
        toast.error('Failed to update trading pair');
      }
    } catch (error) {
      console.error('Error updating trading pair:', error);
      toast.error('Failed to update trading pair');
    }
  };
  const handleCustomizationSave = (customizations) => {
    // Update agent customizations
    setCustomizeModalOpen(false);
  };

  if (!publicKey || !connected) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Connect Your Wallet</Typography>
        <Typography>Please connect your wallet to manage your AI agents.</Typography>
        <WalletMultiButton />
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    );
  }

  if (isLoading) {
    return (
      <div className="agents-management">
        <div className="loading-spinner">Loading your agents...</div>
      </div>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>Your AI Agents</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{
            background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
            color: 'white',
            borderRadius: '16px',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)'
          }}>
            <CardContent>
              <Typography variant="h6">
                Total Credits
                <Chip
                  label="Coming Soon"
                  color="primary"
                  size="small"
                  sx={{ ml: 1, animation: 'pulse 2s infinite' }}
                />
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <CircularProgress size={16} sx={{ mr: 1 }} />
                <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'white' }}>
                  Feature in development
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{
            background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
            color: 'white',
            borderRadius: '16px',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)'
          }}>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="h6">BNNA Balance</Typography>
              <Typography variant="h4">{bnnaBalance} BNNA</Typography>
              {stakingBalance > 0 && (
          <Typography 
            variant="subtitle1" 
            sx={{ 
              color: '#4caf50',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <LockIcon sx={{ fontSize: 16 }} />
            {stakingBalance.toLocaleString()} BNNA Staked
          </Typography>
        )}
        <Typography 
          variant="subtitle2" 
          sx={{ color: 'rgba(255,255,255,0.7)' }}
        >
          Total: {(bnnaBalance + stakingBalance).toLocaleString()} BNNA
        </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>

        </Grid>
        {showClusterAnalysis && (
          <Dialog
            open={showClusterAnalysis}
            onClose={() => setShowClusterAnalysis(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Wallet Cluster Analysis
              <IconButton
                onClick={() => setShowClusterAnalysis(false)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {!tierFeatures.canUseAdvancedFeatures ? (
                <Alert severity="info" sx={{ mt: 2 }}>
                  This feature requires Pro Tier. You need 1,000,000 more $BNNA to unlock it.
                </Alert>
              ) : (
                <WalletClusterAnalysis
                  data={clusterData}
                  isLoading={!clusterData}
                  publicKey={publicKey}
                />
              )}
            </DialogContent>
          </Dialog>
        )}
        {selectedAgent && (
          <Dialog
            open={showTokensModal}
            onClose={() => setShowTokensModal(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              Associated Tokens
              <IconButton
                onClick={() => setShowTokensModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ mt: 2, px: 1, pb: 2 }}>
                {selectedAgent.tokens?.length > 0 ? (
                  <Grid container spacing={3}>
                    {selectedAgent.tokens.map((token) => (
                      <Grid item xs={12} key={token._id}>
                        <Card sx={{
                          background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
                          color: 'white',
                          borderRadius: '16px',
                          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
                          transition: 'transform 0.2s ease-in-out',
                          '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 12px 40px rgba(0, 0, 0, 0.2)'
                          }
                        }}>
                          <CardContent sx={{ p: 3 }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 500,
                                mb: 1
                              }}
                            >
                              {token.name} ({token.symbol})
                            </Typography>
                            <Typography
                              sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                fontSize: '0.95rem',
                                mb: 1
                              }}
                            >
                              Mint: {token.mint}
                            </Typography>
                            <Button
                              variant="contained"
                              href={`https://solscan.io/token/${token.mint}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              startIcon={<LaunchIcon />}
                              size="small"
                              sx={{
                                mb: 2,
                                background: 'linear-gradient(135deg, #3b82f6 0%, #8b5cf6 100%)',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '8px',
                                fontSize: '0.8rem',
                                padding: '6px 12px',
                                '&:hover': {
                                  background: 'linear-gradient(135deg, #2563eb 0%, #7c3aed 100%)',
                                  boxShadow: '0 4px 12px rgba(59, 130, 246, 0.3)',
                                  transform: 'translateY(-1px)',
                                },
                                transition: 'all 0.2s ease',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                              }}
                            >
                              View on Solscan
                            </Button>
                            <Typography
                              variant="caption"
                              sx={{
                                color: 'rgba(255, 255, 255, 0.6)',
                                display: 'block',
                                mt: 1
                              }}
                            >
                              Created: {new Date(token.createdAt).toLocaleDateString()}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography sx={{
                    textAlign: 'center',
                    color: 'text.secondary',
                    py: 4
                  }}>
                    No tokens associated with this agent
                  </Typography>
                )}
              </Box>
            </DialogContent>
          </Dialog>
        )}

        {agents.length === 0 ? (
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', p: 4 }}>
              <Typography gutterBottom>You haven't created any agents yet.</Typography>
              <Button
                variant="contained"
                onClick={() => setActiveTab('create')}
                startIcon={<AddIcon />}
              >
                Create Your First Agent
              </Button>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {agents.map(agent => (
                <Grid item xs={12} md={6} key={agent._id}>
                  <Card sx={{
                    background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
                    color: 'white',
                    borderRadius: '16px',
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)'
                  }}>
                    <CardContent sx={{ p: 3 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography sx={{
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          background: 'linear-gradient(90deg, #fff 0%, #e3f2fd 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        }} variant="h6">{agent.name}</Typography>
                        <Chip sx={{
                          background: agent.status === 'active' ?
                            'linear-gradient(90deg, #00c853 0%, #69f0ae 100%)' :
                            'linear-gradient(90deg, #757575 0%, #bdbdbd 100%)',
                          color: 'white',
                          fontWeight: 500,
                          '&:hover': { opacity: 0.9 }
                        }}
                          label={agent.status}
                          color={agent.status === 'active' ? 'success' : 'default'}
                        />
                      </Box>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography color="rgba(255,255,255,0.7)" sx={{ fontSize: '0.875rem' }}>Max Trade</Typography>
                          <Typography sx={{
                            fontSize: '1.25rem',
                            fontWeight: 500,
                            color: 'white'
                          }}>{agent.settings.maxTradeAmount} SOL</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="rgba(255,255,255,0.7)" sx={{ fontSize: '0.875rem' }}>
                            Success Rate</Typography>
                          <Typography sx={{
                            fontSize: '1.25rem',
                            fontWeight: 500,
                            color: 'white'
                          }}>{agent.performance.successRate}%</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="rgba(255,255,255,0.7)" sx={{ fontSize: '0.875rem' }}>
                            Profit/Loss</Typography>
                          <Typography sx={{
                            fontSize: '1.25rem',
                            fontWeight: 500,
                            color: 'white'
                          }} color={agent.performance.profitLoss >= 0 ? 'success.main' : 'error.main'}>
                            {agent.performance.profitLoss}%
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="rgba(255,255,255,0.7)" sx={{ fontSize: '0.875rem' }}>
                            Total Trades</Typography>
                          <Typography sx={{
                            fontSize: '1.25rem',
                            fontWeight: 500,
                            color: 'white'
                          }}>{agent.performance.totalTrades}</Typography>
                        </Grid>
                      </Grid>
                      <Box sx={{ mb: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        <Chip
                          label="BONK"
                          size="small"
                          clickable
                          onClick={() => {
                            setTradingPairInputs(prev => ({
                              ...prev,
                              [agent._id]: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263"
                            }));
                            handleTradingPairUpdate(agent._id, "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263");
                          }}
                          sx={{
                            background: 'linear-gradient(90deg, #ff9800 0%, #ffb74d 100%)',
                            color: 'white',
                            '&:hover': {
                              background: 'linear-gradient(90deg, #f57c00 0%, #ffa726 100%)'
                            }
                          }}
                        />
                        <Chip
                          label="BNNA"
                          size="small"
                          clickable
                          onClick={() => {
                            setTradingPairInputs(prev => ({
                              ...prev,
                              [agent._id]: "DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump"
                            }));
                            handleTradingPairUpdate(agent._id, "DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump");
                          }}
                          sx={{
                            background: 'linear-gradient(90deg, #ffd700 0%, #ffeaa7 100%)',
                            color: 'black',
                            '&:hover': {
                              background: 'linear-gradient(90deg, #ffc400 0%, #ffe082 100%)'
                            }
                          }}
                        />
                        <Chip
                          label="SAMO"
                          size="small"
                          clickable
                          onClick={() => {
                            setTradingPairInputs(prev => ({
                              ...prev,
                              [agent._id]: "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU"
                            }));
                            handleTradingPairUpdate(agent._id, "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU");
                          }}
                          sx={{
                            background: 'linear-gradient(90deg, #e91e63 0%, #f48fb1 100%)',
                            color: 'white',
                            '&:hover': {
                              background: 'linear-gradient(90deg, #c2185b 0%, #f06292 100%)'
                            }
                          }}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          fullWidth
                          label="Token Address to Monitor"
                          value={tradingPairInputs[agent._id] || agent.settings.tradingPairs?.[0] || ''}
                          onChange={(e) => setTradingPairInputs(prev => ({
                            ...prev,
                            [agent._id]: e.target.value
                          }))}
                          onBlur={() => {
                            const newPair = tradingPairInputs[agent._id];
                            if (newPair && newPair !== agent.settings.tradingPairs?.[0]) {
                              handleTradingPairUpdate(agent._id, newPair);
                            }
                          }}
                          size="small"
                          margin="normal"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              color: 'white',
                              '& fieldset': {
                                borderColor: 'rgba(255,255,255,0.23)'
                              },
                              '&:hover fieldset': {
                                borderColor: 'rgba(255,255,255,0.5)'
                              }
                            },
                            '& .MuiInputLabel-root': {
                              color: 'rgba(255,255,255,0.7)'
                            }
                          }}
                        />

<Button
  fullWidth
  variant="contained"
  color={agent.settings?.strategyEnabled ? 'error' : 'primary'}
  onClick={() => toggleStrategy(agent._id)}
  sx={{ mt: 1 }}
>
  {agent.settings?.strategyEnabled ? 'Disable Strategy' : 'Enable Strategy'}
</Button>

<Button
  fullWidth
  variant="contained"
  onClick={() => setSelectedAgentId(agent._id)}
  sx={{ 
    mt: 1,
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    '&:hover': {
      background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
    }
  }}
>
  Choose Strategy
</Button>

                        {agent.settings?.strategyEnabled && (
                          <DipRipStrategy
                            agent={agent}
                            handleBuy={handleBuy}
                            handleSell={handleSell}
                            isActive={agent.status === 'active'}
                          />
                        )}


<Box sx={{ 
  display: 'flex', 
  flexDirection: { xs: 'column', sm: 'row' },
  flexWrap: 'wrap',
  gap: 1, 
  mt: 2 
}}>
  {/* Social Media Buttons Container */}
  {/* Social Media Buttons Container */}
<Box sx={{ 
  display: 'flex', 
  gap: 1,
  width: { xs: '100%', sm: 'auto' },
  flexWrap: 'wrap'
}}>
  {/* Twitter Button */}
  <Tooltip 
    title={!tierFeatures.canUseAdvancedFeatures 
      ? `Requires Pro Tier (10,000,000 $BNNA needed)`
      : 'Connect Twitter'
    }
  >
    <span> {/* Wrapper span for disabled button */}
      <IconButton
        disabled={!tierFeatures.canUseAdvancedFeatures || isConnectingTwitter}
        sx={{
          minWidth: '40px',
          width: '40px',
          height: '40px',
          background: 'linear-gradient(90deg, #2196f3 0%, #90caf9 100%)',
          color: 'white',
          '&:hover': {
            background: 'linear-gradient(90deg, #1976d2 0%, #64b5f6 100%)'
          },
          '&.Mui-disabled': {
            background: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(255, 255, 255, 0.3)'
          }
        }}
        onClick={() => {/* TODO: Add Twitter OAuth */}}
      >
        <svg viewBox="0 0 24 24" width="20" height="20">
          <path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"/>
      </svg>
      </IconButton>
    </span>
  </Tooltip>

  {/* Telegram Button */}
  <Tooltip 
    title={!tierFeatures.canUseAdvancedFeatures 
      ? `Requires Pro Tier (10,000,000 $BNNA needed)`
      : 'Connect Telegram'
    }
  >
    <span> {/* Wrapper span for disabled button */}
    <IconButton
    disabled={!tierFeatures.canUseAdvancedFeatures || isConnectingTelegram}
    onClick={() => {
      setSelectedAgentForTelegram(agent);
      setTelegramModalOpen(true);
    }}
    sx={{
      minWidth: '40px',
      width: '40px',
      height: '40px',
      background: 'linear-gradient(90deg, #2196f3 0%, #90caf9 100%)',
      color: 'white',
      '&:hover': {
        background: 'linear-gradient(90deg, #1976d2 0%, #64b5f6 100%)'
      },
      '&.Mui-disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(255, 255, 255, 0.3)'
      }
    }}
  >
    <svg viewBox="0 0 24 24" width="20" height="20">
      <path fill="currentColor" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.5 1.201-.82 1.23-.697.064-1.226-.461-1.901-.903-1.056-.692-1.653-1.123-2.678-1.799-1.185-.781-.417-1.21.258-1.911.177-.184 3.247-2.977 3.307-3.23.007-.032.015-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.139-5.062 3.345-.479.329-.913.489-1.302.481-.428-.009-1.252-.242-1.865-.44-.751-.245-1.349-.374-1.297-.789.027-.216.324-.437.893-.663 3.498-1.524 5.831-2.529 6.998-3.015 3.333-1.386 4.025-1.627 4.477-1.635.099-.002.321.023.465.141.119.098.238.318.207.505z"/>
    </svg>
  </IconButton>
    </span>
  </Tooltip>
</Box>

  {/* Action Buttons */}
  <Box sx={{ 
    display: 'flex', 
    gap: 1,
    flexWrap: 'wrap',
    width: { xs: '100%', sm: 'auto' }
  }}>
    <Button
      variant="outlined"
      startIcon={<ChartIcon />}
      onClick={() => {
        setSelectedAgent(agent);
        setShowTokensModal(true);
      }}
      sx={{
        minWidth: { xs: '100%', sm: 'auto' },
        background: 'linear-gradient(90deg, #2196f3 0%, #90caf9 100%)',
        color: 'white',
        textTransform: 'none',
        fontWeight: 500,
        fontSize: { xs: '0.75rem', sm: '0.875rem' },
        '&:hover': {
          background: 'linear-gradient(90deg, #1976d2 0%, #64b5f6 100%)'
        }
      }}
    >
      View Tokens
    </Button>

    <Button
  variant="outlined"
  disabled={stakingBalance < 10000000}
  startIcon={<SettingsIcon />}
  onClick={() => {
    setSelectedAgentForTraining(agent);
    setShowTrainingModal(true);
  }}
  sx={{
    minWidth: { xs: '100%', sm: 'auto' },
    background: 'linear-gradient(90deg, #2196f3 0%, #90caf9 100%)',
    color: 'white',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: { xs: '0.75rem', sm: '0.875rem' },
    '&:hover': {
      background: 'linear-gradient(90deg, #1976d2 0%, #64b5f6 100%)'
    }
  }}
>
  Modify/Train
</Button>

    <Button
      disabled={ stakingBalance < 10000000}
      variant="outlined"
      onClick={() => setCustomizeModalOpen(true)}
      startIcon={<StyleIcon />}
      sx={{
        minWidth: { xs: '100%', sm: 'auto' },
        background: 'linear-gradient(90deg, #9c27b0 0%, #ce93d8 100%)',
        color: 'white',
        textTransform: 'none',
        fontWeight: 500,
        fontSize: { xs: '0.75rem', sm: '0.875rem' },
        '&:hover': {
          background: 'linear-gradient(90deg, #7b1fa2 0%, #ba68c8 100%)'
        }
      }}
    >
      Customize Bot
    </Button>

    <IconButton
      color="error"
      onClick={() => handleDeleteAgent(agent._id)}
      sx={{
        alignSelf: { xs: 'center', sm: 'auto' }
      }}
    >
      <DeleteIcon />
    </IconButton>
  </Box>
</Box>


                        <Grid item xs={6}>
                          <AgentWalletManagement agent={agent} bnnaBalance={bnnaBalance} stakingBalance={stakingBalance} />
                        </Grid>
                        {/* <Button
                          variant="contained"
                          startIcon={<ChartIcon />}
                          onClick={() => {
                            setShowClusterAnalysis(true);
                            fetchWalletClusters();
                          }}
                          sx={{
                            mt: 1,
                            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                            '&:hover': {
                              background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
                            }
                          }}
                        >
                          View Token Stats
                        </Button> */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="h6">Wallet Cluster Analysis</Typography>
                          <Button
                            variant="contained"
                            startIcon={<ChartIcon />}
                            disabled={!tierFeatures.canUseAdvancedFeatures}
                            onClick={() => {
                              setShowClusterAnalysis(true);
                              fetchWalletClusters();
                            }}
                            sx={{
                              mt: 1,
                              background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                              '&:hover': {
                                background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
                              }
                            }}
                          >
                            {!tierFeatures.canUseAdvancedFeatures
                              ? `Requires Pro Tier (1,000,000 $BNNA needed)`
                              : 'View Analysis'
                            }
                          </Button>
                        </Box>

                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setActiveTab('create')}
          startIcon={<AddIcon />}
        >
          Create New Agent
        </Button>
        <Button
          variant="contained"
          color="primary"
          href="/tier-structure"
          startIcon={<AddIcon />}
        >
          Upgrade
        </Button>
        <CustomizeModal
          open={customizeModalOpen}
          onClose={() => setCustomizeModalOpen(false)}
          agent={agents[0]}
        />

      </Box>
      <StrategyDialog
        open={!!selectedAgentId}
        onClose={() => setSelectedAgentId(null)}
        agentId={selectedAgentId}
        onSelectStrategy={(strategyId) => {
          // Here you would implement the logic to update the agent's strategy
          console.log(`Selected strategy ${strategyId} for agent ${selectedAgentId}`);
          toast.success(`Strategy updated successfully`);
          setSelectedAgentId(null);
        }}
      />
      <TrainingModal
  open={showTrainingModal}
  onClose={() => {
    setShowTrainingModal(false);
    setSelectedAgentForTraining(null);
  }}
  agent={selectedAgentForTraining}
  onUpdate={fetchAgents}
/>

{selectedAgentForTelegram && (
    <TelegramConfigModal
      open={telegramModalOpen}
      onClose={() => {
        setTelegramModalOpen(false);
        setSelectedAgentForTelegram(null);
      }}
      agent={selectedAgentForTelegram}
      onConnect={handleTelegramConnect}
    />
  )}
    </Box>
    
  );
};

export default AgentsManagement;