import {
    Box,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Typography,
    Button,
    Grid,
    Tabs,
    Tab,
    Chip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Stepper,
    Step,
    StepLabel

  } from '@mui/material';
  import { 
    Add as AddIcon,
    Favorite as FavoriteIcon,
    Share as ShareIcon
  } from '@mui/icons-material';
  import { styled } from '@mui/material/styles';
import { useState } from 'react';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ShoppingCart as ShoppingCartIcon, SwapHoriz as SwapHorizIcon } from '@mui/icons-material';

const ActivityFeed = styled(Box)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '16px',
    padding: '1rem',
    maxHeight: '600px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.05)'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '4px'
    }
  }));

  const CreateListingModal = ({ open, onClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [listingType, setListingType] = useState('');
    const [formData, setFormData] = useState({
      title: '',
      description: '',
      price: '',
      category: '',
      image: null
    });
  
    const handleNext = () => {
      setActiveStep((prev) => prev + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prev) => prev - 1);
    };
  
    const handleSubmit = async () => {
      // Handle listing creation
      onClose();
    };
  
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
            borderRadius: '16px',
            color: 'white'
          }
        }}
      >
        <DialogTitle>
          Create New Listing
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} sx={{ my: 3 }}>
            <Step>
              <StepLabel StepIconProps={{ sx: { color: 'white' } }}>
                Choose Type
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconProps={{ sx: { color: 'white' } }}>
                Details
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconProps={{ sx: { color: 'white' } }}>
                Preview
              </StepLabel>
            </Step>
          </Stepper>
  
          {activeStep === 0 && (
            <Grid container spacing={2}>
              {['AI Agent', 'Item', 'Collectible', 'Tool'].map((type) => (
                <Grid item xs={6} sm={3} key={type}>
                  <Card
                    onClick={() => {
                      setListingType(type);
                      handleNext();
                    }}
                    sx={{
                      background: 'rgba(255, 255, 255, 0.1)',
                      cursor: 'pointer',
                      transition: '0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        background: 'rgba(255, 255, 255, 0.2)'
                      },
                      p: 2,
                      textAlign: 'center'
                    }}
                  >
                    <Typography variant="h6" color="white">
                      {type}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
  
          {activeStep === 1 && (
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Title"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                sx={{ mb: 2, input: { color: 'white' } }}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                sx={{ mb: 2, input: { color: 'white' } }}
              />
              <TextField
                fullWidth
                label="Price (BNNA)"
                type="number"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                sx={{ mb: 2, input: { color: 'white' } }}
              />
              <Button
                variant="outlined"
                component="label"
                sx={{ mt: 2, color: 'white', borderColor: 'white' }}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })}
                />
              </Button>
            </Box>
          )}
  
          {activeStep === 2 && (
            <Box sx={{ mt: 2 }}>
              <ListingCard>
                <CardMedia
                  component="img"
                  height="200"
                  image={formData.image ? URL.createObjectURL(formData.image) : `/api/placeholder/400/300`}
                  alt="Listing preview"
                />
                <CardContent>
                  <Typography variant="h6" color="white">
                    {formData.title}
                  </Typography>
                  <Typography variant="body2" color="rgba(255,255,255,0.7)" sx={{ mb: 2 }}>
                    {formData.description}
                  </Typography>
                  <PriceTag>
                    <img src="/bnna-icon.png" alt="BNNA" style={{ width: 20, height: 20 }} />
                    {formData.price} BNNA
                  </PriceTag>
                </CardContent>
              </ListingCard>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          {activeStep > 0 && (
            <Button onClick={handleBack} sx={{ color: 'white' }}>
              Back
            </Button>
          )}
          {activeStep < 2 ? (
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                background: 'linear-gradient(90deg, #FFD700, #FFA500)',
                color: 'black'
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                background: 'linear-gradient(90deg, #FFD700, #FFA500)',
                color: 'black'
              }}
            >
              Create Listing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
  
  const ActivityItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    '&:last-child': {
      borderBottom: 'none'
    }
  }));
  
  const ActivityIcon = styled(Box)(({ type }) => ({
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: type === 'sale' ? 'linear-gradient(135deg, #4CAF50, #45a049)' :
                type === 'listing' ? 'linear-gradient(135deg, #2196F3, #1976D2)' :
                'linear-gradient(135deg, #FF9800, #F57C00)',
    color: 'white'
  }));
  
  const activityData = [
    {
      type: 'sale',
      title: 'Trading Bot Sold',
      price: '50,000 BNNA',
      time: '2 minutes ago',
      buyer: '0x1234...5678',
      seller: '0x8765...4321'
    },
    {
      type: 'listing',
      title: 'New AI Agent Listed',
      price: '75,000 BNNA',
      time: '15 minutes ago',
      seller: '0x9876...5432'
    },
    // Add more activity items...
  ];


// Marketplace styled components
const MarketplaceContainer = styled(Box)(({ theme }) => ({
    padding: '2rem',
    background: 'linear-gradient(135deg, #322E29 0%, #423D36 100%)',
    borderRadius: '24px',
    minHeight: '80vh',
    '& .MuiTab-root': {
      color: 'rgba(254, 255, 235, 0.7)',
      '&.Mui-selected': {
        color: '#C59E57'
      }
    }
  }));
  
  const MarketplaceGrid = styled(Grid)(({ theme }) => ({
    marginTop: '2rem',
    gap: '2rem',
  }));
  
  const ListingCard = styled(Card)(({ theme }) => ({
    background: 'rgba(254, 255, 235, 0.05)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    border: '1px solid rgba(197, 158, 87, 0.1)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 24px rgba(197, 158, 87, 0.15)'
    }
  }));
  
  const PriceTag = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    background: 'linear-gradient(90deg, #C59E57, #9B7940)',
    borderRadius: '20px',
    fontWeight: 'bold',
    color: '#FEFFEB'
  }));
  
  const CategoryChip = styled(Chip)(({ theme }) => ({
    background: 'rgba(197, 158, 87, 0.1)',
    color: '#FEFFEB',
    borderRadius: '12px',
    border: '1px solid rgba(197, 158, 87, 0.2)',
    '&:hover': {
      background: 'rgba(197, 158, 87, 0.2)'
    }
  }));
  
  const StatsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    background: 'rgba(50, 46, 41, 0.4)',
    borderRadius: '12px',
    marginBottom: '1rem',
    border: '1px solid rgba(197, 158, 87, 0.1)'
  }));

const Marketplace = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [createListingOpen, setCreateListingOpen] = useState(false);

  
    const categories = [
      { label: 'All', value: 'all' },
      { label: 'AI Agents', value: 'agents' },
      { label: 'Items', value: 'items' },
      { label: 'Collectibles', value: 'collectibles' },
      { label: 'Tools', value: 'tools' }
    ];
  
    return (
    //   <MarketplaceContainer>
    //     <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
    //       <Typography variant="h4" color="white" gutterBottom>
    //         BNNA Marketplace
    //       </Typography>
          
    //       <StatsContainer>
    //         <Box>
    //           <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
    //             Total Volume
    //           </Typography>
    //           <Typography variant="h6" color="white">
    //             1.2M BNNA
    //           </Typography>
    //         </Box>
    //         <Box>
    //           <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
    //             Active Listings
    //           </Typography>
    //           <Typography variant="h6" color="white">
    //             142
    //           </Typography>
    //         </Box>
    //         <Box>
    //           <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
    //             Floor Price
    //           </Typography>
    //           <Typography variant="h6" color="white">
    //             5K BNNA
    //           </Typography>
    //         </Box>
    //       </StatsContainer>
  
    //       <Box sx={{ display: 'flex', gap: 1, mb: 3, flexWrap: 'wrap' }}>
    //         {categories.map((category) => (
    //           <CategoryChip
    //             key={category.value}
    //             label={category.label}
    //             onClick={() => setSelectedCategory(category.value)}
    //             variant={selectedCategory === category.value ? 'filled' : 'outlined'}
    //           />
    //         ))}
    //       </Box>
  
    //       <Tabs 
    //         value={activeTab} 
    //         onChange={(e, v) => setActiveTab(v)}
    //         sx={{ borderBottom: 1, borderColor: 'divider' }}
    //       >
    //         <Tab label="All Listings" />
    //         <Tab label="My Listings" />
    //         <Tab label="Activity" />
    //       </Tabs>
    //     </Box>
  
    //     <MarketplaceGrid container spacing={3}>
    //       {[...Array(8)].map((_, index) => (
    //         <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
    //           <ListingCard>
    //             <CardMedia
    //               component="img"
    //               height="200"
    //               image={`/api/placeholder/${400}/${300}`}
    //               alt="Listing image"
    //             />
    //             <CardContent>
    //               <Typography variant="h6" color="white" gutterBottom>
    //                 Premium Trading Bot
    //               </Typography>
    //               <Typography variant="body2" color="rgba(255,255,255,0.7)" sx={{ mb: 2 }}>
    //                 Advanced trading bot with custom strategies and automated execution
    //               </Typography>
    //               <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    //                 <PriceTag>
    //                   <img 
    //                     src="/bnna-icon.png" 
    //                     alt="BNNA" 
    //                     style={{ width: 20, height: 20 }} 
    //                   />
    //                   50,000 BNNA
    //                 </PriceTag>
    //                 <Button 
    //                   variant="contained"
    //                   sx={{
    //                     background: 'linear-gradient(90deg, #2196f3, #21CBF3)',
    //                     '&:hover': {
    //                       background: 'linear-gradient(90deg, #1976d2, #2196f3)'
    //                     }
    //                   }}
    //                 >
    //                   Buy Now
    //                 </Button>
    //               </Box>
    //             </CardContent>
    //             <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
    //               <Typography variant="caption" color="rgba(255,255,255,0.5)">
    //                 Listed 2 hours ago
    //               </Typography>
    //               <Box sx={{ display: 'flex', gap: 1 }}>
    //                 <IconButton size="small" sx={{ color: 'white' }}>
    //                   <FavoriteIcon />
    //                 </IconButton>
    //                 <IconButton size="small" sx={{ color: 'white' }}>
    //                   <ShareIcon />
    //                 </IconButton>
    //               </Box>
    //             </CardActions>
    //           </ListingCard>
    //         </Grid>
    //       ))}
    //     </MarketplaceGrid>
  
    //     <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
    //       <Button
    //         variant="contained"
    //         sx={{
    //           background: 'linear-gradient(90deg, #FFD700, #FFA500)',
    //           color: 'black',
    //           '&:hover': {
    //             background: 'linear-gradient(90deg, #FFA500, #FFD700)'
    //           }
    //         }}
    //         startIcon={<AddIcon />}
    //       >
    //         Create Listing
    //       </Button>
    //     </Box>
    //   </MarketplaceContainer>
      <MarketplaceContainer>
     <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Typography variant="h4" color="white" gutterBottom>
            BNNA Marketplace
          </Typography>
          
          <StatsContainer>
            <Box>
              <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
                Total Volume
              </Typography>
              <Typography variant="h6" color="white">
                1.2M BNNA
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
                Active Listings
              </Typography>
              <Typography variant="h6" color="white">
                142
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" color="rgba(255,255,255,0.7)">
                Floor Price
              </Typography>
              <Typography variant="h6" color="white">
                5K BNNA
              </Typography>
            </Box>
          </StatsContainer>
  
          <Box sx={{ display: 'flex', gap: 1, mb: 3, flexWrap: 'wrap' }}>
            {categories.map((category) => (
              <CategoryChip
                key={category.value}
                label={category.label}
                onClick={() => setSelectedCategory(category.value)}
                variant={selectedCategory === category.value ? 'filled' : 'outlined'}
              />
            ))}
          </Box>
  
          <Tabs 
            value={activeTab} 
            onChange={(e, v) => setActiveTab(v)}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="All Listings" />
            <Tab label="My Listings" />
            <Tab label="Activity" />
          </Tabs>
        </Box>
      
      {activeTab === 2 ? (
        <ActivityFeed>
          {activityData.map((activity, index) => (
            <ActivityItem key={index}>
              <ActivityIcon type={activity.type}>
                {activity.type === 'sale' ? <ShoppingCartIcon /> :
                 activity.type === 'listing' ? <AddIcon /> : <SwapHorizIcon />}
              </ActivityIcon>
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle1" color="white">
                  {activity.title}
                </Typography>
                <Typography variant="body2" color="rgba(255,255,255,0.7)">
                  {activity.type === 'sale' ? 
                    `Sold by ${activity.seller} to ${activity.buyer}` :
                    `Listed by ${activity.seller}`}
                </Typography>
                <Typography variant="caption" color="rgba(255,255,255,0.5)">
                  {activity.time} • {activity.price}
                </Typography>
              </Box>
            </ActivityItem>
          ))}
        </ActivityFeed>
      ) : (
        <MarketplaceGrid container spacing={3}>
          {[...Array(8)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ListingCard>
                <CardMedia
                  component="img"
                  height="200"
                  image={`https://raw.githubusercontent.com/apeoutmeme/resources/refs/heads/main/assets/strats/alpha_seekers.webp`}
                  alt="Listing image"
                />
                <CardContent>
                  <Typography variant="h6" color="white" gutterBottom>
                    Premium Trading Bot
                  </Typography>
                  <Typography variant="body2" color="rgba(255,255,255,0.7)" sx={{ mb: 2 }}>
                    Advanced trading bot with custom strategies and automated execution
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <PriceTag>
                      <img 
                        src="https://raw.githubusercontent.com/apeoutmeme/resources/refs/heads/main/assets/bnna-token.webp" 
                        alt="BNNA" 
                        style={{ width: 20, height: 20 }} 
                      />
                      50,000 BNNA
                    </PriceTag>
                    <Button 
                      variant="contained"
                      sx={{
                        background: 'linear-gradient(90deg, #2196f3, #21CBF3)',
                        '&:hover': {
                          background: 'linear-gradient(90deg, #1976d2, #2196f3)'
                        }
                      }}
                    >
                      Buy Now
                    </Button>
                  </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
                  <Typography variant="caption" color="rgba(255,255,255,0.5)">
                    Listed 2 hours ago
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton size="small" sx={{ color: 'white' }}>
                      <FavoriteIcon />
                    </IconButton>
                    <IconButton size="small" sx={{ color: 'white' }}>
                      <ShareIcon />
                    </IconButton>
                  </Box>
                </CardActions>
              </ListingCard>
            </Grid>
          ))}
        </MarketplaceGrid>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => setCreateListingOpen(true)}
          sx={{
            background: 'linear-gradient(90deg, #FFD700, #FFA500)',
            color: 'black',
            '&:hover': {
              background: 'linear-gradient(90deg, #FFA500, #FFD700)'
            }
          }}
          startIcon={<AddIcon />}
        >
          Create Listing
        </Button>
      </Box>

      <CreateListingModal
        open={createListingOpen}
        onClose={() => setCreateListingOpen(false)}
      />
    </MarketplaceContainer>
    );
  };

  export default Marketplace;