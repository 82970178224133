import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  top: '20px',
  left: '20px',
  background: 'linear-gradient(45deg, #C59E57, #FFD700)',
  color: 'black',
  padding: '10px 20px',
  borderRadius: '25px',
  boxShadow: '0 4px 15px rgba(197, 158, 87, 0.3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(197, 158, 87, 0.4)',
    background: 'linear-gradient(45deg, #FFD700, #C59E57)',
  }
}));

const BackHomeButton = () => {
  const navigate = useNavigate();

  return (
    <StyledButton
      startIcon={<Home />}
      onClick={() => navigate('/')}
    >
      Back to Home
    </StyledButton>
  );
};

export default BackHomeButton;