import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Paper, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SwapVert, Cached } from '@mui/icons-material';
import { toast } from 'react-toastify';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, rgba(197, 158, 87, 0.1), rgba(50, 46, 41, 0.9))',
  backdropFilter: 'blur(20px)',
  border: '2px solid rgba(254, 255, 235, 0.2)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  color: '#FEFFEB',
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    width: '200%',
    height: '200%',
    background: 'radial-gradient(circle, rgba(254, 255, 235, 0.1) 0%, transparent 70%)',
    zIndex: 0,
    pointerEvents: 'none',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: '#FEFFEB',
    fontFamily: "'VT323', monospace",
    '& fieldset': {
      borderColor: 'rgba(197, 158, 87, 0.5)',
      borderWidth: 2,
    },
    '&:hover fieldset': {
      borderColor: '#C59E57',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FEFFEB',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(254, 255, 235, 0.7)',
    fontFamily: "'VT323', monospace",
  },
}));

const ConversionResult = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'rgba(66, 61, 54, 0.5)',
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
  border: '1px dashed rgba(197, 158, 87, 0.3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
  }
}));

const ActionButton = styled(Button)({
  background: 'linear-gradient(135deg, #C59E57, #9B7940)',
  color: '#322E29',
  padding: '12px 24px',
  fontFamily: "'Press Start 2P', cursive",
  textTransform: 'none',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(135deg, #FEFFEB, #C59E57)',
    transform: 'translateY(-3px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
  },
});

const BnnaConverter = () => {
  const [bnnaPrice, setBnnaPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState('');
  const [isConvertingToUsd, setIsConvertingToUsd] = useState(true);

  useEffect(() => {
    fetchBnnaPrice();
    const interval = setInterval(fetchBnnaPrice, 60000);
    return () => clearInterval(interval);
  }, []);

  const fetchBnnaPrice = async () => {
    try {
      const response = await fetch(
        'https://api.dexscreener.com/latest/dex/tokens/DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
      );
      const data = await response.json();
      
      if (data.pairs && data.pairs[0]) {
        setBnnaPrice(parseFloat(data.pairs[0].priceUsd));
        toast.success('Price updated successfully', {
          style: {
            fontFamily: "'VT323', monospace",
            backgroundColor: '#322E29',
            color: '#FEFFEB'
          }
        });
      } else {
        toast.error('Unable to fetch BNNA price', {
          style: {
            fontFamily: "'VT323', monospace",
            backgroundColor: '#322E29',
            color: '#FEFFEB'
          }
        });
      }
    } catch (err) {
      toast.error('Failed to fetch price data', {
        style: {
          fontFamily: "'VT323', monospace",
          backgroundColor: '#322E29',
          color: '#FEFFEB'
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setAmount(value);
  };

  const toggleConversion = () => {
    setIsConvertingToUsd(!isConvertingToUsd);
    setAmount('');
  };

  const getConvertedAmount = () => {
    if (!amount || !bnnaPrice) return '0.00';
    const numAmount = parseFloat(amount);
    
    if (isConvertingToUsd) {
      return (numAmount * bnnaPrice).toFixed(6);
    } else {
      return (numAmount / bnnaPrice).toFixed(2);
    }
  };

  return (
    <StyledPaper elevation={24}>
      <Box sx={{ 
        textAlign: 'center', 
        mb: 3,
        position: 'relative',
        zIndex: 1
      }}>
        <Typography 
          variant="h5" 
          sx={{ 
            fontWeight: 'bold', 
            mb: 1,
            fontFamily: "'Press Start 2P', cursive",
            color: '#C59E57',
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
          }}
        >
          BNNA/USD Converter
        </Typography>
        {bnnaPrice && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'rgba(254, 255, 235, 0.7)',
              fontFamily: "'VT323', monospace"
            }}
          >
            1 BNNA = ${bnnaPrice.toFixed(6)} USD
          </Typography>
        )}
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress sx={{ color: '#C59E57' }} />
        </Box>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 2,
          position: 'relative',
          zIndex: 1
        }}>
          <StyledTextField
            fullWidth
            label={isConvertingToUsd ? 'BNNA Amount' : 'USD Amount'}
            value={amount}
            onChange={handleAmountChange}
            variant="outlined"
            placeholder="Enter amount"
          />
          
          <ActionButton
            onClick={toggleConversion}
            startIcon={<SwapVert />}
            fullWidth
          >
            Swap Conversion
          </ActionButton>

          <ConversionResult>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'rgba(254, 255, 235, 0.7)',
                fontFamily: "'VT323', monospace"
              }}
            >
              {isConvertingToUsd ? 'USD Value' : 'BNNA Value'}
            </Typography>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 'bold',
                color: '#C59E57',
                fontFamily: "'VT323', monospace",
                fontSize: '1.5rem'
              }}
            >
              {isConvertingToUsd ? '$' : ''}{getConvertedAmount()}
              {!isConvertingToUsd ? ' BNNA' : ''}
            </Typography>
          </ConversionResult>

          <Button
            onClick={fetchBnnaPrice}
            startIcon={<Cached />}
            sx={{
              color: 'rgba(254, 255, 235, 0.7)',
              fontFamily: "'VT323', monospace",
              transition: 'all 0.3s ease',
              '&:hover': {
                color: '#C59E57',
                transform: 'translateY(-3px)'
              }
            }}
          >
            Refresh Price
          </Button>
        </Box>
      )}
    </StyledPaper>
  );
};

export default BnnaConverter;