import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  Stack
} from '@mui/material';
import {
  Twitter as TwitterIcon,
  Telegram as TelegramIcon,
  GitHub as GitHubIcon,
  YouTube as YouTubeIcon,
  Receipt,
  Book,
  SmartToy,
  AccountBalanceWallet,
  Rocket,
  Assessment,
  Token,
  Assistant
} from '@mui/icons-material';
import { FaTiktok } from 'react-icons/fa';
import { styled, keyframes } from '@mui/material/styles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logoImage from '../assets/bnnalogo.png';
import logoGif from '../assets/gifs/banana-single.gif'
import logoGif2 from '../assets/gifs/bnna.gif'

// Animation keyframes
const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const AnimatedLogo = styled('img')({
  width: '120px',
  height: 'auto',
  margin: '2rem auto',
  display: 'block',
  filter: 'drop-shadow(0 0 8px rgba(251, 191, 36, 0.3))',
});

// Styled components with enhanced animations
const AnimatedSection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(8, 0),
  position: 'relative',
  transition: 'background-color 0.6s ease-in-out',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at center, rgba(59,130,246,0.1) 0%, rgba(0,0,0,0) 70%)',
    opacity: 0,
    transition: 'opacity 0.6s ease-in-out',
  },
  '&.aos-animate::before': {
    opacity: 1,
  }
}));

const FloatingIcon = styled(Box)(({ theme }) => ({
  animation: `${float} 6s ease-in-out infinite`,
  '& svg': {
    fontSize: 48,
    color: 'white',
  }
}));

const PulsingCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '&:hover': {
    transform: 'translateY(-8px) scale(1.02)',
    background: 'rgba(255, 255, 255, 0.08)',
    '& .icon-wrapper': {
      animation: `${pulse} 2s ease-in-out infinite`,
    }
  }
}));

const RotatingBackground = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'radial-gradient(circle at center, white 0%, transparent 70%)',
  opacity: 0.1,
  animation: `${rotate} 20s linear infinite`,
  pointerEvents: 'none',
});

const GlowingButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    width: '200%',
    height: '200%',
    background: 'radial-gradient(circle, rgba(255,255,255,0.2) 0%, transparent 70%)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover::after': {
    opacity: 1,
  }
}));

const SocialFooter = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.7))',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  flexDirection: 'column', // Changed to column to stack rows
  alignItems: 'center',
  gap: theme.spacing(2),
  zIndex: 1000,
}));
const FooterLink = styled('a')(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.8)',
  textDecoration: 'none',
  fontSize: '0.875rem',
  padding: theme.spacing(0, 1),
  transition: 'all 0.3s ease',
  '&:hover': {
    color: 'white',
    transform: 'translateY(-2px)',
  }
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px) scale(1.1)',
    color: 'white',
  }
}));

const LogoContainer = styled(Box)({
  position: 'absolute',
  top: '20px',
  left: '20px',
  zIndex: 1000,
  '& img': {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    boxShadow: '0 0 10px rgba(255, 255, 255, 0.2)',
  }
});

const LandingPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.AOS.init({
        duration: 1200,
        once: false,
        mirror: true,
        offset: 100
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box sx={{ bgcolor: '#121212', color: 'white' }}>
      {/* Hero Section */}
      <AnimatedSection>
      <LogoContainer>
          <img 
            src={logoGif2} // Replace with your actual gif URL
            alt="Logo"
            data-aos="fade-down"
            data-aos-duration="1000"
          />
        </LogoContainer>
        <RotatingBackground />
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} data-aos="fade-right" data-aos-duration="1500">
            <Typography variant="h1" component="h1" gutterBottom>
                APE OUT
              </Typography>
              <Typography variant="h2" component="h1" gutterBottom>
                Fast. Efficient. Ape-Powered Trading.
              </Typography>
              <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
                Trade instantly on Solana & BSC with AI-powered bots, stake $BNNA, 
                and discover the next moonshot on our launchpad.
              </Typography>
              <Stack direction="row" spacing={2}>
                <GlowingButton 
                  variant="contained" 
                  size="large"
                  startIcon={<AccountBalanceWallet />}
                  href="/app"
                  target="_blank"
                >
                  Start Trading
                </GlowingButton>
                <GlowingButton 
                  variant="outlined" 
                  size="large"
                  href="/banana-stand"
                  target="_blank"
                >
                  Stake $BNNA
                </GlowingButton>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} data-aos="zoom-in-left" data-aos-duration="1500">
            <Box sx={{ position: 'relative' }}>
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      paddingTop: '56.25%', // 16:9 Aspect Ratio
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 4px 30px rgba(59, 130, 246, 0.3)'
    }}
  >
    <video
      autoPlay
      muted
      loop
      playsInline
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '8px',
      }}
    >
      <source 
        src="https://github.com/apeoutmeme/resources/blob/2dd6bd2fbf8f0aefe3f7c9b7eac79b5f56917ffe/dashboard.mp4?raw=true" 
        type="video/mp4" 
      />
      {/* Fallback image in case video fails to load */}
      <img 
        src="https://github.com/apeoutmeme/resources/blob/06c61ea5960379266633d31924dd99f979e000d5/assets/dec27/trending-tokens.png?raw=true" 
        alt="Trading Platform"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </video>
  </Box>
  <FloatingIcon sx={{ position: 'absolute', top: -20, right: -20 }}>
    <img 
      src="https://github.com/apeoutmeme/resources/blob/06c61ea5960379266633d31924dd99f979e000d5/assets/badges/Golden-Banana-Holder.png?raw=true" 
      alt="Bot" 
      style={{ width: '48px', height: '48px' }} 
    />
  </FloatingIcon>
</Box>
            </Grid>
          </Grid>
        </Container>
      </AnimatedSection>
      <Box 
  sx={{ 
    textAlign: 'center',
    py: 4,
    background: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.6))',
    borderRadius: '16px',
    my: 3
  }}
  data-aos="fade-in"
  data-aos-duration="1000"
  data-aos-delay="200"
  data-aos-once="true"
>
  <AnimatedLogo
    src='https://static.vecteezy.com/system/resources/previews/022/227/364/non_2x/openai-chatgpt-logo-icon-free-png.png'
    alt="Logo"
    data-aos="zoom-in"
    data-aos-duration="800" 
    data-aos-delay="400"
    data-aos-once="true"
  />
</Box>
<Box 
  sx={{ 
    textAlign: 'center',
    py: 6,
    background: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.6))',
    borderRadius: '16px',
    my: 4,
    mx: 2
  }}
  data-aos="fade-up"
  data-aos-duration="1000"
>
  <Container>
    <Typography 
      variant="h4" 
      component="h2" 
      gutterBottom 
      sx={{ mb: 4 }}
    >
      Powered By
    </Typography>
    <Grid 
      container 
      spacing={3} 
      justifyContent="center" 
      alignItems="center"
    >
      {/* Solana */}
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://cryptologos.cc/logos/solana-sol-logo.png"
              alt="Solana"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              Solana
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>

      {/* BSC */}
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://cryptologos.cc/logos/bnb-bnb-logo.png"
              alt="BSC"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              BSC
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>

     

      {/* OpenAI */}
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://static.vecteezy.com/system/resources/previews/022/227/364/non_2x/openai-chatgpt-logo-icon-free-png.png"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              OpenAI
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
 
    </Grid>
  </Container>
</Box>

{/* As Seen On Section */}
<Box 
        sx={{ 
          textAlign: 'center',
          py: 6,
          background: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.6))',
          borderRadius: '16px',
          my: 4,
          mx: 2
        }}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Container>
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom 
            sx={{ mb: 4 }}
          >
            As Seen On
          </Typography>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4} md={3}>
              <PulsingCard>
                <Box 
                  component="a" 
                  href="https://memecoinseason.net/p/apeout" 
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    textDecoration: 'none'
                  }}
                >
                  <img
                    src="https://memecoinseason.net/assets/logo/logo.png"
                    alt="Claude AI"
                    style={{ 
                      width: '80px', 
                      height: '80px',
                      marginBottom: '1rem'
                    }}
                  />
                  <Typography sx={{color: 'white'}} variant="subtitle1">
                    memecoinseason.net
                  </Typography>
                </Box>
              </PulsingCard>
            </Grid>
            {/* OpenAI */}
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://www.mintme.com/build/images/WEB.8a500667..svg"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              MintMe
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      {/* OpenAI */}
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://pbs.twimg.com/profile_images/1659521244465602560/3Dpnso6C_400x400.jpg"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              CryptHub
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://pbs.twimg.com/profile_images/1876593111419224065/5bVlhohW_400x400.png"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              DexTools
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://play-lh.googleusercontent.com/cS10a-HBUpLz-pamX_bMQ8iH8Aj8g5a1IkPc2ERMSsNMeczHBYKg4gmDvnIS3qWpFg"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              DexScreener
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://github.com/apeoutmeme/resources/blob/43745db806d12b37349b4407bb414630b8921fab/assets/Hackathon-2025-Solana-assets/hackathon.png?raw=true"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              Solana AI Hackathon (SendAI)
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <PulsingCard>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2
            }}
          >
            <img
              src="https://github.com/apeoutmeme/resources/blob/43745db806d12b37349b4407bb414630b8921fab/assets/raydium.ico?raw=true"
              alt="OpenAI"
              style={{ 
                width: '60px', 
                height: '60px',
                objectFit: 'contain'
              }}
            />
            <Typography sx={{color: 'white'}} variant="subtitle1">
              Raydium
            </Typography>
          </Box>
        </PulsingCard>
      </Grid>
          </Grid>
        </Container>
      </Box>

      {/* AI Trading Section */}
      <AnimatedSection>
        <RotatingBackground />
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} data-aos="fade-down">
              <Typography variant="h3" component="h2" gutterBottom textAlign="center">
                AI-Powered Trading Experience
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} data-aos="zoom-in" data-aos-delay="200">
              <PulsingCard>
                <Box className="icon-wrapper">
                <img src="https://github.com/apeoutmeme/resources/blob/main/assets/badges/banana-alchemist.png?raw=true" alt="Bot" style={{ width: '48px', height: '48px' }} />
                </Box>
                <Typography sx={{color: 'white'}} variant="h5" gutterBottom>
                  Smart Trading Bots
                </Typography>
                <Typography sx={{color: 'white'}}>
                  Automated strategies that execute trades with precision and efficiency
                </Typography>
                <GlowingButton 
                  variant="contained" 
                  color="primary"
                  href="https://apeout.notion.site/16595c4b66bd80a8866fca86f11d9ee2?v=16595c4b66bd809fab47000c93c737ee"
                  target="_blank"
                >
                 Docs
                </GlowingButton>
              </PulsingCard>
            </Grid>
            <Grid item xs={12} md={4} data-aos="zoom-in" data-aos-delay="400">
              <PulsingCard>
                <Box className="icon-wrapper">
                  <Assessment sx={{ fontSize: 48, color: 'white' }} />
                </Box>
                <Typography sx={{color: 'white'}} variant="h5" gutterBottom>
                  Market Analysis
                </Typography>
               
                <Typography sx={{color: 'white'}}>
                  Real-time market data and AI-driven insights for informed trading
                </Typography>
                <GlowingButton 
                  variant="contained" 
                  color="primary"
                  href="/trending-tokens"
                  target="_blank"
                >
                  Try Now
                </GlowingButton>
              </PulsingCard>
            </Grid>
            <Grid item xs={12} md={4} data-aos="zoom-in" data-aos-delay="600">
              <PulsingCard>
                <Box className="icon-wrapper">
                <img src="https://github.com/apeoutmeme/resources/blob/06c61ea5960379266633d31924dd99f979e000d5/assets/strats/alpha_seekers.webp?raw=true" alt="Bot" style={{ width: '48px', height: '48px' }} />
                </Box>
                <Typography sx={{color: 'white'}} variant="h5" gutterBottom>
                  AI Agents
                </Typography>
                <Typography sx={{color: 'white'}}>
                  Automated social management and trading assistance
                </Typography>
                <GlowingButton 
                  variant="contained" 
                  color="primary"
                  href="/ai-agents"
                  target="_blank"
                >
                  Try Now
                </GlowingButton>
              </PulsingCard>
            </Grid>
          </Grid>
        </Container>
      </AnimatedSection>

      {/* Staking Section */}
      <AnimatedSection>
        <RotatingBackground />
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} data-aos="fade-right" data-aos-duration="1500">
              <Typography variant="h3" component="h2" gutterBottom>
                Stake $BNNA
              </Typography>
              <Typography variant="h6" gutterBottom>
                Earn passive income by staking your $BNNA tokens
              </Typography>
              <GlowingButton 
                variant="contained" 
                size="large"
                startIcon={<Token />}
                href="/banana-stand"
                target="_blank"
                sx={{ mt: 4 }}
              >
                Start Staking
              </GlowingButton>
            </Grid>
            <Grid item xs={12} md={6} data-aos="zoom-in-left" data-aos-duration="1500">
            <Box sx={{ position: 'relative' }}>
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      paddingTop: '56.25%', // 16:9 Aspect Ratio
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 4px 30px rgba(59, 130, 246, 0.3)'
    }}
  >
    <video
      autoPlay
      muted
      loop
      playsInline
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '8px',
      }}
    >
      <source 
        src="https://github.com/apeoutmeme/resources/blob/2dd6bd2fbf8f0aefe3f7c9b7eac79b5f56917ffe/banana-stand.mp4?raw=true" 
        type="video/mp4" 
      />
      {/* Fallback image in case video fails to load */}
      <img 
        src="https://github.com/apeoutmeme/resources/blob/06c61ea5960379266633d31924dd99f979e000d5/assets/dec27/banana-stand.webp?raw=true" 
        alt="Staking Platform"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </video>
  </Box>
  <FloatingIcon sx={{ position: 'absolute', bottom: -20, right: -20 }}>
    <img 
      src="https://github.com/apeoutmeme/resources/blob/06c61ea5960379266633d31924dd99f979e000d5/assets/common/17.png?raw=true" 
      alt="Bot" 
      style={{ width: '48px', height: '48px' }} 
    />
  </FloatingIcon>
</Box>
            </Grid>
          </Grid>
        </Container>
      </AnimatedSection>

      {/* Launchpad Section */}
      <AnimatedSection>
        <RotatingBackground />
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} data-aos="zoom-in-right" data-aos-duration="1500">
            <Box sx={{ position: 'relative' }}>
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      paddingTop: '56.25%', // 16:9 Aspect Ratio
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 4px 30px rgba(59, 130, 246, 0.3)'
    }}
  >
    <video
      autoPlay
      muted
      loop
      playsInline
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '8px',
      }}
    >
      <source 
        src="https://github.com/apeoutmeme/resources/blob/43745db806d12b37349b4407bb414630b8921fab/lootboxes.mp4?raw=true" 
        type="video/mp4" 
      />
      {/* Fallback image in case video fails to load */}
      <img 
        src="https://github.com/apeoutmeme/resources/blob/06c61ea5960379266633d31924dd99f979e000d5/assets/badges/Golden-Banana-Holder.png?raw=true" 
        alt="Launchpad"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </video>
  </Box>
  <FloatingIcon sx={{ position: 'absolute', top: -20, left: -20 }}>
    <Rocket />
  </FloatingIcon>
</Box>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} data-aos="fade-left" data-aos-duration="1500">
              <Typography variant="h3" component="h2" gutterBottom>
                Lootboxes
              </Typography>
              <Typography variant="h6" gutterBottom>
                Open Lootboxes to upgrade your bots and trading strategies
              </Typography>
              <GlowingButton 
                variant="contained" 
                size="large"
                startIcon={<Rocket />}
                href="/lootbox"
                target="_blank"
                sx={{ mt: 4 }}
              >
                Open Lootboxes
              </GlowingButton>
            </Grid>
          </Grid>
        </Container>
      </AnimatedSection>

      {/* YouTube Video Section */}
      <AnimatedSection>
        <RotatingBackground />
        <Container>
          <Typography 
            variant="h3" 
            component="h2" 
            gutterBottom 
            textAlign="center"
            data-aos="fade-down"
          >
            Featured Videos
          </Typography>
          <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={8} lg={6} data-aos="zoom-in">
              <PulsingCard>
                <Box
                  sx={{
                    position: 'relative',
                    paddingTop: '56.25%', // 16:9 Aspect Ratio
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '8px',
                  }}
                >
                  <iframe
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    src="https://video.twimg.com/amplify_video/1825525423113228288/vid/avc1/1920x1080/G2Ggr0M_dLYqnIPB.mp4?tag=16"
                    title="ApeOut Video"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mt: 2, 
                    color: 'white',
                    fontWeight: 'bold' 
                  }}
                >
                  Why We Created ApeOut
                </Typography>
                <Typography 
                  variant="p" 
                  sx={{ 
                    mt: 2, 
                    color: 'white',
                    fontWeight: 'bold' 
                  }}
                >
                  They have automated tools to take your liquidity, we have AI to and automation to prevent that and assist you in winning the memecoin game. On solana, without these types of tools, you are at an extreme disadvantage.

                  Read More
                </Typography>
              </PulsingCard>
            </Grid>
            <Grid item xs={12} md={8} lg={6} data-aos="zoom-in">
              <PulsingCard>
                <Box
                  sx={{
                    position: 'relative',
                    paddingTop: '56.25%', // 16:9 Aspect Ratio
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '8px',
                  }}
                >
                  <iframe
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    src="https://www.youtube.com/embed/f4OeszM6ukE"
                    title="ApeOut Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mt: 2, 
                    color: 'white',
                    fontWeight: 'bold' 
                  }}
                >
                  Wojak Buys $BNNA & Trades on ApeOut
                </Typography>
              </PulsingCard>
            </Grid>
            <Grid item xs={12} md={8} lg={6} data-aos="zoom-in">
              <PulsingCard>
                <Box
                  sx={{
                    position: 'relative',
                    paddingTop: '56.25%', // 16:9 Aspect Ratio
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '8px',
                  }}
                >
                  <iframe
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    src="https://www.youtube.com/embed/Ck9TU1ztTd8"
                    title="ApeOut Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mt: 2, 
                    color: 'white',
                    fontWeight: 'bold' 
                  }}
                >
                  Apeout Official Trailer
                </Typography>
              </PulsingCard>
            </Grid>
            <Grid item xs={12} md={8} lg={6} data-aos="zoom-in">
              <PulsingCard>
                <Box
                  sx={{
                    position: 'relative',
                    paddingTop: '56.25%', // 16:9 Aspect Ratio
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '8px',
                  }}
                >
                  <iframe
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    src="https://www.youtube.com/embed/2DHG9wW21Y4"
                    title="ApeOut Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mt: 2, 
                    color: 'white',
                    fontWeight: 'bold' 
                  }}
                >
                  Solana SendAI Hackathon Submission Video
                </Typography>
              </PulsingCard>
            </Grid>
          </Grid>
        </Container>
      </AnimatedSection>

      {/* Social Footer */}
      <SocialFooter>
  <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
    <SocialIconButton href="https://x.com/apeoutmeme" target="_blank" aria-label="Twitter">
      <TwitterIcon />
    </SocialIconButton>
    <SocialIconButton href="https://t.me/apeoutmeme" target="_blank" aria-label="Telegram">
      <TelegramIcon />
    </SocialIconButton>
    <SocialIconButton href="https://github.com/apeoutmeme/apeoutdotfun-" target="_blank" aria-label="GitHub">
      <GitHubIcon />
    </SocialIconButton>
    <SocialIconButton href="https://www.youtube.com/shorts/f4OeszM6ukE" target="_blank" aria-label="YouTube">
      <YouTubeIcon />
    </SocialIconButton>
    <SocialIconButton href="https://apeout.meme/whitepaper" target="_blank" aria-label="Whitepaper">
      <Receipt />
    </SocialIconButton>
    <SocialIconButton href="https://medium.com/@apeoutcrypto" target="_blank" aria-label="Medium">
      <Book />
    </SocialIconButton>
    <SocialIconButton href="https://tiktok.com/@apeoutmeme" target="_blank" aria-label="TikTok">
      <FaTiktok />
    </SocialIconButton>
  </Box>
  <Box 
    sx={{ 
      display: 'flex', 
      gap: 3, 
      justifyContent: 'center',
      flexWrap: 'wrap',
      px: 2
    }}
  >
    <FooterLink 
      href="https://apeout.notion.site/16595c4b66bd80a8866fca86f11d9ee2?v=16595c4b66bd809fab47000c93c737ee&p=17695c4b66bd80f08c03c76b869f54e9&pm=s" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Press Releases
    </FooterLink>
    <FooterLink 
      href="/terms-of-use" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Service
    </FooterLink>
    <FooterLink 
      href="/bounties" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Bounties
    </FooterLink>
    <FooterLink 
      href="https://apeout.notion.site/16595c4b66bd80a8866fca86f11d9ee2" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Documentation
    </FooterLink>
    <FooterLink 
      href="/roadmap" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Roadmap
    </FooterLink>
    <FooterLink 
      href="/tier-structure" 
      target="_blank"
      rel="noopener noreferrer"
    >
      Tiers
    </FooterLink>
  </Box>
</SocialFooter>
    </Box>
  );
};
export default LandingPage;