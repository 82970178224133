import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Button, 
  IconButton, 
  Tooltip, 
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import { 
  YouTube, 
  Settings, 
  SmartToy, 
  Send 
} from '@mui/icons-material';

// Mock WebSocket and OBS integration (to be replaced with actual implementations)
class MockOBSWebSocket {
  constructor() {
    this.isConnected = false;
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.isConnected = true;
      resolve(true);
    });
  }

  startStream(streamKey) {
    return new Promise((resolve, reject) => {
      console.log('Streaming started with key:', streamKey);
      resolve(true);
    });
  }

  stopStream() {
    return new Promise((resolve, reject) => {
      console.log('Stream stopped');
      resolve(true);
    });
  }
}

const StreamIntegration = ({ onSpeak, isMuted, avatarUrl }) => {
  // Streaming states
  const [isStreaming, setIsStreaming] = useState(false);
  const [streamKey, setStreamKey] = useState('');
  const [obsWebSocket, setOBSWebSocket] = useState(null);

  // Chat states
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // Market data for responses
  const marketData = {
    bnna: { price: 0.00001, change: 10.5 },
    sol: { price: 125, change: 5.2 }
  };

  // Initialize OBS WebSocket
  useEffect(() => {
    const initOBSWebSocket = async () => {
      const socket = new MockOBSWebSocket();
      try {
        await socket.connect();
        setOBSWebSocket(socket);
      } catch (error) {
        console.error('OBS WebSocket initialization failed:', error);
      }
    };

    initOBSWebSocket();
  }, []);

  // Start streaming
  const startStream = useCallback(async () => {
    if (!obsWebSocket) {
      alert('OBS WebSocket not initialized');
      return;
    }

    try {
      await obsWebSocket.startStream(streamKey);
      setIsStreaming(true);
      onSpeak('Streaming has begun!');
    } catch (error) {
      console.error('Stream start error:', error);
      alert('Failed to start stream');
    }
  }, [obsWebSocket, streamKey, onSpeak]);

  // Stop streaming
  const stopStream = useCallback(async () => {
    if (!obsWebSocket) return;

    try {
      await obsWebSocket.stopStream();
      setIsStreaming(false);
      onSpeak('Streaming has ended.');
    } catch (error) {
      console.error('Stream stop error:', error);
      alert('Failed to stop stream');
    }
  }, [obsWebSocket, onSpeak]);

  // Handle chat commands
  const handleCommand = useCallback((command, author) => {
    let response = 'Unknown command';
    
    switch(command.toLowerCase()) {
      case 'price':
        response = Object.entries(marketData)
          .map(([token, data]) => 
            `${token.toUpperCase()} price: $${data.price} (${data.change}%)`
          )
          .join(', ');
        break;
      case 'help':
        response = 'Available commands: !price, !help';
        break;
    }

    // Speak response if not muted
    onSpeak(response);

    // Add response to chat
    setChatMessages(prev => [
      ...prev, 
      { author: 'Bot', text: response }
    ]);
  }, [marketData, onSpeak]);

  // Send chat message
  const sendMessage = () => {
    if (!newMessage.trim()) return;

    const message = { 
      author: 'User', 
      text: newMessage 
    };

    // Speak the message if not muted
    if (!isMuted) {
      onSpeak(message.text);
    }

    setChatMessages(prev => [...prev, message]);

    // Check if it's a command
    if (newMessage.startsWith('!')) {
      handleCommand(newMessage.substring(1), 'User');
    }

    setNewMessage('');
  };

  // Render
  return (
    <Box sx={{ p: 2 }}>
      {/* Stream Controls */}
      <Box sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <Button
          variant="contained"
          color={isStreaming ? "error" : "primary"}
          onClick={isStreaming ? stopStream : startStream}
          startIcon={<YouTube />}
          disabled={!streamKey}
        >
          {isStreaming ? 'Stop Stream' : 'Start Stream'}
        </Button>

        <Tooltip title="Stream Settings">
          <IconButton onClick={() => setIsSettingsOpen(true)}>
            <Settings />
          </IconButton>
        </Tooltip>

        {isStreaming && (
          <Typography sx={{ color: 'green', ml: 2 }}>
            🔴 Live
          </Typography>
        )}
      </Box>

      {/* Chat Interface */}
      <Box
  sx={{
    height: 300,
    overflowY: 'auto',
    bgcolor: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(8px)',
    p: 2,
    borderRadius: 2,
    mb: 2,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '4px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  }}
>
  {chatMessages.map((msg, i) => (
    <Box 
      key={i} 
      sx={{ 
        mb: 1.5,
        textAlign: msg.author === 'Bot' ? 'left' : 'right',
        display: 'flex',
        flexDirection: 'column',
        alignItems: msg.author === 'Bot' ? 'flex-start' : 'flex-end',
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'flex-start',
          maxWidth: '80%',
        }}
      >
        {msg.author === 'Bot' && (
          <SmartToy 
            sx={{ 
              fontSize: 20, 
              mr: 1, 
              color: '#fbbf24',
              mt: 0.5
            }} 
          />
        )}
        <Typography 
          variant="body2"
          sx={{ 
            display: 'inline-block',
            bgcolor: msg.author === 'Bot' 
              ? 'rgba(251, 191, 36, 0.1)' 
              : 'rgba(59, 130, 246, 0.1)',
            color: msg.author === 'Bot' 
              ? '#fbbf24' 
              : '#60a5fa',
            p: 1.5,
            borderRadius: 2,
            wordBreak: 'break-word'
          }}
        >
          <strong>{msg.author === 'Bot' ? 'AI Assistant' : 'You'}:</strong> {msg.text}
        </Typography>
      </Box>
    </Box>
  ))}
</Box>

{/* Message Input */}
<Box 
  sx={{ 
    display: 'flex', 
    gap: 2,
    position: 'relative'
  }}
>
  <TextField
    fullWidth
    variant="outlined"
    value={newMessage}
    onChange={(e) => setNewMessage(e.target.value)}
    placeholder="Send a message or command (!help for commands)"
    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
    sx={{
      '& .MuiOutlinedInput-root': {
        bgcolor: 'rgba(0, 0, 0, 0.4)',
        backdropFilter: 'blur(8px)',
        color: 'white',
        '& fieldset': {
          borderColor: 'rgba(251, 191, 36, 0.2)',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(251, 191, 36, 0.4)',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fbbf24',
        },
      },
      '& .MuiOutlinedInput-input::placeholder': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    }}
  />
  <Button 
    variant="contained" 
    onClick={sendMessage}
    startIcon={<Send />}
    sx={{
      background: 'linear-gradient(to right, #fbbf24, #d97706)',
      '&:hover': {
        background: 'linear-gradient(to right, #d97706, #b45309)',
      }
    }}
  >
    Send
  </Button>
</Box>

      {/* Stream Settings Dialog */}
      <Dialog 
        open={isSettingsOpen} 
        onClose={() => setIsSettingsOpen(false)}
      >
        <DialogTitle>Stream Settings</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="YouTube Stream Key"
            fullWidth
            variant="outlined"
            value={streamKey}
            onChange={(e) => setStreamKey(e.target.value)}
            type="password"
            helperText="Enter your YouTube stream key"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSettingsOpen(false)}>Cancel</Button>
          <Button 
            onClick={() => {
              setIsSettingsOpen(false);
              // Validate stream key
              if (streamKey) {
                onSpeak('Stream key updated successfully');
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StreamIntegration;