import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AutoTPSLButton from '../trading/AutoTPSLButton';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Rocket, Psychology, AutoGraph, Speed } from '@mui/icons-material';

const PremiumModal = ({
  isOpen,
  setIsOpen,
  publicKey,
  tradingWallet,
  handleBuy,
  handleAutoTPSLSell,
  isPremium,
  handleBuyWithRaydium,
  navigate,
  setShowPumpAssessment,
  setShowDemoModal,
  setShowAutoBumpModal,
  handleSell,
  setShowTokenHolders
}) => {
  const handleClose = () => setIsOpen(false);

  const FeatureCard = ({ icon, title, description, onClick, disabled }) => (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        width: '100%',
        height: '100%',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        background: 'linear-gradient(145deg, rgba(251, 191, 36, 0.1), rgba(251, 191, 36, 0.05))',
        border: '1px solid rgba(251, 191, 36, 0.2)',
        borderRadius: '16px',
        transition: 'all 0.3s ease',
        textTransform: 'none',
        '&:hover': {
          transform: 'translateY(-5px)',
          background: 'linear-gradient(145deg, rgba(251, 191, 36, 0.15), rgba(251, 191, 36, 0.1))',
          boxShadow: '0 10px 20px rgba(251, 191, 36, 0.1)',
        },
      }}
    >
      <Box sx={{
        color: '#fbbf24',
        fontSize: '2rem',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}>
        {icon}
      </Box>
      <Typography variant="h6" sx={{ color: '#fbbf24', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
        {description}
      </Typography>
    </Button>
  );

  return (
    <Dialog 
      open={isOpen} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: 'linear-gradient(135deg, rgba(17, 24, 39, 0.95), rgba(0, 0, 0, 0.98))',
          borderRadius: '24px',
          border: '1px solid rgba(251, 191, 36, 0.2)',
          boxShadow: '0 0 40px rgba(251, 191, 36, 0.15)',
          backdropFilter: 'blur(12px)',
          margin: { xs: 2, sm: 'auto' },
          maxWidth: '42rem',
          overflow: 'hidden',
        }
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: '#fbbf24',
          background: 'rgba(251, 191, 36, 0.1)',
          '&:hover': {
            background: 'rgba(251, 191, 36, 0.2)',
            transform: 'rotate(90deg)',
          },
          transition: 'all 0.3s ease',
          zIndex: 10,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle>
        <Box sx={{ 
          textAlign: 'center',
          mt: 2,
          mb: 4,
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-10px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '150px',
            height: '2px',
            background: 'linear-gradient(90deg, transparent, #fbbf24, transparent)',
          }
        }}>
          <Typography variant="h4" sx={{ 
            color: '#fbbf24',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            fontSize: { xs: '1.5rem', sm: '2rem' },
          }}>
            <span role="img" aria-label="banana">🍌</span>
            Premium Features
            <span role="img" aria-label="ape">🦍</span>
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: { xs: 2, sm: 3 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FeatureCard
              icon={<Rocket />}
              title="Analyze Pump Tokens"
              description="Advanced analytics for potential pump tokens"
              onClick={() => {
                setShowPumpAssessment(true);
                handleClose();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FeatureCard
              icon={<Rocket />}
              title="Analyze Token Holders"
              description="Advanced analytics for token holders"
              onClick={() => {
                setShowTokenHolders(true);
                handleClose();
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FeatureCard
              icon={<Speed />}
              title="Auto-Ape (On Mint)"
              description="Automated trading on token mint"
              onClick={() => {
                setShowDemoModal(true);
                handleClose();
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FeatureCard
              icon={<Psychology />}
              title="AI Trading Agent"
              description="Create your personal AI trading assistant"
              onClick={() => {
                navigate('/ai-agents', { 
                  state: { publicKey: publicKey?.toString() } 
                });
                handleClose();
              }}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <FeatureCard
              icon={<AutoGraph />}
              title="Auto-Bumper"
              description="Automated price bumping strategy"
              onClick={() => {
                setShowAutoBumpModal(true);
                handleClose();
              }}
            />
          </Grid> */}
          

          <Grid item xs={12}>
          <AutoTPSLButton
                publicKey={publicKey}
                tradingWallet={tradingWallet}
                handleBuy={handleBuy}
                handleAutoTPSLSell={handleAutoTPSLSell}
                isPremium={isPremium}
                handleBuyWithRaydium={handleBuyWithRaydium}
                handleSell={handleSell}
              />
            
          </Grid>
          <Box sx={{ 
              background: 'rgba(251, 191, 36, 0.1)',
              borderRadius: '16px',
              p: 2,
              border: '1px solid rgba(251, 191, 36, 0.2)',
              color: '#fbbf24',
            }}>
              More Tools Coming Soon!
             
            </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PremiumModal;