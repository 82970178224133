import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Stack,
  CircularProgress,
  IconButton,
  Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon, Camera as CameraIcon } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import logoImage from '../../assets/logo.webp';
import { assessToken } from '../../utils/tokenAssessment';
import { toast } from 'react-toastify';

const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    overflow: 'auto', // Allow scrolling if needed
  }));

  const StyledPaper = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
    backdropFilter: 'blur(16px)',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    maxWidth: 600,
    maxHeight: '90vh', // Limit height to 90% of viewport
    width: '100%',
    color: 'white',
    position: 'relative',
    overflowY: 'auto', // Enable scrolling within the modal
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '4px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  }));

  const ResultCard = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.8), rgba(31, 41, 55, 0.8))',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: { // Responsive padding for mobile
      padding: theme.spacing(2),
    },
  }));

const PumpAssessmentModal = ({ open, onClose, publicKey, setCompletedTasks, setPoints }) => {
  const [mintAddress, setMintAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const resultCardRef = React.useRef(null);

  const handleAssessment = async () => {
    setLoading(true);
    try {
      console.log('[Assessment] Starting token assessment');
      const result = await assessToken(mintAddress);
      setAssessment(result);
  
      if (publicKey) {
        console.log('[Assessment] Attempting to update task completion');
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/update-task`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              walletAddress: publicKey.toString(),
              taskName: 'usedTokenAssessor'
            })
          });
  
          const data = await response.json();
          console.log('[Assessment] Task update response:', data);
  
          if (data.success) {
            if (setCompletedTasks && setPoints) {
              setCompletedTasks(prev => ({
                ...prev,
                usedTokenAssessor: true
              }));
              setPoints(data.points);
              toast.success('🎉 Task completed: Token Assessment!');
            } else {
              console.warn('[Assessment] setCompletedTasks or setPoints not provided');
            }
          }
        } catch (taskError) {
          console.error('[Assessment] Task update error:', taskError);
        }
      } else {
        console.log('[Assessment] No wallet connected, skipping task update');
      }
    } catch (error) {
      console.error('[Assessment] Error:', error);
      toast.error('Failed to assess token');
    } finally {
      setLoading(false);
    }
  };

  const handleScreenshot = async () => {
    if (resultCardRef.current) {
      const canvas = await html2canvas(resultCardRef.current);
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'pump-assessment.png';
      link.click();
    }
  };

  const RiskLevelBadge = styled(Box)(({ level, theme }) => {
    const colors = {
      'Low Risk': '#22c55e',
      'Moderate Risk': '#eab308',
      'High Risk': '#ef4444',
      'Extreme Risk': '#7f1d1d',
      'Unknown': '#6b7280',
    };
  
    return {
      backgroundColor: colors[level] || colors['Unknown'],
      color: 'white',
      padding: '4px 12px',
      borderRadius: '9999px',
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      fontWeight: 'bold',
    };
  });
  
  const MetricBox = styled(Box)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  }));

  return (
    <StyledModal open={open} onClose={onClose} sx={{
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(50, 46, 41, 0.7)',
        backdropFilter: 'blur(10px)'
      }
    }}>
      <StyledPaper elevation={24} sx={{
        background: 'linear-gradient(135deg, rgba(197, 158, 87, 0.1), rgba(50, 46, 41, 0.9))',
        backdropFilter: 'blur(20px)',
        border: '2px solid rgba(254, 255, 235, 0.2)',
        borderRadius: '16px',
        position: 'relative',
        // Remove overflow: 'hidden' to allow scrolling
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '-50%',
          left: '-50%',
          width: '200%',
          height: '200%',
          background: 'radial-gradient(circle, rgba(254, 255, 235, 0.1) 0%, transparent 70%)',
          zIndex: 0,
          pointerEvents: 'none',
        }
      }}>
        <IconButton
          onClick={onClose}
          sx={{ 
            position: 'absolute', 
            right: 8, 
            top: 8, 
            color: '#C59E57',
            transition: 'transform 0.2s ease',
            zIndex: 10,
            '&:hover': {
              transform: 'rotate(90deg)',
              color: '#FEFFEB'
            }
          }}
        >
          <CloseIcon />
        </IconButton>

        <Stack spacing={3}>
          <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2,
          borderBottom: '1px dashed rgba(197, 158, 87, 0.3)',
          pb: 2
        }}>
            <img src={logoImage} alt="Logo" style={{ 
              width: 40, 
              height: 40, 
              borderRadius: '50%',
              boxShadow: '0 4px 6px rgba(0,0,0,0.2)'
            }}  />
            <Typography variant="h5" component="h2" sx={{ 
              color: '#FEFFEB',
              fontFamily: "'Press Start 2P', cursive",
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
              letterSpacing: '1px'
            }}>
              Pump Token Assessment
            </Typography>
          </Box>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter token mint address"
            value={mintAddress}
            onChange={(e) => setMintAddress(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: '#FEFFEB',
                fontFamily: "'VT323', monospace",
                '& fieldset': {
                  borderColor: 'rgba(197, 158, 87, 0.5)',
                  borderWidth: 2,
                },
                '&:hover fieldset': {
                  borderColor: '#C59E57',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FEFFEB',
                },
              },
            }}
          />

          <Button
            variant="contained"
            onClick={handleAssessment}
            disabled={loading || !mintAddress}
            sx={{
              background: 'linear-gradient(135deg, #C59E57, #9B7940)',
              color: '#322E29',
              fontFamily: "'Press Start 2P', cursive",
              textTransform: 'none',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: 'linear-gradient(135deg, #FEFFEB, #C59E57)',
                transform: 'translateY(-3px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
              },
              '&.Mui-disabled': {
                background: 'rgba(197, 158, 87, 0.3)',
                color: 'rgba(50, 46, 41, 0.5)'
              }
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Assess Token'}
          </Button>

          {assessment && (
            <ResultCard ref={resultCardRef} sx={{
              background: 'rgba(66, 61, 54, 0.5)',
              border: '1px dashed rgba(197, 158, 87, 0.3)',
              borderRadius: 2,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0 6px 12px rgba(0,0,0,0.2)'
              }
            }}>
              <Stack spacing={3}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                borderBottom: '1px dashed rgba(197, 158, 87, 0.3)',
                pb: 2
              }}>
                <Typography sx={{ 
                    color: '#FEFFEB', 
                    fontFamily: "'Press Start 2P', cursive",
                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
                  }} variant="h6">Token Assessment</Typography>
                <IconButton onClick={handleScreenshot} sx={{ 
                    color: '#C59E57',
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.2)',
                      color: '#FEFFEB'
                    }
                  }}>
                  <CameraIcon />
                </IconButton>
              </Box>
                

                {/* Token Basic Info */}
              {assessment.tokenData && (
                <Box>
                  <Typography sx={{ 
                      color: '#C59E57', 
                      fontFamily: "'VT323', monospace",
                      fontWeight: 'bold'
                    }}  variant="h6" gutterBottom>
                    {assessment.tokenData.tokenInfo.name} ({assessment.tokenData.tokenInfo.symbol})
                  </Typography>
                  <Typography variant="body2" sx={{ 
                      color: '#FEFFEB', 
                      opacity: 0.7,
                      fontFamily: "'Courier Prime', monospace"
                    }}>
                    {assessment.tokenData.tokenInfo.mintAddress}
                  </Typography>
                </Box>
              )}

              {/* Risk Level and Score */}
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                color: 'white' 
              }}>
                <RiskLevelBadge sx={{
                    fontFamily: "'Press Start 2P', cursive",
                    textTransform: 'uppercase',
                    letterSpacing: '1px'
                  }} level={assessment.riskLevel}>
                  {assessment.riskLevel}
                </RiskLevelBadge>
                <Typography  sx={{ 
                    color: '#FEFFEB',
                    fontFamily: "'VT323', monospace",
                    fontSize: '1.2rem'
                  }}  variant="h6">
                  Score: {assessment.safetyScore}/{assessment.maxScore}
                </Typography>
              </Box>

              {/* Key Metrics Grid */}
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: '1fr 1fr', 
                gap: 2, 
                color: 'white' 
              }}>
              <MetricBox sx={{
                    border: '1px dashed rgba(197, 158, 87, 0.3)',
                    borderRadius: 1,
                    p: 1.5,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                    }
                  }}>
                <Typography variant="subtitle2" sx={{ 
                      color: '#C59E57',
                      fontFamily: "'Press Start 2P', cursive"
                    }}>Market Cap</Typography>
                <Typography sx={{ 
                      color: '#FEFFEB',
                      fontFamily: "'VT323', monospace"
                    }} variant="body1">
                    ${assessment?.metrics?.marketCapUSD ? 
                    Number(assessment.metrics.marketCapUSD).toLocaleString() : 
                    '0.00'}
                </Typography>
                </MetricBox>
                <MetricBox sx={{
                    border: '1px dashed rgba(197, 158, 87, 0.3)',
                    borderRadius: 1,
                    p: 1.5,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                    }
                  }}>
                <Typography sx={{ 
                      color: '#C59E57',
                      fontFamily: "'Press Start 2P', cursive"
                    }} variant="subtitle2">Liquidity</Typography>
                <Typography sx={{ 
                      color: '#FEFFEB',
                      fontFamily: "'VT323', monospace"
                    }} variant="body1">
                    {assessment?.metrics?.liquiditySOL || '0.00'} SOL
                </Typography>
                </MetricBox>
                <MetricBox sx={{
                    border: '1px dashed rgba(197, 158, 87, 0.3)',
                    borderRadius: 1,
                    p: 1.5,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                    }
                  }}>
                <Typography sx={{ 
                      color: '#C59E57',
                      fontFamily: "'Press Start 2P', cursive"
                    }} variant="subtitle2">Supply</Typography>
                <Typography sx={{ 
                      color: '#FEFFEB',
                      fontFamily: "'VT323', monospace"
                    }} variant="body1">
                    {assessment?.metrics?.totalSupply ? 
                    Number(assessment.metrics.totalSupply).toLocaleString() : 
                    '0'}
                </Typography>
                </MetricBox>
                <MetricBox sx={{
                    border: '1px dashed rgba(197, 158, 87, 0.3)',
                    borderRadius: 1,
                    p: 1.5,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                    }
                  }}>
                <Typography sx={{ 
                      color: '#C59E57',
                      fontFamily: "'Press Start 2P', cursive"
                    }} variant="subtitle2">Community</Typography>
                <Typography sx={{ 
                      color: '#FEFFEB',
                      fontFamily: "'VT323', monospace"
                    }} variant="body1">
                    {assessment?.metrics?.replyCount || '0'} replies
                </Typography>
                </MetricBox>
              </Box>

              {/* Positive Indicators */}
              <Box sx={{ color: 'white' }}>
                <Typography variant="subtitle1" sx={{ 
                    mb: 1, 
                    color: '#22c55e',
                    fontFamily: "'Press Start 2P', cursive"
                  }}>
                  ✓ Positive Indicators
                </Typography>
                <Stack spacing={0.5}>
                  {assessment.reasons.map((reason, index) => (
                    <Typography sx={{ 
                      color: '#FEFFEB',
                      fontFamily: "'VT323', monospace"
                    }} key={index} variant="body2">
                      {reason}
                    </Typography>
                  ))}
                </Stack>
              </Box>

              {/* Warnings */}
              {assessment.warnings.length > 0 && (
                <Box sx={{ color: 'white' }}>
                  <Typography variant="subtitle1" sx={{ 
                      mb: 1, 
                      color: '#eab308',
                      fontFamily: "'Press Start 2P', cursive"
                    }}>
                    ⚠ Warnings
                  </Typography>
                  <Stack spacing={0.5}>
                    {assessment.warnings.map((warning, index) => (
                      <Typography key={index} variant="body2">
                        {warning}
                      </Typography>
                    ))}
                  </Stack>
                </Box>
              )}
              {/* Overall Assessment */}
              <Box sx={{ 
                color: 'white',
                background: 'rgba(255, 255, 255, 0.05)', 
                p: 2, 
                borderRadius: 1,
                borderLeft: '4px solid',
                borderColor: assessment.riskLevel === 'Low Risk' ? '#22c55e' : 
                           assessment.riskLevel === 'Moderate Risk' ? '#eab308' : 
                           assessment.riskLevel === 'High Risk' ? '#ef4444' : '#7f1d1d'
              }}>
                <Typography variant="body1">
                  {assessment.overall}
                </Typography>
              </Box>

              {/* Social Links */}
              {assessment.tokenData && (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {Object.entries(assessment.tokenData.socialLinks).map(([platform, link]) => (
                    link !== 'N/A' && (
                      <Button
                        key={platform}
                        variant="outlined"
                        size="small"
                        href={link}
                        target="_blank"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {platform}
                      </Button>
                    )
                  ))}
                </Box>
              )}

                {/* Footer */}
              <Box sx={{ 
                mt: 2, 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                pt: 2
              }}>
                <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                  Powered by ApeOut
                </Typography>
                <img src={logoImage} alt="Logo" style={{ width: 24, height: 24 }} />
              </Box>
              </Stack>
            </ResultCard>
          )}
        </Stack>
      </StyledPaper>
    </StyledModal>
  );
};

export default PumpAssessmentModal;