import React from 'react';
import { 
  Modal, 
  Box, 
  Typography,
  IconButton
} from '@mui/material';
import { Close as CloseIcon, EmojiEvents } from '@mui/icons-material';
import PointsDisplay from '../PointsDisplay';
import Leaderboard from '../Leaderboard';
import { FaTrophy } from 'react-icons/fa';

const AchievementsModal = ({
  open,
  onClose,
  connected,
  hasExistingWallet,
  userTradingWallet,
  tradingWallet,
  points,
  completedTasks,
  isSignatureVerified,
  publicKey,
  isAchievementsOpen
}) => {
  return (
    <Modal
    open={isAchievementsOpen}
    onClose={onClose}
    aria-labelledby="achievements-modal"
  >
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: 600,
      background: 'linear-gradient(135deg, rgba(17, 24, 39, 0.95), rgba(0, 0, 0, 0.98))',
      borderRadius: '24px',
      border: '1px solid rgba(251, 191, 36, 0.2)',
      boxShadow: '0 0 40px rgba(251, 191, 36, 0.15)',
      p: 4,
      maxHeight: '90vh',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(251, 191, 36, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#fbbf24',
        borderRadius: '4px',
      },
    }}>
      {/* Header Section */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 4,
        position: 'relative',
      }}>
        <IconButton 
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: -16,
            top: -16,
            color: '#fbbf24',
            background: 'rgba(251, 191, 36, 0.1)',
            '&:hover': {
              background: 'rgba(251, 191, 36, 0.2)',
              transform: 'rotate(90deg)',
            },
            transition: 'all 0.3s ease',
          }}
        >
          <CloseIcon />
        </IconButton>
  
        <Typography variant="h4" sx={{
          color: 'white',
          fontWeight: 'bold',
          textAlign: 'center',
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}>
          <span role="img" aria-label="trophy">🏆</span>
          Achievements
          <span role="img" aria-label="star">⭐</span>
        </Typography>
  
        <Box sx={{
          display: 'flex',
          gap: 2,
          mb: 2,
        }}>
          <span role="img" aria-label="banana" style={{ fontSize: '24px' }}>🍌</span>
          <span role="img" aria-label="ape" style={{ fontSize: '24px' }}>🦍</span>
          <span role="img" aria-label="rocket" style={{ fontSize: '24px' }}>🚀</span>
        </Box>
  
        <Typography variant="subtitle1" sx={{
          color: 'rgba(255, 255, 255, 0.7)',
          textAlign: 'center',
          fontStyle: 'italic',
          mb: 3,
        }}>
          Complete tasks to earn rewards and climb the leaderboard!
        </Typography>
      </Box>
  
      {connected && hasExistingWallet && userTradingWallet && (
        <Box sx={{
          background: 'linear-gradient(145deg, rgba(251, 191, 36, 0.1), rgba(251, 191, 36, 0.05))',
          borderRadius: '16px',
          p: 3,
          border: '1px solid rgba(251, 191, 36, 0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 10px 20px rgba(251, 191, 36, 0.1)',
          }
        }}>
          {tradingWallet?.walletPublicKey && (
            <>
              <Box sx={{
                background: 'rgba(0, 0, 0, 0.3)',
                borderRadius: '12px',
                p: 3,
                mb: 3,
              }}>
                <PointsDisplay 
                  points={points}
                  completedTasks={completedTasks}
                  walletPublicKey={tradingWallet?.walletPublicKey}
                  publicKey={publicKey}
                />
              </Box>
  
              {isSignatureVerified && (
                <Box sx={{
                  mt: 4,
                  background: 'rgba(0, 0, 0, 0.3)',
                  borderRadius: '12px',
                  p: 3,
                  border: '1px solid rgba(251, 191, 36, 0.1)',
                }}>
                  <Typography variant="h6" sx={{
                    color: '#fbbf24',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 2,
                  }}>
                    <FaTrophy style={{ color: '#fbbf24' }} />
                    Leaderboard
                  </Typography>
                  <Leaderboard currentWallet={publicKey?.toString()} />
                </Box>
              )}
            </>
          )}
        </Box>
      )}
  
      {/* Footer Section */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 4,
        pt: 3,
        borderTop: '1px solid rgba(251, 191, 36, 0.1)',
      }}>
        <Typography sx={{
          color: 'rgba(255, 255, 255, 0.5)',
          fontSize: '0.9rem',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}>
          <span role="img" aria-label="info">ℹ️</span>
          Complete more tasks to unlock special rewards!
        </Typography>
      </Box>
    </Box>
  </Modal>
  );
};

export default AchievementsModal;