import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const MarketDataCard = styled(Card)({
  background: 'rgba(0, 0, 0, 0.4)',
  border: '1px solid rgba(251, 191, 36, 0.2)',
  backdropFilter: 'blur(8px)',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.5)',
    transform: 'translateY(-2px)',
    transition: 'all 0.3s ease'
  }
});

// Token addresses to track
const TOKENS = {
  SOL: 'So11111111111111111111111111111111111111112',
  USDC: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  BONK: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
  BNNA: 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'
};

const TokenMarketData = () => {
  const [marketData, setMarketData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDexScreenerData = async (address) => {
    try {
      const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${address}`);
      if (!response.ok) throw new Error('DexScreener API error');
      const data = await response.json();
      
      // Get the pair with highest liquidity
      const pair = data.pairs?.sort((a, b) => b.liquidity.usd - a.liquidity.usd)[0];
      
      if (!pair) return null;

      return {
        price: parseFloat(pair.priceUsd),
        priceChange24h: parseFloat(pair.priceChange?.h24 || 0),
        volume24h: pair.volume?.h24 || 0,
        liquidity: pair.liquidity?.usd || 0,
        marketCap: pair.marketCap || 0,
        fdv: pair.fdv || 0,
        symbol: pair.baseToken.symbol
      };
    } catch (error) {
      console.error('Error fetching DexScreener data:', error);
      return null;
    }
  };

  const fetchData = async () => {
    try {
      const results = {};
      for (const [symbol, address] of Object.entries(TOKENS)) {
        // Add delay between requests to respect rate limits
        if (Object.keys(results).length > 0) {
          await new Promise(resolve => setTimeout(resolve, 500));
        }

        const dexScreenerData = await fetchDexScreenerData(address);
        
        if (dexScreenerData) {
          results[symbol] = {
            ...dexScreenerData,
            address
          };
        }
      }

      setMarketData(results);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching market data:', err);
      setError(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography sx={{ color: 'white' }}>Loading market data...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography sx={{ color: 'red' }}>Error loading market data: {error}</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {Object.entries(marketData).map(([symbol, data]) => (
        <Grid item xs={12} key={symbol}>
          <MarketDataCard>
            <CardContent sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Box>
                <Typography sx={{ color: 'white', fontWeight: 'bold' }}>
                  ${symbol}
                </Typography>
                <Typography sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  ${data.price?.toFixed(4) || 'N/A'}
                </Typography>
                <Typography sx={{ color: 'rgba(255,255,255,0.5)', fontSize: '0.8rem' }}>
                  Vol: ${(data.volume24h / 1000000).toFixed(2)}M
                </Typography>
              </Box>
              <Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: data.priceChange24h >= 0 ? '#10b981' : '#ef4444',
                  marginBottom: 1
                }}>
                  {data.priceChange24h >= 0 ? <TrendingUp /> : <TrendingDown />}
                  <Typography sx={{ ml: 1 }}>
                    {Math.abs(data.priceChange24h).toFixed(2)}%
                  </Typography>
                </Box>
                <Typography sx={{ color: 'rgba(255,255,255,0.5)', fontSize: '0.8rem', textAlign: 'right' }}>
                  MC: ${(data.marketCap / 1000000).toFixed(2)}M
                </Typography>
              </Box>
            </CardContent>
          </MarketDataCard>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Box sx={{
          mt: 4,
          p: 3,
          background: 'rgba(0,0,0,0.2)',
          borderRadius: 2,
          border: '1px solid rgba(251, 191, 36, 0.2)'
        }}>
          <Typography sx={{ 
            color: 'white',
            fontStyle: 'italic'
          }}>
            Market data updated every minute
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TokenMarketData;