import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert
} from '@mui/material';
import { Close as CloseIcon, Launch as LaunchIcon } from '@mui/icons-material';


const TokenHoldersModal = ({ open, onClose, publicKey }) => {
  const [mintAddress, setMintAddress] = useState('');
  const [holders, setHolders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [walletToAnalyze, setWalletToAnalyze] = useState('');
    const [aiLoading, setAiLoading] = useState(false);
    const [bundleAnalysis, setBundleAnalysis] = useState(null);
    const [isEliteTier, setIsEliteTier] = useState(false);
    const [stakers, setStakers] = useState([]);



    const analyzeBundleProbability = (holders) => {
        // Key indicators of bundling
        let bundleIndicators = {
          roundedAmounts: 0,
          similarHoldings: 0,
          patternedWallets: 0,
          topHolderConcentration: 0
        };
      
        const totalSupply = holders.reduce((sum, h) => sum + h.amount, 0);
        const averageHolding = totalSupply / holders.length;
        
        // Track similar amounts for pattern detection
        let amountFrequencies = {};
        
        holders.forEach(holder => {
          // Check for rounded amounts (common in bundles)
          if (holder.amount % 1000 === 0 || holder.amount % 10000 === 0) {
            bundleIndicators.roundedAmounts++;
          }
          
          // Check for similar holdings (within 1% difference)
          const amountKey = Math.round(holder.amount / 100) * 100;
          amountFrequencies[amountKey] = (amountFrequencies[amountKey] || 0) + 1;
          
          // Check if holding is very close to average (within 5%)
          if (Math.abs(holder.amount - averageHolding) / averageHolding < 0.05) {
            bundleIndicators.similarHoldings++;
          }
        });
      
        // Calculate top holder concentration
        const topHoldersAmount = holders
          .slice(0, Math.ceil(holders.length * 0.1)) // Top 10% of holders
          .reduce((sum, h) => sum + h.amount, 0);
        const concentrationRatio = topHoldersAmount / totalSupply;
      
        // Look for patterns in amounts
        const hasFrequentSimilarAmounts = Object.values(amountFrequencies)
          .some(freq => freq >= holders.length * 0.15); // If 15% have very similar amounts
      
        // Weight the indicators
        let bundleProbability = 0;
        bundleProbability += (bundleIndicators.roundedAmounts / holders.length) * 30; // 30% weight
        bundleProbability += (bundleIndicators.similarHoldings / holders.length) * 25; // 25% weight
        bundleProbability += hasFrequentSimilarAmounts ? 25 : 0; // 25% weight
        bundleProbability += concentrationRatio * 20; // 20% weight
      
        return {
          probability: Math.min(bundleProbability, 100),
          details: {
            roundedAmounts: bundleIndicators.roundedAmounts,
            similarHoldings: bundleIndicators.similarHoldings,
            topHolderConcentration: (concentrationRatio * 100).toFixed(2) + '%',
            hasPatternedDistribution: hasFrequentSimilarAmounts
          }
        };
      };
      const checkEliteTierAccess = useCallback(() => {
        if (!publicKey || !stakers.length) return false;
        const stakerInfo = stakers.find(staker => staker.Staker === publicKey.toString());
        return stakerInfo && stakerInfo.Amount >= 1000000; // 1M tokens threshold
      }, [publicKey, stakers]);


        
      const fetchHolders = async () => {
        try {
          setLoading(true);
          setError(null);
          
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-holders/${mintAddress}`);
          const data = await response.json();
          
          if (!response.ok) {
            throw new Error(data.error || 'Failed to fetch holders');
          }
          
          setHolders(data.data);
          // Add bundle analysis
          const analysis = analyzeBundleProbability(data.data.holders);
          setBundleAnalysis(analysis);
        } catch (err) {
          setError(err.message);
          setHolders(null);
          setBundleAnalysis(null);
        } finally {
          setLoading(false);
        }
      };

  const getHolderType = (amount) => {
    // Adjust these thresholds based on your needs
    if (amount >= 1000000) return { type: 'WHALE', color: '#3b82f6' }; // Blue
    if (amount >= 100000) return { type: 'FISH', color: '#10b981' };   // Green
    // Bot detection based on exact rounded numbers or specific patterns
    if (amount % 1000 === 0 || amount % 10000 === 0) return { type: 'BOT', color: '#ef4444' }; // Red
    // Pool detection based on address format or known pool addresses
    if (amount >= 50000) return { type: 'POOL', color: '#f59e0b' };    // Yellow
    return { type: 'HOLDER', color: '#6b7280' };                       // Gray
  };

  useEffect(() => {
    setIsEliteTier(checkEliteTierAccess());
  }, [checkEliteTierAccess]);

  useEffect(() => {
    const fetchStakers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/stakers`);
        const data = await response.json();
        if (data.success) {
          setStakers(data.stakers);
        }
      } catch (error) {
        console.error('Error fetching stakers:', error);
      }
    };

    fetchStakers();
  }, []);

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: 'linear-gradient(135deg, rgba(17, 24, 39, 0.95), rgba(0, 0, 0, 0.98))',
          borderRadius: '24px',
          border: '1px solid rgba(251, 191, 36, 0.2)',
          boxShadow: '0 0 40px rgba(251, 191, 36, 0.15)',
          backdropFilter: 'blur(12px)',
          margin: { xs: 2, sm: 'auto' },
          maxWidth: '42rem',
          overflow: 'hidden',
        }
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: '#fbbf24',
          background: 'rgba(251, 191, 36, 0.1)',
          '&:hover': {
            background: 'rgba(251, 191, 36, 0.2)',
            transform: 'rotate(90deg)',
          },
          transition: 'all 0.3s ease',
          zIndex: 10,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle>
        <Box sx={{ 
          textAlign: 'center',
          mt: 2,
          mb: 4,
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-10px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '150px',
            height: '2px',
            background: 'linear-gradient(90deg, transparent, #fbbf24, transparent)',
          }
        }}>
          <Typography variant="h4" sx={{ 
            color: '#fbbf24',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            fontSize: { xs: '1.5rem', sm: '2rem' },
          }}>
            Token Holders Analysis
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: { xs: 2, sm: 3 } }}>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter token mint address"
            value={mintAddress}
            onChange={(e) => setMintAddress(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: '#FEFFEB',
                '& fieldset': {
                  borderColor: 'rgba(251, 191, 36, 0.5)',
                  borderWidth: 2,
                },
                '&:hover fieldset': {
                  borderColor: '#fbbf24',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FEFFEB',
                },
              },
            }}
          />
          <Button
            variant="contained"
            onClick={fetchHolders}
            disabled={loading || !mintAddress}
            sx={{
              mt: 2,
              background: 'linear-gradient(135deg, #fbbf24, #d97706)',
              color: '#000',
              fontWeight: 'bold',
              '&:hover': {
                background: 'linear-gradient(135deg, #d97706, #b45309)',
              },
              '&:disabled': {
                background: 'rgba(251, 191, 36, 0.3)',
                color: 'rgba(0, 0, 0, 0.3)',
              }
            }}
          >
            {loading ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} />
                Loading...
              </Box>
            ) : 'Analyze Holders'}
          </Button>
        </Box>
        <Box sx={{ mt: 3, mb: 3 }}>
  <Typography variant="subtitle1" sx={{ 
    color: '#fbbf24', 
    mb: 1,
    fontWeight: 'bold' 
  }}>
    AI Wallet Analysis
  </Typography>
  <TextField
    fullWidth
    variant="outlined"
    placeholder="Enter wallet address for AI analysis"
    value={walletToAnalyze}
    onChange={(e) => setWalletToAnalyze(e.target.value)}
    sx={{
      '& .MuiOutlinedInput-root': {
        color: '#FEFFEB',
        '& fieldset': {
          borderColor: 'rgba(251, 191, 36, 0.5)',
          borderWidth: 2,
        },
        '&:hover fieldset': {
          borderColor: '#fbbf24',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FEFFEB',
        },
      },
    }}
  />
  <Button
    variant="contained"
    disabled
    sx={{
      mt: 2,
      background: 'linear-gradient(135deg, #fbbf24, #d97706)',
      color: '#000',
      fontWeight: 'bold',
      width: '100%',
      '&.Mui-disabled': {
        background: 'rgba(251, 191, 36, 0.2)',
        color: 'rgba(255, 255, 255, 0.5)',
      }
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <CircularProgress size={16} sx={{ mr: 1 }} />
      <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'white' }}>
        AI Analysis Coming Soon (Elite Tier Required)
      </Typography>
      
    </Box>
  </Button>
  <Box sx={{ mt: 1, textAlign: 'center' }}>
  <Button
    onClick={() => window.open('https://apeout.fun/tier-structure', '_blank')}
    sx={{
      textTransform: 'none',
      color: '#fbbf24',
      fontSize: '0.75rem',
      textDecoration: 'underline',
      '&:hover': {
        color: '#d97706',
        background: 'transparent',
      },
    }}
  >
    View Tiers & Stake $BNNA for Access
  </Button>
</Box>
</Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2, bgcolor: 'rgba(239, 68, 68, 0.1)', color: '#fef2f2' }}>
            {error}
          </Alert>
        )}

        {holders && (
          <Box>
            <Typography variant="h6" sx={{ color: '#fbbf24', mb: 2 }}>
              Found {holders.totalHolders} holders
            </Typography>
            {bundleAnalysis && (
  <>
    {isEliteTier ? (
      <Box sx={{ 
        mb: 3,
        p: 2,
        borderRadius: '8px',
        border: '1px solid rgba(251, 191, 36, 0.2)',
        background: 'rgba(251, 191, 36, 0.1)',
      }}>
        <Typography variant="h6" sx={{ color: '#fbbf24', mb: 1 }}>
          Bundle Analysis
        </Typography>
        <Typography variant="body1" sx={{ color: '#FEFFEB', mb: 1 }}>
          Bundle Probability: {bundleAnalysis.probability.toFixed(1)}%
        </Typography>
        <Typography variant="body2" sx={{ color: '#FEFFEB', opacity: 0.8 }}>
          • {bundleAnalysis.details.roundedAmounts} holders have rounded amounts
        </Typography>
        <Typography variant="body2" sx={{ color: '#FEFFEB', opacity: 0.8 }}>
          • {bundleAnalysis.details.similarHoldings} holders have similar holdings
        </Typography>
        <Typography variant="body2" sx={{ color: '#FEFFEB', opacity: 0.8 }}>
          • Top holder concentration: {bundleAnalysis.details.topHolderConcentration}
        </Typography>
        <Typography variant="body2" sx={{ color: '#FEFFEB', opacity: 0.8 }}>
          • {bundleAnalysis.details.hasPatternedDistribution ? 'Detected' : 'No'} patterned distribution
        </Typography>
      </Box>
    ) : (
      <Box sx={{ 
        mb: 3,
        p: 2,
        borderRadius: '8px',
        border: '1px solid rgba(251, 191, 36, 0.2)',
        background: 'rgba(251, 191, 36, 0.1)',
        textAlign: 'center'
      }}>
        <Typography variant="h6" sx={{ color: '#fbbf24', mb: 1 }}>
          Bundle Analysis
        </Typography>
        <Typography variant="body2" sx={{ color: '#FEFFEB', mb: 2 }}>
          Stake 1M+ $BNNA tokens to unlock detailed bundle analysis
        </Typography>
        <Button
          onClick={() => window.open('https://apeout.fun/tier-structure', '_blank')}
          sx={{
            color: '#fbbf24',
            textDecoration: 'underline',
            '&:hover': {
              background: 'transparent',
              color: '#d97706'
            }
          }}
        >
          View Tier Structure
        </Button>
      </Box>
    )}
  </>
)}
            
            <TableContainer component={Paper} sx={{ 
              bgcolor: 'transparent',
              '& .MuiTableCell-root': {
                borderColor: 'rgba(251, 191, 36, 0.2)',
                color: '#FEFFEB',
              }
            }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Owner</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell align="center">Explorer</TableCell>
                    <TableCell align="center">Analyze Wallet</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                {holders.holders.map((holder) => {
  const holderType = getHolderType(holder.amount);
  return (
    <TableRow key={holder.owner}>
      <TableCell 
        component="th" 
        scope="row"
        sx={{ fontFamily: 'monospace' }}
      >
        {holder.owner}
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'inline-block',
            px: 1.5,
            py: 0.5,
            borderRadius: '4px',
            backgroundColor: `${holderType.color}20`,
            color: holderType.color,
            fontSize: '0.75rem',
            fontWeight: 'bold',
            border: `1px solid ${holderType.color}40`
          }}
        >
          {holderType.type}
        </Box>
      </TableCell>
      <TableCell align="right">
        {holder.amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </TableCell>
      <TableCell align="center">
  <IconButton
    onClick={() => window.open(`https://solscan.io/account/${holder.owner}`, '_blank')}
    size="small"
    sx={{
      color: '#fbbf24',
      '&:hover': {
        color: '#d97706',
        transform: 'translateY(-2px)',
      },
      transition: 'all 0.2s ease',
    }}
  >
    <LaunchIcon fontSize="small" />
  </IconButton>
</TableCell>
<TableCell align="center">
  <Button
    variant="contained"
    disabled
    size="small"
    sx={{
      background: 'rgba(251, 191, 36, 0.2)',
      color: 'white',
      '&.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
      minWidth: '120px'
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <CircularProgress size={16} sx={{ mr: 1 }} />
      <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'white' }}>
        Feature in development
      </Typography>
    </Box>
  </Button>
</TableCell>
    </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TokenHoldersModal;