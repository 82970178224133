import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const BountyCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    background: 'rgba(50, 46, 41, 0.95)', // #322E29 with opacity
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    border: '1px solid #C59E57', // Gold border
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 20px rgba(197, 158, 87, 0.2)', // Gold glow effect
    }
  }));

  const StyledChip = styled(Chip)(({ theme }) => ({
    background: 'rgba(155, 121, 64, 0.2)', // #9B7940 with opacity
    color: '#FEFFEB', // Light beige text
    border: '1px solid #9B7940',
    '&.reward': {
      background: '#C59E57', // Gold background
      color: '#322E29', // Dark text
      border: 'none',
      fontWeight: 'bold'
    }
  }));

  const bounties = [
    {
      id: 1,
      title: 'ASIA Expansion - Kakao Setup',
      description: 'Help ApeOut expand to the Korean market by creating and managing our Kakao presence. This includes setting up a Kakao channel, adding relevant information, and providing documentation for the handover to our team.',
      requirements: [
        'Fluent in Korean',
        'Experience with Kakao platform',
        'Understanding of crypto/DeFi space'
      ],
      reward: '50K $BNNA',
      status: 'OPEN',
      difficulty: 'Medium',
      timeEstimate: '2-3 days',
      action: './'
    },
    {
      id: 2,
      title: 'Meme Contest with a Twist',
      description: 'Create and promote memes about ApeOut and $BNNA that showcase creativity, humor, and memecoin culture. Share your memes on Twitter, Telegram, and Discord, tagging us and using the hashtag #ApeOutMemes. Winning entries will be rewarded with a custom AI trading bot with unique functionalities (e.g., "BananaBot").',
      requirements: [
        'Ability to create original memes',
        'Post memes to social media using the provided hashtag',
        'Tag @apeoutmeme in your posts'
      ],
      reward: 'Custom AI Trading Bot + 10K $BNNA',
      status: 'OPEN',
      difficulty: 'Easy',
      timeEstimate: '1-2 weeks',
      action: 'https://apeout.lol'
    },
    {
      id: 3,
      title: 'Celebrity "Banana Bucks" Campaign',
      description: 'Help us find a quirky celebrity or internet influencer to promote $BNNA in fun and unusual ways, such as a "Banana Hoarding" challenge or other creative ideas. Suggest potential partners and connect us with them to execute this campaign.',
      requirements: [
        'Connections with celebrities or influencers',
        'Research potential partners who align with our brand',
        'Provide contact details or introductions for collaboration'
      ],
      reward: '75K $BNNA + Special Mention on the ApeOut Platform',
      status: 'OPEN',
      difficulty: 'High',
      timeEstimate: '3-4 weeks',
      action: 'https://t.me/apeoutmeme'
    },
    {
      id: 4,
      title: 'Hourly Airdrop Challenge',
      description: 'Help us run an hourly $BNNA airdrop campaign by engaging with the community. Tweet using specific hashtags, tag influencers, and encourage others to participate. Track your engagement and ensure you qualify for hourly rewards.',
      requirements: [
        'Active presence on Twitter and Telegram',
        'Use specific campaign hashtags in tweets',
        'Track engagement and submit proof of participation'
      ],
      reward: '50K $BNNA + Additional Airdrops Based on Performance',
      status: 'OPEN',
      difficulty: 'Medium',
      timeEstimate: '1 week',
      action: 'https://t.me/apeoutmeme'
    },
    {
        id: 5,
        title: 'Vote for $BNNA on CoinMarketCap',
        description: 'Support ApeOut by voting for our $BNNA token on CoinMarketCap. Go to the provided link and upvote $BNNA to increase its visibility and popularity.',
        requirements: [
          'Visit the CoinMarketCap page for $BNNA',
          'Upvote the token to show your support',
          'Share a screenshot of your vote on Telegram or Discord'
        ],
        reward: '5K $BNNA',
        status: 'OPEN',
        difficulty: 'Easy',
        timeEstimate: '5 minutes',
        action: 'https://coinmarketcap.com/dexscan/solana/G3RHsoEwMmvEMBDkBec6SfwU91RbMKDYeztWdnmAWJXB/'
      },
      {
        id: 6,
        title: 'Daily Thumbs Up for $BNNA on GeckoTerminal',
        description: 'Visit the GeckoTerminal page for $BNNA daily and give a thumbs up to boost sentiment for the token. Consistent activity will help improve visibility and community confidence.',
        requirements: [
          'Visit the GeckoTerminal page daily',
          'Click the thumbs-up button to vote for positive sentiment',
          'Provide a weekly summary of your daily votes (e.g., screenshots or logs)'
        ],
        reward: '5K $BNNA per week',
        status: 'OPEN',
        difficulty: 'Easy',
        timeEstimate: '1-2 minutes daily',
        action: 'https://www.geckoterminal.com/solana/pools/G3RHsoEwMmvEMBDkBec6SfwU91RbMKDYeztWdnmAWJXB/'
      },
      {
        id: 7,
        title: 'Star the ApeOut GitHub Repository',
        description: 'Support the ApeOut project by starring our GitHub repository. This helps increase visibility among developers and highlights community support for the platform.',
        requirements: [
          'Log in to GitHub',
          'Visit the ApeOut repository',
          'Click the "Star" button to show your support',
          'Provide a screenshot of the starred repository as proof'
        ],
        reward: '2K $BNNA',
        status: 'OPEN',
        difficulty: 'Easy',
        timeEstimate: '2 minutes',
        action: 'https://github.com/apeoutmeme/apeoutdotfun-'
      },
      {
        id: 8,
        title: 'Follow the ApeOut AI Twitter Bot',
        description: 'Show your support for ApeOut by following our AI Twitter bot. Stay updated on the latest insights and engage with our AI-driven updates.',
        requirements: [
          'Log in to your Twitter account',
          'Follow the ApeOut AI Twitter bot',
          'Provide a screenshot showing that you are following the bot as proof'
        ],
        reward: '3K $BNNA',
        status: 'OPEN',
        difficulty: 'Easy',
        timeEstimate: '2 minutes',
        action: 'https://x.com/apesassbot'
      },
      {
        id: 9,
        title: 'Follow the ApeOut Medium Blog',
        description: 'Stay informed with the latest updates and announcements by following the ApeOut Medium blog. Your support helps spread awareness about our platform.',
        requirements: [
          'Log in to your Medium account',
          'Follow the ApeOut Medium blog',
          'Provide a screenshot showing that you are following the blog as proof'
        ],
        reward: '3K $BNNA',
        status: 'OPEN',
        difficulty: 'Easy',
        timeEstimate: '2 minutes',
        action: 'https://medium.com/@apeoutcrypto'
      } 
  ];
  
  
  

const Bounties = () => {
  const { connected } = useWallet();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading state
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  return (
    <Box sx={{ 
        bgcolor: '#322E29', // Dark background
        minHeight: '100vh', 
        color: '#FEFFEB', // Light beige text
        pt: 8, 
        pb: 15,
        background: 'linear-gradient(to bottom, #322E29, #423D36)' // Gradient background
      }}>
        <Container>
          <Typography 
            variant="h2" 
            gutterBottom 
            textAlign="center" 
            sx={{ 
              mb: 6,
              color: '#C59E57', // Gold heading
              textShadow: '0 2px 4px rgba(0,0,0,0.3)'
            }}
          >
            Community Bounties
          </Typography>
  
          {!connected ? (
            <Box sx={{ 
              textAlign: 'center', 
              my: 4,
              p: 4,
              border: '1px solid #C59E57',
              borderRadius: '16px',
              background: 'rgba(50, 46, 41, 0.8)'
            }}>
              <Typography 
                variant="h6" 
                gutterBottom 
                sx={{ color: '#FEFFEB' }}
              >
                Connect your wallet to view and claim bounties
              </Typography>
              <WalletMultiButton 
                style={{
                  background: '#C59E57',
                  color: '#322E29',
                  marginTop: '1rem'
                }}
              />
            </Box>
          ) : (
            <Grid container spacing={4}>
              {bounties.map((bounty) => (
                <Grid item xs={12} md={6} key={bounty.id}>
                  <BountyCard>
                    <Stack spacing={2}>
                      <Typography 
                        variant="h5" 
                        gutterBottom 
                        sx={{ color: '#C59E57' }}
                      >
                        {bounty.title}
                      </Typography>
                      
                      <Typography 
                        variant="body1" 
                        sx={{ color: '#FEFFEB' }}
                      >
                        {bounty.description}
                      </Typography>
  
                      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        <StyledChip label={bounty.difficulty} />
                        <StyledChip label={bounty.timeEstimate} />
                        <StyledChip label={bounty.status} />
                        <StyledChip className="reward" label={`${bounty.reward} Reward`} />
                      </Box>
  
                      <Typography 
                        variant="subtitle2" 
                        sx={{ 
                          mt: 2,
                          color: '#C59E57'
                        }}
                      >
                        Requirements:
                      </Typography>
                      <ul style={{ margin: 0, color: '#FEFFEB' }}>
                        {bounty.requirements.map((req, index) => (
                          <li key={index}>{req}</li>
                        ))}
                      </ul>
  
                      <Button 
  variant="contained" 
  fullWidth
  sx={{ 
    mt: 2,
    background: '#C59E57',
    color: '#322E29',
    fontWeight: 'bold',
    '&:hover': {
      background: '#9B7940',
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(197, 158, 87, 0.3)'
    }
  }}
  onClick={() => window.open('https://t.me/apeoutmeme', '_blank')}
>
  Apply for Bounty
</Button>
<Button 
  variant="outlined" 
  fullWidth
  sx={{ 
    mt: 1,
    borderColor: '#C59E57',
    color: '#C59E57',
    fontWeight: 'bold',
    '&:hover': {
      background: '#C59E57',
      color: '#322E29',
      borderColor: '#9B7940',
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(197, 158, 87, 0.3)'
    }
  }}
  onClick={() => window.open(bounty.action, '_blank')}
>
  Perform Bounty
</Button>

                    </Stack>
                  </BountyCard>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
    );
  };

export default Bounties;