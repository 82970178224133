import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Button, 
  Box, 
  Typography, 
  TextField,
  CircularProgress
} from '@mui/material';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { stripePromise } from '../../utils/stripe';
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
  backdropFilter: 'blur(16px)',
  padding: theme.spacing(3),
}));

const ProductCard = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '12px',
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'transform 0.2s',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '&:hover': {
    transform: 'translateY(-4px)',
    border: '1px solid rgba(251, 191, 36, 0.3)',
  }
}));

const StyledInput = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  }
});

const MERCHANDISE = [
  {
    id: 1,
    name: 'BNNA T-Shirt',
    price: 29.99,
    description: 'Premium cotton t-shirt with BNNA logo',
    image: 'https://raw.githubusercontent.com/apeoutmeme/resources/10e8ebb85ddbaea17469d95cb3355666e97c53f4/assets/merch/t1.png'
  },
  {
    id: 2,
    name: 'BNNA Hoodie',
    price: 59.99,
    description: 'Cozy hoodie with embroidered BNNA logo',
    image: 'https://raw.githubusercontent.com/apeoutmeme/resources/44cd2d4730fdf393be88f7eb23fa97b67bf739e6/assets/merch/hoodie.png'
  },
  {
    id: 3,
    name: 'Stickers',
    price: 13.99,
    description: 'High-quality trading journal with BNNA branding',
    image: 'https://raw.githubusercontent.com/apeoutmeme/resources/44cd2d4730fdf393be88f7eb23fa97b67bf739e6/assets/merch/sticker.png'
  },
  {
    id: 4,
    name: 'test item',
    price: 0.99,
    description: 'test item',
    image: '/api/placeholder/200/200'
  }
];

const API_URL = process.env.REACT_APP_API_URL || 'https://apeout-server-42e2c984147f.herokuapp.com';
const CheckoutForm = ({ onSuccess, onError, isProcessing, setIsProcessing }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + '/order-confirmation',
        },
        redirect: 'if_required',
      });
      
      if (error) {
        console.log('Stripe error:', error);
      }
      
      // Call onSuccess without generating orderId - we'll get it from the server
      onSuccess();
      
    } catch (err) {
      console.log('Payment processing error:', err);
      onSuccess();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type="submit"
        variant="contained"
        disabled={isProcessing || !stripe}
        startIcon={isProcessing ? <CircularProgress size={20} /> : <LocalShippingIcon />}
        sx={{
          mt: 2,
          width: '100%',
          background: 'linear-gradient(to right, #22c55e, #16a34a)',
          '&:hover': {
            background: 'linear-gradient(to right, #16a34a, #15803d)'
          }
        }}
      >
        {isProcessing ? 'Processing...' : 'Pay and Place Order'}
      </Button>
    </form>
  );
};

const MerchModal = ({ open, onClose, publicKey, isPremium }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [shippingInfo, setShippingInfo] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    email: ''
  });

  const handleShippingInfoChange = (field) => (event) => {
    setShippingInfo(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handlePurchase = async () => {
    // if (!publicKey) {
    //   toast.error('Please connect your wallet first');
    //   return;
    // }

    if (!Object.values(shippingInfo).every(field => field.trim())) {
      toast.error('Please fill in all shipping information');
      return;
    }

    setIsProcessing(true);

    try {
      // Create payment intent first
        const intentResponse = await fetch(`${API_URL}/api/create-merch-payment-intent`, {
          method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          items: [{
            ...selectedItem,
            quantity
          }],
          isPremium
        })
      });

      const intentData = await intentResponse.json();
      
      if (!intentData.success) {
        throw new Error(intentData.error);
      }

      setClientSecret(intentData.clientSecret);
      setIsProcessing(false);
      
    } catch (error) {
      toast.error(`Failed to initialize payment: ${error.message}`);
      setIsProcessing(false);
    }
  };

  const handlePaymentSuccess = async () => {
    try {
      const response = await fetch('/api/create-merch-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          walletAddress: publicKey ? publicKey.toString() : 'guest-user',
          items: [{
            ...selectedItem,
            quantity
          }],
          shippingInfo,
          paymentIntentId: clientSecret?.split('_secret')[0] || 'manual-order'
        })
      });
  
      const data = await response.json();
      
      if (data.success) {
        toast.success(`Order placed successfully! Order ID: ${data.orderId}`);
        onClose();
      } else {
        throw new Error(data.error || 'Failed to create order');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      toast.error('Failed to create order. Please contact support.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
          backdropFilter: 'blur(16px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'white',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ShoppingBasketIcon />
          BNNA Merchandise
        </Box>
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <StyledDialogContent>
        {!selectedItem ? (
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: 3,
            p: 2
          }}>
            {MERCHANDISE.map((item) => (
              <ProductCard
                key={item.id}
                onClick={() => setSelectedItem(item)}
              >
                <img src={item.image} alt={item.name} style={{ width: '100%', borderRadius: '8px' }} />
                <Typography variant="h6" sx={{ color: 'white', mt: 2 }}>{item.name}</Typography>
                <Typography sx={{ color: '#22c55e', fontSize: '1.2rem' }}>
                  ${isPremium ? (item.price * 0.9).toFixed(2) : item.price}
                  {isPremium && <span style={{ fontSize: '0.8rem' }}> (10% off)</span>}
                </Typography>
                <Typography sx={{ color: '#94a3b8', fontSize: '0.9rem' }}>{item.description}</Typography>
              </ProductCard>
            ))}
          </Box>
        ) : !clientSecret ? (
          <Box sx={{ color: 'white' }}>
            <Button 
              onClick={() => setSelectedItem(null)}
              sx={{ color: 'white', mb: 2 }}
            >
              ← Back to Items
            </Button>

            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
              gap: 4
            }}>
              <Box>
                <img 
                  src={selectedItem.image} 
                  alt={selectedItem.name} 
                  style={{ width: '100%', borderRadius: '12px' }} 
                />
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5">{selectedItem.name}</Typography>
                  <Typography sx={{ color: '#22c55e', fontSize: '1.2rem' }}>
                    ${isPremium ? (selectedItem.price * 0.9).toFixed(2) : selectedItem.price}
                    {isPremium && ' (10% off)'}
                  </Typography>
                  <StyledInput
                    type="number"
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                    sx={{ mt: 2, width: '100px' }}
                  />
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h6">Shipping Information</Typography>
                {Object.keys(shippingInfo).map((field) => (
                  <StyledInput
                    key={field}
                    label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                    value={shippingInfo[field]}
                    onChange={handleShippingInfoChange(field)}
                    fullWidth
                  />
                ))}

                <Button
                  variant="contained"
                  onClick={handlePurchase}
                  disabled={isProcessing}
                  startIcon={isProcessing ? <CircularProgress size={20} /> : <LocalShippingIcon />}
                  sx={{
                    mt: 2,
                    background: 'linear-gradient(to right, #22c55e, #16a34a)',
                    '&:hover': {
                      background: 'linear-gradient(to right, #16a34a, #15803d)'
                    }
                  }}
                >
                  {isProcessing ? 'Processing...' : 'Continue to Payment'}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm
              onSuccess={handlePaymentSuccess}
              onError={(message) => toast.error(message)}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
          </Elements>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};

export default MerchModal;