import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import pumpLogo from '../assets/pump-logo.png'


const TradeCard = styled(Paper)(({ theme, isbuy }) => ({
  padding: theme.spacing(2),
  background: isbuy === 'true' 
    ? 'linear-gradient(135deg, rgba(16, 185, 129, 0.1), rgba(5, 150, 105, 0.2))'
    : 'linear-gradient(135deg, rgba(239, 68, 68, 0.1), rgba(185, 28, 28, 0.2))',
  border: `1px solid ${isbuy === 'true' ? 'rgba(16, 185, 129, 0.2)' : 'rgba(239, 68, 68, 0.2)'}`,
  borderRadius: theme.spacing(2),
  backdropFilter: 'blur(10px)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4]
  }
}));


const FETCH_INTERVAL = 30000; // 30 seconds instead of 10
const RETRY_DELAY = 5000; // 5 seconds delay for retries


const LatestTrades = () => {
  const [latestTrade, setLatestTrade] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastProcessedSignature, setLastProcessedSignature] = useState(null);
  const [isProcessingTrade, setIsProcessingTrade] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(0);


  // Remove the date-fns import and add this helper function
const getRelativeTime = (timestamp) => {
    const now = new Date().getTime();
    const diff = now - (timestamp * 1000);
    
    // Convert milliseconds to appropriate units
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  
    if (days > 0) return rtf.format(-days, 'day');
    if (hours > 0) return rtf.format(-hours, 'hour');
    if (minutes > 0) return rtf.format(-minutes, 'minute');
    return rtf.format(-seconds, 'second');
  };

  // Add this function to handle the toast sequence
// Optimized toast sequence handler

const showTradeSequence = useCallback((trade) => {
    // Prevent multiple sequences from running
    if (isProcessingTrade) {
      console.debug('Trade sequence already in progress, skipping...');
      return;
    }
  
    const solAmount = trade.solAmount.toFixed(2);
    const symbol = trade.symbol;
    const tradeType = trade.isBuy ? 'Buy' : 'Sell';
  
    setIsProcessingTrade(true);
    console.debug(`Starting trade sequence for ${symbol}`);
  
    // Clear any existing toasts
    toast.dismiss();
  
    // First toast - Whale detected
    toast.info(`🐋 WHALE Alert: ${solAmount} SOL ${tradeType} detected for ${symbol}!`, {
      autoClose: 3000,
      position: "top-right",
      toastId: 'whale-alert' // Prevent duplicate toasts
    });
  
    // Second toast - Copying trade
    const copyingTimeout = setTimeout(() => {
      toast.loading(`🤖 Auto-Aping: Copying ${tradeType} trade for ${symbol}...`, {
        autoClose: 2000,
        position: "top-right",
        toastId: 'copying-trade'
      });
    }, 3000);
  
    // Third toast - Trade successful
    const successTimeout = setTimeout(() => {
      toast.success(`✅ Trade Successful: ${tradeType} ${solAmount} SOL of ${symbol} completed!`, {
        autoClose: 4000,
        position: "top-right",
        toastId: 'trade-success'
      });
      
      // Reset processing flag after sequence completes
      setIsProcessingTrade(false);
      console.debug(`Trade sequence completed for ${symbol}`);
    }, 5000);
  
    // Cleanup function
    return () => {
      clearTimeout(copyingTimeout);
      clearTimeout(successTimeout);
      setIsProcessingTrade(false);
    };
  }, [isProcessingTrade]);

  const fetchLatestTrade = useCallback(async () => {
    const now = Date.now();
    // Check if enough time has passed since last fetch
    if (now - lastFetchTime < RETRY_DELAY) {
      console.debug('Skipping fetch - too soon since last attempt');
      return;
    }

    try {
      setLastFetchTime(now);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/latest-trades`);
      
      if (response.status === 429) {
        console.debug('Rate limited, will retry later');
        return;
      }

      const data = await response.json();
      
      if (data.success) {
        if (data.trade.signature !== lastProcessedSignature && !isProcessingTrade) {
          console.debug('New trade detected:', data.trade.symbol);
          setLatestTrade(data.trade);
          setLastProcessedSignature(data.trade.signature);
          
        //   if (data.trade.solAmount > 1) {
        //     showTradeSequence(data.trade);
        //   }
        }
      }
    } catch (error) {
      console.error('Error fetching latest trade:', error);
    } finally {
      setLoading(false);
    }
  }, [lastProcessedSignature, showTradeSequence, isProcessingTrade, lastFetchTime]);


  useEffect(() => {
    fetchLatestTrade();
    const interval = setInterval(fetchLatestTrade, FETCH_INTERVAL);

    return () => {
      clearInterval(interval);
      toast.dismiss();
      setIsProcessingTrade(false);
    };
  }, [fetchLatestTrade]);
    

  if (loading) {
    return <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />;
  }

  if (!latestTrade) {
    return null;
  }

  return (
    <TradeCard isbuy={latestTrade.isBuy.toString()} elevation={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          component="img"
          src={pumpLogo}
          alt={latestTrade.symbol}
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            border: '2px solid rgba(255, 255, 255, 0.1)'
          }}
        />
        <Box>
          <Typography variant="subtitle1" sx={{ color: 'white' }}>
            {latestTrade.isBuy ? '🟢 Buy' : '🔴 Sell'} {latestTrade.symbol}
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            {latestTrade.solAmount.toFixed(3)} SOL • {getRelativeTime(latestTrade.timestamp)}
          </Typography>
        </Box>
      </Box>
    </TradeCard>
  );
};

export default LatestTrades;