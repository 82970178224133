import React, { useState } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Slider,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
  Chip,
  Avatar,
  Grid
} from '@mui/material';
import { 
  Close as CloseIcon, 
  TrendingUp as TrendingUpIcon, 
  TrendingDown as TrendingDownIcon 
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import useAutoTPSL from './UseAutoTPSL';
import { toast } from 'react-toastify';
import { Sell as SellIcon } from '@mui/icons-material';
import TradeSettings from './TradeSettings';
import { 
  Timeline as TimelineIcon,
  ShowChart as ShowChartIcon,

} from '@mui/icons-material';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
    backdropFilter: 'blur(16px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '16px',
  }
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.4)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3b82f6',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-focused': {
      color: '#3b82f6',
    },
  },
});

const StyledSlider = styled(Slider)(({ color }) => ({
  color: color,
  '& .MuiSlider-thumb': {
    backgroundColor: color,
  },
  '& .MuiSlider-track': {
    backgroundColor: color,
  },
}));

const ActionButton = styled(Button)(({ ismonitoring }) => ({
  padding: '12px',
  background: ismonitoring === 'true'
    ? 'linear-gradient(to right, #ef4444, #dc2626)'
    : 'linear-gradient(to right, #22c55e, #16a34a)',
  '&:hover': {
    background: ismonitoring === 'true'
      ? 'linear-gradient(to right, #dc2626, #b91c1c)'
      : 'linear-gradient(to right, #16a34a, #15803d)',
  },
}));

const ManualSellButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    background: 'linear-gradient(to right, #ef4444, #dc2626)',
    '&:hover': {
      background: 'linear-gradient(to right, #dc2626, #b91c1c)',
    },
  }));

const AutoTPSLModal = ({ 
    open, 
    onClose, 
    handleBuy, 
    handleBuyWithRaydium,
    handleAutoTPSLSell, 
    publicKey,
    tradingWallet,
    tradeSettings = { // Add default values
        priorityFee: 0.00001,
        slippage: 15,
        amount: 0.0001,
        },
      setTradeSettings,
      handleSell
  }) => {
    const [mintAddress, setMintAddress] = useState('');
    const [buyAmount, setBuyAmount] = useState(0.1);
    const [takeProfitPercent, setTakeProfitPercent] = useState(50);
    const [stopLossPercent, setStopLossPercent] = useState(20);
    const [isLoading, setIsLoading] = useState(false);
    const [localSettings, setLocalSettings] = useState({
      priorityFee: tradeSettings.priorityFee,
      slippage: Math.round(tradeSettings.slippage * 10) / 10, // Ensure proper decimal handling
      amount: tradeSettings.amount || buyAmount // Sync amount with buyAmount
  });
  
    const {
      isMonitoring,
      currentMarketCap,
      initialMarketCap,
      targetTp,
      targetSl,
      startMonitoring,
      stopMonitoring
    } = useAutoTPSL({ handleBuy, handleBuyWithRaydium, handleAutoTPSLSell, tradingWallet, tradeSettings, setTradeSettings, handleSell });
  
    const handleSubmit = async () => {
        if (!mintAddress) {
          toast.error('Please enter a mint address');
          return;
        }
      
        if (buyAmount <= 0) {
          toast.error('Buy amount must be greater than 0');
          return;
        }
      
        if (!tradingWallet) {
          toast.error('Trading wallet is required');
          return;
        }
      
        setIsLoading(true);
        try {
          await startMonitoring(mintAddress, tradeSettings.amount, takeProfitPercent, stopLossPercent);
          toast.success('Monitoring started successfully');
        } catch (error) {
          console.error('Error starting TP/SL:', error);
          toast.error(error.message || 'Failed to start monitoring');
        } finally {
          setIsLoading(false);
        }
      };
  
    const handleStop = () => {
      stopMonitoring();
    };
    const handleManualSell = async () => {
      try {
        // Add confirmation dialog
        const confirmSell = window.confirm('Are you sure you want to sell?');
        if (!confirmSell) {
          return;
        }
    
        let result;
        const isPumpToken = window.confirm("Is this a pump.fun token?");
        if (isPumpToken) {
          // Use regular handleSell for pump.fun tokens
          result = await handleSell(mintAddress, buyAmount);
        } else {
          // Use handleAutoTPSLSell for other tokens
          result = await handleAutoTPSLSell(mintAddress, buyAmount);
        }
    
        if (result) {
          toast.success('Manual sell executed successfully');
        }
      } catch (error) {
        console.error('Error executing manual sell:', error);
        const errorMessage = error.message || 'Failed to execute manual sell';
        toast.error(errorMessage);
      }
    };
  
    // Add status display for monitoring
    const renderMonitoringStatus = () => {
      if (!isMonitoring) return null;
  
      return (
        <Box 
  sx={{ 
    mt: 2, 
    p: 3, 
    bgcolor: 'rgba(0, 0, 0, 0.2)', 
    borderRadius: 2,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)'
  }}
>
<Box 
  sx={{ 
    mt: 2, 
    p: 3, 
    bgcolor: 'rgba(0, 0, 0, 0.2)', 
    borderRadius: 2,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)'
  }}
>
  <Typography 
    variant="h6" 
    color="white" 
    sx={{ 
      mb: 2,
      display: 'flex',
      alignItems: 'center',
      gap: 1
    }}
  >
    <CircularProgress 
      size={16} 
      sx={{ 
        color: '#3b82f6',
        animation: 'pulse 1.5s ease-in-out infinite'
      }} 
    />
    Live Monitoring Status
  </Typography>

  <Grid container spacing={2}>
    {[
      {
        label: 'Initial Market Cap',
        value: initialMarketCap,
        icon: <TimelineIcon sx={{ color: '#3b82f6' }} />,
        color: 'white'
      },
      {
        label: 'Current Market Cap',
        value: currentMarketCap,
        icon: <ShowChartIcon sx={{ color: '#3b82f6' }} />,
        color: currentMarketCap > initialMarketCap ? '#22c55e' : '#ef4444'
      },
      {
        label: 'Take Profit Target',
        value: targetTp,
        icon: <TrendingUpIcon sx={{ color: '#22c55e' }} />,
        color: targetTp && currentMarketCap >= targetTp ? '#22c55e' : 'white'
      },
      {
        label: 'Stop Loss Target',
        value: targetSl,
        icon: <TrendingDownIcon sx={{ color: '#ef4444' }} />,
        color: targetSl && currentMarketCap <= targetSl ? '#ef4444' : 'white'
      }
    ].map((item, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <Box
          sx={{
            p: 2,
            bgcolor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 1,
            height: '100%',
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'translateY(-2px)',
              bgcolor: 'rgba(255, 255, 255, 0.08)',
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            {item.icon}
            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
              {item.label}
            </Typography>
          </Box>
          <Typography 
            variant="h6" 
            sx={{ 
              color: item.color,
              fontWeight: 'bold',
              transition: 'color 0.3s'
            }}
          >
            ${item.value?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) || '0.00'}
          </Typography>
        </Box>
      </Grid>
    ))}
  </Grid>

  {/* Percentage Change Indicator */}
  {initialMarketCap && currentMarketCap && (
    <Box sx={{ mt: 2, textAlign: 'center' }}>
      <Typography 
        variant="body1" 
        sx={{ 
          color: currentMarketCap > initialMarketCap ? '#22c55e' : '#ef4444',
          fontWeight: 'bold'
        }}
      >
        {((currentMarketCap - initialMarketCap) / initialMarketCap * 100).toFixed(2)}% Change
      </Typography>
    </Box>
  )}
</Box>

{/* Add this CSS keyframe for the pulse animation */}
<style>
  {`
    @keyframes pulse {
      0% { opacity: 0.6; }
      50% { opacity: 1; }
      100% { opacity: 0.6; }
    }
  `}
</style>
          <ManualSellButton
          variant="contained"
          onClick={handleManualSell}
          fullWidth
          startIcon={<SellIcon />}
        >
          Manual Sell
        </ManualSellButton>
        </Box>
      );
    };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >

      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        color: 'white'
      }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TrendingUpIcon sx={{ color: '#3b82f6' }} /> Auto TP/SL
        </Typography>
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pb: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
        <Box sx={{ mb: -2 }}>
        <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1824073444910055424/GdSdd1l6_400x400.jpg" />}
          label="$WIF"
          onClick={() => setMintAddress('21AErpiB8uSb94oQKRcwuHqyHF93njAxBSbdUrpupump')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
         
         <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1880437297625055232/vHBvbO0J_400x400.jpg" />}
          label="$TRUMP"
          onClick={() => setMintAddress('6p6xgHyF7AeE6TZkSmFsko444wqoP15icUSqi2jfGiPN')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
         <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1848199326838988800/GhfGjGyR_400x400.jpg" />}
          label="$BITCOIN"
          onClick={() => setMintAddress('0x72e4f9F808C49A2a61dE9C5896298920Dc4EEEa9')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
        <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1747652306521255936/LlxV2aBK_400x400.jpg" />}
          label="$GIGA"
          onClick={() => setMintAddress('63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
        <Chip
          avatar={<Avatar src="https://pbs.twimg.com/profile_images/1706379006063288321/rwnCvTH2_400x400.jpg" />}
          label="$MOG"
          onClick={() => setMintAddress('0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a')}
          sx={{
            color: 'white',
            bgcolor: 'rgba(59, 130, 246, 0.1)',
            '&:hover': {
              bgcolor: 'rgba(59, 130, 246, 0.2)',
            },
            cursor: 'pointer'
          }}
        />
      </Box>
          <StyledTextField
            label="Token Mint Address"
            value={mintAddress}
            onChange={(e) => setMintAddress(e.target.value)}
            fullWidth
          />

          {/* <Box>
            <Typography sx={{ color: 'white', mb: 1 }}>Buy Amount (SOL)</Typography>
            <StyledTextField
              type="number"
              value={buyAmount}
              onChange={(e) => setBuyAmount(parseFloat(e.target.value))}
              inputProps={{ step: 0.1, min: 0 }}
              fullWidth
            />
          </Box> */}

          <Box>
            <Typography sx={{ color: 'white', mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrendingUpIcon sx={{ color: '#22c55e' }} />
              Take Profit ({takeProfitPercent}%)
            </Typography>
            <StyledSlider
              value={takeProfitPercent}
              onChange={(_, value) => setTakeProfitPercent(value)}
              min={10}
              max={1000}
              color="#22c55e"
            />
          </Box>

          <Box>
            <Typography sx={{ color: 'white', mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrendingDownIcon sx={{ color: '#ef4444' }} />
              Stop Loss ({stopLossPercent}%)
            </Typography>
            <StyledSlider
              value={stopLossPercent}
              onChange={(_, value) => setStopLossPercent(value)}
              min={5}
              max={50}
              color="#ef4444"
            />
          </Box>

          {!tradingWallet && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Please create a trading wallet first to use this feature.
            </Alert>
          )}

<ActionButton
    variant="contained"
    onClick={isMonitoring ? handleStop : handleSubmit}
    disabled={isLoading || !tradingWallet}
    ismonitoring={isMonitoring.toString()}
    fullWidth
  >
    {isLoading ? (
      <CircularProgress size={24} sx={{ color: 'white' }} />
    ) : isMonitoring ? (
      'Stop Monitoring'
    ) : (
      'Start Monitoring'
    )}
  </ActionButton>
        </Box>
        {renderMonitoringStatus()}
    
        <Box sx={{ mt: 3 }}>
    <Typography sx={{ color: 'white', mb: 2 }}>Trade Settings</Typography>
    <TradeSettings
      priorityFee={localSettings.priorityFee}
      setPriorityFee={(fee) => {
        setLocalSettings(prev => ({ ...prev, priorityFee: fee }));
        setTradeSettings(prev => ({ ...prev, priorityFee: fee }));
      }}
      slippage={localSettings.slippage}
      setSlippage={(slip) => {
        const roundedSlippage = Math.round(slip * 10) / 10;
        setLocalSettings(prev => ({ ...prev, slippage: roundedSlippage }));
        setTradeSettings(prev => ({ ...prev, slippage: roundedSlippage }));
        localStorage.setItem('tradeSettings', JSON.stringify({
          ...tradeSettings,
          slippage: roundedSlippage
        }));
      }}
      amount={localSettings.amount} // Pass buyAmount consistently
      setAmount={(amt) => {
        setBuyAmount(amt);
        setLocalSettings(prev => ({ ...prev, amount: amt }));
        setTradeSettings(prev => ({ ...prev, amount: amt }));
      }}
    />
  </Box>
      </DialogContent>
     
      
    </StyledDialog>
  );
};

export default AutoTPSLModal;