import React from 'react';
import { Box } from '@mui/material';

const GMGNChart = ({ tokenAddress }) => {
  
  if (!tokenAddress) return null;

  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%',
      minHeight: '400px',
      position: 'relative',
      borderRadius: '8px',
      overflow: 'hidden',
      background: 'rgba(0, 0, 0, 0.2)',
      border: '1px solid rgba(255, 255, 255, 0.1)'
    }}>
      <iframe
        src={`https://www.gmgn.cc/kline/sol/${tokenAddress}`}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          position: 'absolute',
          top: 0,
          left: 0
        }}
        title="Token Price Chart"
      />
    </Box>
  );
};

export default GMGNChart;