import React, { useState } from 'react';
import { 
  IconButton, 
  Menu, 
  MenuItem, 
  Box,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography
} from '@mui/material';
import { Savings, LocalAtm, LocalFireDepartment, Lock } from '@mui/icons-material';

const BnnaActionsMenu = ({
  setShowBuyModal,
  setIsEarnModalOpen,
  navigate,
  isBnnaLoading,
  bnnaBalance
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const BalanceChip = ({ balance, loading }) => (
    <span style={{ 
      marginLeft: '8px', 
      fontSize: '0.8em', 
      backgroundColor: 'rgba(255,255,255,0.1)',
      padding: '2px 6px',
      borderRadius: '4px',
      display: 'inline-flex',
      alignItems: 'center'
    }}>
      {loading ? (
        <CircularProgress size={12} sx={{ color: 'white' }} />
      ) : balance > 0 ? (
        balance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      ) : '0.00'}
    </span>
  );

  const bnnaActions = [
    {
      label: 'Buy $BNNA',
      icon: <LocalAtm />,
      onClick: () => {
        setShowBuyModal(true);
        handleClose();
      },
      sx: {
        background: 'linear-gradient(to right, #10b981, #059669)',
        '&:hover': {
          background: 'linear-gradient(to right, #059669, #047857)',
        },
      },
      showBalance: true
    },
    {
      label: 'Burn $BNNA',
      icon: <LocalFireDepartment />,
      onClick: () => {
        handleClose();
      },
      disabled: true,
      sx: {
        background: 'linear-gradient(to right, #dc2626, #b91c1c)',
        '&:hover': {
          background: 'linear-gradient(to right, #b91c1c, #991b1b)',
        },
        transition: 'all 0.3s ease',
        boxShadow: '0 4px 6px rgba(220, 38, 38, 0.2)',
      },
      showBalance: true
    },
    {
      label: 'Lock $BNNA',
      icon: <Lock />,
      onClick: () => {
        navigate('/banana-stand');
        handleClose();
      },
      sx: {
        background: 'linear-gradient(to right, #fbbf24, #d97706)',
        '&:hover': {
          background: 'linear-gradient(to right, #d97706, #b45309)',
        },
        transition: 'all 0.3s ease',
        boxShadow: '0 4px 6px rgba(251, 191, 36, 0.2)',
      },
      showBalance: true
    },
    {
      label: 'Earn More $BNNA',
      icon: <Savings />,
      onClick: () => {
        setIsEarnModalOpen(true);
        handleClose();
      },
      sx: {
        background: 'linear-gradient(to right, #fbbf24, #d97706)',
        '&:hover': {
          background: 'linear-gradient(to right, #d97706, #b45309)',
        },
      },
      showBalance: false
    }
  ];

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      
        <>
          <Button
            onClick={handleMenuClick}
            startIcon={<LocalAtm />}
            variant="contained"
            sx={{
              width: '100%',
              py: 2,
              background: 'linear-gradient(to right, #10b981, #059669)',
              '&:hover': {
                background: 'linear-gradient(to right, #059669, #047857)',
              },
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography>BNNA Actions</Typography>
            <BalanceChip balance={bnnaBalance} loading={isBnnaLoading} />
          </Button>
          
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                width: '90%',
                maxWidth: '300px',
                background: 'linear-gradient(135deg, rgba(17, 24, 39, 0.95), rgba(0, 0, 0, 0.98))',
                mt: 1,
                border: '1px solid rgba(16, 185, 129, 0.2)',
                boxShadow: '0 4px 20px rgba(16, 185, 129, 0.15)'
              }
            }}
          >
            {bnnaActions.map((action, index) => (
              <MenuItem 
                key={index} 
                onClick={action.onClick}
                disabled={action.disabled}
                sx={{
                  color: 'white',
                  '&:hover': {
                    background: 'rgba(16, 185, 129, 0.1)',
                  },
                  gap: 2,
                  py: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  opacity: action.disabled ? 0.5 : 1
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {action.icon}
                  {action.label}
                </Box>
                {action.showBalance && (
                  <BalanceChip balance={bnnaBalance} loading={isBnnaLoading} />
                )}
              </MenuItem>
            ))}
          </Menu>
        </>
      
    </Box>
  );
};

export default BnnaActionsMenu;