import React from 'react';
import '../../styles/SettingsModal.css';
import { QRCodeSVG } from 'qrcode.react';
import { toast } from 'react-toastify';


const SettingsModal = ({ onClose, buyAmount, setBuyAmount, customAmount, setCustomAmount, tradingWallet, wallet, sendFunds }) => {
    console.log('SettingsModal props:', { buyAmount, customAmount, tradingWallet, wallet });    
    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text).then(() => {
          toast.success(`${type} copied to clipboard!`);
        }, (err) => {
          toast.error(`Failed to copy ${type}`);
        });
      };
    
    const handleBuyAmountChange = (value) => {
    if (value === 'custom') {
      setBuyAmount('custom');
    } else {
      setBuyAmount(parseFloat(value));
    }
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || (!isNaN(parseFloat(value)) && isFinite(value))) {
      setCustomAmount(value);
      if (value !== '') {
        setBuyAmount(parseFloat(value));
      }
    }
  };

  return (
    <div className="settings-modal-overlay">
      <div className="settings-modal">
        <h2>Settings</h2>
        <div>
          {[0.001, 0.1, 0.5, 1].map((amount) => (
            <label key={amount}>
              <input
                type="radio"
                value={amount}
                checked={buyAmount === amount}
                onChange={(e) => handleBuyAmountChange(e.target.value)}
              />
              {amount} SOL
            </label>
          ))}
          <label>
            <input
              type="radio"
              value="custom"
              checked={buyAmount === 'custom' || typeof buyAmount === 'number' && ![0.001, 0.1, 0.5, 1].includes(buyAmount)}
              onChange={(e) => handleBuyAmountChange(e.target.value)}
            />
            Custom
          </label>
          {(buyAmount === 'custom' || typeof buyAmount === 'number' && ![0.001, 0.1, 0.5, 1].includes(buyAmount)) && (
            <input
              type="number"
              value={customAmount}
              onChange={handleCustomAmountChange}
              placeholder="Enter custom amount"
              step="0.001"
              min="0"
            />
          )}
        </div>
        {wallet && wallet.public_key && wallet.private_key && (
        <div>
            <h3>Wallet Information</h3>
            <p>Public Key: {wallet.public_key}</p>
            <p>Private Key: {wallet.private_key.slice(0, 4)}...{wallet.private_key.slice(-4)}</p>
        </div>
        )}
        <h3>Trading Wallet</h3>
        {console.log('Trading wallet in SettingsModal:', wallet?.trading_wallet || tradingWallet)}
        {(wallet?.trading_wallet || tradingWallet) ? (
        <>
            <QRCodeSVG value={(wallet?.trading_wallet || tradingWallet).publicKey} />
            <p>Public Key: {(wallet?.trading_wallet || tradingWallet).publicKey}</p>
            <p>Private Key: {(wallet?.trading_wallet || tradingWallet).privateKey.slice(0, 4)}...{(wallet?.trading_wallet || tradingWallet).privateKey.slice(-4)}</p>
            <p>API Key: {(wallet?.trading_wallet || tradingWallet).apiKey.slice(0, 4)}...{(wallet?.trading_wallet || tradingWallet).apiKey.slice(-4)}</p>

            <button onClick={() => copyToClipboard((wallet?.trading_wallet || tradingWallet).publicKey, 'Public key')}>
            Copy Public Key
            </button>
            <button onClick={() => copyToClipboard((wallet?.trading_wallet || tradingWallet).privateKey, 'Private key')}>
            Copy Private Key
            </button>
            <button onClick={sendFunds}>
            Deposit Funds
            </button>
        </>
        ) : (
        <p>Trading wallet not available or not properly initialized.</p>
        )}
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SettingsModal;