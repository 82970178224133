import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';

const VIDEO_DATA = [
  {
    id: 1,
    title: "Banana Bills - Billion Dollar Dream",
    embedId: "HopjNu1xvjo",
  },
  {
    id: 2,
    title: "Blockchain Solstice - Banana Benny",
    embedId: "1pHgnynm2QQ?",
  },
  {
    id: 3,
    title: "Banana Bills - Great Coin Adventure",
    embedId: "pi3UNgMBfL4",
  },
];

const VideoModal = ({ open, onClose, isPlaying, setIsPlaying }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  
  const handlePrevVideo = () => {
    setCurrentVideoIndex((prev) => (prev > 0 ? prev - 1 : VIDEO_DATA.length - 1));
  };

  const handleNextVideo = () => {
    setCurrentVideoIndex((prev) => (prev < VIDEO_DATA.length - 1 ? prev + 1 : 0));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.95)',
          borderRadius: '12px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'white',
          borderBottom: '1px solid rgba(255,255,255,0.1)',
        }}
      >
        <Typography variant="h6">
          {VIDEO_DATA[currentVideoIndex].title}
        </Typography>
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent sx={{ p: 0, position: 'relative' }}>
        <Box
          sx={{
            position: 'relative',
            paddingTop: '56.25%', // 16:9 Aspect Ratio
            width: '100%',
          }}
        >
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 0,
            }}
            src={`https://www.youtube.com/embed/${VIDEO_DATA[currentVideoIndex].embedId}?autoplay=${isPlaying ? 1 : 0}`}
            title={VIDEO_DATA[currentVideoIndex].title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
          />
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            background: 'rgba(0,0,0,0.8)',
          }}
        >
          <Button
            onClick={handlePrevVideo}
            startIcon={<NavigateBefore />}
            sx={{
              color: 'white',
              '&:hover': { background: 'rgba(255,255,255,0.1)' },
            }}
          >
            Previous
          </Button>
          
          <Typography
            sx={{
              color: 'white',
              fontSize: '0.9rem',
            }}
          >
            {`${currentVideoIndex + 1} / ${VIDEO_DATA.length}`}
          </Typography>
          
          <Button
            onClick={handleNextVideo}
            endIcon={<NavigateNext />}
            sx={{
              color: 'white',
              '&:hover': { background: 'rgba(255,255,255,0.1)' },
            }}
          >
            Next
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoModal;