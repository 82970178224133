import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Grid,
  Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

const TrainingModal = ({ open, onClose, agent, onUpdate }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [basePrompt, setBasePrompt] = useState(agent?.aiConfig?.basePrompt || '');
  const [rules, setRules] = useState(agent?.aiConfig?.rules || []);
  const [newRule, setNewRule] = useState('');
  const [chatInput, setChatInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isTraining, setIsTraining] = useState(false);

  const handleTrain = async () => {
    setIsTraining(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/ai-agents/${agent._id}/train`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          basePrompt,
          rules,
          chatHistory
        })
      });

      if (response.ok) {
        onUpdate();
        onClose();
      }
    } catch (error) {
      console.error('Training error:', error);
    } finally {
      setIsTraining(false);
    }
  };

  const handleChat = async () => {
    if (!chatInput.trim()) return;

    const newMessage = { role: 'user', content: chatInput };
    setChatHistory([...chatHistory, newMessage]);
    setChatInput('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/ai-agents/${agent._id}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: chatInput })
      });

      const data = await response.json();
      setChatHistory(prev => [...prev, { role: 'assistant', content: data.response }]);
    } catch (error) {
      console.error('Chat error:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Train {agent?.name}
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
          <Tab label="Base Configuration" />
          <Tab label="Rules" />
          <Tab label="Chat Training" />
        </Tabs>

        {activeTab === 0 && (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Base Prompt"
              value={basePrompt}
              onChange={(e) => setBasePrompt(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Box>
        )}

        {activeTab === 1 && (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Add New Rule"
              value={newRule}
              onChange={(e) => setNewRule(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && newRule.trim()) {
                  setRules([...rules, newRule.trim()]);
                  setNewRule('');
                }
              }}
            />
            <Box sx={{ mt: 2 }}>
              {rules.map((rule, index) => (
                <Chip
                  key={index}
                  label={rule}
                  onDelete={() => setRules(rules.filter((_, i) => i !== index))}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </Box>
        )}

        {activeTab === 2 && (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ height: 300, overflowY: 'auto', mb: 2 }}>
              {chatHistory.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 1,
                    p: 1,
                    bgcolor: msg.role === 'user' ? 'primary.light' : 'grey.100',
                    borderRadius: 1
                  }}
                >
                  <Typography>{msg.content}</Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                fullWidth
                label="Chat with bot"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleChat()}
              />
              <Button
                variant="contained"
                onClick={handleChat}
                endIcon={<SendIcon />}
              >
                Send
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleTrain}
          variant="contained"
          disabled={isTraining}
        >
          {isTraining ? 'Training...' : 'Save & Train'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrainingModal;