import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Paper,
  CircularProgress, 
  Divider,
  Container,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(17, 24, 39, 0.95)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  }
}));

const DetailItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .label': {
    color: 'rgba(255, 255, 255, 0.7)',
    marginBottom: theme.spacing(0.5),
  },
  '& .value': {
    color: 'white',
  }
}));

const OrderLookupPage = () => {
  const [orderId, setOrderId] = useState('');
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLookup = async () => {
    if (!orderId.trim().startsWith('BNNA-')) {
      setError('Please enter a valid order ID starting with BNNA-');
      return;
    }

    setLoading(true);
    setError('');
    setOrder(null);

    try {
      const response = await fetch(`/api/orders/lookup/${orderId}`);
      const data = await response.json();

      if (!data.success) {
        throw new Error(data.error || 'Order not found');
      }

      setOrder(data.order);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      backgroundColor: '#111827',
      py: 4
    }}>
      <Container maxWidth="md">
        <Typography variant="h4" sx={{ color: 'white', mb: 4, textAlign: 'center' }}>
          Order Lookup
        </Typography>

        <StyledPaper elevation={3}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <StyledTextField
              fullWidth
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              placeholder="Enter your order ID (BNNA-XXXXXX-XXXX)"
              variant="outlined"
            />
            <Button
              onClick={handleLookup}
              disabled={loading || !orderId.trim()}
              variant="contained"
              startIcon={loading ? <CircularProgress size={20} /> : <SearchIcon />}
              sx={{
                bgcolor: '#22c55e',
                '&:hover': {
                  bgcolor: '#16a34a'
                }
              }}
            >
              {loading ? 'Searching...' : 'Look Up'}
            </Button>
          </Box>

          {error && (
            <Typography sx={{ color: '#ef4444', mt: 2 }}>
              {error}
            </Typography>
          )}
        </StyledPaper>

        {order && (
          <StyledPaper elevation={3}>
            <Typography variant="h6" sx={{ color: 'white', mb: 3 }}>
              Order Details
            </Typography>

            <DetailItem>
              <Typography className="label">Order ID</Typography>
              <Typography className="value">{order.orderId}</Typography>
            </DetailItem>

            <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', my: 2 }} />

            <DetailItem>
              <Typography className="label">Shipping Information</Typography>
              <Typography className="value">{order.shippingInfo.name}</Typography>
              <Typography className="value">{order.shippingInfo.address}</Typography>
              <Typography className="value">
                {order.shippingInfo.city}, {order.shippingInfo.state} {order.shippingInfo.zipCode}
              </Typography>
              <Typography className="value">{order.shippingInfo.email}</Typography>
            </DetailItem>

            <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', my: 2 }} />

            <DetailItem>
              <Typography className="label">Items</Typography>
              {order.items.map((item, index) => (
                <Box key={index} sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  my: 1
                }}>
                  <Box>
                    <Typography className="value">{item.name}</Typography>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.875rem' }}>
                      Quantity: {item.quantity}
                    </Typography>
                  </Box>
                  <Typography className="value">
                    ${(item.price * item.quantity).toFixed(2)}
                  </Typography>
                </Box>
              ))}
            </DetailItem>

            <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', my: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Total</Typography>
              <Typography variant="h6" sx={{ color: 'white' }}>
                ${order.totalAmount.toFixed(2)}
              </Typography>
            </Box>

            <DetailItem>
              <Typography className="label">Status</Typography>
              <Typography sx={{ 
                color: '#22c55e', 
                fontWeight: 600 
              }}>
                {order.status.toUpperCase()}
              </Typography>
            </DetailItem>
          </StyledPaper>
        )}
      </Container>
    </Box>
  );
};

export default OrderLookupPage;