import React, { useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { 
    MonetizationOn,
    Lock,
    Refresh,
  } from '@mui/icons-material';

const GameCard = styled(Card)(({ theme }) => ({
    height: '100%',
    // background: 'rgba(255, 255, 255, 0.05)',
    // backdropFilter: 'blur(10px)',
    border: '2px solid rgba(255, 215, 0, 0.1)',
    borderRadius: '16px',
    transition: 'all 0.3s ease-in-out',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -100,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(90deg, transparent, rgba(255, 215, 0, 0.1), transparent)',
      transition: 'all 0.5s ease',
    },
    '&:hover': {
      transform: 'translateY(-5px)',
      borderColor: 'rgba(255, 215, 0, 0.5)',
      boxShadow: '0 8px 24px rgba(255, 215, 0, 0.2)',
      '&::before': {
        left: 100,
      },
    },
  }));


const StakingVisualization = ({ tokenBalance = 0, poolName = "Main Pool" }) => {
  // Generate mock historical data
  const data = [
    { name: 'Week 1', amount: tokenBalance * 0.7 },
    { name: 'Week 2', amount: tokenBalance * 0.8 },
    { name: 'Week 3', amount: tokenBalance * 0.9 },
    { name: 'Current', amount: tokenBalance }
  ];

  const [chartData] = useState([
    { week: 'W1', value: 70 },
    { week: 'W2', value: 85 },
    { week: 'W3', value: 95 },
    { week: 'W4', value: 100 }
  ]);

  return (
    <GameCard>
  <CardContent sx={{ textAlign: 'center', minHeight: '300px', background: 'rgba(0, 0, 0, 0.8)', }}>
    <MonetizationOn sx={{ color: '#C59E57', fontSize: 40, mb: 2 }} />
    <Typography variant="h6" color="white" gutterBottom>
      Total BNNA Staked
    </Typography>
    <Typography variant="h4" color="#C59E57" mb={2}>
      {tokenBalance ? `${tokenBalance.toLocaleString()} BNNA` : 'N/A'}
    </Typography>
    
    <Box sx={{ height: '150px', display: 'flex', alignItems: 'flex-end', mt: 2, p: 2 }}>
      {chartData.map((item, index) => (
        <Box
          key={index}
          sx={{
            flex: 1,
            mx: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              height: item.value,
              minWidth: '30px',
              background: 'linear-gradient(180deg, #C59E57 0%, rgba(197, 158, 87, 0.3) 100%)',
              borderRadius: '4px',
              transition: 'all 0.3s ease'
            }}
          />
          <Typography variant="caption" color="#C59E57" mt={1}>
            {item.week}
          </Typography>
        </Box>
      ))}
    </Box>
  </CardContent>
</GameCard>
  );
};

export default StakingVisualization;