import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  CircularProgress,
  Button,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { 
  Warning,
  CheckCircle,
  Error,
  Info,
  Timeline
} from '@mui/icons-material';




const RiskManagerAgent = ({  activeToken }) => {
  const [loading, setLoading] = useState(false);
  const [riskAnalysis, setRiskAnalysis] = useState(null);
  const [monitoring, setMonitoring] = useState(false);
  const [monitoringData, setMonitoringData] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [metrics, setMetrics] = useState(null);


  const analyzeRisk = async () => {
    if (!activeToken) return;

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/risk/analyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          mintAddress: activeToken,
          amount: 0.1 // Default amount for analysis
        })
      });

      const data = await response.json();
      if (data.success) {
        setRiskAnalysis(data.analysis);
        setMetrics(data.analysis.metrics);
        console.log('Risk analysis completed:', data.analysis);
        console.log('Risk analysis completed:', data.analysis.metrics[0]);
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('Risk analysis failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const startMonitoring = async () => {
    if (!activeToken || !riskAnalysis) return;

    setMonitoring(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/risk/monitor`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          mintAddress: activeToken,
          entryPrice: 0, // Get actual entry price
          amount: 0.1,
          stopLoss: -10,
          takeProfit: 20
        })
      });

      const data = await response.json();
      if (data.success) {
        setMonitoringData(data.report);
        console.log('Position monitoring update:', data.report);
      }
    } catch (error) {
      console.error('Monitoring failed:', error);
    }
  };

  useEffect(() => {
    console.log('RiskManagerAgent received token:', activeToken);
  }, [activeToken]);

  useEffect(() => {
    if (activeToken) {
      analyzeRisk();
    }
  }, [activeToken]);

  // Set up monitoring interval
  useEffect(() => {
    let interval;
    if (monitoring && activeToken) {
      interval = setInterval(startMonitoring, 60000); // Monitor every minute
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [monitoring, activeToken]);

  const getRiskColor = (score) => {
    if (score > 80) return '#ef4444';
    if (score > 60) return '#f97316';
    if (score > 40) return '#eab308';
    return '#22c55e';
  };

  return (
    <Card sx={{ 
      background: 'rgba(255, 255, 255, 0.05)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      border: '1px solid rgba(255, 255, 255, 0.1)'
    }}>
      <CardContent>
        <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
          Risk Manager Agent
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
            <CircularProgress />
          </Box>
        ) : riskAnalysis ? (
          <>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ color: 'white', mb: 1 }}>
                Risk Score
              </Typography>
              <Box sx={{ 
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center'
              }}>
                <CircularProgress
                  variant="determinate"
                  value={riskAnalysis.riskScore}
                  sx={{
                    color: getRiskColor(riskAnalysis.riskScore),
                    transform: 'scale(2)',
                    mr: 4
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    position: 'absolute',
                    left: '16px',
                    color: getRiskColor(riskAnalysis.riskScore)
                  }}
                >
                  {Math.round(riskAnalysis.riskScore)}
                </Typography>
              </Box>
            </Box>

            <List>
              {riskAnalysis.recommendations.map((rec, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    {rec.type === 'warning' ? <Warning color="error" /> :
                     rec.type === 'caution' ? <Error color="warning" /> :
                     rec.type === 'moderate' ? <Info color="info" /> :
                     <CheckCircle color="success" />}
                  </ListItemIcon>
                  <ListItemText
                    primary={rec.message}
                    sx={{ color: 'white' }}
                  />
                </ListItem>
                
                
              ))}
            </List>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
                  variant="p"
                  sx={{
                    left: '16px',
                    color: getRiskColor(riskAnalysis.riskScore)
                  }}
                >
                  Liquidity Score: {metrics.liquidityScore}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    left: '16px',
                    color: getRiskColor(riskAnalysis.riskScore)
                  }}
                >
                  Market Cap Score: {metrics.marketCapScore}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    left: '16px',
                    color: getRiskColor(riskAnalysis.riskScore)
                  }}
                >
                  Price Volatility Score: {metrics.priceVolatility}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    left: '16px',
                    color: getRiskColor(riskAnalysis.riskScore)
                  }}
                >
                  Social Score: {metrics.socialScore}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    left: '16px',
                    color: getRiskColor(riskAnalysis.riskScore)
                  }}
                >
                  Volume Score: {metrics.volumeScore}
                </Typography>
            </Box>
            


            {monitoringData && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" sx={{ color: 'white', mb: 1 }}>
                  Position Monitoring
                </Typography>
                <Alert 
                  severity={
                    monitoringData.riskLevel === 'critical' ? 'error' :
                    monitoringData.riskLevel === 'high' ? 'warning' :
                    monitoringData.riskLevel === 'moderate' ? 'info' : 'success'
                  }
                  sx={{ mb: 2 }}
                >
                  P&L: {monitoringData.pnlPercent.toFixed(2)}% | Risk Level: {monitoringData.riskLevel}
                </Alert>

                {monitoringData.recommendations.map((rec, index) => (
                  <Alert 
                    key={index}
                    severity={rec.type === 'stop_loss' ? 'error' : 'success'}
                    sx={{ mb: 1 }}
                  >
                    {rec.message}
                  </Alert>
                ))}
              </Box>
            )}

            <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                onClick={() => setMonitoring(!monitoring)}
                startIcon={<Timeline />}
                sx={{
                  background: monitoring ? '#ef4444' : '#22c55e',
                  '&:hover': {
                    background: monitoring ? '#dc2626' : '#16a34a',
                  },
                }}
              >
                {monitoring ? 'Stop Monitoring' : 'Start Monitoring'}
              </Button>
              <Button
                variant="outlined"
                onClick={analyzeRisk}
                sx={{
                  color: 'white',
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  '&:hover': {
                    borderColor: 'white',
                  },
                }}
              >
                Refresh Analysis
              </Button>
            </Box>
          </>
        ) : (
          <Alert severity="info">
            Select a token to begin risk analysis
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default RiskManagerAgent;