import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Box, Paper, Stack, Typography, keyframes } from '@mui/material';
import apeSyndicateImage from '../assets/apesyndicate.jpg';
import bgImage from '../assets/bg.webp';

// Define animations
const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const glow = keyframes`
  0%, 100% { box-shadow: 0 0 20px rgba(59, 130, 246, 0.3); }
  50% { box-shadow: 0 0 30px rgba(59, 130, 246, 0.5); }
`;

const StyledBackgroundContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${bgImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  borderRadius: '24px',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 100%)',
    pointerEvents: 'none',
    zIndex: 1
  },
  '& > *': {
    position: 'relative',
    zIndex: 2
  }
}));

const EnhancedPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  // backgroundImage: `url(${bgImage})`,
  background: 'rgba(0, 0, 0, 0.2)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '24px',
  boxShadow: `
    0 0 40px rgba(0, 0, 0, 0.5),
    inset 0 0 20px rgba(255, 255, 255, 0.05)
  `,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: 'linear-gradient(90deg, transparent, rgba(59, 130, 246, 0.5), transparent)',
    animation: `${glow} 3s infinite`,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
    zIndex: 1,
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(8),
  minHeight: '100%',
}));

const LogoImage = styled('img')(({ theme }) => ({
  animation: `${float} 3s ease-in-out infinite`,
  filter: 'drop-shadow(0 0 10px rgba(59, 130, 246, 0.3))',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    filter: 'drop-shadow(0 0 15px rgba(59, 130, 246, 0.5))',
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  background: 'linear-gradient(to right, #60A5FA, #93C5FD)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  color: 'white',
  textAlign: 'center',
  textShadow: '0 0 30px rgba(59, 130, 246, 0.3)',
  letterSpacing: '0.5px',
}));

const ContentSection = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '16px',
  border: '1px solid rgba(255, 255, 255, 0.05)',
}));

// Usage component
const EnhancedContainer = ({ children, hasExistingWallet, logoImage, LatestTrades   }) => {
  return (
    <StyledBackgroundContainer maxWidth="sm">
      <ContentBox>
        <EnhancedPaper elevation={24}>
          <Stack spacing={3} alignItems="center">
            <LogoImage
              src={logoImage}
              alt="App Logo"
              sx={{
                width: {
                  xs: 120,
                  sm: 150,
                  md: 180
                },
                height: 'auto',
              }}
            />
            
            <GradientText variant="h4" component="h1">
              Welcome Anon...
            </GradientText>

            <Typography 
              variant="body1"
              align="center"
              sx={{
                color: 'rgba(255, 255, 255, 0.9)',
                maxWidth: 'sm',
                lineHeight: 1.6,
                fontSize: '1.1rem',
              }}
            >
              {hasExistingWallet 
                ? 'Thx for Being an Early Supporter! Your rewards will be available for Claim Soon.'
                : 'To the future of memecoin Trading, Connect your wallet to access trading features.'}
            </Typography>

            {/* Your existing buttons and components */}
            {children}

          </Stack>
        </EnhancedPaper>

        {/* <ContentSection> */}
          {/* <Typography 
            variant="h6" 
            sx={{ 
              color: 'white',
              mb: 1,
              textAlign: 'center',
              textShadow: '0 0 10px rgba(59, 130, 246, 0.3)',
            }}
          >
            Latest Trade
          </Typography>
          <LatestTrades /> */}
        {/* </ContentSection> */}
      </ContentBox>
    </StyledBackgroundContainer>
  );
};

export default EnhancedContainer;