import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid,
  Button,
  Card,
  CardContent,
  LinearProgress,
  IconButton,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  MonetizationOn,
  Lock,
  Refresh,
} from '@mui/icons-material';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { toast } from 'react-toastify';
import bananaStandBg from '../assets/logo.webp';
import { useNavigate } from 'react-router-dom';
import { FaTiktok } from 'react-icons/fa';
import PumpAssessmentModal from '../components/modals/PumpAssessmentModal';
import { Book } from '@mui/icons-material';
import { VideogameAsset } from '@mui/icons-material';
import { Receipt } from '@mui/icons-material';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CustomLoader from '../components/CustomLoader';
import StakingCalculator from '../components/StakingCalculator';
import StakingVisualization from './StakingVisualization';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import '../assets/fonts/MONKEY.otf'
import { Link } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';


const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px)',
    color: '#3b82f6',
  },
}));
const SocialFooter = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.7))',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
  zIndex: 1000,
}));
const AnimatedGoldButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #ffd700 30%, #ffa500 90%)',
  borderRadius: '25px',
  border: '3px solid rgba(255, 215, 0, 0.5)',
  color: '#000',
  padding: '15px 30px',
  fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 6px 12px rgba(255, 215, 0, 0.3)',
  transition: 'all 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
    animation: '$shine 3s infinite linear'
  },
  '&:hover': {
    transform: 'scale(1.05) translateY(-3px)',
    boxShadow: '0 10px 20px rgba(255, 215, 0, 0.4)',
    background: 'linear-gradient(45deg, #ffa500 30%, #ffd700 90%)',
  },
  '&:active': {
    transform: 'scale(0.95) translateY(2px)'
  },
  '@keyframes shine': {
    '0%': {
      left: '-100%'
    },
    '100%': {
      left: '100%'
    }
  }
 }));
 const DisabledGoldButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  border: '3px solid rgba(197, 158, 87, 0.2)',
  fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
  fontSize: '1.2rem',
  padding: '15px 30px',
  textTransform: 'none',
  position: 'relative',
  overflow: 'hidden',
  '&.Mui-disabled': {
    background: 'black',
    color: 'rgba(255, 255, 255, 0.5)',
    border: '3px solid rgba(197, 158, 87, 0.2)',
    boxShadow: 'inset 0 0 10px rgba(197, 158, 87, 0.1)',
    cursor: 'not-allowed'
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.1), transparent)',
    animation: '$pulseDisabled 3s infinite linear'
  },
  '@keyframes pulseDisabled': {
    '0%, 100%': {
      opacity: 0.3
    },
    '50%': {
      opacity: 0.5
    }
  }
 }));
const DashboardContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  background: `url('https://github.com/apeoutmeme/resources/blob/3d00d942c5d8473e204afac6319733113954be76/assets/banana-stand.png?raw=true`,
  backgroundSize: 'cover',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));
const StakingCard = styled(Card)(({ theme }) => ({
  width: '80%',
  maxWidth: '600px',
  padding: theme.spacing(3),
  background: 'rgba(0, 0, 0, 0.8)',
  border: '2px solid rgba(255, 215, 0, 0.4)',
  borderRadius: theme.spacing(2),
  color: 'white',
  textAlign: 'center',
  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.6)',
}));
const GameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial Rounded MT Bold, Arial, sans-serif',
  fontSize: '1.1rem',
  textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
  background: 'linear-gradient(45deg, #C59E57, #FFD700)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.5px',
  padding: '8px',
  borderRadius: '12px',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)',
    textShadow: '3px 3px 6px rgba(0,0,0,0.3)',
  }
}));
const StyledCard = styled(Card)(({ theme }) => ({
  background: 'rgba(254, 255, 235, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(197, 158, 87, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 20px rgba(197, 158, 87, 0.2)',
  },
}));
const GoldButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #C59E57, #FFD700)',
  color: '#000',
  fontWeight: 'bold',
  padding: '12px 24px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FFD700, #C59E57)',
  },
}));
const StatsBox = styled(Box)(({ theme }) => ({
  background: 'rgba(254, 255, 235, 0.03)',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));
const HeroSection = styled(Box)({
  height: '50vh',
  width: '100%',
  position: 'relative',
  backgroundImage: 'linear-gradient(rgba(19, 21, 26, 0.7), rgba(19, 21, 26, 0.9)), url("https://github.com/apeoutmeme/resources/blob/b59709e3d97f7d77cb7240327c6aaaa7bd2326bf/assets/common/15.png?raw=true")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'white',
  marginBottom: '2rem',
});
const GameCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: 'rgba(0, 0, 0, 0.8)',
  border: '2px solid rgba(255, 215, 0, 0.1)',
  borderRadius: '16px',
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: -100,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255, 215, 0, 0.1), transparent)',
    transition: 'all 0.5s ease',
  },
  '&:hover': {
    transform: 'translateY(-5px)',
    borderColor: 'rgba(255, 215, 0, 0.5)',
    boxShadow: '0 8px 24px rgba(255, 215, 0, 0.2)',
    '&::before': {
      left: 100,
    },
  },
}));
const MemeSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  padding: theme.spacing(4),
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  border: '2px solid rgba(197, 158, 87, 0.1)',
}));
const MemeCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 24px rgba(197, 158, 87, 0.2)',
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '12px',
  },
}));
const TenorGif = ({ postId }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://tenor.com/embed.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div 
      className="tenor-gif-embed" 
      data-postid={postId} 
      data-share-method="host" 
      data-aspect-ratio="1.36667" 
      data-width="100%"
    />
  );
};
const StakingModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(0, 0, 0, 0.95)',
    border: '2px solid #C59E57',
    borderRadius: '16px',
    padding: theme.spacing(2),
    maxWidth: '500px'
  }
}));


const BananaStandDashboard = (setCompletedTasks, setPoints, publicKey) => {
  const [stakingInfo, setStakingInfo] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(null);
  const [stakers, setStakers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isStaking, setIsStaking] = useState(false);
  const navigate = useNavigate();
  const memeIds = ['8725033', '13646462', '16566503']; // Replace with your chosen GIF IDs
  const [showPumpAssessment, setShowPumpAssessment] = useState(false);
  const NEW_STAKING_ADDRESS = "4pPkqv4jf444iiyRzdzSWcVghhQHqr917Q4aFr6CyMUE";
  const REWARDS_WALLET = "YOUR_REWARDS_WALLET_ADDRESS"; // Add the rewards wallet address
  const [newPoolStakingInfo, setNewPoolStakingInfo] = useState(null);
  const [newPoolTokenBalance, setNewPoolTokenBalance] = useState(null);
  const [unclaimedRewards, setUnclaimedRewards] = useState(100000); // Mock value for demo
  const [openStakingModal, setOpenStakingModal] = useState(false);
  const [baseMultiplier] = useState('1x');
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const STAKING_ADDRESS = "7bhLjEQeakWBhfgrdKg3DbERrQZKdoe2BSP7cPzD4w18";
  const RPC_URL = "https://mainnet.helius-rpc.com/?api-key=03227d28-b6de-4a36-9d90-cd0cc7c2f8eb";
  const BNNA_TOKEN = "DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump";
  const [memes, setMemes] = useState([]);

const fetchMemes = async () => {
  try {
    const response = await fetch(
      `https://tenor.googleapis.com/v2/search?q=banana%20money&key=YOUR_TENOR_API_KEY&limit=3`
    );
    const data = await response.json();
    setMemes(data.results);
  } catch (error) {
    console.error('Error fetching memes:', error);
  }
};
const handleStake = () => {
  window.open('https://app.streamflow.finance/staking/solana/mainnet/4pPkqv4jf444iiyRzdzSWcVghhQHqr917Q4aFr6CyMUE', '_blank', 'noopener,noreferrer');
};
  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Fetch staking info
      const stakingResponse = await fetch(RPC_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getAccountInfo',
          params: [
            STAKING_ADDRESS,
            {
              encoding: 'jsonParsed',
            },
          ],
        }),
      });
      const stakingData = await stakingResponse.json();
      setStakingInfo(stakingData.result);
      console.log("Staking data:", stakingData);
  
      await delay(5000);
      // Inside fetchData function, add:
      const newPoolResponse = await fetch(RPC_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getAccountInfo',
          params: [
            NEW_STAKING_ADDRESS,
            {
              encoding: 'jsonParsed',
            },
          ],
        }),
      });
      const newPoolData = await newPoolResponse.json();
      setNewPoolStakingInfo(newPoolData.result);

      await delay(3000);
        
      // Fetch token balance using specific token account
      const tokenResponse = await fetch(RPC_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getTokenAccountBalance',
          params: [
            "GLz5murzW8PMheJMG4KHczuEo4PF5fSMnCPfxXgMpMN"
          ],
        }),
      });
      const tokenData = await tokenResponse.json();
      console.log("Token data:", tokenData);
      if (tokenData.result?.value) {
        setTokenBalance(tokenData.result.value.uiAmount);
      }
  
      await delay(5000);
  
      // Fetch stakers with modified filters
      // Fetch stakers by getting signature history
    const stakersResponse = await fetch(RPC_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: 1,
        method: 'getSignaturesForAddress',
        params: [
          "GLz5murzW8PMheJMG4KHczuEo4PF5fSMnCPfxXgMpMN",
          {
            limit: 1000,
          }
        ],
      }),
    });
      const stakersData = await stakersResponse.json();
      console.log("Stakers data:", stakersData);
      if (stakersData.result) {
        setStakers(stakersData.result);
      }
  
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
      setLoading(false);
    }
  };
  const fetchStakingInfo = async () => {
    try {
      const response = await fetch(RPC_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getAccountInfo',
          params: [
            STAKING_ADDRESS,
            {
              encoding: 'jsonParsed',
            },
          ],
        }),
      });

      const data = await response.json();
      console.log(data);
      setStakingInfo(data.result);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemes();
  }, []);
  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 160000);
    return () => clearInterval(interval);
  }, []);
  

  if (loading) {
    return (
      <DashboardContainer>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CustomLoader
            media="https://media1.tenor.com/m/X8r5xQB_GyAAAAAd/banana-shake-banana.gif"
            mediaType="gif"
            text="Loading..."
            size={500}
            bgColor="rgba(26, 35, 126, 0.9)"
            pulseColor="#90caf9"
          />
        </Box>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
    <HeroSection>
      <Typography 
        variant="h2" 
        sx={{ 
          background: 'linear-gradient(45deg, #C59E57, #FFD700)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontWeight: 'bold',
          mb: 2
        }}
      >
        Banana Stand
      </Typography>
      <Typography variant="h5" sx={{ color: 'white', mb: 4 }}>
        Stake $BNNA and earn rewards
      </Typography>
      <StakingCard>
  <Typography
    variant="h4"
    sx={{
      background: 'linear-gradient(45deg, #C59E57, #FFD700)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 'bold',
    }}
  >
    Stake $BNNA, Get Bananas! 🍌
  </Typography>
  <Typography variant="body1" sx={{ mt: 2, color: '#f1c40f' }}>
    Stake a minimum of 1 BNNA to start earning weekly rewards.
  </Typography>
  <Box sx={{ mt: 3 }}>
    <Typography variant="body2" sx={{ mb: 1 }}>
      Staking Balance: {tokenBalance || 0} BNNA 🍌
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, 
      flexDirection: { xs: 'row', sm: 'row' }, overflow: 'auto' // Add this
     }}>
    <Button
  variant="contained"
  disabled
  sx={{
    background: 'rgba(197, 158, 87, 0.3)',
    color: '#FEFFEB',
    border: '2px solid #00ff00',
    boxShadow: '0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00',
    animation: 'pulsate 1.5s infinite',
    '@keyframes pulsate': {
      '0%': {
        boxShadow: '0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00',
      },
      '50%': {
        boxShadow: '0 0 15px #00ff00, 0 0 25px #00ff00, 0 0 35px #00ff00',
        transform: 'scale(1.03)',
      },
      '100%': {
        boxShadow: '0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00',
      },
    },
    '&.Mui-disabled': {
      color: '#FEFFEB',
      background: 'rgba(197, 158, 87, 0.3)',
      border: '2px solid #00ff00',
      opacity: 1,
    },
    transition: 'all 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
      animation: 'shine 2s infinite',
    },
    '@keyframes shine': {
      '100%': {
        left: '100%',
      },
    }
  }}
>
  {baseMultiplier}
</Button>
  <GoldButton onClick={() => setOpenStakingModal(true)}>
    Base
  </GoldButton>
  <GoldButton  onClick={handleStake}sx={{
    background: 'rgba(197, 158, 87, 0.3)',
    color: '#FEFFEB',
    border: '2px solid #00ff00',
    boxShadow: '0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00',
    animation: 'pulsate 1.5s infinite',
    '@keyframes pulsate': {
      '0%': {
        boxShadow: '0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00',
      },
      '50%': {
        boxShadow: '0 0 15px #00ff00, 0 0 25px #00ff00, 0 0 35px #00ff00',
        transform: 'scale(1.03)',
      },
      '100%': {
        boxShadow: '0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00',
      },
    },
    '&.Mui-disabled': {
      color: '#FEFFEB',
      background: 'rgba(197, 158, 87, 0.3)',
      border: '2px solid #00ff00',
      opacity: 1,
    },
    transition: 'all 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
      animation: 'shine 2s infinite',
    },
    '@keyframes shine': {
      '100%': {
        left: '100%',
      },
    }
  }}
>Stake Now</GoldButton>
</Box>
   
  </Box>
</StakingCard>
    </HeroSection>

    {/* Stats Section */}
    <Grid container spacing={3} mb={6}>
      {/* <Grid item xs={12} md={4}>
        <GameCard>
          <CardContent sx={{ textAlign: 'center' }}>
            <MonetizationOn sx={{ color: '#C59E57', fontSize: 40, mb: 2 }} />
            <Typography variant="h6" color="black" gutterBottom>
              Total BNNA Staked
            </Typography>
            <Typography variant="h4" color="#C59E57">
              {tokenBalance ? `${tokenBalance.toLocaleString()} BNNA 🍌` : 'N/A'}
            </Typography>
          </CardContent>
        </GameCard>
      </Grid> */}
      <Grid item xs={12} md={4}>
  <StakingVisualization  tokenBalance={tokenBalance} poolName="Main Pool" />
</Grid>

<Grid item xs={12} md={4}>
<GameCard sx={{ mt: 4,  mb: 4, }}>
  
    <CardContent sx={{ textAlign: 'center', minHeight: '300px', background: 'rgba(254, 255, 235, 0.05)' }}>
      <Lock sx={{ color: '#C59E57', fontSize: 40, mb: 2 }} />
      <Typography variant="h6" color="white" gutterBottom>
        Total Stakers 
      </Typography>
      <Typography variant="h4" color="#C59E57" mb={2}>
        {stakers?.length - 3 || 0} 🦍
      </Typography>
      
      <Box sx={{ height: '150px', display: 'flex', alignItems: 'flex-end', mt: 2, p: 2 }}>
        {[45, 65, 85, stakers?.length - 3 || 100].map((value, index) => (
          <Box
            key={index}
            sx={{
              flex: 1, 
              mx: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'flex-end'
            }}
          >
            <Box
              sx={{
                height: value,
                minWidth: '30px',
                maxWidth: '50px',
                background: 'linear-gradient(180deg, #C59E57 0%, rgba(197, 158, 87, 0.3) 100%)',
                borderRadius: '4px',
                transition: 'all 0.3s ease'
              }}
            />
            <Typography variant="caption" color="#C59E57" mt={1}>
              W{index + 1}
            </Typography>
          </Box>
        ))}
      </Box>
    </CardContent>
  </GameCard>
  </Grid>
  <Grid item xs={12} md={4}>
  <GameCard sx={{ mt: 4,  mb: 4, }}>
  <CardContent sx={{ 
    textAlign: 'center', 
    minHeight: '300px',
    background: 'linear-gradient(180deg, rgba(254, 255, 235, 0.08) 0%, rgba(254, 255, 235, 0.02) 100%)',
    position: 'relative',
    overflow: 'hidden'
  }}>
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center',
      width: '100%',
      mb: 2,
      position: 'relative'
    }}>
      <Box sx={{
        width: '100%',
        height: '300px',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle, transparent 50%, rgba(0,0,0,0.4) 100%)',
          pointerEvents: 'none'
        }
      }}>
        <img 
          style={{ 
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.3))'
          }} 
          src="https://github.com/apeoutmeme/resources/blob/32f5092cc73b7141524c2ac0abe5ea0daa56c25c/assets/staking/2025.webp?raw=true"
          alt="New Staking Pool" 
        />
      </Box>
    </Box>
    <Typography 
      variant="h5" 
      sx={{
        color: '#FEFFEB',
        textShadow: '0 2px 4px rgba(0,0,0,0.5)',
        fontWeight: 600
      }} 
      gutterBottom
    >
      New Staking Pool (100K-500K BNNA)
    </Typography>
    <Box sx={{ 
      my: 3,
      backdropFilter: 'blur(4px)',
      background: 'rgba(0,0,0,0.2)',
      borderRadius: 2,
      p: 2
    }}>
      <Typography 
        variant="body1" 
        sx={{ 
          color: '#C59E57',
          textShadow: '0 0 10px rgba(197, 158, 87, 0.3)'
        }}
      >
        Program Owner: {newPoolStakingInfo?.value?.owner || 'N/A'}
      </Typography>
      <Typography 
        variant="body1" 
        sx={{ 
          color: '#C59E57',
          mt: 2,
          textShadow: '0 0 10px rgba(197, 158, 87, 0.3)'
        }}
      >
        Pool Balance: {newPoolTokenBalance || 0} BNNA 🍌
      </Typography>
      <LinearProgress 
        variant="determinate" 
        value={70} 
        sx={{ 
          my: 2,
          height: 10,
          borderRadius: 5,
          backgroundColor: 'rgba(197, 158, 87, 0.2)',
          '& .MuiLinearProgress-bar': {
            background: 'linear-gradient(45deg, #C59E57, #FFD700)',
          }
        }}
      />
      <AnimatedGoldButton
        fullWidth
        href="https://app.streamflow.finance/staking/solana/mainnet/4pPkqv4jf444iiyRzdzSWcVghhQHqr917Q4aFr6CyMUE"
        target="_blank"
        rel="noopener noreferrer"
        startIcon={<Lock sx={{ animation: 'bounce 1s infinite' }} />}
      >
        🎮 Stake Now! 🎮
      </AnimatedGoldButton>
    </Box>
  </CardContent>
</GameCard>
  </Grid>


<Grid item xs={12} md={4}>
{/* Legacy Stakers Rewards Section */}
<GameCard sx={{ mt: 4,  mb: 4, }}>
<CardContent sx={{ 
    textAlign: 'center', 
    minHeight: '300px',
    background: 'linear-gradient(180deg, rgba(254, 255, 235, 0.08) 0%, rgba(254, 255, 235, 0.02) 100%)',
    position: 'relative',
    overflow: 'hidden'
  }}>
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center',
      width: '100%',
      mb: 2,
      position: 'relative'
    }}>
      <Box sx={{
        width: '100%',
        height: '300px',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle, transparent 50%, rgba(0,0,0,0.4) 100%)',
          pointerEvents: 'none'
        }
      }}>
        <img 
          style={{ 
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.3))'
          }} 
          src="https://github.com/apeoutmeme/resources/blob/32f5092cc73b7141524c2ac0abe5ea0daa56c25c/assets/staking/sorry.webp?raw=true"
          alt="Legacy Staker Rewards" 
        />
      </Box>
    </Box>
    <Typography 
  variant="h5" 
  sx={{
    color: 'white',
    textShadow: '0 2px 4px rgba(0,0,0,0.5)',
    fontWeight: 600
  }} 
  gutterBottom
>
  Legacy Staker Rewards
</Typography>
<a
  href="https://medium.com/@apeoutcrypto/correcting-staking-rewards-our-fix-and-new-pool-100ed369a004"
  target="_blank"
  rel="noopener noreferrer"
  style={{
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.9rem',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
  }}
>
  <InfoOutlined sx={{ fontSize: '16px', color: 'white' }} />
  Read about legacy staking issues
</a>
    <Box sx={{ 
      my: 3,
      backdropFilter: 'blur(4px)',
      background: 'rgba(0,0,0,0.2)',
      borderRadius: 2,
      p: 2
    }}>
      <Typography 
        variant="h4" 
        sx={{ 
          color: '#C59E57',
          textShadow: '0 0 10px rgba(197, 158, 87, 0.3)',
          mb: 2
        }}
      >
        {unclaimedRewards} BNNA 🍌
      </Typography>
      <Typography 
        variant="body2" 
        sx={{ 
          color: '#A0A0A0',
          mb: 3
        }}
      >
        Your unclaimed rewards from the previous pool
      </Typography>
      <AnimatedGoldButton
        fullWidth
        href="https://app.streamflow.finance/"
        target="_blank"
        rel="noopener noreferrer"
        startIcon={<Lock />}
      >
        🔒 Claim Rewards  🔒
      </AnimatedGoldButton>
    </Box>
  </CardContent>
</GameCard>
</Grid>

<Grid item xs={12} md={4}>
<GameCard sx={{ mt: 4 }}>
<CardContent sx={{ 
    minHeight: '300px',
    background: 'linear-gradient(180deg, rgba(254, 255, 235, 0.08) 0%, rgba(254, 255, 235, 0.02) 100%)',
    position: 'relative',
    overflow: 'hidden'
  }}>
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center',
      width: '100%',
      mb: 2,
      position: 'relative'
    }}>
      <Box sx={{
        width: '100%',
        height: '300px',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle, transparent 50%, rgba(0,0,0,0.4) 100%)',
          pointerEvents: 'none'
        }
      }}>
        <img 
          style={{ 
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.3))'
          }} 
          src="https://github.com/apeoutmeme/resources/blob/32f5092cc73b7141524c2ac0abe5ea0daa56c25c/assets/staking/2024.webp?raw=true"
          alt="Staking Info" 
        />
      </Box>
    </Box>

    <Typography 
      sx={{ 
        fontFamily: 'MONKEY',
        fontSize: '2rem',
        textShadow: '0 2px 4px rgba(0,0,0,0.5)',
        background: 'linear-gradient(45deg, #FEFFEB, #FFF)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      }} 
      gutterBottom
    >
      Staking Info
    </Typography>

    <Box sx={{ 
      my: 3,
      backdropFilter: 'blur(4px)',
      background: 'rgba(0,0,0,0.2)',
      borderRadius: 2,
      p: 3
    }}>
      <GameTypography 
        variant="h6"
        sx={{ 
          color: '#FEFFEB',
          opacity: 0.7,
          fontSize: '0.9rem',
          mb: 1
        }}
      >
        Program Owner
      </GameTypography>
      <GameTypography 
        variant="h5"
        sx={{
          color: '#C59E57',
          textShadow: '0 0 8px rgba(197, 158, 87, 0.3)',
          mb: 3
        }}
      >
        {stakingInfo?.value?.owner || 'N/A'}
      </GameTypography>

      <GameTypography 
        variant="h6"
        sx={{ 
          color: '#FEFFEB',
          opacity: 0.7,
          fontSize: '0.9rem',
          mb: 1
        }}
      >
        Program Space
      </GameTypography>
      <GameTypography 
        variant="h5"
        sx={{
          color: '#C59E57',
          textShadow: '0 0 8px rgba(197, 158, 87, 0.3)',
          mb: 3
        }}
      >
        {stakingInfo?.value?.space || 'N/A'} bytes
      </GameTypography>

      <GameTypography 
        variant="h6"
        sx={{ 
          color: '#FEFFEB',
          opacity: 0.7,
          fontSize: '0.9rem',
          mb: 1
        }}
      >
        Legacy Contract
      </GameTypography>
      <GameTypography 
        variant="h5"
        sx={{
          color: '#C59E57',
          textShadow: '0 0 8px rgba(197, 158, 87, 0.3)',
          mb: 3,
          '&:hover': {
            color: '#FEFFEB',
            transition: 'color 0.3s ease'
          }
        }}
      >
        {STAKING_ADDRESS}
      </GameTypography>

      <GameTypography 
        variant="h6"
        sx={{ 
          color: '#FEFFEB',
          opacity: 0.7,
          fontSize: '0.9rem',
          mb: 1
        }}
      >
        2025 Contract
      </GameTypography>
      <GameTypography 
        variant="h5"
        sx={{
          color: '#C59E57',
          textShadow: '0 0 8px rgba(197, 158, 87, 0.3)',
          mb: 3,
          '&:hover': {
            color: '#FEFFEB',
            transition: 'color 0.3s ease'
          }
        }}
      >
        4pPkqv4jf444iiyRzdzSWcVghhQHqr917Q4aFr6CyMUE
      </GameTypography>

      <LinearProgress 
        variant="determinate" 
        value={70} 
        sx={{ 
          my: 2,
          height: 10,
          borderRadius: 5,
          backgroundColor: 'rgba(197, 158, 87, 0.2)',
          '& .MuiLinearProgress-bar': {
            background: 'linear-gradient(45deg, #C59E57, #FFD700)',
          }
        }}
      />

      <AnimatedGoldButton
        fullWidth
        href="https://app.streamflow.finance/staking/solana/mainnet/7bhLjEQeakWBhfgrdKg3DbERrQZKdoe2BSP7cPzD4w18"
        target="_blank"
        rel="noopener noreferrer"
        startIcon={<Lock sx={{ animation: 'bounce 1s infinite' }} />}
      >
        View Legacy Staking! No Rewards!
      </AnimatedGoldButton>
    </Box>
  </CardContent>
</GameCard>
</Grid>
      <Grid item xs={12} md={4}>
        <GameCard>
        <CardContent sx={{ textAlign: 'center' }}>
      <Refresh sx={{ color: '#C59E57', fontSize: 40, marginBottom: 2 }} />
      <Typography variant="h6" color="white" gutterBottom>
        Last Update
      </Typography>
      <Typography variant="h4" sx={{ color: '#C59E57' }}>
        {new Date().toLocaleTimeString()}
      </Typography>
    </CardContent>
        </GameCard>
      </Grid>
    </Grid>

    {/* Staking Info Section */}
    
    {/* Meme Section */}
    <MemeSection>
  <Typography 
    variant="h4" 
    sx={{ 
      textAlign: 'center',
      mb: 4,
      background: 'linear-gradient(45deg, #C59E57, #FFD700)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 'bold'
    }}
  >
    Trending Memes
  </Typography>
  
  <Grid container spacing={3}>
    {memeIds.map((memeId, index) => (
      <Grid item xs={12} md={4} key={index}>
        <GameCard sx={{ overflow: 'hidden' }}>
          <Box sx={{ p: 2 }}>
            <TenorGif postId={memeId} />
          </Box>
        </GameCard>
      </Grid>
    ))}
  </Grid>
</MemeSection>
{/* New Staking Pool Section */}

<SocialFooter>
<SocialIconButton
href="https://x.com/apeoutmeme"
target="blank"
aria-label="Twitter"
>
<TwitterIcon />
</SocialIconButton>
<SocialIconButton
href="https://t.me/apeoutmeme
"
target="blank"
aria-label="Telegram"
>
<TelegramIcon />
</SocialIconButton>
<SocialIconButton
href="https://github.com/apeoutmeme/apeoutdotfun-
"
target="blank"
aria-label="GitHub"
>
<GitHubIcon />
</SocialIconButton>
<SocialIconButton
href="https://www.youtube.com/shorts/f4OeszM6ukE"
target="blank"
aria-label="YouTube"
>

<YouTubeIcon />
</SocialIconButton>
<SocialIconButton
href="https://apeout.meme/whitepaper"
target="blank"
aria-label="Whitepaper"
>
<Receipt />
</SocialIconButton>
<SocialIconButton
href="./app"
target="blank"
aria-label="Whitepaper"
>
<VideogameAsset />
</SocialIconButton>
<SocialIconButton
href="https://medium.com/@apeoutcrypto"
target="blank"
aria-label="Whitepaper"
>
<Book />
</SocialIconButton>
<SocialIconButton
  href="https://tiktok.com/@apeoutmeme" // Add your TikTok URL
  target="_blank"
  rel="noopener noreferrer"
  aria-label="TikTok"
>
  <FaTiktok />
</SocialIconButton>

</SocialFooter>
<StakingModal open={openStakingModal} onClose={() => setOpenStakingModal(false)}>
  <DialogTitle sx={{ color: '#C59E57' }}>Staking Calculations [2025 Pool] (At time of launch)</DialogTitle>
  <DialogContent>
    <Typography variant="h6" color="#FFD700" gutterBottom>Base Staking (10M BNNA)</Typography>
    <Box sx={{ mb: 3 }}>
      <Typography color="#FEFFEB">Daily Rewards: 0.000558</Typography>
      <Typography color="#FEFFEB">Initial Reward Amount: 500,000</Typography>
      <Typography color="#FEFFEB">Daily: 5,580 BNNA</Typography>
      <Typography color="#FEFFEB">Weekly: 39,060 BNNA</Typography>
      <Typography color="#FEFFEB">Monthly: 117,180 BNNA</Typography>
    </Box>
    <Typography variant="h6" color="#FFD700" gutterBottom>Price Conversions (At time of launch)</Typography>
    <Box>
      <Typography color="#FEFFEB">1 BNNA = $0.000345 USD</Typography>
      <Typography color="#FEFFEB">1M BNNA = $344</Typography>
      <Typography color="#FEFFEB">10M BNNA = $3.5K</Typography>
      <Typography color="#FEFFEB">20M BNNA = $6.8K</Typography>
      <Typography color="#FEFFEB">30M BNNA = $10K</Typography>
    </Box>
  </DialogContent>
</StakingModal>
  </DashboardContainer>
  
  );
};

export default BananaStandDashboard;