import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TrendingUp, TrendingDown, TrendingFlat, Refresh } from '@mui/icons-material';
import { useTwitterData } from '../../../utils/UseTwitterData';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  marginBottom: theme.spacing(2)
}));

const MentionCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.03)',
  backdropFilter: 'blur(5px)',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateX(5px)',
  }
}));

const SentimentScore = styled(Box)(({ score }) => ({
  display: 'flex',
  alignItems: 'center',
  color: score > 0 ? '#10b981' : score < 0 ? '#ef4444' : '#f59e0b',
  marginTop: '8px'
}));

const SentimentAnalyzerAgent = ({ activeToken }) => {
  console.log('[SentimentAnalyzer] Initializing with token:', activeToken);
  const [sentimentData, setSentimentData] = useState(null);
  const [selectedMention, setSelectedMention] = useState(null);
  const { mentions, loading, error, analyzeSentiment, refreshMentions } = useTwitterData(activeToken);

  const handleAnalyzeMention = async (mention) => {
    console.log('[SentimentAnalyzer] Analyzing mention:', mention);
    try {
      setSelectedMention(mention);
      const data = await analyzeSentiment(mention.text);
      console.log('[SentimentAnalyzer] Analysis result:', data);
      setSentimentData(data);
    } catch (err) {
      console.error('[SentimentAnalyzer] Analysis failed:', err);
    }
  };

  const getSentimentIcon = (score) => {
    if (score > 0.2) return <TrendingUp />;
    if (score < -0.2) return <TrendingDown />;
    return <TrendingFlat />;
  };

  return (
    <Box>
      <StyledCard>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h6" sx={{ color: 'white' }}>
              Social Sentiment Analysis
            </Typography>
            <Button
              startIcon={<Refresh />}
              onClick={refreshMentions}
              disabled={loading}
              sx={{ color: 'white' }}
            >
              Refresh
            </Button>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : error ? (
            <Typography sx={{ color: '#ef4444' }}>
              Error: {error}
            </Typography>
          ) : mentions.length === 0 ? (
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              No mentions found for this token
            </Typography>
          ) : (
            <>
              <Typography sx={{ color: 'white', mb: 2 }}>
                Recent Mentions ({mentions.length})
              </Typography>
              {mentions.map((mention) => (
                <MentionCard 
                  key={mention.id}
                  onClick={() => handleAnalyzeMention(mention)}
                  sx={{
                    borderColor: selectedMention?.id === mention.id ? '#8b5cf6' : undefined
                  }}
                >
                  <CardContent>
                    <Typography sx={{ color: 'white', mb: 1 }}>
                      {mention.text}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                      @{mention.author} · {new Date(mention.created_at).toLocaleString()}
                    </Typography>
                  </CardContent>
                </MentionCard>
              ))}
            </>
          )}

          {sentimentData && selectedMention && (
            <Box sx={{ mt: 3, p: 2, background: 'rgba(255, 255, 255, 0.03)', borderRadius: '12px' }}>
              <Typography sx={{ color: 'white', mb: 2 }}>
                Analysis Results
              </Typography>
              
              <SentimentScore score={sentimentData.sentiment}>
                {getSentimentIcon(sentimentData.sentiment)}
                <Typography sx={{ ml: 1 }}>
                  Sentiment Score: {(sentimentData.sentiment * 100).toFixed(1)}%
                </Typography>
              </SentimentScore>

              <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 2 }}>
                Key Topics: {sentimentData.topics.join(', ')}
              </Typography>

              <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 1 }}>
                Confidence: {(sentimentData.confidence * 100).toFixed(1)}%
              </Typography>
            </Box>
          )}
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default SentimentAnalyzerAgent;