import React, { useState } from 'react';
import { Box, Card, Typography, IconButton, Collapse, Paper, Grid } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, CheckCircle } from '@mui/icons-material';
import logo from '../assets/bnnalogo.png';
import donateImage from '../assets/donate-wallet.png';


const RoadmapItem = ({ phase, title, description, items, isCompleted, isActive }) => {
    const [expanded, setExpanded] = useState(false);
  
    return (
      <Card 
        sx={{ 
          mb: 2, 
          position: 'relative',
          backgroundColor: isActive ? 'rgba(197, 158, 87, 0.1)' : 
                        isCompleted ? 'rgba(197, 158, 87, 0.05)' : '#FEFFEB',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 20px rgba(197, 158, 87, 0.2)'
          },
          border: '1px solid',
          borderColor: isActive ? '#C59E57' : 
                     isCompleted ? 'rgba(197, 158, 87, 0.3)' : 'rgba(197, 158, 87, 0.1)',
          borderRadius: 2
        }}
      >
        <Box 
          sx={{ 
            p: 3, 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            {isCompleted && (
              <CheckCircle sx={{ color: '#C59E57', position: 'absolute', top: 16, right: 16 }} />
            )}
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ color: '#C59E57', fontWeight: 600 }}
            >
              Phase {phase}
            </Typography>
            <Typography variant="h6" sx={{ color: 'grey', fontWeight: 500 }}>
              {title}
            </Typography>
          </Box>
          <IconButton sx={{ color: '#C59E57' }}>
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        
        <Collapse in={expanded}>
          <Box sx={{ p: 3, pt: 0 }}>
            <Typography paragraph sx={{ color: '#423D36', mb: 3 }}>
              {description}
            </Typography>
            <Grid container spacing={3}>
              {items.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper 
                    sx={{ 
                      p: 3,
                      height: '100%',
                      backgroundColor: '#FEFFEB',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      border: '1px solid rgba(197, 158, 87, 0.2)',
                      boxShadow: '0 2px 12px rgba(197, 158, 87, 0.1)',
                      borderRadius: 2
                    }}
                  >
                    <Typography 
                      variant="subtitle1" 
                      sx={{ color: '#C59E57', fontWeight: 600 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#423D36' }}>
                      {item.description}
                    </Typography>
                    {item.status && (
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          mt: 'auto',
                          color: item.status === 'Completed' ? '#9B7940' : 
                                 item.status === 'In Progress' ? '#C59E57' : '#423D36',
                          fontWeight: 500
                        }}
                      >
                        Status: {item.status}
                      </Typography>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Collapse>
      </Card>
    );
  };

const Roadmap = () => {
  const roadmapData = [
    {
      phase: 1,
      title: "Foundation",
      description: "Building the core infrastructure and establishing key features",
      isCompleted: true,
      items: [
        {
          title: "Smart Contract Development",
          description: "Develop and audit core smart contracts",
          status: "Completed"
        },
        {
          title: "Website Launch",
          description: "Launch main website with basic functionality",
          status: "Completed"
        },
        {
          title: "Community Building",
          description: "Establish social media presence and community channels",
          status: "Completed"
        },
        {
            title: "Token Launch",
            description: "Launch the BNNA token on the Solana blockchain",
            status: "Completed"
          },
          {
            title: "Launchpad",
            description: "Launch the BNNA Launchpad",
            status: "Completed"
          }
      ]
    },
    {
      phase: 2,
      title: "Growth & Development",
      description: "Expanding features and improving user experience",
      isActive: true,
      items: [
        {
          title: "Advanced Trading Features",
          description: "Implement advanced trading capabilities and tools",
          status: "In Progress"
        },
        {
          title: "Mobile App Development",
          description: "Begin development of mobile applications",
          status: "In Progress"
        },
        {
          title: "Partnership Expansion",
          description: "Form strategic partnerships in the industry",
          status: "Pending"
        },
        {
            title: "Marketplace",
            description: "Launch the BNNA Marketplace",
            status: "Pending"
        },
        {
            title: "AIAgents",
            description: "Launch the BNNA AIAgents",
            status: "Completed"
        },
        {
            title: "ApeMind",
            description: "Launch the BNNA ApeMind",
            status: "In Progress"
        },
        {
            title: "PawChain",
            description: "Launch the BNNA PawChain",
            status: "In Progress"
        },
        {
            title: "GOAT-SDK",
            description: "GOAT SDK Integration",
            status: "In Progress"
        },
        {
            title: "Coinbase",
            description: "Coinbase On-Ramp Integration",
            status: "In Progress"
        },
        {
            title: "ORCA",
            description: "ORCA Staking Integration",
            status: "Planned"
        },
        
        {
            title: "Helio, Shopify, and WooCommerce",
            description: "Enable 1M+ Users to transact with your token across 6,000+ creators & merchants!",
            status: "Planned"
        },
        {
            title: "Privy",
            description: "Privy Integration",
            status: "Planned"
        },
        {
            title: "Twitter v2 API",
            description: "Twitter Integration",
            status: "Planned"
        }
      ]
    },
    {
      phase: 3,
      title: "Expansion",
      description: "Scaling the platform and adding new capabilities",
      items: [
        {
          title: "Cross-chain Integration",
          description: "Implement support for multiple blockchains",
          status: "Planned"
        },
    
        {
          title: "Advanced Analytics",
          description: "Launch comprehensive analytics dashboard",
          status: "Planned"
        },
        {
            title: "SUPRA Network",
            description: "Start development on SUPRA Network",
            status: "Planned"
          },
          {
            title: "Humans.AI",
            description: "Start development on Humans.AI",
            status: "Planned"
          },
        {
          title: "Global Expansion",
          description: "Expand into new markets and regions",
          status: "Planned"
        },
        {
            title: "HIVE",
            description: "HIVE Integration",
            status: "Planned"
        }
      ]
    }
  ];

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #322E29 0%, #423D36 100%)',
        py: 6
      }}
    >
      <Box 
        sx={{ 
          maxWidth: 1200, 
          mx: 'auto', 
          p: 4,
          backgroundColor: 'rgba(254, 255, 235, 0.02)',
          borderRadius: 4,
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(197, 158, 87, 0.1)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <img 
            src={logo} 
            alt="Logo" 
            style={{ 
              height: '80px', 
              marginBottom: '24px',
              filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2))'
            }} 
          />
          <Typography 
            variant="h3" 
            component="h1" 
            sx={{ 
              color: '#C59E57',
              fontWeight: 600,
              textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              mb: 2
            }}
          >
            Project Roadmap
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              color: '#FEFFEB',
              opacity: 0.9,
              maxWidth: '600px',
              mx: 'auto'
            }}
          >
            Click on each phase to explore our journey and upcoming milestones
          </Typography>
        </Box>
        <Box 
  sx={{ 
    textAlign: 'center', 
    mb: 4, 
    py: 4, 
    backgroundColor: 'rgba(197, 158, 87, 0.1)', 
    borderRadius: 4 
  }}
>
  <Typography 
    variant="h4" 
    sx={{ 
      color: '#C59E57', 
      fontWeight: 600, 
      mb: 2 
    }}
  >
    Support Our Development
  </Typography>
  <Typography 
    variant="body1" 
    sx={{ 
      color: '#FEFFEB', 
      mb: 3 
    }}
  >
    Donate to help us continue building innovative tools for the ApeOut community.
  </Typography>
  <img 
    src={donateImage} 
    alt="Donate QR Code" 
    style={{ 
      height: '150px', 
      marginBottom: '16px', 
      filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2))' 
    }} 
  />
  <Typography 
    variant="body2" 
    sx={{ 
      color: '#C59E57', 
      fontWeight: 500 
    }}
  >
    Wallet Address: <span style={{ color: '#FEFFEB' }}>6gMrZxSBhY5isoFdDpzKyfGGbmSZkyU4HydPC9WQTspu</span>
  </Typography>
</Box>
{/* <Box 
  sx={{ 
    mt: 4, 
    textAlign: 'center', 
    py: 3, 
    backgroundColor: 'rgba(197, 158, 87, 0.05)', 
    borderRadius: 4 
  }}
>
  <Typography 
    variant="h5" 
    sx={{ 
      color: '#C59E57', 
      fontWeight: 600, 
      mb: 2 
    }}
  >
    Proof of Payments
  </Typography>
  <Typography 
    variant="body1" 
    sx={{ 
      color: '#FEFFEB', 
      mb: 3 
    }}
  >
    Here are the records of payments for our marketing efforts and completed roadmap milestones:
  </Typography>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
    <a 
      href="https://example.com/payment-proof-1" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ 
        color: '#C59E57', 
        textDecoration: 'none', 
        fontWeight: 500 
      }}
    >
      🛠️ Marketing Campaign 1 - Twitter Promotions
    </a>
    <a 
      href="https://example.com/payment-proof-2" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ 
        color: '#C59E57', 
        textDecoration: 'none', 
        fontWeight: 500 
      }}
    >
      🛠️ Influencer Partnership Proof
    </a>
    <a 
      href="https://example.com/payment-proof-3" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ 
        color: '#C59E57', 
        textDecoration: 'none', 
        fontWeight: 500 
      }}
    >
      🛠️ Roadmap Milestone: Token Launch
    </a>
    <a 
      href="https://example.com/payment-proof-4" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ 
        color: '#C59E57', 
        textDecoration: 'none', 
        fontWeight: 500 
      }}
    >
      🛠️ Community Growth and Ads
    </a>
  </Box>
</Box> */}


        
        {roadmapData.map((item, index) => (
          <RoadmapItem key={index} {...item} />
        ))}
      </Box>
    </Box>
  );
};

export default Roadmap;