import React, { useState, useEffect, useContext } from 'react';
import { 
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Slider } from '@mui/material';

import { InfoOutlined, Refresh } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useWallet } from '@solana/wallet-adapter-react';
import { useConnection } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Settings } from '@mui/icons-material';

const QuickTradeBox = ({ selectedToken, selectedChain }) => {
  const [tradeType, setTradeType] = useState('buy');
  const [amount, setAmount] = useState('');
  const [tokenBalance, setTokenBalance] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const { publicKey, connected } = useWallet();
  const { connection } = useConnection();
  const [allBalances, setAllBalances] = useState(null); // Add this
  const [tradingWallet, setTradingWallet] = useState(null);
  const [hasRequiredTokens, setHasRequiredTokens] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
const [tradeSettings, setTradeSettings] = useState({
  slippage: 15,
  priorityFee: 0.00001
});



  const handleTradeTypeChange = (event, newType) => {
    if (newType !== null) {
      setTradeType(newType);
    }
  };
  const fetchBalance = async () => {
    if (!connected || !publicKey) {
      console.log('Wallet not connected');
      return;
    }
    if (!tradingWallet?.walletPublicKey) {
      console.log('Trading wallet not initialized');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${tradingWallet.walletPublicKey}`);
      const data = await response.json();

      if (data.success) {
        setAllBalances(data);
        
        // Update specific token balance if selected token exists
        if (selectedToken?.baseToken?.address) {
          const tokenData = data.balances.find(
            token => token.mint === selectedToken.baseToken.address
          );
          setTokenBalance(tokenData?.balance || 0);
        }
      } else {
        console.error('Failed to fetch balances:', data.message);
      }
    } catch (error) {
      console.error('Error fetching balances:', error);
      toast.error('Failed to fetch token balances');
    }
  };

  const handleTrade = async () => {
    if (!amount || isNaN(amount)) {
      toast.error('Please enter a valid amount');
      return;
    }
  
    if (!connected || !publicKey) {
      toast.error('Please connect your wallet');
      return;
    }
  
    // Add this check
    if (!tradingWallet?.walletPublicKey) {
      toast.error('Trading wallet not properly initialized');
      return;
    }
  
    setIsProcessing(true);
    const toastId = toast.loading(`Processing ${tradeType === 'buy' ? 'Buy' : 'Sell'} Order...`);
  
    try {
      // Get encrypted wallet data first
      const encryptResponse = await fetch(`${process.env.REACT_APP_API_URL}api/decrypt-key`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer true` // Or your auth token
        },
        body: JSON.stringify({
          encryptedKey: tradingWallet.privateKey,
          walletAddress: tradingWallet.walletPublicKey
        })
      });
  
      const encryptData = await encryptResponse.json();
      if (!encryptData.success) {
        throw new Error('Failed to decrypt wallet key');
      }
  
      const endpoint = tradeType === 'buy' ? '/api/trade/buy' : '/api/trade/sell';
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          coin: selectedToken.baseToken.address,
          amount: Number(amount),
          tradeSettings,
          walletData: {
            walletPublicKey: tradingWallet.walletPublicKey,
            privateKey: encryptData.decryptedKey
          }
        })
      });
  
      const result = await response.json();
  
      if (!result.success) {
        throw new Error(result.error || 'Transaction failed');
      }
  
      toast.update(toastId, {
        render: (
          <div>
            <div>{tradeType === 'buy' ? 'Buy' : 'Sell'} successful!</div>
            <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
              <a 
                href={`https://solscan.io/tx/${result.signature}`} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: '#4ade80' }}
              >
                View on Solscan
              </a>
            </div>
          </div>
        ),
        type: "success",
        isLoading: false,
        autoClose: 5000
      });
  
      // Immediately fetch new balance
      await fetchBalance();
      
      // Clear input after successful trade
      setAmount('');
  
    } catch (error) {
      console.error('Trade error:', error);
      toast.update(toastId, {
        render: error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  useEffect(() => {
    const checkTradingWallet = async () => {
      if (!connected || !publicKey) return;
    
      try {
        // First check localStorage
        const storedTradingWallet = localStorage.getItem('tradingWallet');
        
        if (storedTradingWallet) {
          const parsedWallet = JSON.parse(storedTradingWallet);
          setTradingWallet(parsedWallet);
          
          // Move the balance check after setting trading wallet
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/user_balances/${parsedWallet.walletPublicKey}`);
          const data = await response.json();
          
          const hasToken = data.balances?.some(
            token => token.mint === 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump' && token.balance > 0
          );
          
          setHasRequiredTokens(hasToken);
        } else {
          // If no local wallet, try fetching from server
          const userResponse = await fetch(`${process.env.REACT_APP_API_URL}api/users/${publicKey.toString()}`);
          const userData = await userResponse.json();
          
          if (userData?.tradingWallet) {
            setTradingWallet(userData.tradingWallet);
            localStorage.setItem('tradingWallet', JSON.stringify(userData.tradingWallet));
          }
        }
      } catch (error) {
        console.error('Error checking trading wallet:', error);
        toast.error('Failed to verify trading wallet');
      }
    };

    checkTradingWallet();
  }, [connected, publicKey]);

  useEffect(() => {
    if (tradingWallet?.walletPublicKey) {
      fetchBalance();
      
      // Set up periodic refresh
      const intervalId = setInterval(fetchBalance, 30000);
      return () => clearInterval(intervalId);
    }
  }, [publicKey, connected, selectedToken?.baseToken?.address, tradingWallet]);

  if (!selectedToken) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        background: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3))',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        borderRadius: 2,
        height: '100%',
      }}
    >
            <WalletMultiButton />

      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <CircularProgress size={16} sx={{ mr: 1 }} />
                <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'white' }}>
                  Feature in development
                </Typography>
              </Box>
        <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
          Quick Trade
        </Typography>
        <Tooltip title="Trade tokens instantly at market price">
          <IconButton size="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            <InfoOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
    Balance: {tokenBalance.toFixed(6)} {selectedToken?.baseToken?.symbol}
  </Typography>
  <IconButton 
    onClick={fetchBalance}
    size="small"
    sx={{ 
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover': { color: 'white' }
    }}
  >
    <Refresh fontSize="small" />
  </IconButton>
</Box>
<IconButton 
  onClick={() => setSettingsOpen(true)}
  sx={{ 
    color: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    right: '8px',
    top: '8px'
  }}
>
  <Settings fontSize="small" />
</IconButton>


<Dialog 
  open={settingsOpen} 
  onClose={() => setSettingsOpen(false)}
  PaperProps={{
    sx: {
      background: 'rgba(0, 0, 0, 0.8)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    }
  }}
>
  <DialogTitle sx={{ color: 'white' }}>Trade Settings</DialogTitle>
  <DialogContent>
    <Typography sx={{ color: 'white', mb: 1 }}>Slippage Tolerance (%)</Typography>
    <Slider
      value={tradeSettings.slippage}
      onChange={(e, newValue) => setTradeSettings(prev => ({...prev, slippage: newValue}))}
      min={0.1}
      max={50}
      step={0.1}
      sx={{ color: 'primary.main' }}
    />
    <Typography sx={{ color: 'white', mt: 2, mb: 1 }}>Priority Fee (SOL)</Typography>
    <TextField
      value={tradeSettings.priorityFee}
      onChange={(e) => setTradeSettings(prev => ({...prev, priorityFee: Number(e.target.value)}))}
      type="number"
      inputProps={{ min: 0.000001, step: 0.000001 }}
      sx={{
        '& .MuiInputBase-input': { color: 'white' },
        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.1)' }
      }}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setSettingsOpen(false)} sx={{ color: 'white' }}>Close</Button>
  </DialogActions>
</Dialog>

      <ToggleButtonGroup
        value={tradeType}
        exclusive
        onChange={handleTradeTypeChange}
        sx={{ 
          mb: 3,
          width: '100%',
          '& .MuiToggleButton-root': {
            flex: 1,
            color: 'white',
            borderColor: 'rgba(255, 255, 255, 0.1)',
            '&.Mui-selected': {
              backgroundColor: tradeType === 'buy' ? 'rgba(76, 175, 80, 0.3)' : 'rgba(244, 67, 54, 0.3)',
              color: 'white',
              '&:hover': {
                backgroundColor: tradeType === 'buy' ? 'rgba(76, 175, 80, 0.4)' : 'rgba(244, 67, 54, 0.4)',
              }
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)'
            }
          }
        }}
      >
        <ToggleButton value="buy">Buy</ToggleButton>
        <ToggleButton value="sell">Sell</ToggleButton>
      </ToggleButtonGroup>
      <Box 
  sx={{ 
    display: 'flex', 
    gap: 1, 
    mb: 2,
    flexWrap: 'wrap'
  }}
>
  {[0.01, 0.1, 0.5, 1].map((preset) => (
    <Button
      key={preset}
      variant="outlined"
      size="small"
      onClick={() => setAmount(preset)}
      sx={{
        color: 'white',
        borderColor: 'rgba(255, 255, 255, 0.2)',
        backgroundColor: amount === preset ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
        '&:hover': {
          borderColor: 'rgba(255, 255, 255, 0.4)',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
        },
        flex: { xs: '1 0 calc(50% - 4px)', sm: '0 1 auto' },
        minWidth: { xs: 'calc(50% - 4px)', sm: '80px' },
      }}
    >
      {preset} {selectedChain === 'solana' ? 'SOL' : 'ETH'}
    </Button>
  ))}
</Box>
{tradeType === 'sell' && tokenBalance > 0 && (
  <Box 
    sx={{ 
      display: 'flex', 
      gap: 1, 
      mb: 2,
      flexWrap: 'wrap'
    }}
  >
    {[25, 50, 100].map((percentage) => {
      const sellAmount = (tokenBalance * percentage) / 100;
      return (
        <Button
          key={percentage}
          variant="outlined"
          size="small"
          onClick={() => setAmount(sellAmount.toFixed(6))}
          sx={{
            color: 'white',
            borderColor: 'rgba(255, 255, 255, 0.2)',
            backgroundColor: Number(amount) === sellAmount ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
            '&:hover': {
              borderColor: 'rgba(255, 255, 255, 0.4)',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            },
            flex: { xs: '1 0 calc(33% - 8px)', sm: '0 1 auto' },
            minWidth: { xs: 'calc(33% - 8px)', sm: '80px' },
          }}
        >
          {percentage}% ({sellAmount.toFixed(2)})
        </Button>
      );
    })}
  </Box>
)}

      <TextField
        fullWidth
        variant="outlined"
        label="Amount"
        type="number"
        value={amount}
        onChange={handleAmountChange}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.1)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.2)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
          '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
          },
        }}
        InputProps={{
          endAdornment: (
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {selectedToken?.baseToken?.symbol}
            </Typography>
          ),
        }}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={handleTrade}
        sx={{
          bgcolor: tradeType === 'buy' ? 'success.main' : 'error.main',
          color: 'white',
          py: 1.5,
          '&:hover': {
            bgcolor: tradeType === 'buy' ? 'success.dark' : 'error.dark',
          },
        }}
      >
        {tradeType === 'buy' ? 'Buy' : 'Sell'} {selectedToken?.baseToken?.symbol}
      </Button>
    </Paper>
  );
};

export default QuickTradeBox;