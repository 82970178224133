import { useState, useEffect, useCallback } from 'react';

export const useTwitterData = (activeToken) => {
  const [mentions, setMentions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchMentions = useCallback(async () => {
    if (!activeToken) return;
    
    console.log('[TwitterHook] Fetching mentions for token:', activeToken);
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch('/api/twitter/mentions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: '4330998194' // Replace with dynamic user ID if needed
        })
      });

      if (!response.ok) throw new Error('Failed to fetch mentions');
      const data = await response.json();
      
      
      setMentions(data.data || []);
      console.log('[TwitterHook] Fetched mentions:', data);
    } catch (err) {
      console.error('[TwitterHook] Error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [activeToken]);

  const analyzeSentiment = async (text) => {
    console.log('[TwitterHook] Analyzing sentiment for:', text);
    
    try {
      const response = await fetch('/api/analyze-sentiment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text })
      });

      if (!response.ok) throw new Error('Failed to analyze sentiment');
      const data = await response.json();
      
      console.log('[TwitterHook] Analysis result:', data);
      return data;
    } catch (err) {
      console.error('[TwitterHook] Analysis error:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchMentions();
    const interval = setInterval(fetchMentions, 120000); // 2 minute interval to avoid rate limits
    return () => clearInterval(interval);
  }, [activeToken, fetchMentions]);

  return {
    mentions,
    loading,
    error,
    analyzeSentiment,
    refreshMentions: fetchMentions
  };
};