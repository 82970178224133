import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Alert,
  CircularProgress,
  Box,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import {
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import DiamondIcon from '@mui/icons-material/Diamond';
import StarIcon from '@mui/icons-material/Star';

import AgentIntelligence from './AgentIntelligence';

const AgentWalletManagement = ({ agent, bnnaBalance, stakingBalance }) => {
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);
  const [isCreatingToken, setIsCreatingToken] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [hasWallet, setHasWallet] = useState(false);
  const [walletInfo, setWalletInfo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [tokenInfo, setTokenInfo] = useState({
    name: `${agent.name} Token`,
    symbol: agent.name.slice(0, 4).toUpperCase(),
    description: `AI-generated token by ${agent.name}`,
    twitter: '',
    telegram: '',
    website: 'https://apeout.fun'
  });

  const [userTier, setUserTier] = useState('basic');
const [tierFeatures, setTierFeatures] = useState({
  canLaunchTokens: false,
  canCreateAgents: false,
  canUseAdvancedFeatures: false
});

const [marketData, setMarketData] = useState(null);
const [tradeHistory, setTradeHistory] = useState([]);

// Add this method to check balance and set tier
const checkTierEligibility = useCallback(() => {
  // Define tier thresholds
  const TIER_THRESHOLDS = {
    elite: 10000000,  // 100k BNNA
    pro: 1000000,     // 10k BNNA
    basic: 0
  };

  // Determine tier based on balance
  let newTier = 'basic';
  let features = {
    canLaunchTokens: false,
    canCreateAgents: false,
    canUseAdvancedFeatures: false
  };

  if (bnnaBalance || stakingBalance >= TIER_THRESHOLDS.elite) {
    newTier = 'elite';
    features = {
      canLaunchTokens: true,
      canCreateAgents: true,
      canUseAdvancedFeatures: true
    };
  } else if (bnnaBalance || stakingBalance >= TIER_THRESHOLDS.pro) {
    newTier = 'pro';
    features = {
      canLaunchTokens: true,
      canCreateAgents: true,
      canUseAdvancedFeatures: false
    };
  } else if (bnnaBalance || stakingBalance > 0) {
    newTier = 'basic';
    features = {
      canLaunchTokens: false,
      canCreateAgents: true,
      canUseAdvancedFeatures: false
    };
  }

  setUserTier(newTier);
  setTierFeatures(features);

  // Log tier update for debugging
  console.log('User tier updated:', {
    balance: bnnaBalance,
    tier: newTier,
    features
  });

  return features;
}, [bnnaBalance]);

// Add useEffect to run the check whenever balance changes
useEffect(() => {
  checkTierEligibility();
}, [bnnaBalance, checkTierEligibility]);

  useEffect(() => {
    const fetchAgentWallet = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/agents/${agent._id}`);
        const data = await response.json();
        
        if (data.success && data.agent.wallet) {
          setHasWallet(true);
          setWalletInfo(data.agent.wallet);
        }
      } catch (err) {
        console.error('Error fetching agent wallet:', err);
        setError(err.message);
      }
    };

    fetchAgentWallet();
  }, [agent._id]);



  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const createAgentWallet = async () => {
    setIsCreatingWallet(true);
    setError(null);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/create-agent-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          agentId: agent._id
        })
      });

      const data = await response.json();
      
      if (data.success) {
        setSuccess('Agent wallet created successfully!');
      } else {
        throw new Error(data.error || 'Failed to create wallet');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsCreatingWallet(false);
    }
  };

  const createAgentToken = async () => {
    setIsCreatingToken(true);
    setError(null);
  
    try {
      const formData = new FormData();
  
      // Handle image
      if (!selectedImage) {
        const canvas = document.createElement('canvas');
        canvas.width = 400;
        canvas.height = 400;
        const ctx = canvas.getContext('2d');
        
        const gradient = ctx.createLinearGradient(0, 0, 400, 400);
        gradient.addColorStop(0, '#4a90e2');
        gradient.addColorStop(1, '#7c4dff');
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, 400, 400);
        
        ctx.fillStyle = 'white';
        ctx.font = 'bold 48px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(agent.name.slice(0, 4).toUpperCase(), 200, 200);
        
        const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
        formData.append('file', blob, 'ai-token.png');
      } else {
        formData.append('file', selectedImage);
      }
  
      // Add metadata fields
      formData.append('name', tokenInfo.name);
    formData.append('symbol', tokenInfo.symbol);
    formData.append('description', tokenInfo.description);
        formData.append('twitter', '');
      formData.append('telegram', '');
      formData.append('website', 'https://apeout.fun');
      formData.append('showName', 'true');
      formData.append('agentId', agent._id);
      formData.append('initialSupply', '0.001'); // 1 SOL denominated buy
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/create-agent-token`, {
        method: 'POST',
        body: formData
      });
  
      const data = await response.json();
      
      if (data.success) {
        setSuccess(`Token created successfully! View transaction: https://solscan.io/tx/${data.token.signature}`);
      } else {
        throw new Error(data.error || 'Failed to create token');
      }
    } catch (err) {
      console.error('Token creation error:', err);
      setError(err.message);
    } finally {
      setIsCreatingToken(false);
    }
  };

  return (
    <>
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Wallet & Token Management
        </Typography>

        {error && (
          <Alert 
            severity="error" 
            icon={<WarningIcon />}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        )}
        
        {success && (
          <Alert 
            severity="success"
            icon={<CheckCircleIcon />}
            sx={{ mb: 2 }}
          >
            {success}
          </Alert>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {!hasWallet && (
  <>
    <Button
      variant="contained"
      onClick={createAgentWallet}
      disabled={isCreatingWallet}
      startIcon={isCreatingWallet && <CircularProgress size={20} />}
    >
      {isCreatingWallet ? 'Creating Wallet...' : 'Create AI Wallet'}
    </Button>
    
  </>
)}

          {hasWallet && walletInfo && (
              <>
                <Typography 
                  variant="body2" 
                  color="textSecondary"
                  sx={{ 
                    wordBreak: 'break-all',
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.8 }
                  }}
                  onClick={(e) => {
                    navigator.clipboard.writeText(walletInfo.publicKey);
                    setSuccess('Address copied to clipboard!');
                  }}
                >
                  Wallet Address: {walletInfo.publicKey}
                </Typography>
                <Button
      variant="contained"
      onClick={() => setOpenModal(true)}
      disabled={!tierFeatures.canLaunchTokens}
      startIcon={isCreatingToken && <CircularProgress size={20} />}
    >
      {!tierFeatures.canLaunchTokens 
        ? `Requires Pro Tier (1,000,000 $BNNA needed)`
        : isCreatingToken 
          ? 'Creating Token...' 
          : 'Launch AI Token'
      }
    </Button>
    <Typography 
      variant="subtitle2" 
      sx={{ 
        mt: 1,
        color: theme => theme.palette.grey[400],
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}
    >
      Current Tier: {userTier.charAt(0).toUpperCase() + userTier.slice(1)}
      {userTier === 'elite' && <DiamondIcon sx={{ fontSize: 16 }} />}
      {userTier === 'pro' && <StarIcon sx={{ fontSize: 16 }} />}
    </Typography>
    <Typography
      variant="caption"
      color="textSecondary"
      sx={{ 
        display: 'block', 
        mt: 1, 
        fontSize: '0.75rem',
        fontStyle: 'italic',
        maxWidth: '300px',
        textAlign: 'center' 
      }}
    >
      Note: AI Agents use trading wallets for transactions. This wallet is exclusively for creating new tokens.
    </Typography>
    
              </>
            )}
        </Box>
      </CardContent>
    </Card>
    <Dialog open={openModal} onClose={() => setOpenModal(false)}>
    <DialogTitle>Create Token</DialogTitle>
    <DialogContent>
      <Box component="form" sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Token Name"
          value={tokenInfo.name}
          onChange={(e) => setTokenInfo({ ...tokenInfo, name: e.target.value })}
          fullWidth
        />
        <TextField
          label="Symbol"
          value={tokenInfo.symbol}
          onChange={(e) => setTokenInfo({ ...tokenInfo, symbol: e.target.value })}
          fullWidth
        />
        <TextField
          label="Description"
          value={tokenInfo.description}
          onChange={(e) => setTokenInfo({ ...tokenInfo, description: e.target.value })}
          multiline
          rows={3}
          fullWidth
        />
        <Input
          type="file"
          onChange={handleImageChange}
          fullWidth
        />
        <Button 
          variant="contained"
          onClick={() => {
            createAgentToken();
            setOpenModal(false);
          }}
        >
          Create Token
        </Button>
      </Box>
    </DialogContent>
  </Dialog>
  {/* <Grid item xs={12} md={8}>
        <AgentIntelligence 
          agent={agent}
          marketData={marketData}
          tradeHistory={tradeHistory}
        />
      </Grid> */}
 </>
  );
};

export default AgentWalletManagement;