import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Chip
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material';

const WalletClusterAnalysis = ({ data, isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Typography sx={{ p: 2 }}>No cluster data available</Typography>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      {data.map((cluster, index) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">{cluster.id}</Typography>
              <Chip 
                label={`${cluster.wallets.length} wallets`}
                color="primary"
                size="small"
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TrendingUpIcon sx={{ mr: 1, color: 'success.main' }} />
                  <Typography>
                    ROI: {cluster.averageROI}%
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ScheduleIcon sx={{ mr: 1, color: 'info.main' }} />
                  <Typography>
                    Hold Time: {cluster.averageHoldingDuration} days
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography color="textSecondary">
                  Top Tokens: {cluster.topTokens.join(', ')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography color="textSecondary">
                  Volume: {cluster.tradingVolume.toLocaleString()} SOL
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default WalletClusterAnalysis;