import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  CardHeader, 
  Typography, 
  TextField, 
  IconButton, 
  Paper,
  CircularProgress,
  Tooltip,
  Switch,
  Select,
  FormControl
} from '@mui/material';
import { 
  Send as SendIcon, 
  SmartToy as BotIcon,
  Person as UserIcon,
  Help as HelpIcon,
  TrendingUp as TrendingUpIcon,
  AttachMoney as MoneyIcon,
  School as SchoolIcon,
  Twitter as TwitterIcon,
  Assessment as AssessmentIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Palette } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { 
  MicNone as MicIcon,
  MicOff as MicOffIcon,
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon 
} from '@mui/icons-material';


// Add at the top of your component
const THEME_OPTIONS = {
  default: {
    primary: '#fbbf24',
    secondary: '#4f46e5',
    gradient: 'linear-gradient(145deg, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
    messageUser: 'linear-gradient(135deg, #4f46e5 0%, #6366f1 100%)',
    messageBot: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%)'
  },
  purple: {
    primary: '#8b5cf6',
    secondary: '#7c3aed',
    gradient: 'linear-gradient(145deg, rgba(76, 29, 149, 0.95), rgba(91, 33, 182, 0.95))',
    messageUser: 'linear-gradient(135deg, #7c3aed 0%, #8b5cf6 100%)',
    messageBot: 'linear-gradient(135deg, rgba(139, 92, 246, 0.2) 0%, rgba(139, 92, 246, 0.1) 100%)'
  },
  ocean: {
    primary: '#06b6d4',
    secondary: '#0284c7',
    gradient: 'linear-gradient(145deg, rgba(8, 47, 73, 0.95), rgba(12, 74, 110, 0.95))',
    messageUser: 'linear-gradient(135deg, #0284c7 0%, #06b6d4 100%)',
    messageBot: 'linear-gradient(135deg, rgba(6, 182, 212, 0.2) 0%, rgba(6, 182, 212, 0.1) 100%)'
  },
  ember: {
    primary: '#f97316',
    secondary: '#dc2626',
    gradient: 'linear-gradient(145deg, rgba(153, 27, 27, 0.95), rgba(127, 29, 29, 0.95))',
    messageUser: 'linear-gradient(135deg, #dc2626 0%, #f97316 100%)',
    messageBot: 'linear-gradient(135deg, rgba(249, 115, 22, 0.2) 0%, rgba(249, 115, 22, 0.1) 100%)'
  },
  forest: {
    primary: '#22c55e',
    secondary: '#15803d',
    gradient: 'linear-gradient(145deg, rgba(20, 83, 45, 0.95), rgba(22, 101, 52, 0.95))',
    messageUser: 'linear-gradient(135deg, #15803d 0%, #22c55e 100%)',
    messageBot: 'linear-gradient(135deg, rgba(34, 197, 94, 0.2) 0%, rgba(34, 197, 94, 0.1) 100%)'
  }
};

const ChatHeader = styled(CardHeader)(({ theme }) => ({
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  background: 'linear-gradient(90deg, rgba(251, 191, 36, 0.1), rgba(79, 70, 229, 0.1))',
  '& .MuiCardHeader-title': {
    color: '#fbbf24',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontSize: '1.25rem',
    fontWeight: 600,
    textShadow: '0 2px 4px rgba(251, 191, 36, 0.2)',
  }
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  height: '400px',
  overflowY: 'auto',
  padding: theme.spacing(2),
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'linear-gradient(180deg, #fbbf24 0%, #4f46e5 100%)',
    borderRadius: '3px',
    '&:hover': {
      background: 'linear-gradient(180deg, #fcd34d 0%, #6366f1 100%)',
    }
  }
}));

const ChatCard = styled(Card)(({ theme: muiTheme, chatTheme = 'default' }) => ({  // Add default value
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  background: THEME_OPTIONS[chatTheme]?.gradient || THEME_OPTIONS.default.gradient,
  backdropFilter: 'blur(12px)',
  border: `1px solid ${THEME_OPTIONS[chatTheme]?.primary || THEME_OPTIONS.default.primary}33`,
  borderRadius: '16px',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 8px 28px ${THEME_OPTIONS[chatTheme]?.primary || THEME_OPTIONS.default.primary}33`,
  }
}));

const MessageBubble = styled(Paper)(({ type, chatTheme = 'default' }) => ({  // Add default value
  padding: '12px 16px',
  maxWidth: '80%',
  wordBreak: 'break-word',
  marginBottom: '12px',
  background: type === 'user' 
    ? THEME_OPTIONS[chatTheme]?.messageUser || THEME_OPTIONS.default.messageUser
    : THEME_OPTIONS[chatTheme]?.messageBot || THEME_OPTIONS.default.messageBot,
  color: '#fff',
  borderRadius: type === 'user' ? '12px 12px 0 12px' : '12px 12px 12px 0',
  alignSelf: type === 'user' ? 'flex-end' : 'flex-start',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.01)',
    boxShadow: `0 4px 12px ${THEME_OPTIONS[chatTheme]?.primary || THEME_OPTIONS.default.primary}33`,
  }
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  background: 'linear-gradient(90deg, rgba(251, 191, 36, 0.05), rgba(79, 70, 229, 0.05))',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: 'linear-gradient(90deg, transparent, rgba(251, 191, 36, 0.2), transparent)',
  }
}));

const VoiceControl = ({ onStartListening, onStopListening, isListening, isSpeaking, voiceEnabled, onToggleVoice }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <Tooltip title={voiceEnabled ? "Voice enabled" : "Voice disabled"}>
      <Switch
        checked={voiceEnabled}
        onChange={onToggleVoice}
        color="primary"
      />
    </Tooltip>
    {voiceEnabled && (
      <Tooltip title={isListening ? "Stop listening" : "Start listening"}>
        <IconButton 
          onClick={isListening ? onStopListening : onStartListening}
          sx={{
            color: isListening ? 'error.main' : 'primary.main',
            animation: isListening ? 'pulseGlow 2s infinite' : 'none'
          }}
        >
          {isListening ? <MicIcon /> : <MicOffIcon />}
        </IconButton>
      </Tooltip>
    )}
    {voiceEnabled && (
      <Tooltip title={isSpeaking ? "Speaking..." : "Idle"}>
        <IconButton disabled>
          {isSpeaking ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </IconButton>
      </Tooltip>
    )}
  </Box>
);

const ApeBuddyChat = ({ 
  onExecuteTrade,
  tradingWallet,
  handleBuy,
  handleSell,
  connectedWalletBalance,
  bnnaBalance,
  bnnaConnectedBalance
  
}) => {
    const [messages, setMessages] = useState([
        {
          id: 1,
          type: 'bot',
          content: "Hey, I'm ApeBuddy! 🦍 I can help you trade $BNNA and answer your questions. Try:\n• Trading: Buy/Sell $BNNA [amount]\n• Price/Chart analysis\n• Market sentiment\n• Learn trading basics\n• Type 'help' for more"
        }
      ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [priceData, setPriceData] = useState(null);
  const [socialData, setSocialData] = useState(null);
  const scrollRef = useRef(null);
  const [colorMenuAnchor, setColorMenuAnchor] = useState(null);
  const [modelMenuAnchor, setModelMenuAnchor] = useState(null);
  const [selectedModel, setSelectedModel] = useState('gpt-4');
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [voiceEnabled, setVoiceEnabled] = useState(false);
  const recognitionRef = useRef(null);

  useEffect(() => {
    // Initialize Web Speech API
    if ('webkitSpeechRecognition' in window) {
      const SpeechRecognition = window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
  
      // In the onresult handler, update the cleanup logic:
recognitionRef.current.onresult = (event) => {
  const lastResult = event.results[event.results.length - 1];
  
  if (lastResult.isFinal) {
    const transcript = lastResult[0].transcript
      .toLowerCase()
      .trim()
      .replace(/\s+/g, ' '); // Normalize spaces

    console.log('Original voice command:', transcript);
    
    // Only process if we have a clean command
    if (transcript) {
      // Clean up common voice recognition issues - simplified and ordered
      let cleanCommand = transcript
        // First convert buy variations to purchase
        .replace(/\b(buy|bye|by)\b/g, 'purchase')
        // Then handle token name variations
        .replace(/\b(purchase|sell|price)\s*(banana|b\s*n\s*n\s*a)\b/g, '$1 bnna')
        // Clean up spacing around core commands
        .replace(/\s+/g, ' ')
        .trim();
      
      console.log('Cleaned command:', cleanCommand);
      
      // Check for core commands with word boundaries
      if (cleanCommand.match(/\b(purchase|sell|price|help|chart|sentiment|trending|learn|balance)\b/)) {
        handleVoiceCommand(cleanCommand);
        setInputMessage('');
      }
    }
  }
};
  
      recognitionRef.current.onerror = (error) => {
        console.error('Speech recognition error:', error);
        setIsListening(false);
      };
    }
  }, []);
  


  

  
  const FAQ_RESPONSES = {
    'what is bnna': "BNNA is a meme token on Solana that combines DeFi trading with gamification elements.",
    'how to buy': "To buy BNNA, simply type 'Buy $BNNA' followed by the amount in SOL you want to spend.",
    'how to sell': "To sell BNNA, type 'Sell $BNNA' followed by the amount you want to sell.",
    'help': "Available commands:\n• Buy $BNNA [amount]\n• Sell $BNNA [amount]\n• Price $BNNA\n• Balance\n• Help",
  };
  // Enhanced FAQ and educational content
  const RESPONSES = {
    FAQ: {
      'what is bnna': "BNNA is a meme token on Solana that combines DeFi trading with gamification elements.",
      'how to buy': "To buy BNNA, simply type 'Buy $BNNA' followed by the amount in SOL you want to spend.",
      'how to sell': "To sell BNNA, type 'Sell $BNNA' followed by the amount you want to sell.",
      'help': "Available commands:\n• Trading:\n  - Buy $BNNA [amount]\n  - Sell $BNNA [amount]\n• Market Info:\n  - Price $BNNA\n  - Chart $BNNA\n  - Sentiment\n - Trending\n Education:\n  - Learn trading\n  - Learn [topic]\n• Utilities:\n  - Balance\n  - Help",
    },
    EDUCATION: {
      'trading': "Here's a quick guide to trading basics:\n1. Always DYOR (Do Your Own Research)\n2. Never invest more than you can afford to lose\n3. Use stop-losses to protect your investment\n4. Monitor market sentiment\n5. Keep track of your trades\n\nType 'learn [topic]' to dive deeper into specific subjects.",
      'risk': "Risk Management in Trading:\n• Set stop-loss orders\n• Diversify your portfolio\n• Only use funds you can afford to lose\n• Monitor market conditions\n• Keep emotion out of trading",
      'analysis': "Types of Analysis:\n• Technical Analysis: Chart patterns, indicators\n• Fundamental Analysis: Project value, team, roadmap\n• Sentiment Analysis: Social media, community engagement\n• Volume Analysis: Trading volume, liquidity",
      'terms': `Common Trading Terms:
- Market Cap: Total value of all tokens in circulation
- Liquidity: Amount of tokens available for trading
- Slippage: Price difference due to trade execution
- APY: Annual Percentage Yield
- DEX: Decentralized Exchange
- AMM: Automated Market Maker

Type 'learn [term]' for more details on any term`,

    'marketcap': `Market Capitalization (Market Cap) 📊
- What: Total value of all tokens in circulation
- Calculation: Token Price × Circulating Supply
- Usage: Helps compare relative size of tokens
- Warning: Can be misleading if supply isn't verified

Market Cap Categories:
- Small Cap: < $100M
- Mid Cap: $100M - $1B
- Large Cap: > $1B`,

    'liquidity': `Liquidity in Trading 💧
- Definition: Ease of buying/selling without price impact
- Importance: Higher liquidity = less price impact
- Risk: Low liquidity means higher risk

Tips:
- Check liquidity before trading
- Watch for liquidity removals
- Use limit orders in low liquidity`,

    'strategy': `Trading Strategies 📈
1. Position Trading
   • Long-term holding
   • Based on strong fundamentals

2. Swing Trading
   • Medium-term positions
   • Takes advantage of price swings

3. Day Trading
   • Short-term trades
   • Requires active monitoring

4. Scalping
   • Very short-term trades
   • Focuses on small profits

Type 'learn [strategy type]' for details`,

    'position': `Position Trading Strategy 📊
- Timeframe: Weeks to months
- Analysis: Fundamental + Technical
- Best for: Patient traders
- Risk: Lower than day trading

Key Points:
- Research thoroughly
- Monitor key developments
- Set wider stop losses
- Have clear exit strategy`,

    'swing': `Swing Trading Strategy 🔄
- Timeframe: Days to weeks
- Analysis: Technical + Momentum
- Best for: Part-time traders
- Risk: Moderate

Key Points:
- Look for range-bound markets
- Use technical indicators
- Set clear entry/exit points
- Monitor market sentiment`,

    'indicators': `Technical Indicators 📉
Common Indicators:
- RSI (Relative Strength Index)
- MACD (Moving Average Convergence Divergence)
- Moving Averages
- Volume

Type 'learn [indicator]' for details`,

    'rsi': `RSI (Relative Strength Index) 📊
- Purpose: Measures momentum
- Range: 0 to 100
- Overbought: Above 70
- Oversold: Below 30

Usage Tips:
- Combine with other indicators
- Watch for divergences
- Don't rely on it alone`,

    'risk-reward': `Risk/Reward Ratio 📊
- What: Potential profit vs potential loss
- Good ratio: 2:1 or higher
- Example: $200 profit target vs $100 stop loss

Tips:
- Always calculate before trading
- Adjust position size accordingly
- Consider market volatility`,

    'psychology': `Trading Psychology 🧠
Key Aspects:
- Emotional control
- Discipline
- Patience
- Risk acceptance

Common Pitfalls:
- FOMO (Fear of Missing Out)
- Revenge trading
- Overconfidence
- Analysis paralysis

Tips:
- Keep a trading journal
- Follow your plan
- Accept losses
- Take regular breaks`,
  
    },
    TERMS: {
      'apy': "Annual Percentage Yield: Total returns over one year, including compound interest.",
      'dex': "Decentralized Exchange: Platform for trading without intermediaries.",
      'amm': "Automated Market Maker: Smart contract that creates liquidity pools.",
      'slippage': "Price difference between expected and executed trade price.",
      'fomo': "Fear Of Missing Out: Emotional trading based on market excitement.",
      'dyor': "Do Your Own Research: Important principle of investigating before investing.",
      'ta': "Technical Analysis: Study of price patterns and indicators.",
      'fa': "Fundamental Analysis: Study of project value and fundamentals.",
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [messages]);

// Change this line in your state declarations
const [theme, setTheme] = useState('default'); // Ensure it's initialized with 'default'
// Add this theme toggle function
  const toggleTheme = () => {
    setTheme(prev => prev === 'default' ? 'alternate' : 'default');
  };
  // Fetch real-time price data
  const fetchPriceData = async (mintAddress) => {
    try {
      const response = await fetch(`/api/dexscreener/${mintAddress}`);
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching price data:', error);
      return null;
    }
  };

  // Format price message
  const formatPriceMessage = (data) => {
    if (!data || !data.pairs) return "Unable to fetch price data at this time.";
    
    const pair = data.pairs;
    return `$BNNA Price Data 📊\n` +
           `Price: $${pair.priceUsd}\n` +
           `24h Change: ${pair.priceChange.h24}%\n` +
           `24h Volume: $${pair.volume.h24.toLocaleString()}\n` +
           `Liquidity: $${pair.liquidity.toLocaleString()}\n` +
           `Market Cap: $${pair.fdv.toLocaleString()}`;
  };

  // Get social sentiment
  const fetchSocialSentiment = async () => {
    try {
      // Use your latest trades endpoint to gauge sentiment
      const response = await fetch('/api/latest-trades');
      const data = await response.json();
      return data.trade;
    } catch (error) {
      console.error('Error fetching social data:', error);
      return null;
    }
  };

  // Format sentiment message
  const formatSentimentMessage = (data) => {
    if (!data) return "Unable to fetch sentiment data at this time.";

    const sentiment = data.isBuy ? 'Bullish 📈' : 'Bearish 📉';
    return `Market Sentiment:\n` +
           `Current Trend: ${sentiment}\n` +
           `Latest Trade: ${data.solAmount.toFixed(3)} SOL\n` +
           `Type: ${data.isBuy ? 'Buy' : 'Sell'}\n` +
           `Time: ${new Date(data.timestamp).toLocaleString()}`;
  };

  const addMessage = (content, type = 'bot') => {
    setMessages(prev => [...prev, {
      id: Date.now(),
      type,
      content
    }]);
  };
  const startListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  const speakResponse = async (text) => {
    if (!voiceEnabled || !text) return;
  
    try {
      setIsSpeaking(true);
      // Try native Web Speech API first (immediate response)
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.onend = () => setIsSpeaking(false);
      window.speechSynthesis.speak(utterance);
    } catch (error) {
      console.error('Speech synthesis error:', error);
      setIsSpeaking(false);
    }
  };

  const handleVoiceCommand = async (command) => {
    console.log('Voice command received:', command);
    const lowerCommand = command.toLowerCase();
    console.log('Lowercase command:', lowerCommand);
    console.log('BNNA Balance:', bnnaBalance);
    console.log('Connected Wallet Balance:', connectedWalletBalance);
    console.log('BNNA Connected Balance:', bnnaConnectedBalance);

    if (bnnaBalance || bnnaConnectedBalance < 1000000) {
      const response = '⚠️ You need at least 1,000,000 BNNA tokens to use trading commands. Please acquire more BNNA tokens to access this feature.';
      addMessage(response, 'bot');
      return response;
    }
  
    try {
      if (lowerCommand.includes('purchase')) {
        console.log('Buy command detected');
        setIsProcessing(true);
        const amount = parseFloat(lowerCommand.split(' ').pop());
        
        console.log('Extracted amount:', amount);
        
        if (!isNaN(amount)) {
          console.log('Valid amount detected, calling handleBuy');
          const mintAddress = 'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump'.trim();
          await handleBuy(mintAddress, amount, 15, 0.0001);
          const response = `Successfully bought BNNA for ${amount} SOL! 🎉`;
          addMessage(response, 'bot');
          speakResponse(response);
        } else {
          const response = "Please specify the amount to buy.";
          addMessage(response, 'bot');
          speakResponse(response);
        }
      } else if (lowerCommand.includes('sell') && lowerCommand.includes('bnna')) {
        setIsProcessing(true);
        const amount = parseFloat(lowerCommand.split(' ').pop());
        if (!isNaN(amount)) {
          await handleSell('DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump', amount, 'raydium');
          const response = `Successfully sold ${amount} BNNA! 💰`;
          addMessage(response, 'bot');
          speakResponse(response);
        } else {
          const response = "Please specify the amount to sell.";
          addMessage(response, 'bot');
          speakResponse(response);
        }
      } else if (lowerCommand.includes('price')) {
        console.log('Price command detected');
        setIsProcessing(true);
        const response = "Fetching price information...";
        addMessage(response, 'bot');
        speakResponse(response);
        
        const data = await fetchPriceData('DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump');
        const priceResponse = formatPriceMessage(data);
        addMessage(priceResponse, 'bot');
        speakResponse(priceResponse);
      } else if (lowerCommand.includes('balance')) {
        setIsProcessing(true);
        const response = `Current Balances:\nSOL: ${connectedWalletBalance?.toFixed(4) || 0}\nBNNA: ${bnnaBalance?.toLocaleString() || 0}`;
        addMessage(response, 'bot');
        speakResponse(response);
      } else {
        const response = "I didn't understand that command. Try saying 'purchase BNNA [amount]', 'sell BNNA [amount]', or 'price'.";
        addMessage(response, 'bot');
        speakResponse(response);
      }
    } catch (error) {
      console.error('Command error:', error);
      const errorResponse = `Failed to execute command: ${error.message}`;
      addMessage(errorResponse, 'bot');
      speakResponse(errorResponse);
    } finally {
      setIsProcessing(false);
    }
  };
  const handleCommand = async (command) => {
    console.log('BNNA Balance:', bnnaBalance);
    console.log('Connected Wallet Balance:', connectedWalletBalance);
    console.log('BNNA Connected Balance:', bnnaConnectedBalance);

    if (bnnaBalance || bnnaConnectedBalance < 1000000) {
      const response = '⚠️ You need at least 1,000,000 BNNA tokens to use trading commands. Please acquire more BNNA tokens to access this feature.';
      addMessage(response, 'bot');
      return response;
    }
    const lowerCommand = command.toLowerCase();
    const words = lowerCommand.split(' ');
    let commandHandled = false;
    let response = '';

    // Help command
    if (lowerCommand === 'help') {
      response = RESPONSES.FAQ['help'];
      addMessage(response, 'bot');
      return response;
    }

    // Educational content
    if (lowerCommand.startsWith('learn')) {
      const topic = words.slice(1).join(' '); // Get full topic name
      
      // Check education content first
      if (RESPONSES.EDUCATION[topic]) {
        response = RESPONSES.EDUCATION[topic];
        addMessage(response, 'bot');
        return response;
      }
      
      // Check terms dictionary
      if (RESPONSES.TERMS[topic]) {
        response = RESPONSES.TERMS[topic];
        addMessage(response, 'bot');
        return response;
      }
      
      // If no specific topic found, show available topics
      response = "Available topics:\n" +
        "• Basic: trading, terms, strategy\n" +
        "• Analysis: indicators, ta, fa\n" +
        "• Psychology: psychology, risk-reward\n" +
        "• Strategies: position, swing\n" +
        "• Tools: rsi, marketcap, liquidity\n\n" +
        "Type 'learn [topic]' to learn more about any topic.";
      addMessage(response, 'bot');
      return response;
    }

    if (lowerCommand.startsWith('what is') || lowerCommand.startsWith('define')) {
      const term = words.slice(lowerCommand.startsWith('what is') ? 2 : 1).join('').toLowerCase();
      if (RESPONSES.TERMS[term]) {
        response = RESPONSES.TERMS[term];
        addMessage(response, 'bot');
        return response;
      }
    }

    // Price check
    else if (lowerCommand.includes('price') && lowerCommand.includes('bnna')) {
      setIsProcessing(true);
      const priceData = await fetchPriceData('DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump');
      setIsProcessing(false);
      response = formatPriceMessage(priceData);
      addMessage(response, 'bot');
      return response;
    }

    // Sentiment check
    if (lowerCommand.includes('sentiment')) {
      setIsProcessing(true);
      const sentimentData = await fetchSocialSentiment();
      setIsProcessing(false);
      response = formatSentimentMessage(sentimentData);
      addMessage(response, 'bot');
      return response;
      commandHandled = true;
    }

    if (lowerCommand === 'trending') {
      setIsProcessing(true);
      const trendingData = await fetchTrendingTokens();
      setIsProcessing(false);
      response = formatTrendingMessage(trendingData);
      addMessage(response, 'bot');
      return response;
    }
    
    if (lowerCommand.startsWith('trending details')) {
      const mintAddress = words[words.length - 1];
      if (!mintAddress || mintAddress === 'details') {
        response = "Please provide a token mint address (e.g., 'trending details [mint]')";
        addMessage(response, 'bot');
        return response;
      }
      
      setIsProcessing(true);
      const tokenDetails = await fetchTokenDetails(mintAddress);
      setIsProcessing(false);
      response = formatTokenDetailsMessage(tokenDetails);
      addMessage(response, 'bot');
      return response;
    }

    // Trading commands
    if (lowerCommand.startsWith('buy') && lowerCommand.includes('bnna')) {
      const amount = parseFloat(words[words.length - 1]);
      if (isNaN(amount)) {
        response = "Please specify an amount to buy (e.g., 'Buy $BNNA 0.1')";
        addMessage(response, 'bot');
        return response;
      }
      
      try {
        setIsProcessing(true);
        await handleBuy('DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump', amount);
        response = `Successfully bought BNNA for ${amount} SOL! 🎉`;
        addMessage(response, 'bot');
        return response;
      } catch (error) {
        response = `Failed to buy: ${error.message}`;
        addMessage(response, 'bot');
        return response;
      } finally {
        setIsProcessing(false);
      }
    }
    
    else if (lowerCommand.startsWith('sell') && lowerCommand.includes('bnna')) {
      const amount = parseFloat(words[words.length - 1]);
      if (isNaN(amount)) {
        response = "Please specify an amount to sell (e.g., 'Sell $BNNA 1000')";
        addMessage(response, 'bot');
        return response;
      }
      
      try {
        setIsProcessing(true);
        await handleSell('DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump', amount, 'raydium');
        response = `Successfully sold ${amount} BNNA! 💰`;
        addMessage(response, 'bot');
        return response;
      } catch (error) {
        response = `Failed to sell: ${error.message}`;
        addMessage(response, 'bot');
        return response;
      } finally {
        setIsProcessing(false);
      }
    }

    else if (lowerCommand === 'balance') {
      response = `Current Balances:\nSOL: ${connectedWalletBalance?.toFixed(4) || 0}\nBNNA: ${bnnaBalance?.toLocaleString() || 0}`;
      addMessage(response, 'bot');
      return response;
    }

    // FAQ responses
    for (const [key, value] of Object.entries(RESPONSES.FAQ)) {
      if (lowerCommand.includes(key)) {
        response = value;
        addMessage(response, 'bot');
        return response;
      }
    }
    
    // Default response if no command matched
    response = "I didn't understand that command. Type 'help' to see available commands.";
    addMessage(response, 'bot');
    return response;
};

  const handleSendMessage = async () => {
    if (!inputMessage.trim() || isProcessing) return;
    
    addMessage(inputMessage, 'user');
    setInputMessage('');
    
    await handleCommand(inputMessage);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const fetchTrendingTokens = async () => {
    try {
      console.log('Fetching trending tokens from DexScreener...');
      const response = await fetch('https://api.dexscreener.com/token-boosts/top/v1');
      const tokens = await response.json();
      
      // Filter for Solana tokens and get first 5
      const solanaTokens = tokens
        .filter(token => token.chainId === 'solana')
        .slice(0, 5);
      
      console.log(`Found ${solanaTokens.length} Solana trending tokens`);
  
      // Get additional price data for each token
      const enrichedTokens = await Promise.all(
        solanaTokens.map(async (token) => {
          try {
            const dexData = await fetch(`/api/dexscreener/${token.tokenAddress}`);
            const dexInfo = await dexData.json();
            return {
              ...token,
              marketData: dexInfo.data
            };
          } catch (error) {
            console.error(`Error fetching data for ${token.tokenAddress}:`, error);
            return token;
          }
        })
      );
  
      return enrichedTokens;
    } catch (error) {
      console.error('Error fetching trending tokens:', error);
      return null;
    }
  };
  
  const formatTrendingMessage = (tokens) => {
    if (!tokens || tokens.length === 0) {
      return "Unable to fetch trending tokens at this time.";
    }
  
    let message = "🔥 Trending Tokens on Solana:\n\n";
    tokens.forEach((token, index) => {
      // Use token description or URL as fallback name
      const name = token.description || token.url?.split('/').pop() || 'Unknown Token';
      message += `${index + 1}. ${name}\n`;
      
      // Add price info if available
      if (token.marketData?.pairs) {
        const pair = token.marketData.pairs;
        message += `   💰 $${pair.priceUsd || 'N/A'}\n`;
        if (pair.priceChange?.h24) {
          const change = parseFloat(pair.priceChange.h24);
          message += `   ${change >= 0 ? '📈' : '📉'} ${change}%\n`;
        }
        if (pair.volume?.h24) {
          message += `   📊 Vol: $${Math.round(pair.volume.h24).toLocaleString()}\n`;
        }
      }
      
      // Add social links if available
      if (token.links && token.links.length > 0) {
        const socials = token.links.map(link => {
          if (link.type === 'twitter') return '𝕏';
          if (link.type === 'telegram') return '📱';
          if (link.label === 'Website') return '🌐';
          return null;
        }).filter(Boolean);
        
        if (socials.length > 0) {
          message += `   ${socials.join(' ')}\n`;
        }
      }
      
      message += `   🔑 ${token.tokenAddress.slice(0, 8)}...\n\n`;
    });
  
    message += "\nFor token details, use 'trending details [mint]'\n";
    message += "Data provided by DexScreener";
    return message;
  };
  
  const fetchTokenDetails = async (mintAddress) => {
    try {
      console.log(`Fetching details for token: ${mintAddress}`);
      const [dexData, boostData] = await Promise.all([
        fetch(`/api/dexscreener/${mintAddress}`).then(r => r.json()),
        fetch('https://api.dexscreener.com/token-boosts/top/v1')
          .then(r => r.json())
          .then(tokens => tokens.find(t => t.tokenAddress.toLowerCase() === mintAddress.toLowerCase()))
      ]);
  
      return {
        marketData: dexData.data,
        tokenInfo: boostData
      };
    } catch (error) {
      console.error('Error fetching token details:', error);
      return null;
    }
  };
  
  const formatTokenDetailsMessage = (details) => {
    if (!details || (!details.tokenInfo && !details.marketData)) {
      return "Unable to fetch token details at this time.";
    }
  
    const { tokenInfo, marketData } = details;
    let message = `📊 Token Details:\n\n`;
    
    // Basic info
    message += `Token: ${tokenInfo?.description || 'Unknown'}\n`;
    message += `Chain: Solana\n`;
    
    // Market data
    if (marketData?.pairs) {
      const pair = marketData.pairs;
      message += `\nMarket Data:\n`;
      message += `💰 Price: $${pair.priceUsd || 'N/A'}\n`;
      if (pair.priceChange?.h24) {
        const change = parseFloat(pair.priceChange.h24);
        message += `${change >= 0 ? '📈' : '📉'} 24h Change: ${change}%\n`;
      }
      message += `📊 24h Volume: $${pair.volume?.h24?.toLocaleString() || 'N/A'}\n`;
      if (pair.liquidity) {
        message += `💧 Liquidity: $${pair.liquidity.toLocaleString()}\n`;
      }
      if (pair.fdv) {
        message += `🎯 FDV: $${pair.fdv.toLocaleString()}\n`;
      }
    }
  
    // Social links
    if (tokenInfo?.links && tokenInfo.links.length > 0) {
      message += `\nLinks:\n`;
      tokenInfo.links.forEach(link => {
        if (link.type === 'twitter') message += `𝕏 ${link.url}\n`;
        else if (link.type === 'telegram') message += `📱 ${link.url}\n`;
        else if (link.label === 'Website') message += `🌐 ${link.url}\n`;
      });
    }
  
    message += `\n🔑 Contract: ${tokenInfo?.tokenAddress || 'N/A'}`;
    
    return message;
  };
  

  return (
    <ChatCard chatTheme={theme}>
      <ChatHeader 
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <BotIcon />
              <Typography variant="h6">ApeBuddy Chat</Typography>
            </Box>
            <FormControl 
                size="small"
                sx={{
                  minWidth: 120,
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    '& fieldset': {
                      borderColor: `${THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary}33`,
                    },
                    '&:hover fieldset': {
                      borderColor: `${THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary}66`,
                    },
                  }
                }}
              >
                <Select
                  value={selectedModel}
                  onChange={(e) => setSelectedModel(e.target.value)}
                  displayEmpty
                  
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    '& .MuiSelect-icon': { color: THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary }
                  }}
                >
                  <MenuItem value="gpt-4">GPT-4</MenuItem>
                  <MenuItem value="gpt-3.5">GPT-3.5</MenuItem>
                  <MenuItem value="claude-3">Claude 3</MenuItem>
                </Select>
              </FormControl>
            <IconButton 
              onClick={(e) => setColorMenuAnchor(e.currentTarget)}
              sx={{ 
                color: THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary,
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <Palette />
            </IconButton>
            <Menu
              anchorEl={colorMenuAnchor}
              open={Boolean(colorMenuAnchor)}
              onClose={() => setColorMenuAnchor(null)}
              PaperProps={{
                sx: {
                  background: THEME_OPTIONS[theme]?.gradient || THEME_OPTIONS.default.gradient,
                  border: `1px solid ${THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary}33`,
                  borderRadius: '12px',
                  mt: 1,
                }
              }}
            >
              {Object.entries(THEME_OPTIONS).map(([key, value]) => (
                <MenuItem
                  key={key}
                  onClick={() => {
                    setTheme(key);
                    setColorMenuAnchor(null);
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: 'white',
                    '&:hover': {
                      background: `${value.primary}33`
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      background: value.messageUser,
                      border: '2px solid white',
                    }}
                  />
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
      />
      
      <CardContent sx={{ p: 0 }}>
        <MessagesContainer ref={scrollRef}>
          {messages.map((message) => (
            <Box
              key={message.id}
              sx={{
                display: 'flex',
                justifyContent: message.type === 'user' ? 'flex-end' : 'flex-start',
                mb: 2,
                gap: 1,
              }}
            >
              {message.type === 'bot' && (
                <BotIcon sx={{ 
                  color: THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary 
                }} />
              )}
              
              <MessageBubble type={message.type} chatTheme={theme}>
                <Typography 
                  component="pre"
                  sx={{ 
                    fontFamily: 'inherit',
                    m: 0,
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {message.content}
                </Typography>
              </MessageBubble>
              
              {message.type === 'user' && (
                <UserIcon sx={{ 
                  color: THEME_OPTIONS[theme]?.secondary || THEME_OPTIONS.default.secondary 
                }} />
              )}
            </Box>
          ))}
        </MessagesContainer>
  
        <InputContainer>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a command or question..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            disabled={isProcessing}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'white',
                background: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '12px',
                transition: 'all 0.2s ease-in-out',
                '& fieldset': {
                  borderColor: `${THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary}33`,
                  transition: 'all 0.2s ease-in-out',
                },
                '&:hover fieldset': {
                  borderColor: `${THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary}66`,
                },
                '&.Mui-focused fieldset': {
                  borderColor: THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary,
                  boxShadow: `0 0 0 2px ${THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary}33`,
                },
              },
              '& .MuiInputBase-input': {
                '&::placeholder': {
                  color: 'rgba(255, 255, 255, 0.5)',
                },
              },
            }}
          />
          <IconButton 
            onClick={handleSendMessage}
            disabled={!inputMessage.trim() || isProcessing}
            sx={{
              color: THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary,
              '&:disabled': {
                color: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          >
            {isProcessing ? (
              <CircularProgress size={24} sx={{ 
                color: THEME_OPTIONS[theme]?.primary || THEME_OPTIONS.default.primary 
              }} />
            ) : (
              <SendIcon />
            )}
          </IconButton>
        </InputContainer>
      </CardContent>
  
      {!tradingWallet && (
        <Box sx={{ p: 2, bgcolor: 'rgba(220, 38, 38, 0.1)', borderTop: '1px solid rgba(220, 38, 38, 0.2)' }}>
          <Typography color="error">
            Please connect your trading wallet to execute trades.
          </Typography>
        </Box>
      )}
      <VoiceControl
  onStartListening={startListening}
  onStopListening={stopListening}
  isListening={isListening}
  isSpeaking={isSpeaking}
  voiceEnabled={voiceEnabled}
  onToggleVoice={() => setVoiceEnabled((prev) => !prev)}
/>

    </ChatCard>
    
  );
};

const keyframes = `
  @keyframes pulseGlow {
    0% { box-shadow: 0 0 0 0 rgba(251, 191, 36, 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(251, 191, 36, 0); }
    100% { box-shadow: 0 0 0 0 rgba(251, 191, 36, 0); }
  }
`;
export default ApeBuddyChat;