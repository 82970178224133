import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';

const AppTour = () => {
  const [run, setRun] = useState(false);

  useEffect(() => {
    // Start tour after a short delay to ensure elements are loaded
    const timer = setTimeout(() => setRun(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const steps = [
    {
      target: '[data-tour="wallet-connect"]',
      content: 'Connect your wallet here to get started! 🦊',
      placement: 'bottom',
      disableBeacon: true
    },
    {
      target: '[data-tour="how-it-works"]',
      content: 'Watch our tutorial to learn how everything works! 🎥',
      placement: 'bottom'
    },
    {
      target: '[data-tour="enhanced-container"]',
      content: 'This is your main dashboard where you can manage your tokens and see latest trades! 📊',
      placement: 'bottom'
    }
  ];

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      // Optionally save to localStorage to prevent showing again
      localStorage.setItem('tourCompleted', 'true');
    }
  };

  // Check if tour has been completed
//   if (localStorage.getItem('tourCompleted')) {
//     return null;
//   }

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous={true}
      scrollToFirstStep={true}
      showSkipButton={true}
      showProgress={true}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          primaryColor: '#fbbf24',
          backgroundColor: '#1f2937',
          textColor: '#ffffff',
          zIndex: 10000,
        },
        tooltip: {
          backgroundColor: '#1f2937',
          borderRadius: '8px',
          fontSize: '14px',
          padding: '16px',
        },
        tooltipContainer: {
          textAlign: 'center',
        },
        tooltipTitle: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#fbbf24',
        },
        buttonNext: {
          backgroundColor: '#fbbf24',
          color: '#1f2937',
          '&:hover': {
            backgroundColor: '#d97706',
          },
        },
        buttonBack: {
          color: '#fbbf24',
          marginRight: 10,
        },
        buttonSkip: {
          color: '#6b7280',
        },
      }}
    />
  );
};

export default AppTour;