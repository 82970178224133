import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton } from '@mui/material';

const NewPairsTable = () => {
  const [newPairs, setNewPairs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchNewPairs = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/new-pairs`);
      const data = await response.json();

      if (!response.ok) throw new Error(data.error || 'Failed to fetch new pairs');
      setNewPairs(data.pairs || []);
    } catch (error) {
      console.error('Error fetching new pairs:', error);
      setError(error.message);
      setNewPairs([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNewPairs();
    const interval = setInterval(fetchNewPairs, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ mb: 4 }}>
      <Typography 
        variant="h4" 
        sx={{ color: '#FEFFEB', fontWeight: 'bold', mb: 2 }}
      >
        New Pairs
      </Typography>
      <Paper
        elevation={3}
        sx={{
          height: '500px',
          overflow: 'auto',
          background: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3))',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: 2,
        }}
      >
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: 'rgba(0, 0, 0, 0.5)', color: 'white', fontWeight: 'bold' }}>Pair Info</TableCell>
                <TableCell sx={{ background: 'rgba(0, 0, 0, 0.5)', color: 'white', fontWeight: 'bold' }} align="right">TXNs</TableCell>
                <TableCell sx={{ background: 'rgba(0, 0, 0, 0.5)', color: 'white', fontWeight: 'bold' }} align="right">Volume</TableCell>
                <TableCell sx={{ background: 'rgba(0, 0, 0, 0.5)', color: 'white', fontWeight: 'bold' }} align="center">Audit Results</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }} />
                    </TableCell>
                  </TableRow>
                ))
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box sx={{ p: 2, textAlign: 'center', color: 'rgba(255,255,255,0.7)' }}>
                      {error}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                newPairs.map((pair) => (
                  <TableRow key={pair.address}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <img
                          src={pair.icon || '/api/placeholder/24/24'}
                          alt={pair.symbol}
                          style={{ width: 24, height: 24, borderRadius: '50%' }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/api/placeholder/24/24';
                          }}
                        />
                        <Box>
                          {pair.symbol}
                          <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.5)', display: 'block' }}>
                            {pair.dex}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">{pair.txns}</TableCell>
                    <TableCell align="right">${pair.volume.toLocaleString()}</TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" sx={{ color: pair.audit ? '#4caf50' : '#f44336' }}>
                        {pair.audit ? 'Passed' : 'Failed'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default NewPairsTable;
