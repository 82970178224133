import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackHomeButton from '../../components/reusables/BackHomeButton';

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(4),
  background: 'linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.7))',
  color: '#FEFFEB',
}));

const TermsOfUse = () => {
  return (
    <StyledContainer>
      <BackHomeButton />
      <Typography variant="h3" 
        sx={{ 
          textAlign: 'center', 
          mb: 4,
          background: 'linear-gradient(45deg, #C59E57, #FFD700)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Terms of Use
      </Typography>
      <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
        <Typography paragraph>
          Welcome to BNNA. By accessing or using our platform, you agree to these terms.
        </Typography>
        {/* Add your terms content here */}
        <Typography paragraph>
          Welcome to ApeOut! By accessing or using our platform, you agree to the following terms and conditions.
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          General Use
        </Typography>
        <Typography paragraph>
          ApeOut is an automated platform for exploring and trading newly minted memecoins. By using the platform, you acknowledge and accept the risks associated with trading in volatile markets.
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          User Responsibilities
        </Typography>
        <Typography paragraph>
          Users are responsible for the security of their accounts and connected wallets. ApeOut will never store or ask for private keys. Use at your own risk.
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Fees and Services
        </Typography>
        <Typography paragraph>
          Our platform charges fees for automated trades and premium features. Fee structures are disclosed during the use of respective services. Ensure you understand the costs before proceeding.
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Liability
        </Typography>
        <Typography paragraph>
          ApeOut is not responsible for losses incurred due to market volatility, platform downtime, or misuse of the platform. We provide tools to assist users but do not guarantee profits or outcomes.
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Compliance
        </Typography>
        <Typography paragraph>
          Users must ensure they comply with all applicable laws in their jurisdiction. ApeOut does not support illegal activities or unauthorized financial transactions.
        </Typography>
        <Typography paragraph>
          By using ApeOut, you acknowledge and accept these Terms of Use. For additional details, visit our website or contact support.
        </Typography>
      </Box>
    </StyledContainer>
  );
};

export default TermsOfUse;