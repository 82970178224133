// src/components/GatedContent.js
import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlayCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import VideoModal from '../components/modals/VideoModal';
import { toast } from 'react-toastify';
import wojakVideo from '../assets/wojakbuysbanana.mp4';
import dexPaidVideo from '../assets/dexpaid.mp4';
import aiVideo from '../assets/ai-apeout.mp4';
import { useWallet } from '@solana/wallet-adapter-react';
import santaImg from '../assets/bananabill/christmas.webp'
import placeholderImg from '../assets/bananabill/logo.png'
import { Home } from '@mui/icons-material';

const ContentCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const episodes = [
  {
    id: 1,
    title: 'Banana Bill Meets Santa Clause 🎅🍌',
    description: 'One snowy December night, Banana Bill hears a clatter in the jungle. It’s not apes partying—it’s Santa Clause, delivering gifts to loyal $BNNA holders! 🎁“Ho ho ho! Who’s been a diamond-handed ape this year?” Santa asks. Bill steps forward, proudly showing his wallet filled with hodled $BNNA. Santa rewards him with a golden banana and whispers, “The Blockchain Jungle holds big surprises next year. Keep holding.”Banana Bill knows it’s going to be the best December yet.',
    thumbnail: placeholderImg,
    videoSrc: 'https://youtube.com/shorts/1pHgnynm2QQ?feature=share'
  },
  {
    id: 2,
    title: 'The Blockchain Solstice 🌌🍌',
    description: 'On the longest night of the year, the jungle gathers to celebrate the Blockchain Solstice, a festival of trading and prosperity. Banana Bill leads the charge, sharing wisdom about automation tools, like Auto-TP/SL and Auto-Ape. Fruits and apes alike exchange stories of their trades, toast to burned tokens, and set their sights on new goals for the year ahead. The stars above twinkle like memecoins, reminding everyone that the moon is closer than they think.',
    thumbnail: placeholderImg,
    videoSrc: dexPaidVideo
  },
  {
    id: 3,
    title: 'The Gift of Automation 🎄🍌',
    description: 'Banana Bill spends December teaching the jungle animals about ApeOut’s automation tools. “It’s the season of giving,” he says, gifting his friends the knowledge of Auto-Bump and Auto-Ape.As they test the tools, they realize the true gift isn’t just wealth—it’s the freedom to trade smarter and focus on what matters most. Bill smiles, knowing he’s helped make the Blockchain Jungle a better place.',
    thumbnail: placeholderImg,
    videoSrc: aiVideo
  },
];
const NavContainer = styled(Box)({
    position: 'absolute',
    top: '20px',
    left: '20px',
    zIndex: 10,
  });

const GatedContent = ({ tradingWallet, isPremium }) => {
  const [selectedVideo, setSelectedVideo] = React.useState(null);
  const [bnnaBalance, setBnnaBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const [isNavigating, setIsNavigating] = useState(false);
  // Create a navigation handler
  const handleHomeNavigation = () => {
    setIsNavigating(true);
    window.location.href = '/'; // This will force a full page refresh
  };


  const fetchBnnaBalance = async () => {
    if (!publicKey) return;
    console.log('Fetching BNNA balance for:', publicKey.toString());
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/bnna-balance/${publicKey.toString()}`
      );
      const data = await response.json();
      
      if (data.success) {
        setBnnaBalance(data.balance);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching BNNA balance:', error);
      toast.error('Error checking BNNA balance');
      setIsLoading(false);
    }
  };


  useEffect(() => {
    console.log('useEffect triggered');
    console.log('publicKey:', publicKey);
    if (publicKey) {
      fetchBnnaBalance();
    }
  }, [publicKey]);

  if (isLoading) {
    return (
      <Box sx={{ 
        minHeight: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#1a1a1a',
        color: 'white'
      }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (!publicKey || bnnaBalance <= 0) {
    return (
      <Box 
        sx={{ 
          minHeight: '60vh', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          bgcolor: '#1a1a1a',
          color: 'white',
          textAlign: 'center',
          p: 4
        }}
      >
        <Typography variant="h4" gutterBottom>
          Access Restricted
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          You need to hold BNNA tokens to access this exclusive content
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => navigate('/')}
          sx={{
            background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
          }}
        >
          Get BNNA Tokens
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: '#1a1a1a', minHeight: '100vh', p: 4 }}>
        <NavContainer>
      <Button
        onClick={handleHomeNavigation}
        disabled={isNavigating}
        variant="contained"
        startIcon={<Home />}
        sx={{
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.2)',
          },
        }}
      >
        Back Home
      </Button>
    </NavContainer>
      <Typography 
        variant="h3" 
        sx={{ 
          color: 'white', 
          textAlign: 'center', 
          mb: 6,
          fontWeight: 'bold'
        }}
      >
        Exclusive Content
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', textAlign: 'center', mb: 6 }}>
        Welcome to the Banana Bill's exclusive content. Here you can watch the latest UNRELEASED videos and learn about the latest news and updates.
      </Typography>

      <Grid container spacing={4}>
        {episodes.map((episode) => (
          <Grid item xs={12} md={4} key={episode.id}>
            <ContentCard>
              <CardMedia
                component="img"
                height="200"
                image={episode.thumbnail}
                alt={episode.title}
              />
              <CardContent sx={{ color: 'white' }}>
                <Typography variant="h6" gutterBottom>
                  {episode.title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {episode.description}
                </Typography>
                <Button
                  startIcon={<PlayCircle />}
                  onClick={() => setSelectedVideo(episode.videoSrc)}
                  sx={{
                    color: 'white',
                    borderColor: 'white',
                    '&:hover': {
                      borderColor: 'primary.main',
                      color: 'primary.main',
                    }
                  }}
                >
                  Watch Now
                </Button>
              </CardContent>
            </ContentCard>
          </Grid>
        ))}
      </Grid>

      <VideoModal
        open={Boolean(selectedVideo)}
        onClose={() => setSelectedVideo(null)}
        videoSrc={selectedVideo}
      />
    </Box>
  );
};

export default GatedContent;