import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import styled from '@emotion/styled';
import icon from '../assets/gifs/chopnana.gif'
import bananaSmileIcon from '../assets/gifs/banana-smile.gif'
import bananasIcon from '../assets/gifs/bananas.gif'



const Container = styled(Box)({
  backgroundImage: 'url(https://github.com/apeoutmeme/resources/blob/32f5092cc73b7141524c2ac0abe5ea0daa56c25c/assets/staking/2024.webp?raw=true)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: '#fff',
  minHeight: '100vh',
  padding: '20px',
  fontFamily: 'Pixel, Arial, sans-serif',
});

const Header = styled(Box)({
  textAlign: 'center',
  paddingBottom: '20px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
});

const UnlockBox = styled(Box)({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  border: '1px solid #fff',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  margin: '10px',
});

const ClaimButton = styled(Button)({
  backgroundColor: '#00bcd4',
  color: '#fff',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#0097a7',
  },
});

const StyledLink = styled('a')({
    color: '#00bcd4',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#FFD700',
      textDecoration: 'underline',
    }
  });
  
  const Footer = styled(Box)({
    textAlign: 'center',
    marginTop: '20px',
    padding: '20px',
    fontSize: '12px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  });

const Claim = () => {
  return (
    <Container>
      <Header>
        <Typography variant="h4" gutterBottom>
          CLAIM BNNA
        </Typography>
        <Button variant="outlined" color="primary" style={{ color: '#fff', borderColor: '#fff' }}>
          Connect Wallet
        </Button>
        <Button target="_blank" href="https://dexscreener.com/solana/g3rhsoewmmvembdkbec6sfwu91rbmkdyeztwdnmawjxb" variant="outlined" color="primary" style={{ color: '#fff', borderColor: '#fff' }}>
          Buy $BNNA
        </Button>
      </Header>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <UnlockBox>
            <Typography variant="h6">NEXT UNLOCK IN 5 HOURS</Typography>
            <img src={bananaSmileIcon} alt="Fire Sale" style={{ width: '100px', margin: '10px auto' }} />
            <Typography>
              It’s money time! Claim your BNNA from Fire Sale and Private Sale.
            </Typography>
          </UnlockBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <UnlockBox>
            <Typography variant="h6">NEXT UNLOCK IN 9 MIN</Typography>
            <img src={bananasIcon} alt="Airdrop" style={{ width: '100px', margin: '10px auto' }} />
            <Typography>Claim BNNA for your BNNA NFTs.</Typography>
          </UnlockBox>
        </Grid>
      </Grid>

      <Footer>
  By continuing, you agree to{' '}
  <StyledLink target="_blank" href="/terms-of-use">Terms of Use</StyledLink>,{' '}
  <StyledLink target="_blank" href="/terms-fire-sale">Terms for Fire Sale</StyledLink>, and{' '}
  <StyledLink target="_blank" href="/terms-airdrop">Terms for Airdrop</StyledLink>.
</Footer>
    </Container>
  );
};

export default Claim;
