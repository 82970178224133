import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Card, CardContent, Typography, Button, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SecurityIcon from '@mui/icons-material/Security';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const StrategyDialog = ({ open, onClose, agentId, onSelectStrategy }) => {
  const strategies = [
    {
      id: 'aping',
      name: 'Aping with Big Balls',
      description: 'High risk, high reward strategy for the brave',
      icon: <RocketLaunchIcon sx={{ fontSize: 40 }} />,
      color: '#ff4444'
    },
    {
      id: 'bluechip',
      name: 'Blue Chip Guardian',
      description: 'Conservative strategy focusing on established tokens',
      icon: <SecurityIcon sx={{ fontSize: 40 }} />,
      color: '#4444ff'
    },
    {
      id: 'whale',
      name: 'Whale Tracker Elite',
      description: 'Follow smart money movements and large trades',
      icon: <WhatshotIcon sx={{ fontSize: 40 }} />,
      color: '#9c27b0'
    },
    {
      id: 'telegram',
      name: 'Telegram Alpha Hunter',
      description: 'Monitor Telegram channels for trading signals',
      icon: <TelegramIcon sx={{ fontSize: 40 }} />,
      color: '#2196f3'
    },
    {
      id: 'twitter',
      name: 'Twitter Alpha Seeker',
      description: 'Track Twitter influencers and trending topics',
      icon: <TwitterIcon sx={{ fontSize: 40 }} />,
      color: '#1da1f2'
    },
    {
      id: 'dca',
      name: 'DCA Master',
      description: 'Systematic dollar-cost averaging strategy',
      icon: <TrendingUpIcon sx={{ fontSize: 40 }} />,
      color: '#4caf50'
    }
  ];

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: 'linear-gradient(to bottom right, #1a237e, #0d47a1)',
          borderRadius: '16px'
        }
      }}
    >
      <DialogTitle sx={{ color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Choose Your Strategy
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {strategies.map((strategy) => (
            <Grid item xs={12} sm={6} key={strategy.id}>
              <Card 
                sx={{ 
                  cursor: 'pointer',
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(10px)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: `0 8px 24px ${strategy.color}40`
                  }
                }}
                onClick={() => {
                  onSelectStrategy(strategy.id);
                  onClose();
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box 
                      sx={{ 
                        color: strategy.color,
                        mr: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {strategy.icon}
                    </Box>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {strategy.name}
                    </Typography>
                  </Box>
                  <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                    {strategy.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StrategyDialog;