import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const DashboardIntro = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '16px',
        background: 'linear-gradient(135deg, rgba(255, 215, 0, 0.15), rgba(147, 51, 234, 0.15), rgba(59, 130, 246, 0.15))',
        p: 4,
        backdropFilter: 'blur(8px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.2)'
      }}
    >
      {/* Decorative elements */}
      <Box
        sx={{
          position: 'absolute',
          top: -96,
          right: -96,
          width: 192,
          height: 192,
          borderRadius: '50%',
          background: 'linear-gradient(45deg, #FFD700, #FFA500)',
          opacity: 0.3,
          filter: 'blur(64px)'
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: -96,
          left: -96,
          width: 192,
          height: 192,
          borderRadius: '50%',
          background: 'linear-gradient(45deg, #9333EA, #3B82F6)',
          opacity: 0.3,
          filter: 'blur(64px)'
        }}
      />
      
      <Container
        sx={{
          position: 'relative',
          zIndex: 1,
          maxWidth: 'lg'
        }}
      >
        <Typography
          variant="h1"
          sx={{
            mb: 3,
            fontSize: { xs: '2.5rem', md: '3.5rem' },
            fontWeight: 800,
            background: 'linear-gradient(to right, #FFD700, #FFA500)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            letterSpacing: '-0.02em'
          }}
        >
          AI Trading Agents
        </Typography>
        
        <Typography
          sx={{
            fontSize: '1.125rem',
            lineHeight: 1.7,
            color: 'rgba(255, 255, 255, 0.9)'
          }}
        >
          Welcome to <Box component="span" sx={{ fontWeight: 700, color: '#FFD700' }}>ApeOut's AI Trading Agents!</Box>{' '}
          Create or explore <Box component="span" sx={{ fontWeight: 700 }}>automated trading bots</Box> that can execute trades 24/7 based on your strategy.
          
          <Box component="span" sx={{ display: 'block', mt: 2 }}>
            Our agents can monitor <Box component="span" sx={{ fontWeight: 700, color: '#FFD700' }}>Telegram signals</Box>, {' '}
            <Box component="span" sx={{ fontWeight: 700, color: '#1DA1F2' }}>Twitter alpha</Box>, or track {' '}
            <Box component="span" sx={{ fontWeight: 700, color: '#9333EA' }}>whale wallets</Box> to find the best trading opportunities.
          </Box>
          
          <Box
            component="span"
            sx={{
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            Powered by{' '}
            <Typography
              component="span"
              sx={{
                fontFamily: 'monospace',
                fontWeight: 700,
                color: '#FFD700',
                fontSize: '1.25rem',
                px: 1,
                py: 0.5,
                bgcolor: 'rgba(255, 215, 0, 0.1)',
                borderRadius: '4px'
              }}
            >
              $BNNA
            </Typography>
            , these agents help you trade <Box component="span" sx={{ fontWeight: 700 }}>smarter</Box> and {' '}
            <Box component="span" sx={{ fontWeight: 700 }}>faster</Box> while you sleep.
            <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5, ml: 1 }}>
              <Typography
                component="span"
                role="img"
                aria-label="banana"
                sx={{ fontSize: '1.5rem' }}
              >
                🍌
              </Typography>
              <Typography
                component="span"
                role="img"
                aria-label="robot"
                sx={{ fontSize: '1.5rem' }}
              >
                🤖
              </Typography>
            </Box>
          </Box>
        </Typography>
      </Container>
    </Box>
  );
};

export default DashboardIntro;