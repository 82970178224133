import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Stack,
  Modal,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CalculateIcon from '@mui/icons-material/Calculate';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import IconButton from '@mui/material/IconButton';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, rgba(197, 158, 87, 0.1), rgba(50, 46, 41, 0.9))',
  backdropFilter: 'blur(20px)',
  border: '2px solid rgba(254, 255, 235, 0.2)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    width: '200%',
    height: '200%',
    background: 'radial-gradient(circle, rgba(254, 255, 235, 0.1) 0%, transparent 70%)',
    zIndex: 0,
    pointerEvents: 'none',
  }
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: '#FEFFEB',
    fontFamily: "'VT323', monospace",
    '& fieldset': {
      borderColor: 'rgba(197, 158, 87, 0.5)',
      borderWidth: 2,
    },
    '&:hover fieldset': {
      borderColor: '#C59E57',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FEFFEB',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(254, 255, 235, 0.7)',
    fontFamily: "'VT323', monospace",
  },
  '& .MuiInputBase-input': {
    color: '#FEFFEB',
    fontFamily: "'VT323', monospace",
  },
});

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
  
const ModalPaper = styled(Paper)(({ theme }) => ({
  width: '90%',
  maxWidth: '400px',
  maxHeight: '90vh',
  overflow: 'auto',
  padding: theme.spacing(2),
  background: 'linear-gradient(135deg, rgba(197, 158, 87, 0.1), rgba(50, 46, 41, 0.9))',
  backdropFilter: 'blur(20px)',
  border: '2px solid rgba(254, 255, 235, 0.2)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
}));

const ResultRow = ({ label, value, isPercentage = false, isDollar = false }) => (
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    py: 1,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateX(5px)',
      backgroundColor: 'rgba(66, 61, 54, 0.3)'
    }
  }}>
    <Typography 
      color="rgba(254, 255, 235, 0.7)" 
      sx={{ fontFamily: "'VT323', monospace" }}
    >
      {label}
    </Typography>
    <Typography 
      color="#C59E57" 
      fontWeight="bold"
      sx={{ fontFamily: "'VT323', monospace" }}
    >
      {isDollar && '$'}
      {typeof value === 'number' ? value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) : value}
      {isPercentage && '%'}
    </Typography>
  </Box>
);

const MetallicButton = styled(Button)(({ theme, variant }) => {
  const gradients = {
    gold: 'linear-gradient(135deg, #C59E57, #9B7940)',
    silver: 'linear-gradient(135deg, #423D36, #322E29)',
    bronze: 'linear-gradient(135deg, #423D36, #9B7940)'
  };

  const hoverGradients = {
    gold: 'linear-gradient(135deg, #FEFFEB, #C59E57)',
    silver: 'linear-gradient(135deg, #322E29, #423D36)',
    bronze: 'linear-gradient(135deg, #9B7940, #423D36)'
  };

  return {
    background: gradients[variant] || gradients.gold,
    color: '#322E29',
    fontWeight: 'bold',
    fontFamily: "'Press Start 2P', cursive",
    border: '1px solid rgba(254, 255, 235, 0.2)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    padding: '0.75rem 1rem',
    borderRadius: '12px',
    fontSize: '1rem',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    minWidth: '150px',
    maxWidth: '200px',
    flex: 1,
    '&:hover': {
      background: hoverGradients[variant] || hoverGradients.gold,
      transform: 'translateY(-3px)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    }
  };
});

const GainsCalculator = () => {
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({
    investmentUsd: '',
    entryMc: '',
    targetMc: '',
  });
  const [results, setResults] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetCalculator();
  };

  const calculateGains = async () => {
    try {
      const investment = parseFloat(inputs.investmentUsd);
      const entryMc = parseFloat(inputs.entryMc);
      const targetMc = parseFloat(inputs.targetMc);

      if (isNaN(investment) || isNaN(entryMc) || isNaN(targetMc)) {
        throw new Error('Please enter valid numbers');
      }
      if (entryMc === 0) {
        throw new Error('Entry market cap cannot be zero');
      }

      const multiplier = targetMc / entryMc;
      const potentialGainUsd = investment * multiplier;
      const profitUsd = potentialGainUsd - investment;
      const percentageGain = (multiplier - 1) * 100;

      setResults({
        investment,
        entryMc,
        targetMc,
        multiplier,
        potentialGainUsd,
        profitUsd,
        percentageGain,
      });

      // Add points for using calculator
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/update-user-points`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            walletAddress: window.solana?.publicKey?.toString(),
            taskName: 'usedPumpCalculator'
          })
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            console.log('Points awarded for using calculator');
          }
        }
      } catch (error) {
        console.error('Error awarding points:', error);
      }

    } catch (error) {
      alert(error.message);
    }
  };

  const resetCalculator = () => {
    setInputs({
      investmentUsd: '',
      entryMc: '',
      targetMc: '',
    });
    setResults(null);
  };

  const calculatorContent = (
    <Stack spacing={2} sx={{ position: 'relative', zIndex: 1 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 1,
        borderBottom: '1px dashed rgba(197, 158, 87, 0.3)',
        pb: 1
      }}>
        <Typography 
          variant="h6" 
          component="h2" 
          sx={{ 
            color: '#FEFFEB',
            fontFamily: "'Press Start 2P', cursive",
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
          }}
        >
          Gains Calculator
        </Typography>
        <IconButton 
          onClick={handleClose} 
          sx={{ 
            color: '#C59E57',
            transition: 'transform 0.2s ease',
            '&:hover': {
              transform: 'rotate(90deg)',
              color: '#FEFFEB'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <StyledTextField
        label="Investment Amount (USD)"
        name="investmentUsd"
        value={inputs.investmentUsd}
        onChange={handleInputChange}
        type="number"
        size="small"
        fullWidth
      />
      <StyledTextField
        label="Entry Market Cap (USD)"
        name="entryMc"
        value={inputs.entryMc}
        onChange={handleInputChange}
        type="number"
        size="small"
        fullWidth
      />
      <StyledTextField
        label="Target Market Cap (USD)"
        name="targetMc"
        value={inputs.targetMc}
        onChange={handleInputChange}
        type="number"
        size="small"
        fullWidth
      />

      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          onClick={calculateGains}
          startIcon={<CalculateIcon />}
          fullWidth
          size="small"
          sx={{
            background: 'linear-gradient(135deg, #C59E57, #9B7940)',
            color: '#322E29',
            fontFamily: "'Press Start 2P', cursive",
            textTransform: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: 'linear-gradient(135deg, #FEFFEB, #C59E57)',
              transform: 'translateY(-3px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            },
          }}
        >
          Calculate
        </Button>
        <Button
          variant="outlined"
          onClick={resetCalculator}
          startIcon={<RestartAltIcon />}
          fullWidth
          size="small"
          sx={{
            borderColor: 'rgba(197, 158, 87, 0.5)',
            color: '#FEFFEB',
            fontFamily: "'VT323', monospace",
            transition: 'all 0.3s ease',
            '&:hover': {
              borderColor: '#C59E57',
              background: 'rgba(197, 158, 87, 0.1)',
              transform: 'translateY(-3px)'
            },
          }}
        >
          Reset
        </Button>
      </Stack>

      {results && (
        <>
          <Divider sx={{ 
            borderColor: 'rgba(197, 158, 87, 0.3)',
            my: 2 
          }} />
          <Box>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: '#C59E57', 
                fontFamily: "'Press Start 2P', cursive",
                mb: 1
              }}
            >
              Results
            </Typography>
            <ResultRow label="Investment" value={results.investment} isDollar />
            <ResultRow label="Entry MC" value={results.entryMc} isDollar />
            <ResultRow label="Target MC" value={results.targetMc} isDollar />
            <ResultRow label="Multiplier" value={`${results.multiplier}x`} />
            <ResultRow label="Potential Value" value={results.potentialGainUsd} isDollar />
            <ResultRow label="Profit" value={results.profitUsd} isDollar />
            <ResultRow label="Percentage Gain" value={results.percentageGain} isPercentage />
          </Box>
        </>
      )}
    </Stack>
  );

  return (
    <>
    <Box 
      sx={{ 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <MetallicButton
        onClick={handleOpen}
        startIcon={<CalculateOutlinedIcon />}
        variant="gold"
        size="large"
        sx={{
          width: '50%',
          fontSize: '1rem',
          textTransform: 'none',
          borderRadius: '12px',
        }}
      >
        Gains Calculator
      </MetallicButton>
    </Box>
     
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="gains-calculator-modal"
    >
      <ModalPaper>{calculatorContent}</ModalPaper>
    </StyledModal>
    </>
  );
};

export default GainsCalculator;