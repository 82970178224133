import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';

const RegenerateWalletButton = ({ publicKey, onRegenerateSuccess }) => {
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleRegenerateWallet = async () => {
    if (!publicKey) {
      toast.error("Please connect your wallet first");
      return;
    }

    setIsRegenerating(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/regenerate-trading-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress: publicKey.toString()
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to regenerate wallet');
      }

      toast.success('Trading wallet regenerated successfully!');
      if (onRegenerateSuccess) {
        onRegenerateSuccess(data.tradingWallet);
      }
      setShowConfirmDialog(false);
    } catch (error) {
      console.error('Error regenerating wallet:', error);
      toast.error(error.message);
    } finally {
      setIsRegenerating(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setShowConfirmDialog(true)}
        className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        disabled={isRegenerating}
      >
        Regenerate Trading Wallet
      </Button>

      <Dialog 
        open={showConfirmDialog} 
        onClose={() => setShowConfirmDialog(false)}
        PaperProps={{
          className: "bg-gray-900 text-white"
        }}
      >
        <DialogTitle className="border-b border-gray-700">
          Confirm Wallet Regeneration
        </DialogTitle>
        <DialogContent className="mt-4">
          <Typography variant="body1" className="text-gray-300">
            Are you sure you want to regenerate your trading wallet? This action will:
          </Typography>
          <ul className="list-disc ml-6 mt-2 text-gray-300">
            <li>Create a new trading wallet</li>
            <li>Replace your existing trading wallet</li>
            <li>Require you to transfer any existing funds</li>
          </ul>
          <Typography variant="body2" className="mt-4 text-yellow-500">
            Make sure to withdraw any funds from your current trading wallet before proceeding.
          </Typography>
        </DialogContent>
        <DialogActions className="border-t border-gray-700 p-4">
          <Button
            onClick={() => setShowConfirmDialog(false)}
            className="text-gray-300 hover:text-white"
          >
            Cancel
          </Button>
          <Button
            onClick={handleRegenerateWallet}
            disabled={isRegenerating}
            className="bg-red-600 hover:bg-red-700 text-white"
          >
            {isRegenerating ? (
              <div className="flex items-center">
                <CircularProgress size={20} className="mr-2" />
                Regenerating...
              </div>
            ) : (
              'Confirm Regeneration'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegenerateWalletButton;