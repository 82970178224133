import React from 'react';
import { styled } from '@mui/material/styles';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Box, Container, Typography, Grid, Button, Paper, Stack, IconButton, Dialog, DialogTitle, DialogContent, ButtonGroup } from '@mui/material';


const StyledWalletButton = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '20px',
    right: '20px',
    zIndex: 1000,
    width: 'auto',
    '@media (max-width: 600px)': {
      top: '10px',
      right: '10px',
    }
  }));
const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '100%'
});

const StyledBuyButton = styled('button')({
  padding: '12px 24px',
  background: 'linear-gradient(to right, #4f46e5, #3b82f6)',
  border: 'none',
  borderRadius: '12px',
  color: 'white',
  fontWeight: 600,
  transition: 'all 0.3s ease',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
  }
});

const WalletAndBuyButtons = () => {
  return (
    <ButtonContainer>
      {/* <StyledWalletButton>
        <WalletMultiButton 
          style={{
            width: '100%',
            padding: '12px 24px',
            background: 'linear-gradient(to right, #4f46e5, #3b82f6)',
            border: 'none',
            borderRadius: '12px',
            color: 'white',
            fontWeight: 600,
            transition: 'all 0.3s ease',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        />
      </StyledWalletButton> */}
      
      <StyledBuyButton sx={{
    marginTop: '10px',
    width: '100%',
    py: 1.5,
    background: 'linear-gradient(to right, #fbbf24, #d97706)',
    '&:hover': {
      background: 'linear-gradient(to right, #d97706, #b45309)',
        }
      }}
        onClick={() => window.open('https://dexscreener.com/solana/g3rhsoewmmvembdkbec6sfwu91rbmkdyeztwdnmawjxb', '_blank')}
      >
        Buy $BNNA on Dexscreener
      </StyledBuyButton>
    </ButtonContainer>
  );
};

export default WalletAndBuyButtons;