import React, { useState, useEffect } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Box,
  Paper,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(145deg, rgba(17, 24, 39, 0.95), rgba(31, 41, 55, 0.95))',
  backdropFilter: 'blur(12px)',
  border: '1px solid rgba(251, 191, 36, 0.2)',
  borderRadius: '16px',
  maxWidth: '600px',
  margin: '0 auto',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'rgba(55, 65, 81, 0.5)',
  backdropFilter: 'blur(8px)',
  padding: theme.spacing(2),
  borderRadius: '12px',
  border: '1px solid rgba(251, 191, 36, 0.1)',
  marginBottom: theme.spacing(2)
}));

const StakingCalculator = () => {
  const [stakeAmount, setStakeAmount] = useState('');
  const [poolBalance, setPoolBalance] = useState(127000);
  const [loading, setLoading] = useState(true);
  const [apy, setApy] = useState(0);

  const TOTAL_STAKED = 91995853.653807;
  const REWARDS_PER_YEAR = 20000000;

  useEffect(() => {
    const fetchPoolData = async () => {
      try {
        const connection = new Connection('https://api.mainnet-beta.solana.com');
        const rewardsPool = new PublicKey('9yrBCWFrDV2NwnDJamzWmM4A1eQdwYtj1ka46x6JH1J');
        const balance = await connection.getTokenAccountBalance(rewardsPool);
        setPoolBalance(balance.value.uiAmount);
      } catch (error) {
        console.error('Error fetching pool data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPoolData();
  }, []);

  const calculateApy = (amount) => {
    if (!amount) return 0;
    
    const amountNum = parseFloat(amount);
    if (isNaN(amountNum) || amountNum <= 0) return 0;

    const shareOfPool = amountNum / TOTAL_STAKED;
    const yearlyRewards = shareOfPool * REWARDS_PER_YEAR;
    const calculatedApy = (yearlyRewards / amountNum) * 100;

    return calculatedApy;
  };

  const handleStakeAmountChange = (e) => {
    const value = e.target.value;
    setStakeAmount(value);
    setApy(calculateApy(value));
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" component="h1" 
          sx={{ 
            color: 'rgb(251, 191, 36)',
            fontWeight: 'bold',
            mb: 1
          }}>
          BNNA Staking Calculator
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'rgb(209, 213, 219)', mb: 3 }}>
          Calculate your potential staking rewards
        </Typography>

        <StyledPaper>
          <Typography variant="subtitle2" sx={{ color: 'rgb(156, 163, 175)', mb: 1 }}>
            Total Staked BNNA
          </Typography>
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
            {TOTAL_STAKED.toLocaleString()}
          </Typography>
        </StyledPaper>

        <StyledPaper>
          <Typography variant="subtitle2" sx={{ color: 'rgb(156, 163, 175)', mb: 1 }}>
            Rewards Pool Balance
          </Typography>
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
            {poolBalance.toLocaleString()} BNNA
          </Typography>
        </StyledPaper>

        <TextField
          fullWidth
          label="Your Stake Amount"
          type="number"
          value={stakeAmount}
          onChange={handleStakeAmountChange}
          variant="outlined"
          placeholder="Enter BNNA amount"
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': {
                borderColor: 'rgba(251, 191, 36, 0.3)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(251, 191, 36, 0.5)',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(251, 191, 36)',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgb(156, 163, 175)',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'rgb(251, 191, 36)',
            },
          }}
        />

        <StyledPaper>
          <Typography variant="subtitle2" sx={{ color: 'rgb(156, 163, 175)', mb: 1 }}>
            Estimated APY
          </Typography>
          <Typography variant="h4" sx={{ color: 'rgb(251, 191, 36)', fontWeight: 'bold' }}>
            {apy.toFixed(2)}%
          </Typography>
        </StyledPaper>

        <StyledPaper>
          <Typography variant="subtitle2" sx={{ color: 'rgb(156, 163, 175)', mb: 1 }}>
            Estimated Yearly Rewards
          </Typography>
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
            {((parseFloat(stakeAmount) || 0) * apy / 100).toLocaleString()} BNNA
          </Typography>
        </StyledPaper>

        <Typography variant="caption" sx={{ color: 'rgb(156, 163, 175)', display: 'block', mt: 2 }}>
          Note: APY is variable and depends on total staked amount and rewards pool size.
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default StakingCalculator;