import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackHomeButton from '../../components/reusables/BackHomeButton';

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(4),
  background: 'linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.7))',
  color: '#FEFFEB',
}));

const TermsFireSale = () => {
  return (
    <StyledContainer>
      <BackHomeButton />
      <Typography variant="h3" 
        sx={{ 
          textAlign: 'center', 
          mb: 4,
          background: 'linear-gradient(45deg, #C59E57, #FFD700)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Fire Sale Terms
      </Typography>
      <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
        <Typography paragraph>
          These terms govern the BNNA Fire Sale participation.
        </Typography>
        {/* Add your fire sale terms content here */}
      </Box>
    </StyledContainer>
  );
};

export default TermsFireSale;