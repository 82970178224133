import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { FaTiktok } from 'react-icons/fa';


const bgColor = 'rgba(50, 46, 41, 0.9)' // Dark beige background
const pulseColor = '#C59E57' // Gold accent
const CustomLoader = ({ 
  media, // URL to image/gif/video
  mediaType = 'image', // 'image', 'gif', 'video'
  text = 'Loading...', 
  size = 150,
  bgColor,
  pulseColor
}) => {
  const pulseAnimation = {
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(197, 158, 87, 0.7)' // Gold with opacity
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 20px rgba(197, 158, 87, 0)' // Fading gold
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(197, 158, 87, 0)'
      }
    }
  };
  const spinAnimation = {
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    }
  };
  const additionalAnimations = {
    '@keyframes float': {
      '0%, 100%': { transform: 'translateY(0)' },
      '50%': { transform: 'translateY(-10px)' }
    },
    '@keyframes glow': {
      '0%, 100%': { filter: 'drop-shadow(0 0 5px rgba(197, 158, 87, 0.5))' },
      '50%': { filter: 'drop-shadow(0 0 15px rgba(197, 158, 87, 0.8))' }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
        width: '100%',
        gap: 2,
        p: 3,
        background: 'black',
        borderRadius: 4,
        ...pulseAnimation,
        ...spinAnimation,
        ...additionalAnimations
      }}
    >
      <Box
      component="img"
      src="https://github.com/apeoutmeme/resources/blob/3d00d942c5d8473e204afac6319733113954be76/assets/apeoutlogo.png?raw=true"
      alt="Logo"
      sx={{
        width: 100,
        height: 100,
        animation: 'float 3s ease-in-out infinite, glow 2s ease-in-out infinite',
        mb: 2
      }}
    />
      <Box
        sx={{
          position: 'relative',
          width: size,
          height: size,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'pulse 2s infinite',
            background: `linear-gradient(145deg, ${pulseColor}, #9B7940)`, // Gold gradient
            opacity: 0.3
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: `4px solid ${pulseColor}`, // Gold border
            borderTopColor: '#FEFFEB', // Light beige for contrast
            animation: 'spin 1s linear infinite',
            boxShadow: `0 0 15px rgba(197, 158, 87, 0.3)` // Subtle gold glow
          }
        }}
      >
        {media && (
          mediaType === 'video' ? (
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: size * 0.8,
                height: size * 0.8,
                borderRadius: '50%',
                objectFit: 'cover'
              }}
            >
              <source src={media} type="video/mp4" />
            </video>
          ) : (
            <Box
              component="img"
              src={media}
              alt="Loading"
              sx={{
                width: size * 0.8,
                height: size * 0.8,
                borderRadius: '50%',
                objectFit: 'cover'
              }}
            />
          )
        )}
      </Box>

      <Typography
      variant="h5"
      sx={{
        fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
        background: 'linear-gradient(45deg, #C59E57, #FFD700)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        animation: 'pulse 2s infinite',
        mb: 2
      }}
    >
      {text}
    </Typography>
    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
      {[
        { icon: <TwitterIcon />, url: "https://x.com/apeoutmeme" },
        { icon: <TelegramIcon />, url: "https://t.me/apeoutmeme" },
        { icon: <FaTiktok />, url: "https://tiktok.com/@apeoutmeme" }
      ].map((social, index) => (
        <IconButton
          key={index}
          href={social.url}
          target="_blank"
          sx={{
            color: '#C59E57',
            transition: 'all 0.3s',
            animation: 'float 3s ease-in-out infinite',
            animationDelay: `${index * 0.2}s`,
            '&:hover': {
              transform: 'scale(1.2)',
              color: '#FFD700'
            }
          }}
        >
          {social.icon}
        </IconButton>
      ))}
    </Box>

    </Box>
  );
};

export default CustomLoader;