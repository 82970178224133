import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';

const ActivityFeed = () => {
  const [activities, setActivities] = useState([]);
  
  // Mock data generation
// Mock data generation
const mockAddresses = [
  // Ethereum-style addresses
  '0x742d35Cc6634C0532925a3b844Bc454e4438f44e',
  '0x1F98431c8aD98523631AE4a59f267346ea31F984',
  '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  
  // Solana-style addresses
  'HN7cABqLq46Es1jh92dQQisAq662SmxELLLsHHe4YWrH',
  '2NZukH2TXpcuZP4htiuT8CFxcaQSWzkkR6kepSWENVE3',
  '7YttLkHDoNj9wyDur5pYeS976h3FqYHGZ3WERkgb3R8i',
  'G2kVwPyL5nMxT8aRc4qS3hF7mGdN9JvE6XuYtKz4WnA',
  'Z7mKFdQ2vNpA4XwRc8B6TyL9nVhE3sP5WxM2YbH8kSG',
  'Q8nQdKvX2pRwT5aB6hL9cY4sNfG7jWxE3ZuPmVb2kHA',
  'S5mNcKwR8vTxL2aB4hQ9nY7sGdF6jVpE3ZuXtMb2qHA',
  'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
  
  // More Ethereum-style addresses
  '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
  '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
  '0x0D8775F648430679A709E98d2b0Cb6250d2887EF',
  
  // More Solana-style addresses
  'L2kVwPyL5nMxT8aRc4qS3hF7mGdN9JvE6XuYtKz4WnA',
  'SAMoKHmTPuqZP5QqZP5QqZP5QqZP5QqZP5QqZP5QqZ',
  'RVNxKLYFPGvhCAX1RKHcBP8Q6RCUfHhxaJh5pXb8eHH',
  'H9nWdGXPwE5KRtxPjVc6PqmY8mLJhF5BN3LxZdxqT7K',
  'L4pRtNcM8vWxK2aBn5dQ7hJ6sFgY9EmX3ZjUwVb2qHP',
  'B2kVwPyL5nMxT8aRc4qS3hF7mGdN9JvE6XuYtKz4WnA',
  'W3kVwPyL5nMxT8aRc4qS3hF7mGdN9JvE6XuYtKz4WnA'
];

const mockTokens = [
  // Major tokens
  { symbol: 'ETH', price: '2,854', change: '+2.4%', volume: '12.5B' },
  { symbol: 'BNNA', price: '0.42', change: '+15.7%', volume: '2.1M' },
  { symbol: 'SOL', price: '125.47', change: '+4.2%', volume: '1.8B' },
  { symbol: 'USDC', price: '1.00', change: '0%', volume: '45.2B' },
  
  // Meme tokens
  { symbol: 'PEPE', price: '0.000001234', change: '-5.2%', volume: '8.9M' },
  { symbol: 'BONK', price: '0.000012', change: '+8.9%', volume: '15.7M' },
  { symbol: 'WIF', price: '0.00234', change: '+12.5%', volume: '5.2M' },
  { symbol: 'SAMO', price: '0.024', change: '+12.3%', volume: '3.1M' },
  { symbol: 'SIGMA', price: '0.000876', change: '+18.7%', volume: '2.8M' },
  { symbol: 'HAT', price: '0.00567', change: '-4.2%', volume: '1.9M' },
  { symbol: 'POPCAT', price: '0.000045', change: '+25.3%', volume: '4.1M' },
  { symbol: 'MYRO', price: '0.00789', change: '+31.2%', volume: '8.5M' },
  { symbol: 'BOME', price: '0.000023', change: '+15.8%', volume: '3.2M' },
  { symbol: 'SLERF', price: '0.000089', change: '-8.5%', volume: '2.7M' },
  { symbol: 'DOGWIFHAT', price: '0.00456', change: '+22.1%', volume: '6.8M' },
  { symbol: 'PONKE', price: '0.000067', change: '+45.2%', volume: '5.4M' },
  { symbol: 'COPE', price: '0.00123', change: '-2.8%', volume: '1.5M' },
  { symbol: 'KITTY', price: '0.000034', change: '+19.4%', volume: '3.9M' },
  { symbol: 'MOONBIRD', price: '0.00567', change: '+28.7%', volume: '7.2M' },
  { symbol: 'DOGE', price: '0.000789', change: '+5.6%', volume: '12.3M' },
  { symbol: 'FLOKI', price: '0.000234', change: '+16.9%', volume: '4.8M' },
  { symbol: 'PEPE2', price: '0.000002', change: '+33.5%', volume: '9.1M' },
  { symbol: 'WOJAK', price: '0.000012', change: '-12.4%', volume: '2.1M' },
  { symbol: 'SMOG', price: '0.00345', change: '+41.2%', volume: '11.3M' },
  { symbol: 'BOOK', price: '0.000567', change: '+9.7%', volume: '3.4M' },
  
  
  // DeFi tokens
  { symbol: 'JUP', price: '0.85', change: '-2.1%', volume: '25.1M' },
  { symbol: 'ORCA', price: '1.75', change: '+3.4%', volume: '12.3M' },
  { symbol: 'RAY', price: '0.95', change: '-1.2%', volume: '8.7M' },
  { symbol: 'MNGO', price: '0.015', change: '+5.6%', volume: '1.2M' },
  
  // Gaming/Metaverse tokens
  { symbol: 'MAGIC', price: '1.23', change: '+0.8%', volume: '4.5M' },
  { symbol: 'ATLAS', price: '0.012', change: '-3.5%', volume: '2.8M' },
  { symbol: 'POLIS', price: '0.45', change: '+1.9%', volume: '1.5M' },
  { symbol: 'DUST', price: '0.85', change: '+4.7%', volume: '3.2M' },
  
  // Stablecoins
  { symbol: 'USDT', price: '1.00', change: '0%', volume: '52.1B' },
  { symbol: 'DAI', price: '1.00', change: '0%', volume: '8.7B' },
  { symbol: 'BUSD', price: '1.00', change: '0%', volume: '15.4B' },
  
  // Other notable tokens
  { symbol: 'BLAZE', price: '0.95', change: '+18.2%', volume: '5.6M' },
  { symbol: 'PYTH', price: '0.45', change: '+7.8%', volume: '12.3M' },
  { symbol: 'MSOL', price: '126.20', change: '+4.5%', volume: '892.5K' },
  { symbol: 'JITO', price: '2.85', change: '+9.2%', volume: '4.2M' },
  { symbol: 'HNT', price: '1.65', change: '-2.8%', volume: '3.1M' }
];

const mockActions = [
  'Monitoring',
  'Analyzing',
  'Scanning',
  'Processing',
  'Evaluating',
  'Tracking',
];

const mockPatterns = [
  'volume spike',
  'price breakout',
  'accumulation',
  'distribution',
  'whale movement',
  'momentum shift',
];

  const generateMockActivity = () => {
    const activities = [
      {
        type: 'creation',
        address: mockAddresses[Math.floor(Math.random() * mockAddresses.length)],
        timestamp: new Date().toISOString(),
      },
      {
        type: 'trade',
        address: mockAddresses[Math.floor(Math.random() * mockAddresses.length)],
        token: mockTokens[Math.floor(Math.random() * mockTokens.length)],
        action: Math.random() > 0.5 ? 'bought' : 'sold',
        amount: (Math.random() * 10).toFixed(2),
        timestamp: new Date().toISOString(),
      }
    ];
    return activities[Math.floor(Math.random() * activities.length)];
  };

  useEffect(() => {
    // Initial activities
    setActivities([...Array(5)].map(() => generateMockActivity()));

    // Add new activity every few seconds
    const interval = setInterval(() => {
      setActivities(prev => {
        const newActivities = [...prev, generateMockActivity()];
        return newActivities.slice(-5); // Keep last 5 activities
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const formatAddress = (address) => 
    `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
  };

  return (
    <Paper
      elevation={3}
      sx={{
        mt: 4,
        background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.95), rgba(25, 25, 25, 0.95))',
        borderRadius: '12px',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          p: 2,
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          background: 'linear-gradient(90deg, rgba(255, 215, 0, 0.1), transparent)',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#FFD700',
            fontWeight: 700,
            fontSize: '1rem',
          }}
        >
          LIVE AGENT ACTIVITY
        </Typography>
      </Box>
      <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
        {activities.map((activity, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.03)',
              },
              transition: 'background 0.3s ease',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {activity.type === 'creation' ? (
                  <Typography sx={{ color: '#4CAF50' }}>
                    🤖 New Agent Created at{' '}
                    <Box component="span" sx={{ color: '#FFD700', fontFamily: 'monospace' }}>
                      {formatAddress(activity.address)}
                    </Box>
                  </Typography>
                ) : (
                  <Typography sx={{ color: activity.action === 'bought' ? '#4CAF50' : '#f44336' }}>
                    🤖 Agent{' '}
                    <Box component="span" sx={{ color: '#FFD700', fontFamily: 'monospace' }}>
                      {formatAddress(activity.address)}
                    </Box>{' '}
                    {activity.action}{' '}
                    <Box component="span" sx={{ fontWeight: 700 }}>
                      {activity.amount} {activity.token.symbol}
                    </Box>{' '}
                    at ${activity.token.price}
                  </Typography>
                )}
              </Box>
              <Typography
                sx={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  fontSize: '0.875rem',
                  fontFamily: 'monospace',
                }}
              >
                {formatTimestamp(activity.timestamp)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default ActivityFeed;