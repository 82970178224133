import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  IconButton,
  LinearProgress,
  CircularProgress,
  useTheme,
  useMediaQuery
} from '@mui/material';
// import { Close as CloseIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
// import { Sell as SellIcon, TrendingUp } from '@mui/icons-material';
import TradeSettings from '../trading/TradeSettings';
import TokenPurchaseHistory from '../trading/TokenPurchaseHistory';
import TokenBalanceDisplay from '../trading/TokenBalanceDisplay';
import CloseIcon from '@mui/icons-material/Close';
import SellIcon from '@mui/icons-material/Sell';
import LaunchIcon from '@mui/icons-material/Launch';
import RefreshIcon from '@mui/icons-material/Refresh';
import TradeStrategies from '../AIAgents/strategies/TradeStrategies';

const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: {
    background: 'rgba(23, 32, 42, 0.95)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '12px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
    color: 'white',
    fontSize: '14px',
    padding: '16px',
  },
  progressStyle: {
    background: 'linear-gradient(to right, #4CAF50, #81C784)',
  },
};

const getPumpProfileUrl = (walletAddress) => {
  return `https://pump.fun/profile/${walletAddress}`;
};

const DemoAutoApeModal = ({ 
  open, 
  onClose, 
  tradingWallet, 
  handleBuy, 
  handleSell, 
  ownedTokensData = [], // Add default empty array
  setTradeSettings, 
  tradeSettings = [] ,
  tokenPurchases = [], // Add this line


}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [currentMintAddress, setCurrentMintAddress] = useState('');
  const [isDemo, setIsDemo] = useState(false);
  const [progress, setProgress] = useState(0);
  const shownNotifications = useRef(new Set());
  const currentTokenIndex = useRef(0);
  const isProcessing = useRef(false);
  const pollInterval = useRef(10000);
  // Add these at the top of your component with other state/refs
const processedMints = useRef(new Set());
const MAX_POLL_INTERVAL = 30000; // Max 30 second interval
const INITIAL_POLL_INTERVAL = 10000;
const pollTimeoutRef = useRef(null);
const BACKOFF_MULTIPLIER = 1.5;       // How much to increase interval on failure
  const MAX_RETRIES = 3;                // Max retries before backing off
  const REQUEST_TIMEOUT = 5000;
  const retryCount = useRef(0);
  const lastRequestTime = useRef(Date.now());
  // Add a ref to store the cached mint address
const cachedMintAddress = useRef(null);
const [showOwnedTokens, setShowOwnedTokens] = useState(false);
const [tokenDetails, setTokenDetails] = useState({});
const [balances, setBalances] = useState({});
const [isLoadingBalances, setIsLoadingBalances] = useState(true);
const balanceFetchRef = useRef(null);
const lastFetchTime = useRef(Date.now());
const FETCH_COOLDOWN = 5000; // 5s cooldown between fetches
const [tokenDetailsMap, setTokenDetailsMap] = useState({});
const [isLoadingDetails, setIsLoadingDetails] = useState(false);
const [loadingProgress, setLoadingProgress] = useState(0);
const [isFetchingData, setIsFetchingData] = useState(false);
const [localSettings, setLocalSettings] = useState({
  priorityFee: tradeSettings.priorityFee,
  slippage: tradeSettings.slippage,
  amount: tradeSettings.amount || 0.001 // Add default amount
});
const [tokenPurchaseHistory, setTokenPurchaseHistory] = useState([]); // Initialize as array instead of object
const [isStrategyActive, setIsStrategyActive] = useState(false);
const [monitoredTokens, setMonitoredTokens] = useState(new Map());
const monitoringIntervalRef = useRef(null);

  const DEMO_TOKENS = [
    'DPZHWt9TSNq6xyqRFJE4jf3aXcbu3fmpUxMW6eaBpump',
    
    //
  ]
  // Add a global variable to track recently seen mint addresses
const recentMintAddresses = new Set();
const MAX_RECENT_MINTS = 50;

function trackRecentMint(mintAddress) {
  if (recentMintAddresses.has(mintAddress)) {
    return false;
  }
  
  recentMintAddresses.add(mintAddress);
  
  // Prevent memory growth
  if (recentMintAddresses.size > MAX_RECENT_MINTS) {
    const [oldest] = recentMintAddresses;
    recentMintAddresses.delete(oldest);
  }
  
  return true;
}

  useEffect(() => {
    if (isStrategyActive) {
      monitoringIntervalRef.current = setInterval(monitorTokensForSell, 10000);
    }
  
    return () => {
      if (monitoringIntervalRef.current) {
        clearInterval(monitoringIntervalRef.current);
      }
    };
  }, [isStrategyActive, monitoredTokens]);

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();
  
    const fetchBalances = async () => {
      if (!tradingWallet?.walletPublicKey) return;
      
      try {
        setIsLoadingBalances(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/user_balances/${tradingWallet.walletPublicKey}`,
          { signal: controller.signal }
        );
        
        if (!response.ok) throw new Error('Failed to fetch balances');
        
        const data = await response.json();
        if (mounted) {
          console.debug('Balances updated:', data);
          // Extract and format balances
          const formattedBalances = {};
          data.balances.forEach(token => {
            formattedBalances[token.mint] = token.balance;
          });
          setBalances(formattedBalances);
          setIsLoadingBalances(false);
        }
      } catch (error) {
        if (error.name === 'AbortError') return;
        console.error('Error fetching balances:', error);
        if (mounted) setIsLoadingBalances(false);
      }
    };
  
    fetchBalances();
    const interval = setInterval(fetchBalances, 100000);
  
    return () => {
      mounted = false;
      controller.abort();
      clearInterval(interval);
    };
  }, [tradingWallet?.walletPublicKey]);
  // Add manual refresh function
  const handleRefreshBalances = async () => {
    try {
      console.log('Starting balance refresh...');
      setIsLoadingBalances(true);
      
      if (!tradingWallet?.walletPublicKey) {
        console.log('No wallet connected, aborting refresh');
        return;
      }
  
      // Fetch updated balances first
      const balanceResponse = await fetch(
        `${process.env.REACT_APP_API_URL}api/user_balances/${tradingWallet.walletPublicKey}`
      );
      
      if (!balanceResponse.ok) {
        throw new Error('Failed to fetch balances');
      }
      
      const balanceData = await balanceResponse.json();
      console.log('Updated balances:', balanceData);
  
      // Format and set balances
      const formattedBalances = {};
      balanceData.balances.forEach(token => {
        formattedBalances[token.mint] = token.balance;
      });
      setBalances(formattedBalances);
  
      // Then fetch token details
      console.log('Fetching token details...');
      await fetchTokenData();
  
    } catch (error) {
      console.error('Error refreshing data:', error);
      toast.error('Failed to refresh token data');
    } finally {
      setIsLoadingBalances(false);
    }
  };
useEffect(() => {
  let mounted = true;
  let progressInterval;
  
  if (isDemo) {
    // Initialize progress bar
    setProgress(0);
    progressInterval = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + 5;
        if (newProgress >= 100) {
          if (mounted) processNextToken();
          return 0;
        }
        return newProgress;
      });
    }, Math.min(pollInterval.current / 20, 500)); // Adjust progress speed based on poll interval

    // Polling function with rate limiting
    const pollMints = async () => {
      if (!mounted || !isDemo) return;

      try {
        const timeElapsed = Date.now() - lastRequestTime.current;
        if (timeElapsed < pollInterval.current) {
          await new Promise(resolve => 
            setTimeout(resolve, pollInterval.current - timeElapsed)
          );
        }

        await fetchCurrentMint();

        if (mounted && isDemo) {
          // Clear existing timeout before setting new one
          if (pollTimeoutRef.current) {
            clearTimeout(pollTimeoutRef.current);
          }
          pollTimeoutRef.current = setTimeout(pollMints, pollInterval.current);

          // Gradually decrease interval on success if we're not at initial
          if (pollInterval.current > INITIAL_POLL_INTERVAL) {
            pollInterval.current = Math.max(
              pollInterval.current / BACKOFF_MULTIPLIER,
              INITIAL_POLL_INTERVAL
            );
            console.log(`Success - Decreasing poll interval to: ${pollInterval.current}ms`);
          }
        }
      } catch (error) {
        console.error('Polling error:', error);
        if (mounted && isDemo) {
          // Increase interval on error
          retryCount.current++;
          if (retryCount.current >= MAX_RETRIES) {
            console.log('Max retries reached - Backing off significantly');
            pollInterval.current = MAX_POLL_INTERVAL;
            retryCount.current = 0;
          } else {
            pollInterval.current = Math.min(
              pollInterval.current * BACKOFF_MULTIPLIER,
              MAX_POLL_INTERVAL
            );
          }
          console.log(`Error - Increasing poll interval to: ${pollInterval.current}ms`);
          pollTimeoutRef.current = setTimeout(pollMints, pollInterval.current);
        }
      }
    };

    // Start initial poll
    pollMints();
  }

  // Cleanup function
  return () => {
    mounted = false;
    if (progressInterval) clearInterval(progressInterval);
    if (pollTimeoutRef.current) clearTimeout(pollTimeoutRef.current);
    // Reset all state
    retryCount.current = 0;
    pollInterval.current = INITIAL_POLL_INTERVAL;
    lastRequestTime.current = Date.now();
  };
}, [isDemo]); // Only depend on isDemo



const loadPurchaseHistory = async () => {
  if (!tradingWallet?.walletPublicKey) return;
  
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/users/${tradingWallet.walletPublicKey}`
    );
    
    if (!response.ok) throw new Error('Failed to fetch user data');
    
    const userData = await response.json();
    if (Array.isArray(userData.tokenPurchases)) {
      setTokenPurchaseHistory(userData.tokenPurchases);
      console.debug('Loaded purchase history:', userData.tokenPurchases);
    } else {
      console.warn('Invalid token purchase data:', userData.tokenPurchases);
      setTokenPurchaseHistory([]);
    }
  } catch (error) {
    console.error('Error loading purchase history:', error);
    setTokenPurchaseHistory([]);
  }
};
  const fetchWithTimeout = async (url, options = {}) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);

    try {
      const timeElapsed = Date.now() - lastRequestTime.current;
      if (timeElapsed < pollInterval.current) {
        await new Promise(resolve => setTimeout(resolve, pollInterval.current - timeElapsed));
      }

      const response = await fetch(url, {
        ...options,
        signal: controller.signal
      });

      if (!response.ok) {
        if (response.status === 429) {
          retryCount.current++;
          pollInterval.current = Math.min(
            pollInterval.current * BACKOFF_MULTIPLIER,
            MAX_POLL_INTERVAL
          );
          console.log(`Rate limited, increasing interval to ${pollInterval.current}ms`);
          throw new Error('Rate limit reached');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Reset on successful request
      retryCount.current = 0;
      lastRequestTime.current = Date.now();
      return response;
    } finally {
      clearTimeout(timeout);
    }
  };
  const processNextToken = async () => {
    if (isProcessing.current || !isDemo) return;
    
    try {
      isProcessing.current = true;
      const mintAddress = await fetchCurrentMint();
  
      if (mintAddress && processedMints.current.has(mintAddress)) {
        console.log(`Already processed token: ${mintAddress}`);
        return;
      }
  
      if (mintAddress && mintAddress !== currentMintAddress) {
        setCurrentMintAddress(mintAddress);
        
        // Get initial market cap if strategy is active
        let initialMc;
        if (isStrategyActive) {
          const mcResponse = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mintAddress}`);
          const mcData = await mcResponse.json();
          if (mcData.success) {
            initialMc = mcData.data.marketCap;
          }
        }
  
        const buyAmount = tradeSettings.amount || 0.0001;
        const buySuccess = await handleBuy(mintAddress, buyAmount);
  
        if (buySuccess && isStrategyActive && initialMc) {
          // Add to monitored tokens
          setMonitoredTokens(prev => new Map(prev.set(mintAddress, {
            amount: buyAmount,
            entryMc: initialMc,
            buyTimestamp: Date.now()
          })));
        }
  
        processedMints.current.add(mintAddress);
        pollInterval.current = INITIAL_POLL_INTERVAL;
      }
      
    } catch (error) {
      console.error('Error processing token:', error);
      toast.error(`Auto-Ape Buy Failed: ${error.message}`);
    } finally {
      isProcessing.current = false;
    }
  };
  const fetchCurrentMint = async () => {
    try {

      
      if (retryCount.current >= MAX_RETRIES) {
        console.log('Max retries reached, pausing requests temporarily');
        await new Promise(resolve => setTimeout(resolve, MAX_POLL_INTERVAL));
        retryCount.current = 0;
      }

      if (!cachedMintAddress.current) {

        const response = await fetchWithTimeout(`${process.env.REACT_APP_API_URL}api/current-mint`);
        const data = await response.json();
        
        if (data.success && data.mintAddress && data.mintAddress !== currentMintAddress) {
          setCurrentMintAddress(data.mintAddress);
          // Gradually decrease interval on success
          pollInterval.current = Math.max(
            pollInterval.current / BACKOFF_MULTIPLIER,
            INITIAL_POLL_INTERVAL
          );
          return data.mintAddress;
        }
      }
      return cachedMintAddress.current;
    } catch (error) {
      console.error('Error fetching current mint:', error);
      return cachedMintAddress.current;
    }
  };
  const fetchTokenDetails = async () => {
    setIsLoadingDetails(true);
    setLoadingProgress(0);
    const details = {};
    const tokens = Object.keys(balances);
    
    for (let i = 0; i < tokens.length; i++) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${tokens[i]}`);
        const data = await response.json();
        if (data.success) {
          details[tokens[i]] = data.data;
        }
        setLoadingProgress(((i + 1) / tokens.length) * 100);
        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (error) {
        console.error(`Error fetching details for ${tokens[i]}:`, error);
      }
    }
    
    setTokenDetailsMap(details);
    console.log(tokenDetailsMap)
    setIsLoadingDetails(false);
  };
  const fetchTokenData = async () => {
    setIsFetchingData(true);
    try {
      const tokens = Object.keys(balances);
      const details = {};
      
      for (const mint of tokens) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mint}`);
        const data = await response.json();
        if (data.success) {
          details[mint] = data.data;
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      
      setTokenDetailsMap(details);
      
      console.log('MAP', tokenDetailsMap)
    } catch (error) {
      console.error('Error fetching token data:', error);
    }
    setIsFetchingData(false);
   };
   const monitorTokensForSell = async () => {
    if (!isStrategyActive || monitoredTokens.size === 0) return;
  
    try {
      for (const [mintAddress, tokenData] of monitoredTokens.entries()) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-data/${mintAddress}`);
        const data = await response.json();
  
        if (data.success) {
          const currentMc = data.data.marketCap;
          const targetMc = tokenData.entryMc * 2; // 2x target
  
          if (currentMc >= targetMc) {
            // Attempt to sell
            const sellSuccess = await handleSell(mintAddress, tokenData.amount);
            
            if (sellSuccess) {
              // Remove from monitored tokens
              const updatedTokens = new Map(monitoredTokens);
              updatedTokens.delete(mintAddress);
              setMonitoredTokens(updatedTokens);
              
              toast.success(
                <div>
                  <div style={{ fontWeight: 500 }}>2x Target Hit! 🎯</div>
                  <div style={{ opacity: 0.8, fontSize: '12px' }}>
                    Successfully sold {mintAddress.slice(0, 6)}...
                  </div>
                </div>,
                toastConfig
              );
            }
          }
        }
      }
    } catch (error) {
      console.error('Error monitoring tokens:', error);
    }
  };

  const cleanup = () => {
    if (pollTimeoutRef.current) {
      clearTimeout(pollTimeoutRef.current);
    }
    retryCount.current = 0;
    pollInterval.current = INITIAL_POLL_INTERVAL;
    lastRequestTime.current = 0;
  };

  const simulateAutoBuy = async (mintAddress) => {
    try {
      const mintAddressStr = mintAddress.toString();
      
      // Only show notification if we haven't shown it for this mint address
      if (!shownNotifications.current.has(mintAddressStr)) {
        toast.info(`New token detected: ${mintAddressStr.slice(0, 6)}...${mintAddressStr.slice(-4)}`);
        shownNotifications.current.add(mintAddressStr);
      }

      await handleBuy(mintAddressStr);
    } catch (error) {
      console.error('Error in demo buy:', error);
      toast.error('Demo buy failed');
    }
  };

  const startDemo = async () => {
    if (!tradingWallet) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>⚠️</span>
          <div>
            <div style={{ fontWeight: 500 }}>Wallet Required</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              Please connect your trading wallet first
            </div>
          </div>
        </div>,
        toastConfig
      );
      return;
    }
  
    try {
      // Start token monitoring
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/start-monitoring`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to start token monitoring');
      }
  
      setIsDemo(true);
      toast.info(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#81C784' }}>🚀</span>
          <div>
            <div style={{ fontWeight: 500 }}>Auto-Ape  Started</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              Monitoring for new tokens...
            </div>
          </div>
        </div>,
        toastConfig
      );
    } catch (error) {
      console.error('Error starting demo:', error);
      toast.error('Failed to start Auto-Ape demo');
    }
  };
  
  const stopDemo = async () => {
    try {
      // Stop token monitoring
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/stop-monitoring`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to stop token monitoring');
      }
  
      setIsDemo(false);
      setProgress(0);
      setCurrentMintAddress('');
      shownNotifications.current.clear();
      currentTokenIndex.current = 0;
  
      toast.info(
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>✋</span>
          <div>
            <div style={{ fontWeight: 500 }}>Auto-Ape Stopped</div>
            <div style={{ opacity: 0.8, fontSize: '12px', marginTop: '4px' }}>
              Auto-Ape has been stopped
            </div>
          </div>
        </div>,
        toastConfig
      );
    } catch (error) {
      console.error('Error stopping Auto-Ape:', error);
      toast.error('Failed to stop Auto-Ape ');
    }
  };

  return (
    <>
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: 'linear-gradient(135deg, rgba(197, 158, 87, 0.1), rgba(50, 46, 41, 0.9))',
          backdropFilter: 'blur(20px)',
          border: '2px solid rgba(254, 255, 235, 0.2)',
          borderRadius: '16px',
          boxShadow: '0 12px 24px rgba(0,0,0,0.3)',
          margin: { xs: 2, sm: 3 },
          width: { xs: 'calc(100% - 32px)', sm: 'auto' },
          overflow: 'hidden',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '-50%',
            left: '-50%',
            width: '200%',
            height: '200%',
            background: 'radial-gradient(circle, rgba(254, 255, 235, 0.1) 0%, transparent 70%)',
            zIndex: 0,
            pointerEvents: 'none',
          }
        }
      }}
    >
      <DialogTitle sx={{ 
        m: 0, 
        p: { xs: 2, sm: 3 }, 
        color: '#FEFFEB',
        position: 'relative',
        backgroundColor: 'rgba(66, 61, 54, 0.6)',
        borderBottom: '1px solid rgba(254, 255, 235, 0.1)',
        fontFamily: "'Press Start 2P', cursive", // Retro game font
        textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        letterSpacing: '1px'
      }}>
        Auto-Ape Demo
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#C59E57',
            transition: 'transform 0.2s ease',
            '&:hover': {
              transform: 'rotate(90deg)',
              color: '#FEFFEB'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent sx={{ 
        p: { xs: 2, sm: 3 },
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '50px',
          background: 'linear-gradient(transparent, rgba(197, 158, 87, 0.1))',
          pointerEvents: 'none',
          zIndex: 1
        }
      }}>
        <Typography sx={{ 
          mb: 2, 
          color: '#FEFFEB',
          fontFamily: "'VT323', monospace", // Retro-style font
          fontSize: '1.2rem',
          textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
          letterSpacing: '0.5px'
        }}>
          Experience how Auto-Ape automatically buys new tokens as they're minted on the Solana blockchain.
        </Typography>

        {currentMintAddress && (
          <Box sx={{ 
            mb: 2, 
            p: 2, 
            bgcolor: 'rgba(66, 61, 54, 0.5)', 
            borderRadius: 2,
            wordBreak: 'break-all',
            border: '1px dashed rgba(197, 158, 87, 0.3)',
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }
          }}>
            <Typography sx={{ 
              color: '#C59E57',
              fontFamily: "'Courier Prime', monospace",
              fontWeight: 'bold'
            }}>
              Latest Mint: {currentMintAddress.slice(0, 6)}...{currentMintAddress.slice(-4)}
            </Typography>
          </Box>
        )}

        <Box sx={{ width: '100%', mb: 3 }}>
          <LinearProgress 
            variant="determinate" 
            value={progress}
            sx={{
              height: 12,
              borderRadius: 6,
              bgcolor: 'rgba(254, 255, 235, 0.1)',
              '& .MuiLinearProgress-bar': {
                bgcolor: '#C59E57',
                backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0.2) 0%, transparent 100%)',
                boxShadow: '0 3px 5px rgba(0,0,0,0.2)'
              },
            }}
          />
        </Box>

        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
          '& .MuiButton-root': {
            flex: { xs: '1 1 auto', sm: '0 1 auto' },
            transition: 'all 0.3s ease',
            fontFamily: "'Press Start 2P', cursive",
            textTransform: 'none',
            letterSpacing: '0.5px',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 6px 12px rgba(0,0,0,0.2)'
            }
          }
        }}>
          <Button
            onClick={isDemo ? stopDemo : startDemo}
            variant="contained"
            sx={{
              bgcolor: isDemo ? '#9B7940' : '#C59E57',
              color: '#322E29',
              '&:hover': {
                bgcolor: isDemo ? '#322E29' : '#FEFFEB',
                color: isDemo ? '#FEFFEB' : '#322E29'
              },
            }}
          >
            {isDemo ? 'Stop Auto-Ape' : 'Start Auto-Ape'}
          </Button>
          
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              color: '#FEFFEB',
              borderColor: '#C59E57',
              '&:hover': {
                borderColor: '#FEFFEB',
                bgcolor: 'rgba(254, 255, 235, 0.1)',
              },
            }}
          >
            Close
          </Button>

          <Button
            onClick={() => setShowOwnedTokens(true)}
            variant="contained"
            startIcon={<SellIcon />}
            sx={{
              bgcolor: '#423D36',
              color: '#C59E57',
              '&:hover': { 
                bgcolor: '#322E29',
                color: '#FEFFEB'
              },
            }}
          >
            View Owned Tokens
          </Button>

          <Button
            onClick={() => {
              if (tradingWallet?.walletPublicKey) {
                window.open(getPumpProfileUrl(tradingWallet.walletPublicKey), '_blank', 'noopener,noreferrer');
              } else {
                toast.warn('Please connect your trading wallet to view your profile');
              }
            }}
            variant="contained"
            startIcon={<LaunchIcon />}
            sx={{
              bgcolor: '#9B7940',
              color: '#322E29',
              '&:hover': { 
                bgcolor: '#C59E57',
                color: '#FEFFEB'
              },
            }}
          >
            View Profile
          </Button>
        </Box>

        <TradeSettings
          priorityFee={localSettings.priorityFee}
          setPriorityFee={(fee) => {
            setLocalSettings(prev => ({ ...prev, priorityFee: fee }));
            setTradeSettings(prev => ({ ...prev, priorityFee: fee }));
            localStorage.setItem('tradeSettings', JSON.stringify({
              ...tradeSettings,
              priorityFee: fee
            }));
          }}
          slippage={localSettings.slippage}
          setSlippage={(slip) => {
            setLocalSettings(prev => ({ ...prev, slippage: slip }));
            setTradeSettings(prev => ({ ...prev, slippage: slip }));
            localStorage.setItem('tradeSettings', JSON.stringify({
              ...tradeSettings,
              slippage: slip
            }));
          }}
          amount={localSettings.amount}
          setAmount={(amt) => {
            setLocalSettings(prev => ({ ...prev, amount: amt }));
            setTradeSettings(prev => ({ ...prev, amount: amt }));
            localStorage.setItem('tradeSettings', JSON.stringify({
              ...tradeSettings,
              amount: amt
            }));
          }}
          sx={{ 
            mt: 4,
            p: 2,
            bgcolor: 'rgba(66, 61, 54, 0.3)',
            borderRadius: 2,
            border: '1px dashed rgba(197, 158, 87, 0.2)'
          }}
        />
       
{/* Add strategies section here */}
<Box sx={{ mt: 4 }}>
  <Typography sx={{ 
            mb: 2, 
            color: '#FEFFEB',
            fontFamily: "'Press Start 2P', cursive",
            fontSize: '1.1rem',
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
          }}>
    🤖 Auto Trading Strategies
  </Typography>
  <Box sx={{ 
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
    gap: 2
  }}>
    {[
      { id: '2x', name: 'Sell at 2x', desc: 'Automatic sell when price doubles' },
      { id: '5min', name: '5min Trade', desc: 'Buy and sell after 5 minutes' },
      { id: 'vol', name: 'Volume Watch', desc: 'Sell if volume drops below threshold' },
      { id: 'stop', name: 'Stop Loss', desc: 'Sell if price drops 20% from peak' }
    ].map((strategy) => (
      <Button
        key={strategy.id}
        variant={isStrategyActive && strategy.id === '2x' ? "contained" : "outlined"}
        sx={{
          color: isStrategyActive && strategy.id === '2x' ? '#322E29' : '#FEFFEB',
          borderColor: '#C59E57',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 1,
          textAlign: 'left',
          fontFamily: "'VT323', monospace",
          backgroundImage: isStrategyActive && strategy.id === '2x' 
            ? 'linear-gradient(135deg, #C59E57, #9B7940)' 
            : 'none',
          backgroundColor: isStrategyActive && strategy.id === '2x' 
            ? 'rgba(197, 158, 87, 0.8)' 
            : 'transparent',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
            borderColor: '#FEFFEB',
            backgroundColor: isStrategyActive && strategy.id === '2x' 
              ? 'rgba(197, 158, 87, 0.9)'
              : 'rgba(254, 255, 235, 0.1)'
          }
        }}
        onClick={() => {
          if (strategy.id === '2x') {
            setIsStrategyActive(prev => !prev);
            toast.info(
              <div style={{ 
                fontFamily: "'VT323', monospace",
                color: '#322E29'
              }}>
                <div style={{ fontWeight: 500 }}>
                  {isStrategyActive ? 'Strategy Deactivated' : '2x Strategy Activated'}
                </div>
                <div style={{ opacity: 0.8, fontSize: '12px' }}>
                  {isStrategyActive ? 
                    'Stopping automatic sells' : 
                    'Will automatically sell tokens at 2x gain'}
                </div>
              </div>,
              toastConfig
            );
          } else {
            toast.info(
              <div>
                <div style={{ fontWeight: 500 }}>{strategy.name} (Coming Soon)</div>
                <div style={{ opacity: 0.8, fontSize: '12px' }}>{strategy.desc}</div>
              </div>,
              toastConfig
            );
          }
        }}
      >
        <Typography variant="subtitle1" sx={{ 
                    fontWeight: 'bold',
                    fontFamily: "'Press Start 2P', cursive",
                    color: isStrategyActive && strategy.id === '2x' ? '#322E29' : '#C59E57'
                  }}>
          {strategy.name}
        </Typography>
        <Typography variant="body2" sx={{ 
                    opacity: 0.8,
                    color: isStrategyActive && strategy.id === '2x' ? '#322E29' : '#FEFFEB'
                  }}>
          {strategy.desc}
        </Typography>
      </Button>
    ))}
  </Box>
</Box>
      </DialogContent>
    </Dialog>

    <Dialog 
      open={showOwnedTokens} 
      onClose={() => setShowOwnedTokens(false)} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          background: 'linear-gradient(135deg, rgba(197, 158, 87, 0.1), rgba(50, 46, 41, 0.9))',
          backdropFilter: 'blur(20px)',
          border: '2px solid rgba(254, 255, 235, 0.2)',
          borderRadius: '16px',
          margin: { xs: 2, sm: 3 },
          width: { xs: 'calc(100% - 32px)', sm: 'auto' }
        }
      }}
    >
      <DialogTitle sx={{ 
        p: { xs: 2, sm: 3 },
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        gap: 1
      }}>
        <Typography variant="h6" component="div">
          Owned Tokens
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          flexWrap: 'nowrap',
          marginLeft: 'auto'
        }}>
          <IconButton
            onClick={handleRefreshBalances}
            disabled={isLoadingBalances}
          >
            <RefreshIcon sx={{ color: 'green' }} />
          </IconButton>
          <IconButton 
            onClick={() => setShowOwnedTokens(false)}
          >
            <CloseIcon sx={{ color: '#22c55e' }} />
          </IconButton>
          <Button
            onClick={fetchTokenData}
            disabled={isFetchingData}
            variant="contained"
            size="small"
            sx={{ 
              bgcolor: 'primary.main',
              minWidth: { xs: '100%', sm: 'auto' }
            }}
            startIcon={isLoadingDetails ? <CircularProgress size={16} /> : null}
          >
            {isLoadingDetails ? `Loading ${Math.round(loadingProgress)}%` : 'Fetch Details'}
          </Button>
        </Box>
      </DialogTitle>
      
      <DialogContent sx={{ p: { xs: 2, sm: 3 } }}>
        <TokenBalanceDisplay 
          tradingWallet={tradingWallet}
          handleSell={handleSell}
          tokenPurchases={tokenPurchases}
          onRefresh={handleRefreshBalances}
          isLoading={isLoadingBalances}
        />
        <TokenPurchaseHistory 
          tokenPurchases={tokenPurchases}
          currentMarketCaps={Object.fromEntries(
            Object.entries(tokenDetailsMap).map(([mint, details]) => [
              mint, 
              details.marketCap
            ])
          )}
        />
      </DialogContent>
    </Dialog>

    
    
  </>
    
  );
};

export default DemoAutoApeModal;