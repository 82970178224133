import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Paper, Grid, Skeleton, IconButton, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Refresh, ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { toast } from 'react-toastify';
import GMGNChart from './GMGNChart';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import RefreshIcon from '@mui/icons-material/Refresh';
import Navigation from '../components/nav/Navigation'; 
import QuickTradeBox from '../components/QuickTradeBox';
import { Tabs, Tab } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Search } from '@mui/icons-material';
import Menu from '@mui/material/Menu';  // Add this
import { Menu as MenuIcon } from '@mui/icons-material';
import { 
  TrendingUp, 
  AddCircleOutline, 
  ShoppingCart, 
  AccountBalance,
  Leaderboard, 
  Share, 
  CardGiftcard,
  Language as LanguageIcon,
  SwapHoriz,
  PhoneIphone,
  Telegram as TelegramIcon
} from '@mui/icons-material';
import logoImg from '../assets/logo-tp.png';
import NewPairsTable from './NewPairsTable';
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'radial-gradient(circle at top right, rgba(25, 118, 210, 0.1), transparent 70%)',
      pointerEvents: 'none',
      zIndex: -1,
    }
}));
  
  const gradientAnimation = keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  `;
  
  const StyledPaper = styled(Paper)(({ theme }) => ({
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.2)',
      boxShadow: '0 8px 32px rgba(31, 38, 135, 0.15)'
    }
  }));
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    '&.MuiTableCell-head': {
      background: 'rgba(0, 0, 0, 0.3)',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      letterSpacing: '0.1em'
    }
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      transform: 'translateY(-1px)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(25, 118, 210, 0.15) !important',
      border: '1px solid rgba(25, 118, 210, 0.25)',
    }
  }));
  
  const GlowingTitle = styled(Typography)(({ theme }) => ({
    color: 'white',
    background: 'linear-gradient(45deg, #2196f3, #00bcd4, #2196f3)',
    backgroundSize: '200% 200%',
    animation: `${gradientAnimation} 5s ease infinite`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }));
  
  const StatCard = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    background: 'rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      background: 'rgba(0, 0, 0, 0.3)',
    }
  }));
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: 3,
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    '& .MuiTabs-indicator': {
      backgroundColor: '#3b82f6',
    },
    '& .MuiTab-root': {
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#3b82f6',
      },
    },
  }));




const TrendingTokensPage = () => {
  const [trendingTokens, setTrendingTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedChain, setSelectedChain] = useState('solana');
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [newPairs, setNewPairs] = useState([]);
  // Add with other state declarations at the top
  const [selectedView, setSelectedView] = useState('trending'); // 'trending' or 'newPairs'
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fetchTrendingTokens = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const chainParam = selectedChain === 'base' ? '?chain=base' : '';
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/trending-tokens${chainParam}`);
      const data = await response.json();
      
      if (!response.ok) throw new Error(data.error || 'Failed to fetch trending tokens');
      
      if (data.pairs && data.pairs.length > 0) {
        setTrendingTokens(data.pairs);
        setSelectedToken(data.pairs[0]); // Always select first token of new chain
        console.log(data.pairs);
        console.log(data.pairs[0]);
      } else {
        setError(`No tokens found for ${selectedChain}`);
        setTrendingTokens([]);
        setSelectedToken(null);
      }
    } catch (error) {
      console.error('Error fetching trending tokens:', error);
      setError(error.message);
      setTrendingTokens([]);
      setSelectedToken(null);
      toast.error(`Failed to fetch ${selectedChain} tokens`);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchNewPairs = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/new-pairs`);
      const data = await response.json();
  
      if (!response.ok) throw new Error(data.error || 'Failed to fetch new pairs');
      setNewPairs(data.pairs || []);
      console.log(data.pairs);
    } catch (error) {
      console.error('Error fetching new pairs:', error);
      setError(error.message);
      setNewPairs([]);
      toast.error('Failed to fetch new pairs');
    } finally {
      setIsLoading(false);
    }
  };
  const handleSearch = async (addressInput) => {
    const trimmedAddress = addressInput.trim();
    
    if (!trimmedAddress) {
      toast.error('Please enter a token address');
      return;
    }
  
    // Basic address format validation
    if (selectedChain === 'solana' && trimmedAddress.length !== 44) {
      toast.error('Invalid Solana token address format');
      return;
    }
    
    if (selectedChain === 'base' && !trimmedAddress.startsWith('0x')) {
      toast.error('Invalid Base token address format');
      return;
    }
  
    setIsSearching(true);
    try {
      const searchedToken = {
        baseToken: {
          address: trimmedAddress,
          symbol: trimmedAddress.slice(0, 6) + '...' // Show truncated address as symbol
        },
        pairAddress: trimmedAddress,
        priceUsd: '0',
        priceChange: {
          h24: 0
        },
        marketCap: 0,
        volume24h: 0,
        liquidity: {
          usd: 0
        },
        pairCreatedAt: new Date().toISOString(),
        dexId: 'unknown'
      };
  
      setSelectedToken(searchedToken);
      setTrendingTokens([searchedToken]);
      
    } catch (error) {
      console.error('Error processing search:', error);
      toast.error('Invalid token address');
    } finally {
      setIsSearching(false);
      setSearchQuery('');
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
    setSelectedToken(null);
    fetchTrendingTokens(); // Restore trending tokens list
  };

  useEffect(() => {
    if (selectedView === 'newPairs') {
      fetchNewPairs();
      const interval = setInterval(fetchNewPairs, 60000);
      return () => clearInterval(interval);
    }
  }, [selectedView]);
  
  
  useEffect(() => {
    fetchTrendingTokens();
    const interval = setInterval(fetchTrendingTokens, 60000);
    return () => clearInterval(interval);
  }, [selectedChain]);

  const formatNumber = (num) => {
    if (num >= 1e9) return `$${(num / 1e9).toFixed(2)}B`;
    if (num >= 1e6) return `$${(num / 1e6).toFixed(2)}M`;
    if (num >= 1e3) return `$${(num / 1e3).toFixed(2)}K`;
    return `$${num.toFixed(2)}`;
  };
  const handleChainChange = (event, newChain) => {
    setSelectedToken(null);
    setSelectedChain(newChain);
  };

  return (
    <Container maxWidth="xl" sx={{ 
      mt: 4, 
      mb: 4,
      p: 3,
      bgcolor: 'rgba(50, 46, 41, 0.95)', // Using #322E29 with slight transparency
      borderRadius: 2,
      backdropFilter: 'blur(10px)',
      border: '1px solid #C59E57', // Gold border
      boxShadow: '0 4px 20px rgba(197, 158, 87, 0.1)', // Subtle gold glow
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `radial-gradient(circle at top right, rgba(197, 158, 87, 0.15), transparent 70%)`, // Gold gradient
        pointerEvents: 'none',
        zIndex: 0,
        borderRadius: 2,
      }
    }}>
      <Navigation />
      <Box 
  sx={{ 
    position: 'sticky',
    top: 0,
    zIndex: 1100,
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(50, 46, 41, 0.95)',
    borderBottom: '1px solid #C59E57',
    mb: 3,
    py: 2
  }}
>
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    px: 2
  }}>
    {/* Logo and Balance Section */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Typography 
        variant="h6" 
        sx={{ 
          color: '#FEFFEB',
          fontWeight: 'bold' 
        }}
      >
        <img src={logoImg} alt="Logo" style={{ width: '60px', height: '60px' }} />
      </Typography>
      <Typography 
        variant="body2" 
        sx={{ 
          color: '#C59E57',
          bgcolor: 'rgba(197, 158, 87, 0.1)',
          px: 2,
          py: 0.5,
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Balance: 0.00 SOL
      </Typography>
    </Box>

    {/* Search and Menu Icons */}
    <Box sx={{ display: 'flex', gap: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  <Paper
    sx={{
      display: 'flex',
      alignItems: 'center',
      bgcolor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: 1,
      border: '1px solid rgba(197, 158, 87, 0.3)',
      p: '2px 4px',
    }}
  >
    <InputBase
      sx={{
        ml: 1,
        flex: 1,
        color: '#FEFFEB',
        '& input::placeholder': {
          color: 'rgba(254, 255, 235, 0.5)',
        },
      }}
      placeholder="Search token address..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch(searchQuery);
        }
      }}
    />
    {isSearching ? (
      <CircularProgress size={20} sx={{ mr: 1, color: '#C59E57' }} />
    ) : (
      <IconButton 
        sx={{ 
          color: '#FEFFEB',
          '&:hover': { color: '#C59E57' }
        }}
        onClick={() => handleSearch(searchQuery)}
      >
        <Search />
      </IconButton>
    )}
  </Paper>
</Box>
  <IconButton 
    onClick={handleClick}
    sx={{ 
      color: '#FEFFEB',
      '&:hover': { color: '#C59E57' }
    }}
  >
    <MenuIcon />
  </IconButton>
  <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    onClick={handleClose}
    PaperProps={{
      sx: {
        bgcolor: 'rgba(50, 46, 41, 0.95)',
        backdropFilter: 'blur(10px)',
        border: '1px solid #C59E57',
        boxShadow: '0 4px 20px rgba(197, 158, 87, 0.1)',
        color: '#FEFFEB',
        '& .MuiMenuItem-root': {
          px: 2,
          py: 1.5,
          '&:hover': {
            bgcolor: 'rgba(197, 158, 87, 0.1)',
          },
        },
        '& .MuiListItemIcon-root': {
          color: '#C59E57',
          minWidth: '40px',
        },
      },
    }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  >
    <MenuItem onClick={() => {
  setSelectedView('newPairs');
  fetchNewPairs();
  handleClose();
}}>
  <ListItemIcon><AddCircleOutline /></ListItemIcon>
  New Pairs
</MenuItem>

<MenuItem onClick={() => {
  setSelectedView('trending');
  handleClose();
}}>
  <ListItemIcon><TrendingUp /></ListItemIcon>
  Trending
</MenuItem>
    <MenuItem>
      <ListItemIcon><ShoppingCart /></ListItemIcon>
      Orders
    </MenuItem>
    <MenuItem>
      <ListItemIcon><AccountBalance /></ListItemIcon>
      Holdings
    </MenuItem>
    <MenuItem>
      <ListItemIcon><Leaderboard /></ListItemIcon>
      Leaderboard
    </MenuItem>
    <MenuItem>
      <ListItemIcon><Share /></ListItemIcon>
      Referral Tracking
    </MenuItem>
    <MenuItem>
      <ListItemIcon><CardGiftcard /></ListItemIcon>
      Lootboxes
    </MenuItem>
    <MenuItem>
      <ListItemIcon><LanguageIcon /></ListItemIcon>
      Language
    </MenuItem>
    <MenuItem>
      <ListItemIcon><SwapHoriz /></ListItemIcon>
      Switch Network
    </MenuItem>
    <MenuItem>
      <ListItemIcon><PhoneIphone /></ListItemIcon>
      Mobile App
    </MenuItem>
    <MenuItem>
      <ListItemIcon><TelegramIcon /></ListItemIcon>
      Telegram
    </MenuItem>
  </Menu>
      
    </Box>
  </Box>
</Box>


{selectedView === 'trending' ? (
  <>
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              color: '#FEFFEB', // Light beige color for better readability
              fontWeight: 'bold',
              textShadow: `2px 2px 4px rgba(50, 46, 41, 0.5), 0 0 10px rgba(197, 158, 87, 0.3)`, // Dual shadow effect
              letterSpacing: '0.5px',
              position: 'relative',
              '&::after': {  // Subtle underline effect
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                width: '60px',
                height: '2px',
                background: 'linear-gradient(to right, #C59E57, rgba(197, 158, 87, 0.3))',
              }
            }}
          >
            Trending Tokens
          </Typography>
          <IconButton 
            onClick={fetchTrendingTokens} 
            disabled={isLoading}
            sx={{
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
              '&.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.3)',
              }
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
        
        <Tabs
          value={selectedChain}
          onChange={handleChainChange}
          sx={{
            borderBottom: 1,
            borderColor: 'rgba(255, 255, 255, 0.1)',
            '& .MuiTabs-indicator': {
              backgroundColor: '#3b82f6',
            }
          }}
        >
          <Tab 
            label="Solana" 
            value="solana"
            sx={{ 
              color: 'white',
              '&.Mui-selected': { color: '#3b82f6' }
            }}
          />
          <Tab 
            label="Base" 
            value="base"
            sx={{ 
              color: 'white',
              '&.Mui-selected': { color: '#3b82f6' }
            }}
          />
        </Tabs>
      </Box>

      <Grid container spacing={3}>
        {/* Chart Section */}
        <Grid item xs={12} md={8}>
          <Paper 
            elevation={3}
            sx={{
              height: '500px',
              background: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3))',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              overflow: 'hidden',
              borderRadius: 2,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.002)',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)'
              }
            }}
          >
            {selectedToken ? (
              <Box 
                key={`gmgn-${selectedToken.baseToken.address}-${selectedChain}`} 
                sx={{ height: '100%', p: 1 }}
              >
                <React.Suspense fallback={
                  <Box sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    color: 'rgba(255,255,255,0.7)'
                  }}>
                    Loading chart...
                  </Box>
                }>
                  <GMGNChart 
                    key={`gmgn-${selectedToken.baseToken.address}-${selectedChain}`}
                    tokenAddress={selectedToken.baseToken.address}
                    chain={selectedChain}
                  />
                </React.Suspense>
              </Box>
            ) : (
              <Box sx={{ 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: 'rgba(255,255,255,0.7)',
                p: 2,
                textAlign: 'center'
              }}>
                {error ? error : 'Select a token to view chart'}
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Token List Section */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              height: '500px',
              overflow: 'auto',
              background: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3))',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: 2,
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'rgba(255, 255, 255, 0.05)',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.2)',
                },
              },
            }}
          >
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell 
                      sx={{
                        background: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        fontWeight: 'bold',
                        borderBottom: '2px solid rgba(255, 255, 255, 0.1)'
                      }}
                    >
                      Token ({selectedChain === 'solana' ? 'SOL' : 'ETH'})
                    </TableCell>
                    <TableCell 
                      align="right"
                      sx={{
                        background: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        fontWeight: 'bold',
                        borderBottom: '2px solid rgba(255, 255, 255, 0.1)'
                      }}
                    >
                      Price
                    </TableCell>
                    <TableCell 
                      align="right"
                      sx={{
                        background: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        fontWeight: 'bold',
                        borderBottom: '2px solid rgba(255, 255, 255, 0.1)'
                      }}
                    >
                      24h
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    Array.from({ length: 10 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)' }} />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : error ? (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Box sx={{ 
                          p: 2, 
                          textAlign: 'center',
                          color: 'rgba(255,255,255,0.7)'
                        }}>
                          {error}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    trendingTokens.map((token) => (
                      <TableRow
                        key={token.pairAddress}
                        hover
                        selected={selectedToken?.pairAddress === token.pairAddress}
                        onClick={() => setSelectedToken(token)}
                        sx={{ 
                          cursor: 'pointer',
                          transition: 'background-color 0.2s ease',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.08) !important'
                          },
                          '&.Mui-selected': {
                            backgroundColor: 'rgba(255, 255, 255, 0.12) !important'
                          },
                          '& td': {
                            color: 'white',
                            borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
                          }
                        }}
                      >
                        <TableCell>
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            '& img': {
                              width: 24,
                              height: 24,
                              borderRadius: '50%',
                              objectFit: 'cover',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                            }
                          }}>
                            {token.info?.imageUrl && (
                              <img
                                src={token.info.imageUrl}
                                alt={token.baseToken.symbol}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "/api/placeholder/24/24";
                                }}
                              />
                            )}
                            <Box>
                              {token.baseToken.symbol}
                              <Typography
                                variant="caption"
                                sx={{
                                  display: 'block',
                                  color: 'rgba(255,255,255,0.5)',
                                  fontSize: '0.7rem'
                                }}
                              >
                                {token.dexId}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(parseFloat(token.priceUsd))}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: token.priceChange.h24 >= 0 ? '#4caf50' : '#f44336',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: 0.5
                          }}
                        >
                          {token.priceChange.h24 >= 0 ? <ArrowDropUp /> : <ArrowDropDown />}
                          {Math.abs(token.priceChange.h24).toFixed(2)}%
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Token Details Section */}
        {selectedToken && (
          <Grid item xs={12}>
            <QuickTradeBox selectedToken={selectedToken} chain={selectedChain} />
            <Paper sx={{
              p: 3,
              mt: 2,
              background: 'rgba(50, 46, 41, 0.85)', // Dark beige background with transparency
              backdropFilter: 'blur(10px)',
              border: '1px solid #C59E57', // Gold border
              boxShadow: '0 4px 15px rgba(197, 158, 87, 0.1)', // Subtle gold shadow
              borderRadius: 1,
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(145deg, rgba(197, 158, 87, 0.1), transparent)', // Subtle gold gradient
                borderRadius: 'inherit',
                pointerEvents: 'none',
              },
              '&:hover': {
                borderColor: '#9B7940', // Darker gold on hover
                boxShadow: '0 6px 20px rgba(197, 158, 87, 0.15)', // Enhanced shadow on hover
              }
            }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <Typography
                      variant="caption"
                      sx={{
                        backgroundColor: selectedChain === 'solana' ? '#14F195' : '#0052FF',
                        color: selectedChain === 'solana' ? '#000' : '#fff',
                        padding: '4px 8px',
                        borderRadius: '4px',
                      }}
                    >
                      {selectedChain === 'solana' ? 'Solana' : 'Base'}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        color: 'white',
                        padding: '4px 8px',
                        borderRadius:'4px',
                      }}
                    >
                      {selectedToken.dexId}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Market Cap
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {formatNumber(selectedToken.marketCap)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      24h Volume
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {formatNumber(selectedToken.volume24h)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Liquidity
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {formatNumber(selectedToken.liquidity.usd)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Created
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {new Date(selectedToken.pairCreatedAt).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
      </>
      ) : (
        <Box sx={{ mb: 4 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography 
        variant="h4" 
        component="h1" 
        sx={{ 
          color: '#FEFFEB',
          fontWeight: 'bold',
          textShadow: `2px 2px 4px rgba(50, 46, 41, 0.5), 0 0 10px rgba(197, 158, 87, 0.3)`,
          letterSpacing: '0.5px',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: -4,
            left: 0,
            width: '60px',
            height: '2px',
            background: 'linear-gradient(to right, #C59E57, rgba(197, 158, 87, 0.3))',
          }
        }}
      >
        New Pairs
      </Typography>
      <IconButton 
        onClick={fetchNewPairs} 
        disabled={isLoading}
        sx={{
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.3)',
          }
        }}
      >
        <RefreshIcon />
      </IconButton>
    </Box>
    {/* Your NewPairsTable component or content will go here */}
    <NewPairsTable />
  </Box>
  )}
    </Container>
  );
};

export default TrendingTokensPage;