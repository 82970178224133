import React, { useState } from 'react';
import { 
  IconButton, 
  Menu, 
  MenuItem, 
  Box,
  Button,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { 
  PlayCircle, 
  ContentCopy, 
  Calculate, 
  ShoppingCart as ShoppingCartIcon 
} from '@mui/icons-material';
import EnhancedContainer from '../EnhancedContainer';
import PremiumTriggerButton from '../PremiumTriggerButton';
import ApeBuddyModalWrapper from '../modals/ApeBuddyModalWrapper';
import { useNavigate } from 'react-router-dom';

const ResponsiveActionMenu = ({
  setShowVideoModal,
  setIsTokenDetailsOpen,
  setIsUtilitiesModalOpen,
  setShowMerchModal,
  logoImage,
  hasExistingWallet,
  LatestTrades,
  setShowPremiumModal,
  isPremium,
  tradingWallet,
  handleBuyWithRaydium,
  handleSell,
  connectedWalletBalance,
  bnnaBalance,
  bnnaConnectedBalance,
  handleBuy,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: 'Watch How It Works',
      icon: <PlayCircle />,
      onClick: () => {
        setShowVideoModal(true);
        handleClose();
      }
    },
    {
      label: 'View Token Details',
      icon: <ContentCopy />,
      onClick: () => {
        setIsTokenDetailsOpen(true);
        handleClose();
      }
    },
    {
      label: 'Open Utilities',
      icon: <Calculate />,
      onClick: () => {
        setIsUtilitiesModalOpen(true);
        handleClose();
      }
    },
    {
      label: 'BNNA Merch Store',
      icon: <ShoppingCartIcon />,
      onClick: () => {
        setShowMerchModal(true);
        handleClose();
      }
    }
  ];

  const buttonSx = {
    background: 'linear-gradient(to right, #fbbf24, #f97316)',
    '&:hover': {
      background: 'linear-gradient(to right, #f97316, #ea580c)',
    },
    '&:disabled': {
      background: 'linear-gradient(to right, #9ca3af, #6b7280)',
      opacity: 0.6,
      cursor: 'not-allowed',
    },
    textTransform: 'none',
    color: 'white',
    fontWeight: 600,
    padding: '8px 16px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
    width: '100%',
    marginBottom: 1
  };

  return (
    // <EnhancedContainer 
    //   logoImage={logoImage} 
    //   hasExistingWallet={hasExistingWallet} 
    //   LatestTrades={LatestTrades}
    //   data-tour="enhanced-container"
    // >
        <Box sx={{ width: '100%' }}>
          <IconButton
            onClick={handleMenuClick}
            sx={{
              color: 'white',
              background: 'linear-gradient(to right, #fbbf24, #f97316)',
              width: '100%',
              borderRadius: '8px',
              py: 1,
              '&:hover': {
                background: 'linear-gradient(to right, #f97316, #ea580c)',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* Add PremiumTriggerButton */}
        <PremiumTriggerButton 
          setShowPremiumModal={setShowPremiumModal}
          isPremium={isPremium}
          className="my-4"
        />

        {/* Add ApeBuddyModalWrapper */}
        <ApeBuddyModalWrapper 
          tradingWallet={tradingWallet}
          handleBuy={handleBuyWithRaydium}
          handleSell={handleSell}
          connectedWalletBalance={connectedWalletBalance}
          bnnaBalance={bnnaBalance}
          bnnaConnectedBalance={bnnaConnectedBalance}
        />
        <Button
          onClick={() => navigate('/trending-tokens')}
          
          sx={{
            background: 'linear-gradient(to right, #fbbf24, #f97316)',
            '&:hover': {
              background: 'linear-gradient(to right, #f97316, #ea580c)',
            },
            '&:disabled': {
              background: 'linear-gradient(to right, #9ca3af, #6b7280)',
              opacity: 0.6,
              cursor: 'not-allowed',
            },
            textTransform: 'none',
            color: 'white',
            fontWeight: 600,
            padding: '8px 16px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
            margin: '10px 5px, 5px 5px'
          }}
        >
          Trading Dashboard

         
        </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                width: '90%',
                maxWidth: '300px',
                background: 'linear-gradient(135deg, rgba(17, 24, 39, 0.95), rgba(0, 0, 0, 0.98))',
                mt: 1,
              }
            }}
          >
            {menuItems.map((item, index) => (
              <MenuItem 
                key={index} 
                onClick={item.onClick}
                sx={{
                  color: 'white',
                  '&:hover': {
                    background: 'rgba(251, 191, 36, 0.1)',
                  },
                  gap: 1,
                }}
              >
                {item.icon}
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      
    // </EnhancedContainer>
  );
};

export default ResponsiveActionMenu;