import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import React from 'react';
const Navigation = () => {
  const navigate = useNavigate();

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        mb: 3,
        pb: 2,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
      }}
    >
      <IconButton 
        onClick={() => navigate('/')}
        sx={{
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          }
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography 
        variant="body2" 
        sx={{ 
          color: 'rgba(255, 255, 255, 0.7)',
          userSelect: 'none'
        }}
      >
        Back to Home
      </Typography>
    </Box>
  );
};

export default Navigation;