import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Alert,
  CircularProgress
} from '@mui/material';

const TokenHolders = () => {
  const [mintAddress, setMintAddress] = useState('');
  const [holders, setHolders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchHolders = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/token-holders/${mintAddress}`);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch holders');
      }
      
      setHolders(data.data);
    } catch (err) {
      setError(err.message);
      setHolders(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: '1200px', margin: '0 auto', p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          value={mintAddress}
          onChange={(e) => setMintAddress(e.target.value)}
          placeholder="Enter token mint address"
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          onClick={fetchHolders}
          disabled={loading || !mintAddress}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          {loading ? 'Loading...' : 'Fetch Holders'}
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {holders && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Found {holders.totalHolders} holders
          </Typography>
          
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Owner</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holders.holders.map((holder) => (
                  <TableRow key={holder.owner}>
                    <TableCell 
                      component="th" 
                      scope="row"
                      sx={{ fontFamily: 'monospace' }}
                    >
                      {holder.owner}
                    </TableCell>
                    <TableCell align="right">
                      {holder.amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default TokenHolders;