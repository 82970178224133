import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { 
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button
} from '@mui/material';
import {
  TrendingUp,
  TrendingDown,
  Warning,
  AttachMoney,
} from '@mui/icons-material';

import { handleBuy, handleSell } from '../../trading/trading';



const DipRipStrategy = ({ agent, isActive }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [lastCheck, setLastCheck] = useState(null);
  const [marketData, setMarketData] = useState(null); 
  const [logs, setLogs] = useState([]);
  const [strategyState, setStrategyState] = useState({
    lastAction: null,
    lastActionPrice: null,
    successfulTrades: 0,
    failedTrades: 0,
    history: []
  });

  const addLog = (message, type = 'info') => {
    setLogs(prevLogs => [...prevLogs.slice(-49), {
      timestamp: new Date().toLocaleTimeString(),
      message,
      type
    }]);
  };

  const STRATEGY_PARAMS = {
    DIP_THRESHOLD: -10,
    RIP_THRESHOLD: 5,
    VOLUME_MULTIPLIER_DIP: 1.5,
    VOLUME_MULTIPLIER_RIP: 2,
    MIN_LIQUIDITY_USD: 10000,
    MAX_SLIPPAGE: 2,
    COOLDOWN_PERIOD: 300000
  };

  const testBuy = async () => {
    try {
      addLog('Testing buy transaction...');
      console.log('Testing buy with:', {
        mint: agent.settings.tradingPairs[0],
        amount: agent.settings.maxTradeAmount
      });

      const success = await executeTrade(
        'buy', 
        agent.settings.tradingPairs[0], 
        agent.settings.maxTradeAmount
      );

      if (success) {
        addLog('Test buy successful!', 'success');
        toast.success('Test buy successful!');
      }
    } catch (error) {
      addLog(`Test buy failed: ${error.message}`, 'error');
      console.error('Test buy failed:', error);
      toast.error('Test buy failed: ' + error.message);
    }
  };

  const analyzeDipRip = async (mint) => {
    if (!mint) {
      addLog('No token address configured', 'error');
      toast.error('No token address configured');
      return null;
    }

    try {
      addLog('Fetching market data...');
      const response = await fetch(
        `https://api.dexscreener.com/latest/dex/tokens/${mint}`
      );

      if (!response.ok) {
        addLog(`API error: ${response.status}`, 'error');
        toast.error(`API error: ${response.status}`);
        return null;
      }

      const data = await response.json();
      
      if (!data.pairs || data.pairs.length === 0) {
        addLog('No valid pair data found for token', 'error');
        toast.error('No valid pair data found for token');
        return null;
      }

      const pair = data.pairs[0];
      const metrics = {
        volume24h: parseFloat(pair.volume?.h24 || 0),
        volume1h: parseFloat(pair.volume?.h1 || 0),
        priceChange24h: parseFloat(pair.priceChange?.h24 || 0),
        priceChange1h: parseFloat(pair.priceChange?.h1 || 0),
        liquidityUsd: parseFloat(pair.liquidity?.usd || 0),
        currentPrice: parseFloat(pair.priceUsd || 0)
      };

      setMarketData(metrics);
      addLog(`Market data updated - Price: $${metrics.currentPrice}, 1h Change: ${metrics.priceChange1h}%`);

      if (metrics.liquidityUsd < STRATEGY_PARAMS.MIN_LIQUIDITY_USD) {
        addLog(`Insufficient liquidity: $${metrics.liquidityUsd}`, 'warning');
        toast.warn('Insufficient liquidity for safe trading');
        return null;
      }

      const volumeIncreaseMultiplier = metrics.volume1h / (metrics.volume24h / 24);
      const isDip = metrics.priceChange1h <= STRATEGY_PARAMS.DIP_THRESHOLD && 
                   volumeIncreaseMultiplier >= STRATEGY_PARAMS.VOLUME_MULTIPLIER_DIP;
      const isRip = metrics.priceChange1h >= STRATEGY_PARAMS.RIP_THRESHOLD && 
                   volumeIncreaseMultiplier >= STRATEGY_PARAMS.VOLUME_MULTIPLIER_RIP;

      addLog(`Analysis - Volume Multiplier: ${volumeIncreaseMultiplier.toFixed(2)}x, isDip: ${isDip}, isRip: ${isRip}`);

      return {
        isDip,
        isRip,
        currentPrice: metrics.currentPrice,
        liquidityUsd: metrics.liquidityUsd,
        metrics: {
          priceChange1h: metrics.priceChange1h,
          volumeIncreaseMultiplier
        }
      };

    } catch (error) {
      addLog(`Error analyzing token: ${error.message}`, 'error');
      console.error('Error analyzing token:', error);
      toast.error('Failed to analyze market conditions');
      return null;
    }
  };

  const executeTrade = useCallback(async (action, mint, amount) => {
    const timeSinceLastAction = Date.now() - (strategyState.lastAction?.timestamp || 0);
    
    if (timeSinceLastAction < STRATEGY_PARAMS.COOLDOWN_PERIOD) {
      const cooldownRemaining = Math.ceil((STRATEGY_PARAMS.COOLDOWN_PERIOD - timeSinceLastAction) / 1000);
      addLog(`Cooling down - ${cooldownRemaining}s remaining`, 'warning');
      toast.info(`Cooling down - next trade available in ${cooldownRemaining} seconds`);
      return false;
    }

    try {
      addLog(`Executing ${action} trade for ${amount} tokens...`);
      console.log('Attempting trade:', { action, mint, amount });

      const result = action === 'buy' 
        ? await handleBuy(mint, amount)
        : await handleSell(mint, amount);
      console.log('Trade result:', result);

      if (result) {
        const newTrade = {
          type: action,
          timestamp: Date.now(),
          price: result.price,
          amount: amount,
          marketData: { ...marketData }
        };

        setStrategyState(prev => ({
          ...prev,
          lastAction: newTrade,
          successfulTrades: prev.successfulTrades + 1,
          history: [...prev.history, newTrade].slice(-10)
        }));
        addLog(`${action.toUpperCase()} successful at $${result.price}`, 'success');
        return true;
      }
    } catch (error) {
      addLog(`Trade failed: ${error.message}`, 'error');
      console.error('Trade execution error:', {
        action,
        mint,
        amount,
        error: error.message
      });
      setStrategyState(prev => ({
        ...prev,
        failedTrades: prev.failedTrades + 1
      }));
      toast.error(`Trade failed: ${error.message}`);
    }
    return false;
  }, [handleBuy, handleSell, strategyState, marketData]);

  useEffect(() => {
    let interval;
    if (isActive && agent.settings?.strategyEnabled && agent.settings?.tradingPairs?.[0]) {
      addLog('Strategy activated - Starting market analysis...');
      toast.info('Strategy activated - Starting market analysis...', {
        autoClose: 3000
      });
      interval = setInterval(async () => {
        if (!isRunning) {
          setIsRunning(true);
          try {
            addLog('Running market analysis...');
            const analysis = await analyzeDipRip(agent.settings.tradingPairs[0]);
            if (analysis) {
              const tradeAmount = agent.settings.maxTradeAmount || '0.1';
              console.log('Market Analysis:', {
                isDip: analysis.isDip,
                isRip: analysis.isRip,
                priceChange: analysis.metrics.priceChange1h,
                volume: analysis.metrics.volumeIncreaseMultiplier
              });
              
              if (analysis.isDip) {
                addLog('Dip detected! Initiating buy...', 'info');
                toast.info('Dip detected! Initiating buy...', {
                  icon: <TrendingDown />
                });
                const success = await executeTrade('buy', agent.settings.tradingPairs[0], tradeAmount);
                if (success) {
                  toast.success('Buy order executed successfully', {
                    icon: <AttachMoney />
                  });
                }
              } else if (analysis.isRip) {
                addLog('Rip detected! Initiating sell...', 'info');
                toast.info('Rip detected! Initiating sell...', {
                  icon: <TrendingUp />
                });
                const success = await executeTrade('sell', agent.settings.tradingPairs[0], tradeAmount);
                if (success) {
                  toast.success('Sell order executed successfully', {
                    icon: <AttachMoney />
                  });
                }
              }
            }
            setLastCheck(new Date());
          } catch (error) {
            addLog(`Strategy execution error: ${error.message}`, 'error');
            console.error('Strategy execution error:', error);
            toast.error('Strategy execution failed');
          } finally {
            setIsRunning(false);
          }
        }
      }, 60000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        addLog('Strategy monitoring stopped');
        toast.info('Strategy monitoring stopped');
      }
    };
  }, [isActive, agent, executeTrade, isRunning]);

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Strategy Statistics
        </Typography>
        <Box sx={{ 
          width: 10, 
          height: 10, 
          borderRadius: '50%', 
          bgcolor: isRunning ? 'success.main' : 'grey.500',
          animation: isRunning ? 'pulse 2s infinite' : 'none',
          '@keyframes pulse': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0.5 },
            '100%': { opacity: 1 },
          }
        }} />
        <Button 
          variant="contained" 
          color="warning" 
          size="small"
          onClick={testBuy}
          disabled={!agent.settings?.tradingPairs?.[0]}
        >
          Test Buy
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>Performance</Typography>
            <Typography>Successful Trades: {strategyState.successfulTrades}</Typography>
            <Typography>Failed Trades: {strategyState.failedTrades}</Typography>
            <Typography>Last Check: {lastCheck?.toLocaleString()}</Typography>
            <Typography>Status: {isRunning ? 'Analyzing Market' : 'Waiting'}</Typography>
          </Grid>
          {marketData && (
            <Grid item xs={6}>
              <Typography variant="subtitle1" gutterBottom>Market Data</Typography>
              <Typography>Price Change (1h): {marketData.priceChange1h.toFixed(2)}%</Typography>
              <Typography>Volume (24h): ${marketData.volume24h.toLocaleString()}</Typography>
              <Typography>Liquidity: ${marketData.liquidityUsd.toLocaleString()}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>Activity Log</Typography>
            <Box sx={{ 
              maxHeight: 200, 
              overflow: 'auto', 
              bgcolor: 'background.paper',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              p: 1
            }}>
              {logs.map((log, index) => (
                <Typography 
                  key={index} 
                  variant="body2" 
                  sx={{ 
                    color: log.type === 'error' ? 'error.main' : 
                           log.type === 'warning' ? 'warning.main' :
                           log.type === 'success' ? 'success.main' : 
                           'text.primary',
                    py: 0.5
                  }}
                >
                  [{log.timestamp}] {log.message}
                </Typography>
              ))}
            </Box>
          </Grid>
          {strategyState.history.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>Recent Trades</Typography>
              <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                {strategyState.history.map((trade, index) => (
                  <Box key={index} sx={{ borderBottom: 1, borderColor: 'divider', py: 1 }}>
                    <Typography variant="body2">
                      {trade.type.toUpperCase()} - {new Date(trade.timestamp).toLocaleString()}
                    </Typography>
                    <Typography variant="caption">
                      Amount: {trade.amount} @ ${trade.price}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DipRipStrategy;

