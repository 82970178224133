import React from 'react';
import { AutoGraph } from '@mui/icons-material';
import PremiumFeature from '../PremiumFeature';
import AutoTPSLModal from './AutoTPSLModal';
const AutoTPSLButton = ({ 
  publicKey, 
  tradingWallet, 
  handleBuy, 
  handleAutoTPSLSell,
  handleBuyWithRaydium,
  isPremium,
  handleSell
}) => {
  const [showModal, setShowModal] = React.useState(false);
  
  // Load trade settings from localStorage with fallback
  const [tradeSettings, setTradeSettings] = React.useState(() => {
    const savedSettings = localStorage.getItem('tradeSettings');
    return savedSettings 
      ? JSON.parse(savedSettings) 
      : {
          priorityFee: 0.00001,
          slippage: 15,
          amount: 0.1
        };
  });

  // Update localStorage whenever trade settings change
  React.useEffect(() => {
    localStorage.setItem('tradeSettings', JSON.stringify(tradeSettings));
  }, [tradeSettings]);

  const slippage = tradeSettings.slippage; 
  const priorityFee = tradeSettings.priorityFee;
  return (
    <>
      <PremiumFeature
        publicKey={publicKey}
        onClick={() => setShowModal(true)}
        startIcon={<AutoGraph />}
        sx={{
          background: 'linear-gradient(to right, #3b82f6, #1d4ed8)',
          '&:hover': {
            background: 'linear-gradient(to right, #2563eb, #1e40af)',
          },
          color: 'white'
        }}
      >
        Auto TP/SL
      </PremiumFeature>

      <AutoTPSLModal
        open={showModal}
        onClose={() => setShowModal(false)}
        handleBuy={(mintAddress, amount) => {
          // Sanitize mint address to ensure it's base58
          const sanitizedMintAddress = mintAddress.replace(/[^A-Za-z0-9]/g, '');
          return handleBuy(sanitizedMintAddress, amount);
        }}
        handleBuyWithRaydium={(mintAddress, amount, slippage, priorityFee) => {
          // Sanitize mint address to ensure it's base58
          const sanitizedMintAddress = mintAddress.replace(/[^A-Za-z0-9]/g, '');
          return handleBuyWithRaydium(sanitizedMintAddress, amount, slippage, priorityFee);
        }}
        handleAutoTPSLSell={(mintAddress, amount) => {
          // Sanitize mint address to ensure it's base58
          const sanitizedMintAddress = mintAddress.replace(/[^A-Za-z0-9]/g, '');
          return handleAutoTPSLSell(sanitizedMintAddress, amount);
        }}
        handleSell={(mintAddress, amount) => {
          // Sanitize mint address to ensure it's base58
          const sanitizedMintAddress = mintAddress.replace(/[^A-Za-z0-9]/g, '');
          return handleSell(sanitizedMintAddress, amount);
        }}
        publicKey={publicKey}
        tradingWallet={tradingWallet}
        tradeSettings={tradeSettings}
        setTradeSettings={setTradeSettings}
        isPremium={isPremium}
      />
    </>
  );
};
export default AutoTPSLButton;