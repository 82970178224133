import React, { useState } from 'react';
import { 
  IconButton, 
  Menu, 
  MenuItem, 
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { EmojiEvents, Star } from '@mui/icons-material';

const RewardsMenu = ({
  setIsAchievementsOpen,
  navigate
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const rewardItems = [
    {
      label: 'View Achievements',
      icon: <EmojiEvents />,
      onClick: () => {
        setIsAchievementsOpen(true);
        handleClose();
      }
    },
    {
      label: 'Open Loot Box',
      icon: <EmojiEvents />,
      onClick: () => {
        navigate('/lootbox');
        handleClose();
      }
    },
    {
      label: 'Exclusive Content',
      icon: <EmojiEvents />,
      onClick: () => {
        navigate('/gated-content');
        handleClose();
      }
    }
  ];

  const buttonSx = {
    width: '100%',
    py: 1.5,
    mb: 1,
    background: 'linear-gradient(to right, #fbbf24, #d97706)',
    '&:hover': {
      background: 'linear-gradient(to right, #d97706, #b45309)',
    },
    textTransform: 'none',
    color: 'white',
    fontWeight: 600
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      
        <>
          <Button
            onClick={handleMenuClick}
            startIcon={<Star />}
            variant="contained"
            sx={{
              ...buttonSx,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography>Rewards & Achievements</Typography>
            <MenuIcon />
          </Button>
          
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                width: '90%',
                maxWidth: '300px',
                background: 'linear-gradient(135deg, rgba(17, 24, 39, 0.95), rgba(0, 0, 0, 0.98))',
                mt: 1,
                border: '1px solid rgba(251, 191, 36, 0.2)',
                boxShadow: '0 4px 20px rgba(251, 191, 36, 0.15)'
              }
            }}
          >
            {rewardItems.map((item, index) => (
              <MenuItem 
                key={index} 
                onClick={item.onClick}
                sx={{
                  color: 'white',
                  '&:hover': {
                    background: 'rgba(251, 191, 36, 0.1)',
                  },
                  gap: 2,
                  py: 1.5
                }}
              >
                {item.icon}
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      
    </Box>
  );
};

export default RewardsMenu;