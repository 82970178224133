import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon, Calculate } from '@mui/icons-material';
import BnnaConverter from './BnnaConverter';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'linear-gradient(135deg, rgba(197, 158, 87, 0.1), rgba(50, 46, 41, 0.9))',
    backdropFilter: 'blur(20px)',
    border: '2px solid rgba(254, 255, 235, 0.2)',
    borderRadius: theme.spacing(2),
    maxWidth: '600px',
    width: '95%',
    margin: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-50%',
      left: '-50%',
      width: '200%',
      height: '200%',
      background: 'radial-gradient(circle, rgba(254, 255, 235, 0.1) 0%, transparent 70%)',
      zIndex: 0,
      pointerEvents: 'none',
    }
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: '#FEFFEB',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: "'Press Start 2P', cursive",
  textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
  borderBottom: '1px dashed rgba(197, 158, 87, 0.3)',
  padding: theme.spacing(2),
  position: 'relative',
  zIndex: 1
}));

const TriggerButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(135deg, #C59E57, #9B7940)',
  color: '#322E29',
  padding: '10px 24px',
  borderRadius: '12px',
  fontSize: '1rem',
  textTransform: 'none',
  fontFamily: "'Press Start 2P', cursive",
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(135deg, #FEFFEB, #C59E57)',
    transform: 'translateY(-3px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '8px 16px',
    fontSize: '0.9rem'
  }
}));

const BnnaConverterModal = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  return (
    <>
      <TriggerButton
        onClick={handleOpen}
        startIcon={<Calculate />}
        sx={{
          marginTop: '10px',
          width: '100%',
          py: { xs: 1, sm: 1.5 },
          background: 'linear-gradient(135deg, #C59E57, #9B7940)',
          '&:hover': {
            background: 'linear-gradient(135deg, #FEFFEB, #C59E57)',
          }
        }}
      >
        BNNA Calculator
      </TriggerButton>

      <StyledDialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown
      >
        <StyledDialogTitle>
          BNNA/USD Calculator
          <IconButton
            onClick={handleClose}
            sx={{ 
              color: '#C59E57',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'rotate(90deg)',
                color: '#FEFFEB'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent 
          sx={{ 
            p: { xs: 2, sm: 3 },
            position: 'relative',
            zIndex: 1,
            backgroundColor: 'rgba(66, 61, 54, 0.3)',
            minHeight: fullScreen ? 'calc(100vh - 120px)' : 'auto'
          }}
        >
          <BnnaConverter />
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default BnnaConverterModal;