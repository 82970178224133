import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Slider,
  TextField,
  InputAdornment,
  Stack,
  Divider,
  Tooltip,
  IconButton, Chip,  Button, ButtonGroup
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(145deg, rgba(25, 118, 210, 0.05), rgba(25, 118, 210, 0.1))',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  position: 'relative',
  overflow: 'visible',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    background: 'linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0))',
    pointerEvents: 'none'
  }
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    background: 'linear-gradient(to right, #1976d2, #64b5f6)'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  }
}));

const SettingSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  background: 'rgba(255, 255, 255, 0.05)',
  marginBottom: theme.spacing(2)
}));

const PRIORITY_FEE_OPTIONS = [
  { label: 'Low', value: 0.000005 },
  { label: 'Medium', value: 0.00001 },
  { label: 'High', value: 0.00002 },
  { label: 'Custom', value: 'custom' }
];

const TRADE_AMOUNT_OPTIONS = [
  { label: '0.01 SOL', value: 0.01 },
  { label: '0.05 SOL', value: 0.05 },
  { label: '0.1 SOL', value: 0.1 },
  { label: 'Custom', value: 'custom' }
];

const TradeSettings = ({ 
  priorityFee, 
  setPriorityFee, 
  slippage, 
  setSlippage, 
  amount, 
  setAmount,
  onIncrementPriorityFee,  // Add these new props
  onDecrementPriorityFee, 
}) => {
  const [customPriorityFee, setCustomPriorityFee] = useState(priorityFee);
  const [customAmount, setCustomAmount] = useState(amount);
  const [showCustomPriorityFee, setShowCustomPriorityFee] = useState(false);
  const [showCustomAmount, setShowCustomAmount] = useState(false);

  const handlePriorityFeeSelect = (value) => {
    if (value === 'custom') {
      setShowCustomPriorityFee(true);
    } else {
      setShowCustomPriorityFee(false);
      setPriorityFee(value);
    }
  };

  const handleAmountSelect = (value) => {
    if (value === 'custom') {
      setShowCustomAmount(true);
    } else {
      setShowCustomAmount(false);
      setAmount(value);
    }
  };
  return (
    <StyledCard>
      <CardContent>
        <Stack spacing={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <SettingsIcon sx={{ color: 'primary.main' }} />
            <Typography variant="h6" color="white" fontWeight="500">
              Trade Settings
            </Typography>
          </Box>

          <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />

          <SettingSection>
            <Typography color="white" variant="subtitle2" gutterBottom>
              Priority Fee
            </Typography>
            <Box sx={{ mb: 2 }}>
              <ButtonGroup fullWidth variant="outlined" size="small">
                {PRIORITY_FEE_OPTIONS.map((option) => (
                  <Button
                    key={option.label}
                    onClick={() => handlePriorityFeeSelect(option.value)}
                    sx={{
                      color: 'white',
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                      backgroundColor: priorityFee === option.value ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      }
                    }}
                  >
                    {option.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            {showCustomPriorityFee && (
              <TextField
                type="number"
                value={customPriorityFee}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  setCustomPriorityFee(value);
                  setPriorityFee(value);
                }}
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">SOL</InputAdornment>,
                  sx: { color: 'white' }
                }}
              />
            )}
          </SettingSection>

          <SettingSection>
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
    <Typography color="white" variant="subtitle2">
      Slippage Tolerance
    </Typography>
    <Typography color="primary.light" variant="body2">
      {Math.round(slippage)}%
    </Typography>
  </Box>
  <StyledSlider
    value={Math.round(slippage)} // Force whole number
    onChange={(_, value) => setSlippage(Math.round(value))} // Force whole number
    min={1}
    max={100}
    step={1} // Force whole number steps
    valueLabelDisplay="auto"
    valueLabelFormat={value => `${Math.round(value)}%`} // Force whole number display
  />
</SettingSection>

          <SettingSection>
            <Typography color="white" variant="subtitle2" gutterBottom>
              Trade Amount
            </Typography>
            <Box sx={{ mb: 2 }}>
              <ButtonGroup fullWidth variant="outlined" size="small">
                {TRADE_AMOUNT_OPTIONS.map((option) => (
                  <Button
                    key={option.label}
                    onClick={() => handleAmountSelect(option.value)}
                    sx={{
                      color: 'white',
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                      backgroundColor: amount === option.value ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      }
                    }}
                  >
                    {option.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            {showCustomAmount && (
              <TextField
                type="number"
                value={customAmount}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  setCustomAmount(value);
                  setAmount(value);
                }}
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">SOL</InputAdornment>,
                  sx: { color: 'white' }
                }}
              />
            )}
          </SettingSection>
        </Stack>
      </CardContent>
    </StyledCard>
  );
};

export default TradeSettings;