import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Chip,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Timeline,
  TrendingUp,
  Warning,
  Info,
  ShowChart
} from '@mui/icons-material';

// First, get the API URL from environment
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

const AnalyticsCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    background: 'rgba(255, 255, 255, 0.08)',
    boxShadow: '0 8px 24px rgba(255, 215, 0, 0.2)',
  },
}));

const MetricChip = styled(Chip)(({ status }) => ({
  background: status === 'positive' 
    ? 'rgba(46, 125, 50, 0.2)'
    : status === 'negative'
    ? 'rgba(211, 47, 47, 0.2)'
    : 'rgba(255, 255, 255, 0.1)',
  color: status === 'positive'
    ? '#4caf50'
    : status === 'negative'
    ? '#f44336'
    : '#ffffff',
  margin: '4px',
}));

const TokenAnalytics = ({ projectData, analysis, loading, error, handleAnalyzeClick }) => {
  // Remove local state management since it's handled by parent
  if (loading) {
      return (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress sx={{ color: '#FFD700' }} />
          </Box>
      );
  }

  if (error) {
      return (
          <Box sx={{ textAlign: 'center', color: '#f44336', p: 3 }}>
              <Warning /> Error: {error}
          </Box>
      );
  }

  // Only render if we have analysis data
  if (!analysis) return null;

  return (
    <AnalyticsCard>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <ShowChart sx={{ mr: 1, color: '#FFD700' }} />
          <Typography variant="h6" sx={{ color: 'white' }}>
            Launch Analysis
          </Typography>
        </Box>
        <Button 
              variant="contained" 
              onClick={handleAnalyzeClick}
              disabled={loading || !projectData?.name}
              sx={{ bgcolor: '#FFD700', color: 'black', '&:hover': { bgcolor: '#E6C200' } }}
            >
              {loading ? 'Analyzing...' : 'Analyze Token'}
            </Button>

        <Grid container spacing={3}>
          {/* Market Sentiment */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ color: '#a0a0a0', mb: 1 }}>
                Market Sentiment
              </Typography>
              <Typography variant="body2" sx={{ color: 'white' }}>
                {analysis?.marketSentiment || 'Analyzing...'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
                        <Typography variant="subtitle2" sx={{ color: '#a0a0a0', mb: 1 }}>
                            Current Market Trends
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {analysis.trends?.map((trend, index) => (
                                <Tooltip key={index} title={trend}>
                                    <MetricChip
                                        label={trend.replace(/^- /, '')}
                                        status="neutral"
                                    />
                                </Tooltip>
                            ))}
                        </Box>
                    </Grid>

          {/* Launch Timing */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ color: '#a0a0a0', mb: 1 }}>
                Launch Timing
              </Typography>
              <Typography variant="body2" sx={{ color: 'white' }}>
                {analysis?.timing || 'Analyzing...'}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
          </Grid>

          {/* Success Factors */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ color: '#a0a0a0', mb: 1 }}>
              Success Factors
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {analysis?.successFactors?.map((factor, index) => (
                <Tooltip key={index} title={factor}>
                  <MetricChip
                    label={factor}
                    status="positive"
                  />
                </Tooltip>
              ))}
            </Box>
          </Grid>

          {/* Risk Factors */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ color: '#a0a0a0', mb: 1 }}>
              Risk Factors
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {analysis?.riskFactors?.map((factor, index) => (
                <Tooltip key={index} title={factor}>
                  <MetricChip
                    label={factor}
                    status="negative"
                  />
                </Tooltip>
              ))}
            </Box>
          </Grid>

          {/* Growth Potential */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ color: '#a0a0a0', mb: 1 }}>
              Growth Potential & Price Projection
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {analysis?.priceProjection || 'Analyzing...'}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption" sx={{ color: '#a0a0a0' }}>
            Based on analysis of current market trends
          </Typography>
          <Tooltip title="AI-powered launch analysis. Results may vary.">
            <IconButton size="small" sx={{ color: '#a0a0a0' }}>
              <Info />
            </IconButton>
          </Tooltip>
        </Box>
      </CardContent>
    </AnalyticsCard>
  );
};

export default TokenAnalytics;