// components/AdminWhitelist.js
import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Stack, List, ListItem, Divider } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const AdminWhitelist = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [type, setType] = useState('WHITELIST');
  const [bulkAddresses, setBulkAddresses] = useState('');
  const [whitelistStatus, setWhitelistStatus] = useState(null);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState(
    'System maintenance in progress. Some features may be unavailable.'
  );
  const [estimatedDuration, setEstimatedDuration] = useState('1 hour');
  const [userInfo, setUserInfo] = useState(null);
  const [lootboxQuantity, setLootboxQuantity] = useState(1);


  const handleAwardLootbox = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/award-lootbox`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          walletAddress,
          quantity: lootboxQuantity
        })
      });

      const data = await response.json();
      
      if (data.success) {
        toast.success(`Awarded ${lootboxQuantity} lootbox(es)`);
        handleFetchUser(); // Refresh user info to show updated lootbox count
      } else {
        toast.error(data.error || 'Failed to award lootboxes');
      }
    } catch (error) {
      toast.error('Failed to award lootboxes');
    }
  };
  const handleFetchUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${walletAddress}`);
      const data = await response.json();
      console.log(data);
      if (data) {
        setUserInfo(data);
        toast.success('User information fetched successfully');
      } else {
        toast.warning('User not found');
      }
    } catch (error) {
      toast.error('Failed to fetch user information');
    }
  };

  const handleMaintenanceToggle = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/maintenance`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          enabled: !maintenanceMode,
          message: maintenanceMessage,
          estimatedDuration
        })
      });
      const data = await response.json();
      if (data.success) {
        setMaintenanceMode(!maintenanceMode);
        toast.success(`Maintenance mode ${!maintenanceMode ? 'enabled' : 'disabled'}`);
      }
    } catch (error) {
      toast.error('Failed to toggle maintenance mode');
    }
  };

  const handleFetchWhitelist = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/premium/whitelist/${walletAddress}`);
      const data = await response.json();
      setWhitelistStatus(data);
      toast.success('Fetched whitelist status');
    } catch (error) {
      toast.error('Failed to fetch whitelist status');
    }
  };
 

  const handleSingleAdd = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/premium/add-whitelist`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          walletAddress,
          type,
          expiryDays: 30
        })
      });
      const data = await response.json();
      if (data.success) toast.success('User added to whitelist');
    } catch (error) {
      toast.error('Failed to add user');
    }
  };

  const handleBulkAdd = async () => {
    try {
      const addresses = bulkAddresses.split('\n').map(addr => addr.trim());
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/premium/add-whitelist-bulk`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          addresses,
          type,
          expiryDays: 30
        })
      });
      const data = await response.json();
      if (data.success) toast.success(`Added ${data.count} users to whitelist`);
    } catch (error) {
      toast.error('Failed to add users');
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h5">Admin Whitelist Management</Typography>
          {/* Add this new section */}
          <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            <Typography variant="h6" gutterBottom>Maintenance Mode Settings</Typography>
            <Stack spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={maintenanceMode}
                    onChange={handleMaintenanceToggle}
                    color="warning"
                  />
                }
                label="Maintenance Mode"
              />
              <TextField
                label="Maintenance Message"
                value={maintenanceMessage}
                onChange={(e) => setMaintenanceMessage(e.target.value)}
                fullWidth
                multiline
                rows={2}
              />
              <TextField
                label="Estimated Duration"
                value={estimatedDuration}
                onChange={(e) => setEstimatedDuration(e.target.value)}
                fullWidth
                placeholder="e.g., 1 hour"
              />
            </Stack>
          </Box>
          
          <Stack spacing={2}>
            <TextField 
              label="Wallet Address"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
            />
            <Button onClick={handleSingleAdd}>Add Single User</Button>
            <Button onClick={handleFetchWhitelist}>Check Whitelist Status</Button>
            <Button onClick={handleFetchUser}>Fetch User Info</Button>


          </Stack>
          {whitelistStatus && (
           <Box sx={{ mt: 2 }}>
             <Typography variant="h6">Whitelist Status:</Typography>
             <List>
               <ListItem>Is Whitelisted: {whitelistStatus.isWhitelisted ? 'Yes' : 'No'}</ListItem>
               <ListItem>Type: {whitelistStatus.type || 'N/A'}</ListItem>
             </List>
           </Box>
         )}
         <Divider sx={{ my: 2 }} />
          <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            <Typography variant="h6" gutterBottom>Lootbox Management</Typography>
            <Stack spacing={2} direction="row" alignItems="center">
              <TextField
                label="Quantity"
                type="number"
                value={lootboxQuantity}
                onChange={(e) => setLootboxQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                inputProps={{ min: 1 }}
                sx={{ width: '150px' }}
              />
              <Button 
                variant="contained" 
                onClick={handleAwardLootbox}
                disabled={!walletAddress}
              >
                Award Lootboxes
              </Button>
            </Stack>
          </Box>
          {userInfo && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">User Information:</Typography>
              <List>
                <ListItem>Points: {userInfo.points || 0}</ListItem>
                <ListItem>Premium: {userInfo.premium ? 'Yes' : 'No'}</ListItem>
                <ListItem>
                  Lootboxes: {userInfo.lootboxes?.quantity || 0}
                  {userInfo.lootboxes?.lastOpened && 
                    ` (Last opened: ${new Date(userInfo.lootboxes.lastOpened).toLocaleString()})`
                  }
                </ListItem>
                <ListItem>Created: {new Date(userInfo.createdAt).toLocaleDateString()}</ListItem>
                <ListItem>Last Login: {new Date(userInfo.lastLogin).toLocaleDateString()}</ListItem>
              </List>
            </Box>
          )}

          <Stack spacing={2}>
            <TextField
              multiline
              rows={4}
              label="Bulk Addresses (One per line)"
              value={bulkAddresses}
              onChange={(e) => setBulkAddresses(e.target.value)}
            />
            <Button onClick={handleBulkAdd}>Add Multiple Users</Button>
          </Stack>
        </Stack>
      </Paper>
      {userInfo && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">User Information:</Typography>
          <List>
            <ListItem>
              <Typography><strong>Basic Info:</strong></Typography>
            </ListItem>
            <ListItem>Points: {userInfo.points || 0}</ListItem>
            <ListItem>Premium: {userInfo.premium ? 'Yes' : 'No'}</ListItem>
            <ListItem>Created: {new Date(userInfo.createdAt).toLocaleDateString()}</ListItem>
            <ListItem>Last Login: {new Date(userInfo.lastLogin).toLocaleDateString()}</ListItem>

            <ListItem>
              <Typography><strong>Wallet Information:</strong></Typography>
            </ListItem>
            <ListItem>Connected Wallet: {userInfo.walletPublicKey || 'Not set'}</ListItem>
            {userInfo.tradingWallet && (
              <>
                <ListItem>Trading Wallet: {userInfo.tradingWallet.walletPublicKey || 'Not set'}</ListItem>
                <ListItem>Trading Wallet Created: {
                  userInfo.tradingWallet.createdAt ? 
                  new Date(userInfo.tradingWallet.createdAt).toLocaleString() : 
                  'Unknown'
                }</ListItem>
                <ListItem>Last Transaction: {
                  userInfo.tradingWallet.lastTransaction ? 
                  new Date(userInfo.tradingWallet.lastTransaction).toLocaleString() : 
                  'No transactions'
                }</ListItem>
              </>
            )}

            <ListItem>
              <Typography><strong>Activity & Stats:</strong></Typography>
            </ListItem>
            <ListItem>Total Transactions: {userInfo.stats?.totalTransactions || 0}</ListItem>
            <ListItem>Trading Volume: {userInfo.stats?.tradingVolume?.toFixed(2) || 0} SOL</ListItem>
            <ListItem>Success Rate: {
              userInfo.stats?.successRate ? 
              `${(userInfo.stats.successRate * 100).toFixed(1)}%` : 
              'N/A'
            }</ListItem>

            <ListItem>
              <Typography><strong>Rewards & Items:</strong></Typography>
            </ListItem>
            <ListItem>
              Lootboxes: {userInfo.lootboxes?.quantity || 0}
              {userInfo.lootboxes?.lastOpened && 
                ` (Last opened: ${new Date(userInfo.lootboxes.lastOpened).toLocaleString()})`
              }
            </ListItem>
            {userInfo.rewards && (
              <ListItem>
                Active Rewards: {Object.entries(userInfo.rewards).map(([key, value]) => 
                  `${key}: ${value}`
                ).join(', ')}
              </ListItem>
            )}

            <ListItem>
              <Typography><strong>Permissions & Status:</strong></Typography>
            </ListItem>
            <ListItem>Account Status: {userInfo.status || 'Active'}</ListItem>
            <ListItem>Permissions: {userInfo.permissions?.join(', ') || 'Standard'}</ListItem>
            {userInfo.restrictions && (
              <ListItem>
                Restrictions: {userInfo.restrictions.join(', ')}
              </ListItem>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default AdminWhitelist;